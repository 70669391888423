import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Link,
} from "@material-ui/core";

import { Button, Menu, MenuItem } from "@mui/material";
import i18n from "../../reactUtils/systemVariables/languageLocalization/global/language";

// Components
import logoHorAura from "../../assets/images/logo-aura-hor.png";
import logoHorUhoo from "../../assets/images/LogoUhooColor.png";

import { withTranslation } from "react-i18next";

import spainFlag from "../../assets/images/spain-flag.png";
import usFlag from "../../assets/images/united-states-flag.png";

// CSS
import "./css/HeaderComponent.scss";

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "en",
      anchorEl: null,
      open: false,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  changeToSpanish = (e) => {
    let newLang = "es";
    this.setState({ value: newLang, open: false });
    this.props.i18n.changeLanguage(newLang);
  };

  changeToEnglish = (e) => {
    let newLang = "en";
    this.setState({ value: newLang, open: false });
    this.props.i18n.changeLanguage(newLang);
  };

  render() {
    const { anchorEl, open } = this.state;
    const { userSessionInfo } = this.props;

    let logo = "";
    if (process.env.REACT_APP_ISAURA === "true") {
      logo = logoHorAura;
    } else {
      logo = logoHorUhoo;
    }
    const language = i18n.language.slice(0, 2);
    return (
      <div>
        <AppBar className="root" position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" size="medium">
              <Link to="/">
                <img id="headerLogo" src={logo} alt="Aura Logo" />
              </Link>
            </IconButton>

            {/* Language */}
            <div>
              <Button
                id="demo-positioned-button"
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={this.handleClick}
              >
                {language == "es" ? (
                  <img
                    src={spainFlag}
                    alt="spainFlag"
                    style={{ margin: "5%" }}
                  />
                ) : (
                  <img src={usFlag} alt="spainFlag" style={{ margin: "5%" }} />
                )}
                {language}
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={(e) => this.changeToSpanish(e)}>
                  <img
                    src={spainFlag}
                    alt="spainFlag"
                    style={{ margin: "1%" }}
                  />
                  <div className="language" style={{ marginLeft: "5%" }}>
                    Español
                  </div>
                </MenuItem>
                <MenuItem onClick={(e) => this.changeToEnglish(e)}>
                  <img src={usFlag} alt="spainFlag" style={{ margin: "1%" }} />
                  <div className="language" style={{ marginLeft: "5%" }}>
                    English
                  </div>
                </MenuItem>
              </Menu>
            </div>

            <Typography class="body1">
              <Link href="/account" className={"link"}>
                <div>
                  {userSessionInfo.name}{" "}
                  {userSessionInfo.lastName1 ? userSessionInfo.lastName1 : null}
                </div>
                <div class="body2">{userSessionInfo.organizationName}</div>
              </Link>
            </Typography>
            <Link href="/account" className={"link"}>
              <Avatar className="regular">
                {userSessionInfo && userSessionInfo.name
                  ? userSessionInfo.name[0]
                  : ""}
              </Avatar>
            </Link>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withTranslation()(HeaderComponent);
