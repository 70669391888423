import {
    s3Bucket,
} from "../reactUtils/systemVariables/CmsServerInformation";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

const REGION = "us-east-1"; 
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const { fromCognitoIdentityPool } = require("@aws-sdk/credential-provider-cognito-identity");

const s3Client = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: REGION }),
        identityPoolId: "us-east-1:8a148475-c244-48ef-adca-512c33ffadb6",
    }),
});


export async function getFile(path) {
    try {
        let response = path;
        return response;
    } catch (error) {
        return error;
    }
}

export async function updateLpSectionComponentNodeImage(file, key) {
   const params = {
       Bucket: s3Bucket,
       Key: key,
       Body: file,
   };

   try {
       const data = await s3Client.send(new PutObjectCommand(params));
       return data; // For unit tests.
   } catch (err) {
   }
}


//OLD METHOD
// export async function updateLpSectionComponentNodeImage(file, key) {
//     let formData = new FormData();
//     formData.append("file", file);
//     formData.append("bucket", s3Bucket);
//     formData.append("key", key);

//     try {
//         let response = await axios({
//             method: "POST",
//             url: host + port + "/auracms/storage/upload",
//             headers: {
//                 "content-type": "multipart/form-data",
//                 Authorization: "Bearer " + sessionStorage.getItem("access_token"),
//             },
//             data: formData,
//         });
//         return response;
//     } catch (error) {
//         return error;
//     }
// }