import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/userbygroup";

export async function disableUserByGroup(
   userByGroupId,
) {
   try {
      let response = await axios({
         method: 'DELETE',
         url: host + port + route + datasource + resource,
         params: {
            user_by_group_id: userByGroupId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}