import React from "react";

import { GeneralInput } from "../../../components/index";

//Localization
import { useTranslation } from "react-i18next";

export function EditOrganizationForm(props) {
  const { t } = useTranslation();
  return (
    <div className="row contentForm">
      {/* <div className="col-md-6 componentSpace">
            <GeneralInput
               text={Strings.basicWords.name}
               id="name"
               value={props.organization.name}
               placeholder={"Nombre"}
               isReq
               onChange={e => props.onChange(e)}
            />
         </div>
         <div className="col-md-6 componentSpace">
            <GeneralInput
               text={Strings.basicWords.description}
               id="description"
               value={props.organization.description}
               placeholder={"Descripción"}
               isReq={false}
               onChange={e => props.onChange(e)}
            />
         </div> */}
      <div className="row containerField" style={{ display: "flex" }}>
        <div className="fieldLeft">
          <GeneralInput
            id="phone"
            value={props.organization.phone ? props.organization.phone : ""}
            placeholder={t("basicWords.telephone")}
            onChange={(e) => props.onChange(e)}
            isReq
            maxLength={200}
          />
        </div>
        {/* <div className="fieldRigth">
          <GeneralInput
            id="name"
            value={props.organization.region}
            placeholder={Strings.basicWords.region}
            isReq
            onChange={(e) => props.onChange(e)}
            maxLength={200}
          />
        </div> */}
      </div>
      {/* <div className="col-md-6 componentSpace">
            <GeneralInput
               text={"Dirección"}
               id="address"
               value={props.organization.address}
               placeholder={"Dirección"}
               isReq
               onChange={e => props.onChange(e)}
            />
         </div>
         <div className="col-md-6 componentSpace">
            <SelectSingle
               defaultValue={props.organization.countryId}
               text="País"
               isReq={true}
               isDisabled={false}
               isLoading={false}
               name={"countryId"}
               onChange={(option, event) => props.onSelect(option, event)}
               options={props.countries}
            />
         </div>
         <div className="col-md-6 componentSpace">
            <SelectSingle
               defaultValue={props.organization.regionId}
               text="Región"
               isReq={true}
               isDisabled={false}
               isLoading={false}
               name={"regionId"}
               onChange={(option, event) => props.onSelect(option, event)}
               options={props.regions}
            />
         </div>
         <div className="col-md-6 componentSpace">
            <SelectSingle
               defaultValue={props.organization.cityId}
               text="Ciudad"
               isReq={true}
               isDisabled={false}
               isLoading={false}
               name={"cityId"}
               onChange={(option, event) => props.onSelect(option, event)}
               options={props.cities}
            />
         </div>
         <div className="col-md-6 componentSpace">
            <SelectSingle
               defaultValue={props.organization.organizationTypeId}
               text="Tipo de organización"
               isReq={true}
               isDisabled={false}
               isLoading={false}
               name={"organizationTypeId"}
               onChange={(option, event) => props.onSelect(option, event)}
               options={props.organizationTypes}
            />
         </div>
         <div className="col-md-6 componentSpace">
            <SelectSingle
               defaultValue={props.organization.lptypeId}
               text="Tipo de landing page"
               isReq={false}
               isDisabled={false}
               isLoading={false}
               name={"lptypeId"}
               onChange={(option, event) => props.onSelect(option, event)}
               options={props.lpTypes}
            />
         </div> */}
    </div>
  );
}
