import {
   USER_INFORMATION
} from '../../actions'

export function userInfo(state = {}, action) {
   switch (action.type) {
      case USER_INFORMATION:
         return Object.assign({}, state, {userObj: action.payload})
      default:
         return state
   }
}