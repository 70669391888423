import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Card,
  Checkbox,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Edit, Trash2, Home } from "react-feather";

// Queries
import {
  getOrganizationById,
  getPrivileges,
  insertRole,
} from "../../actions/index";

// Component
import {
  Title,
  GeneralInput,
  Breadcrumb,
  NotificationModal,
  ButtonsFooterComponent
} from "../../components/index";

//Localization
import { withTranslation } from 'react-i18next';

const tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;

class AddRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      organizationId: null,
      organizationName: null,

      roleToAdd: {
        id: null,
        name: "",
        description: "",
        privileges: [],
      },

      // Checkbox
      selected: [],
      selectAll: false,
      selectOne: false,

      addButtonDisabled: true,
      addButtonLoading: false,
      loadingInfo: false,
      registers: [],

      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { location } = this.props;

    const organizationId = location
      ? location.props
        ? location.props.organizationId
        : null
      : null;
    const organizationName = location.props
      ? location.props.organizationName
      : null;

    const { props } = location;

    if (organizationName == null) {
      this.getOrganizationById(organizationId);
    }

    if (props) {
      const { organizationId } = props;
      const { organizationName } = props;

      this.getPrivilegesFunction();

      this.setState({
        organizationId,
        organizationName,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getOrganizationById(id) {
    const response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        this.setState({
          organizationName: response.data.data.name,
        });
      }
    }
  }

  async getPrivilegesFunction() {
    let registers = [];
    const response = await getPrivileges();
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        registers = response.data.data;
      }
      this.setState({
        registers,
        loadingInfo: false,
      });
    }
  }

  async postRole(infoToAdd) {
    const response = await insertRole(infoToAdd);
    const {t} = this.props

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal:
          t('modal.internalServerErrorContactSupport'),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.successfulCreation'),
        contenNotificationModal: t('modal.roleSuccesfullyAdded'),
        typeNotificationModal: "success",
      });
      this.setState({
        roleToAdd: {
          id: null,
          name: "",
          description: "",
          privileges: [],
        },
        registers: [],
      });
    }
    this.setState({
      openNotificationModal: true,
      addButtonLoading: false,
    });
  }

  // Events
  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { roleToAdd } = { ...this.state };

    roleToAdd[id] = value;

    if (
      roleToAdd.name.length > 0 &&
      this.state.roleToAdd.privileges.length > 0
    ) {
      this.setState({
        addButtonDisabled: false,
      });
    } else {
      this.setState({
        addButtonDisabled: true,
      });
    }

    this.setState({
      roleToAdd,
    });
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const { roleToAdd } = { ...this.state };

      if (roleToAdd.name.length > 0 && selectedRows.length > 0) {
        this.setState({
          addButtonDisabled: false,
        });
      } else {
        this.setState({
          addButtonDisabled: true,
        });
      }
      roleToAdd.privileges = selectedRows;

      this.setState({
        roleToAdd,
      });
    },
  };

  saveRoleEvent = () => {
    const { organizationId } = this.state;
    let infoToAdd = {
      id: null,
      name: "",
      description: "",
      privilegeIds: [],
      organizationId,
    };
    const { roleToAdd } = { ...this.state };
    this.setState({
      addButtonLoading: true,
    });
    const {t} = this.props

    roleToAdd.name = roleToAdd.name.trim();
    roleToAdd.description = roleToAdd.description.trim();

    const privilegeIds = [];

    roleToAdd.privileges.map((obj) => {
      privilegeIds.push(obj.id);
    });

    infoToAdd = {
      id: null,
      name: roleToAdd.name,
      description: roleToAdd.description,
      privilegeIds,
      organizationId,
    };

    if (infoToAdd.name.length === 0) {
      infoToAdd.name = "";
    }
    if (infoToAdd.description.length === 0) {
      infoToAdd.description = "";
    }
    if (infoToAdd.privilegeIds.length === 0) {
      infoToAdd.privilegeIds = [];
    }

    if (
      infoToAdd.name === "" ||
      infoToAdd.description === "" ||
      infoToAdd.privilegeIds.length === 0
    ) {
      this.setState({
        addButtonLoading: false,
      });
      this.setState({
        titleNotificationModal: t('modal.wrongFile'),
        contenNotificationModal: t('modal.fieldEmpty'),
        typeNotificationModal: "error",
      });
    } else {
      // this.postRole(infoToAdd)
    }
    this.setState({
      openNotificationModal: true,
    });
  };

  warning = () => {
    const {t} = this.props
    this.setState({
      titleNotificationModal: t('modal.organizationNotSelected'),
      contenNotificationModal: t('modal.NoSectionSelected'),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({
      isLoadingButton: false,
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  // Checkbox
  // Select all
  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedAll = this.state.registers.map((item) => item.name);
      this.setState({
        selected: selectedAll,
      });
    } else {
      this.setState({
        selected: [],
      });
    }
    return this.state.selected;
  };

  // Select one
  handleSelectOneClick = (event, name) => {
    const selected = this.state.selected;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selected: newSelected,
    });
  }
  // Close modal
  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  render() {
    const {
      roleToAdd,
      redirectBack,
      organizationName,
      registers,
      addButtonLoading,
      addButtonDisabled,
      selected,
      isLoadingButton,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const {t} = this.props

    if (redirectBack) {
      return <Redirect to="/roles" />;
    }

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Card className="card">
          <Backdrop
            style={{ color: "#510f8a", zIndex: "5" }}
            open={isLoadingButton}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          
          {/* Header */}
          <div className="row componentSpace">
            <Breadcrumb
              principal={t('generalUser.roles')}
              icon={<Home />}
              secondary={t('sections.home')}
            />
          </div>
          {/* Title */}
          <div className="row componentSpace">
            <Title title={t('generalUser.generalParameters')} />
          </div>

          {/* Inputs */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="col-md-4"
              style={{
                width: "20vw",
                paddingLeft: "1.1%",
              }}
            >
              <GeneralInput
                text={t('organization.organization')}
                id="organizationType"
                value={organizationName ? organizationName : ""}
                placeholder={t('organization.organization') + "*"}
                isReq
              />
            </div>
            <div className="col-md-6 componentSpace" style={{
                width: "20vw",
                paddingLeft: "1.1%",
              }}>
              <GeneralInput
                text={t('basicWords.name') + "*"}
                id="name"
                value={roleToAdd.name}
                defaultValue={roleToAdd.name}
                placeholder={t('basicWords.name') + "*"}
                isReq
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
            <div className="col-md-6 componentSpace" style={{
                width: "20vw",
                paddingLeft: "1%",
              }}>
              <GeneralInput
                text={t('basicWords.description') + "*"}
                id="description"
                value={roleToAdd.description}
                defaultValue={roleToAdd.description}
                placeholder={t('basicWords.description') + "*"}
                isReq
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
          </div>

          {/* Subtitle */}
          <div className="row componentSpace">
            <Title title={t('generalUser.availablePrivileges')} />
          </div>

          {/* Table */}
          <div className="row componentSpace">
            <div className="col-12">
              <TableContainer style={{ maxHeight: "440" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          onChange={this.handleSelectAllClick}
                          inputProps={{ "aria-label": "select all" }}
                        />
                      </TableCell>
                      <TableCell>{t('basicWords.description')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registers.map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          key={row.description}
                          onClick={(event) => {
                            this.handleSelectOneClick(event, row.name)
                          }}
                          selected={isItemSelected}
                        >
                          <TableCell>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-label": `${labelId}` }}
                            ></Checkbox>
                          </TableCell>
                          <TableCell>{row.description}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={null}
          FlagclassName={true}
          onClick={(e) => this.saveRoleEvent(e)}
          path="./roles"
        />
      </>
    );
  }
}
export default withTranslation()(AddRole)