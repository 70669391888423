import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/sectionlayoutstyle";

export async function getSectionLayoutStyle(lpId, sectionId) {
    try {
       let response = await axios.get(
          host + port + route + datasource + resource, {
          params: {
            lp_id: lpId,
            section_id: sectionId,
          },
          headers: {
             'Content-Type': 'application/json',
             'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
          }
       })
       return response;
    } catch (error) {
       return error
    }
 }

// export async function updateLpTheme(lpId, lpThemeId) {
//     try {
//        let response = await axios({
//           method: 'PUT',
//           url: host + port + route + datasource + resource,
//           params: {
//             lp_id: lpId,
//             lptheme_id: lpThemeId,
//           },
//           headers: {
//              'Content-Type': 'application/json',
//              'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
//           }
//        })
//        return response;
//     } catch (error) {
//        return error;
//     }
//  }