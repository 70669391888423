import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Card,
} from "@material-ui/core";
import { Edit, Trash2, Grid, CheckCircle, Plus } from "react-feather";

// Components
import {
  SelectSingle,
  Title,
  ButtonComponent,
  Breadcrumb,
  GeneralModal,
  NotificationModal,
} from "../../components/index";

// Queries
import {
  getOrganizationTypes,
  getOrganizationsByOrganizationTypeId,
  getGroupsPaged,
  deleteGroup,
} from "../../actions/index";

import { ValidatePermissionForComponentPart } from "../../reactUtils/generalUtils/validatePermissionForComponentPart";

//Localization
import { withTranslation } from 'react-i18next';

// const { Column } = Table;
const tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;

class GroupsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,

      organizationTypes: [],
      organizationTypeId: null,

      organizations: [],
      organizationId: null,
      organizationIdQuery: null,
      organizationName: null,

      loadingInfo: false,
      registers: [],

      // pagination
      registersAmount: 0,
      page: 1,
      initialPage: 1,
      pageSize: 10,
      attribute: "id",
      order: "ASC",

      openDialog: false,
      titleNotificationModal: "",
      openNotificationModal: false,
      contenNotificationModal: "",
      typeNotificationModal: ""
    };
  }

  componentDidMount() {
    const { page, pageSize, attribute, order } = this.state;

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const organizationId = sessionStorage.getItem("organization_id");

    if (parseInt(organizationId, 10) === 1) {
      this.getOrganizationTypesFunction();
    } else {
      this.getGroupsPagedFunction(
        organizationId,
        page,
        pageSize,
        attribute,
        order
      );
      this.setState({
        organizationTypeId: 2,
        organizationId,
        organizationIdQuery: organizationId,
        loadingInfo: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getOrganizationTypesFunction() {
    let organizationTypes = [];
    const response = await getOrganizationTypes();

    if (String(response).includes("Error:")) {
    } else {
      organizationTypes = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }
    this.setState({ organizationTypes });
  }

  async getOrganizationByOrganizationTypeId(userTypeId) {
    let organizations = [];
    const response = await getOrganizationsByOrganizationTypeId(userTypeId);
    if (String(response).includes("Error:")) {
    } else {
      organizations = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ organizations });
  }

  async getGroupsPagedFunction(
    organizationId,
    page,
    pageSize,
    attribute,
    order
  ) {
    let registers = this.state.registers;
    let registersAmount = 0;
    if (page === 0) {
      return registers;
    } else {
    const response = await getGroupsPaged(
      organizationId,
      page,
      pageSize,
      attribute,
      order
    );
    if (String(response).includes("Error:")) {
    } else {
      registers = [...registers, ...response.data.data.content];
      registersAmount = response.data.data.totalElements;
    }
  }
    this.setState({
      registers,
      registersAmount,
      loadingInfo: false,
      openDialog: false,
    });
  }

  async deleteGroupFunction(roleId) {
    const { organizationIdQuery, page, pageSize, attribute, order } =
      this.state;
    const response = await deleteGroup(roleId);
    const {t} = this.props
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal:
          t('modal.internalServerErrorContactSupport'),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.disablingSuccessful'),
        contenNotificationModal: t('modal.groupSuccesfullDisabled'),
        typeNotificationModal: "success",
      });
      this.getGroupsPagedFunction(
        organizationIdQuery,
        page,
        pageSize,
        attribute,
        order
      );
      this.setState({
        loadingInfo: false,
        openNotificationModal: true,
        openDialog: false,
      });
    }
  }

  // Events
  onSelect = (option, event) => {
    const { page, pageSize, attribute, order } = this.state;
    switch (event.name) {
      case "organizationType":
        this.setState({
          organizationTypeId: option.value,
          organizationId: null,
          registers: [],
        });
        if (option.value === 1) {
          this.setState({
            organizationIdQuery: 1,
            organizationName: option.label,
            loadingInfo: true,
          });
          this.getGroupsPagedFunction(1, page, pageSize, attribute, order);
        } else {
          this.setState({
            organizationIdQuery: null,
          });
          this.getOrganizationByOrganizationTypeId(option.value);
        }
        break;
      case "organization":
        this.setState({
          organizationId: option.value,
          organizationIdQuery: option.value,
          organizationName: option.label,
          loadingInfo: true,
          registers: [],
        });
        this.getGroupsPagedFunction(
          option.value,
          page,
          pageSize,
          attribute,
          order
        );
        break;
      default:
        break;
    }
  };

  disableGroup = (id) => {
    this.setState({
      loadingInfo: true,
    });
    this.deleteGroupFunction(id);
  };

  // Pagination
  showPagination = (page, pageSize) => {
    const { organizationIdQuery, attribute, order } = this.state;
    this.setState({
      loadingInfo: true,
      page,
      pageSize,
    });
    this.getGroupsPagedFunction(
      organizationIdQuery,
      page,
      pageSize,
      attribute,
      order
    );
  };

  handleChangePage = (event, newPage) => {

    this.setState({ page: newPage, initialPage: newPage });
    if (this.state.registers.length < this.state.registersAmount) {
      this.showPagination(newPage + 1, this.state.pageSize);
    } else {
      return this.state.registers;
    }
  };

  handleChangeRowsPerPage = (event) => {
    if (event !== undefined) {
      this.setState({
        pageSize: parseInt(event.target.value, 10) + 1,
        page: 0,
      });
    }
  };

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };
  handleClose = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const userSession = { ...this.props.userSession };
    const { privileges } = this.props;
    const {
      organizationTypeId,
      organizationTypes,
      organizationId,
      organizations,
      organizationIdQuery,
      organizationName,
      loadingInfo,
      height,
      registers,
      // Pagination
      initialPage,
      registersAmount,
      pageSize,

      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;

    const {t} = this.props
    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />

        <Card className="card">
          <div className="row componentSpace">
            <Breadcrumb
              principal={t('generalUser.groups')}
              icon={<Grid />}
              secondary={t('sections.home')}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {userSession.organizationId == 1 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="col-md-4"
                  style={{
                    width: "20vw",
                    paddingLeft: "20px",
                    paddingRight: "15px",
                  }}
                >
                  <SelectSingle
                    defaultValue={organizationTypeId}
                    text={t('basicWords.typeUser') + "*"}
                    isReq
                    isDisabled={false}
                    isLoading={false}
                    name="organizationType"
                    onChange={(option, event) => this.onSelect(option, event)}
                    options={organizationTypes}
                  />
                </div>
                {organizationTypeId != null && organizationTypeId !== 1 ? (
                  <div
                    className="col-md-4"
                    style={{ width: "20vw", paddingRight: "15px" }}
                  >
                    <SelectSingle
                      defaultValue={organizationId}
                      text={t('organization.organization') + "*"}
                      isReq
                      isDisabled={false}
                      isLoading={false}
                      name="organization"
                      onChange={(option, event) => this.onSelect(option, event)}
                      options={organizations}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            {ValidatePermissionForComponentPart("ADD_GROUPS", privileges) ? (
              <div className="col-md-4" style={{ width: "20vw" }}>
                <ButtonComponent
                  text={t('basicWords.createGrupo')}
                  icon={<Plus />}
                  path="./addGroup"
                  props={{
                    organizationTypeId,
                    organizationId: organizationIdQuery,
                    organizationName,
                  }}
                  shape="round"
                  isDisabled={organizationIdQuery == null}
                />
              </div>
            ) : null}
          </div>
          <div className="row componentSpace">
            <Title title={t('generalUser.groupList')} />
          </div>

          <div className="row componentSpace">
            <div className="col-12">
              <TableContainer style={{ maxHeight: "440" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('basicWords.name')}</TableCell>
                      <TableCell>{t('basicWords.description')}</TableCell>
                      <TableCell>{t('basicWords.numberUsers')}</TableCell>
                      <TableCell></TableCell>
                      {ValidatePermissionForComponentPart(
                        "EDIT_USER",
                        privileges
                      ) ? (
                        <TableCell></TableCell>
                      ) : null}
                      {ValidatePermissionForComponentPart(
                        "DISABLE_USER",
                        privileges
                      ) ? (
                        <TableCell></TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registers.slice(
                        initialPage * pageSize,
                        initialPage * pageSize + pageSize
                      ).map((row) => {
                      return (
                        <TableRow key={row.name}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.userAmount}</TableCell>
                          <TableCell>
                            <div className="containerIconsTable">
                              <div className="bgIconEdit">
                                <Link
                                  to={{
                                    pathname: "/editGroup",
                                    props: {
                                      organizationTypeId,
                                      organizationId: organizationIdQuery,
                                      organizationName,
                                      groupId: row.id,
                                      countryId: row.countryId,
                                      regionId: row.regionId,
                                      cityId: row.cityId,
                                    },
                                  }}
                                >
                                  <Edit className="iconEdit" />
                                </Link>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="containerIconsTable">
                              <div className="bgIconTrash">
                                <Trash2
                                  className="iconTrash"
                                  onClick={(e) => this.handleClickOpen()}
                                />
                              </div>
                            </div>
                            <GeneralModal
                              visible={this.state.openDialog}
                              title={t('basicWords.deleteRegister')}
                              footer={[
                                <div
                                  className="row"
                                  key={1}
                                  style={{ display: "flex" }}
                                >
                                  <div className="col-6" style={{ marginRight: "5%" }}>
                                    <ButtonComponent
                                      onClick={(e) => this.handleClose()}
                                      props={null}
                                      shape="round"
                                      text={t('basicWords.cancelText')}
                                    />
                                  </div>
                                  <div>
                                    <ButtonComponent
                                      onClick={() => this.disableGroup(row.id)}
                                      props={null}
                                      shape="round"
                                      text={t('basicWords.okText')}
                                    />
                                  </div>
                                </div>,
                              ]}
                              onClick={(e) => this.handleClose()}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                disabled={registers.length === 0}
                count={registersAmount}
                rowsPerPage={pageSize}
                page={initialPage}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Card>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
    userSession: state.userSession.userSession,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(GroupsList));
