import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import "../css/sections.scss";

// Queries
import {
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
  insertSectionComponent,
  disableLpSectionComponent,
  getFile,
  updateLpSectionComponentNodeImage,
  getSectionLayoutStyle,
  updateLpSectionLayout,
  updateLpSection,
} from "../../../../actions/index";

import SelectLayoutModal from "../../modalComponents/SelectLayoutModal.jsx";

import { Card, CircularProgress, TextField, Tooltip } from "@material-ui/core";

// Components
import {
  Title,
  ButtonComponent,
  BreadcrumbSection,
  GeneralInput,
  NotificationModal,
  DeleteItemModal,
  GeneralModal,
  AccordionComponent,
  ButtonsFooterComponent,
} from "../../../../components/index";

import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Localization
import { withTranslation } from "react-i18next";

// import ShowMoreText from "react-show-more-text";
import { Trash2, Plus, Edit2, X } from "react-feather";

// const { TextArea } = Input;
// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class GallerySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,
      lpSectionId: null,
      sectionName: null,

      limit: 6,
      galleryToShow: [],

      galleryTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: 0,
      },
      galleryText: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: 0,
      },

      image: {
        id: null,
        s3path: null,
        imageUrl: null,
        image: null,
        loading: false,
        modified: false,
      },

      photo: {
        id: null,
        author: "",
        note: "",
        image: null,
        date: moment(),
      },

      parentLPSectionComponentId: null,
      imageToAdd: [],
      isEditModalVisible: false,
      loadingInfo: true,
      section: null,
      lPSections: [],
      images: [],
      saveTitleDisabled: true,
      FlagclassName: false,
      goToWithoutSaveModalVisible: false,
      addImage: false,

      //Modal notification
      haveChangeInLayout: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",

      //Modal general
      openDialog: false,

      openDelete: false,
      isReadyToEdit: false,

      //Name modal
      openModalToChangeName: false,
      customName: "",
    };
  }

  componentDidMount() {
    const { location } = this.props;

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const { props } = location;
    if (props) {
      this.getGalleryInformation(props.landingPageId, props.sectionId);

      this.setState({
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getGalleryInformation(lpId, lpSectionId) {
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "GALLERY"
    );
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({ openNotificationModal: true });
    } else {
      const galleryTitle = { ...this.state.galleryTitle };
      const galleryText = { ...this.state.galleryText };

      const sectionInfo = response.data.data;
      const galleryComponent = sectionInfo.components.find(
        (comp) => comp.name === "GalleryComponent"
      );

      galleryTitle.id = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryTitle"
      )
        ? galleryComponent.nodes.find(
            (node) => node.nodeType === "GalleryTitle"
          ).id
        : null;
      galleryTitle.value = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryTitle"
      )
        ? galleryComponent.nodes.find(
            (node) => node.nodeType === "GalleryTitle"
          ).value
        : null;
      galleryTitle.nodeTypeName = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryTitle"
      )
        ? galleryComponent.nodes.find(
            (node) => node.nodeType === "GalleryTitle"
          ).nodeType
        : null;
      galleryTitle.minLength = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryTitle"
      )
        ? galleryComponent.nodes.find(
            (node) => node.nodeType === "GalleryTitle"
          ).minLength
        : null;
      galleryTitle.maxLength = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryTitle"
      )
        ? galleryComponent.nodes.find(
            (node) => node.nodeType === "GalleryTitle"
          ).maxLength
        : null;
      galleryTitle.length = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryTitle"
      )
        ? galleryComponent.nodes.find(
            (node) => node.nodeType === "GalleryTitle"
          ).value.length
        : null;

      galleryText.id = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryText"
      )
        ? galleryComponent.nodes.find((node) => node.nodeType === "GalleryText")
            .id
        : null;
      galleryText.value = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryText"
      )
        ? galleryComponent.nodes.find((node) => node.nodeType === "GalleryText")
            .value
        : null;
      galleryText.nodeTypeName = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryText"
      )
        ? galleryComponent.nodes.find((node) => node.nodeType === "GalleryText")
            .nodeType
        : null;
      galleryText.minLength = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryText"
      )
        ? galleryComponent.nodes.find((node) => node.nodeType === "GalleryText")
            .minLength
        : null;
      galleryText.maxLength = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryText"
      )
        ? galleryComponent.nodes.find((node) => node.nodeType === "GalleryText")
            .maxLength
        : null;
      galleryText.length = galleryComponent.nodes.find(
        (node) => node.nodeType === "GalleryText"
      )
        ? galleryComponent.nodes.find((node) => node.nodeType === "GalleryText")
            .value.length
        : null;

      const photos = sectionInfo.components.filter(
        (comp) => comp.name === "PhotoComponent"
      );

      for (let i = 0; i < photos.length; i++) {
        if (!photos[i].img) {
          const imgNode = photos[i].nodes.find(
            (node) => node.nodeType === "PhotoImage"
          );
          if (imgNode) {
            if (imgNode.value != null && imgNode.value !== "") {
              // Get image
              let img = await getFile(
                "https://somosaura-cms.s3.amazonaws.com/" + imgNode.value
              );
              photos[i].img = img;
            }
          }
        }
      }

      const galleryToShow = [];
      if (photos) {
        photos.forEach((element) => {
          const galleryElement = {
            id: element.id,
            noteId: element.nodes.find((node) => node.nodeType === "PhotoNote")
              ? element.nodes.find((node) => node.nodeType === "PhotoNote").id
              : null,
            note: element.nodes.find((node) => node.nodeType === "PhotoNote")
              ? element.nodes.find((node) => node.nodeType === "PhotoNote")
                  .value
              : null,
            dateId: element.nodes.find((node) => node.nodeType === "PhotoDate")
              ? element.nodes.find((node) => node.nodeType === "PhotoDate").id
              : "",
            date: element.nodes.find((node) => node.nodeType === "PhotoDate")
              ? element.nodes.find((node) => node.nodeType === "PhotoDate")
                  .value
              : moment(),
            authorId: element.nodes.find(
              (node) => node.nodeType === "PhotoAuthor"
            )
              ? element.nodes.find((node) => node.nodeType === "PhotoAuthor").id
              : null,
            author: element.nodes.find(
              (node) => node.nodeType === "PhotoAuthor"
            )
              ? element.nodes.find((node) => node.nodeType === "PhotoAuthor")
                  .value
              : null,
            imageId: element.nodes.find(
              (node) => node.nodeType === "PhotoImage"
            )
              ? element.nodes.find((node) => node.nodeType === "PhotoImage").id
              : null,
            image: element.img ? element.img : null,
            imageUri: element.img ? element.img : null,
            edited: false,
          };
          galleryToShow.push(galleryElement);
        });
      }
      //Get layout image
      let supgage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        supgage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        supgage = sectionInfo.subpages.find((sp) => sp.id === null);
      }

      let subpageIndex = sectionInfo.subpages.indexOf(supgage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle
          .sectionLayoutId;
      let layoutImg = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
      );

      let customName =
        response.data.data.customName !== null
          ? response.data.data.customName
          : this.state.sectionName;

      this.setState({
        customName,
        galleryTitle,
        galleryText,
        galleryToShow,
        loadingInfo: false,
        lpSectionLayoutStyle: layout,
        lpLayoutImg: layoutImg,
      });
    }
  }

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openNotificationModal: true,
      openModalToChangeName: false,
    });
  }

  async uploadFileToS3Bucket(imageUrl, key) {
    await updateLpSectionComponentNodeImage(imageUrl, key);
  }

  async insertSectionComponentFunction(nodesToUpdate) {
    const { landingPageId, lpSectionId } = this.state;
    const response = await insertSectionComponent(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.successfulCreation"),
        contenNotificationModal: t("modal.photoSuccessfullyAdded"),
        typeNotificationModal: "success",
      });

      let photo = { ...this.state.photo };

      photo = {
        id: null,
        author: "",
        note: "",
        image: null,
        date: moment(),
      };

      this.setState({
        photo,
      });
      this.getGalleryInformation(landingPageId, lpSectionId);
    }

    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async updateNode(nodesToUpdate) {
    const { landingPageId, lpSectionId } = this.state;
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.editionSuccess"),
        contenNotificationModal: t("modal.photoSuccessfullyEdited"),
        typeNotificationModal: "success",
      });
      let photo = { ...this.state.image };

      photo = {
        id: null,
        note: "",
        date: moment(),
        author: "",
        s3path: null,
        imageUrl: null,
        image: null,
        imageLoading: false,
      };

      this.setState({
        photo,
      });
      this.getGalleryInformation(landingPageId, lpSectionId);
    }

    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
      saveTitleDisabled: true,
    });
  }

  async disableNodeFunction(id) {
    const { landingPageId, lpSectionId } = this.state;

    const response = await disableLpSectionComponent(id);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
        isLoadingButton: false,
        loadingInfo: false,
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.disablingSuccessful"),
        contenNotificationModal: t("modal.photoSuccessfullyDisabled"),
        typeNotificationModal: "success",
      });

      this.getGalleryInformation(landingPageId, lpSectionId);
      this.setState({
        openNotificationModal: true,
        isLoadingButton: false,
        loadingInfo: false,
      });
    }
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 7);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
              responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
    }
    this.setState({ openNotificationModal: true, isLoadingButton: false });
  }

  // Events
  handleChange = (event, photoId) => {
    const { id, value } = event.target;
    const { photo, galleryToShow, galleryText, galleryTitle } = this.state;
    if (photoId === null) {
      photo[id] = value; // new photo
    }
    if (id === "GalleryTitle") {
      if (value.length <= galleryTitle.maxLength) {
        galleryTitle.value = value;
        galleryTitle.modified = true;
        galleryTitle.length = value.length;
      }
    }
    if (id === "GalleryText") {
      if (value.length <= galleryText.maxLength) {
        galleryText.value = value;
        galleryText.modified = true;
        galleryText.length = value.length;
      }
    }

    galleryToShow.forEach((element, index) => {
      if (element.id === photoId) {
        if (id === "note") {
          galleryToShow[index].note = value;
        }
        if (id === "author") {
          galleryToShow[index].author = value;
        }
        galleryToShow[index].edited = true;
      }
    });
    this.setState({
      galleryToShow,
      photo,
      galleryTitle,
      galleryText,
      saveTitleDisabled: false,
      FlagclassName: true,
    });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagclassName: true,
    });
  };

  saveChangeName = () => {
    let dto = {
      id: this.state.lpSectionId,
      customName: this.state.newCustomName,
      isEnabled: 1,
    };

    this.updateLpSection(dto);
  };

  onSaveEvent = (event, id, flag) => {
    var {
      haveChangeInLayout,
      galleryTitle,
      galleryText,
      lpSectionId,
      lpSectionLayoutStyle,
      photo,
    } = this.state;
    this.setState({
      isLoadingButton: false,
      FlagclassName: false,
    });
    const { t } = this.props;

    const nodesToAdd = [];
    let errorInput = false;

    if (galleryTitle.modified) {
      const titleDto = {};
      titleDto.id = galleryTitle.id ? galleryTitle.id : null;
      titleDto.sectionComponentNodeId = 28;
      if (galleryTitle.value === "") {
        errorInput = true;
      }
      titleDto.value = galleryTitle.value;
      titleDto.nodeType = "GalleryTitle";

      nodesToAdd.push(titleDto);
    }
    if (galleryText.modified) {
      const textDto = {
        id: galleryText.id ? galleryText.id : null,
        sectionComponentNodeId: 29,
        value: galleryText.value,
        nodeType: "GalleryText",
      };

      nodesToAdd.push(textDto);
    }
    if (nodesToAdd.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToAdd);
      } else {
        this.setState({
          titleNotificationModal: t("modal.wrongFile"),
          contenNotificationModal: t("modal.fieldEmpty"),
          typeNotificationModal: "error",
        });
        this.setState({
          openNotificationModal: true,
          isLoadingButton: false,
        });
      }
    }
    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }
    this.onSaveData();
    photo = {
      id: null,
      note: "",
      author: "",
      image: null,
    };
    this.setState({ photo, addImage: false });
  };

  disableImage(elementToChange) {
    const { galleryToShow, photo, imageIndex } = {
      ...this.state,
    };
    if (elementToChange !== null && imageIndex) {
      galleryToShow[imageIndex].image = null;
      galleryToShow[imageIndex].edited = true;
    } else {
      photo.image = null;
    }
    this.setState({
      isLoadingButton: true,
      FlagclassName: true,
      deleteFlag: true,
      galleryToShow,
      photo,
    });
    this.handleCloseDeleteImage();
  }

  onSaveData = (e, flag) => {
    const { parentLPSectionComponentId, landingPageId, galleryToShow, photo } =
      this.state;

    const { t } = this.props;

    this.setState({
      FlagclassName: false,
      isLoadingButton: true,
    });

    var galleryToValidate = galleryToShow.filter((n) => n.edited === true);
    if (photo.image !== null) {
      galleryToValidate = galleryToValidate.concat(photo);
    }

    galleryToValidate.forEach((image) => {
      const imageToAdd = {
        // --- Con esto se crea el registro lpSectionComponent ---
        lpId: null, // Este valor se obtiene de la vista anterior
        lpSectionId: this.state.lpSectionId,
        sectionId: 7, // Valor estático para la sección de imagenes
        sectionComponentId: 11, // Valor estático de 11, valor para el GalleryComponent
        parentLPSectionComponentId, // Valor estático de 10 puesto que el id 10 es el padre de este nuevo componente
        // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
        nodesToAdd: [],
        buttons: [],
      };

      const nodeImageToAdd = [];
      const errorInput = false;

      if (image.note != null) {
        const imageDto = {};

        imageDto.id = image.noteId ? image.noteId : null;
        imageDto.sectionComponentNodeId = 37;
        imageDto.value = image.note;
        imageDto.nodeType = "PhotoNote";

        nodeImageToAdd.push(imageDto);
      }
      if (image.author != null) {
        const imageDto = {};

        imageDto.id = image.authorId ? image.authorId : null;
        imageDto.sectionComponentNodeId = 39;
        imageDto.value = image.author;
        imageDto.nodeType = "PhotoAuthor";

        nodeImageToAdd.push(imageDto);
      }
      if (image.date != null) {
        const imageDto = {};

        imageDto.id = image.dateId ? image.dateId : null;
        imageDto.sectionComponentNodeId = 38;
        imageDto.value = image.date ? image.date : moment();
        imageDto.nodeType = "PhotoDate";

        nodeImageToAdd.push(imageDto);
      }
      if (image.image !== null) {
        const imageDto = {};

        imageDto.id = image.imageId ? image.imageId : null;
        imageDto.sectionComponentNodeId = 36;
        let haveHttp = false;
        var regex = /somosaura-cms.s3.amazonaws.com/;
        haveHttp = regex.test(image.image);
        if (haveHttp) {
          imageDto.value = image.image.slice(39, image.image.length); //Cut to only save image, not link
        } else {
          imageDto.value = image.image;
        }
        // imageDto.value = image.image;
        imageDto.nodeType = "PhotoImage";

        nodeImageToAdd.push(imageDto);
      }
      if (flag) {
        const imageDto = {};

        imageDto.id = image.imageId ? image.imageId : null;
        imageDto.sectionComponentNodeId = 36;
        imageDto.value = "";
        imageDto.nodeType = "PhotoImage";

        nodeImageToAdd.push(imageDto);
      }

      imageToAdd.lpId = landingPageId;
      imageToAdd.nodesToAdd = nodeImageToAdd;

      if (nodeImageToAdd.length > 0) {
        if (!errorInput) {
          let photoImage = false;
          nodeImageToAdd.forEach((obj) => {
            if (obj.nodeType === "PhotoImage") {
              photoImage = true;
            }
          });
          if (photoImage) {
            if (image.id) {
              this.updateNode(nodeImageToAdd);
            } else {
              this.insertSectionComponentFunction(imageToAdd);
            }
          } else {
            this.setState({
              titleNotificationModal: t("modal.wrongFile"),
              contenNotificationModal: t("modal.fieldEmpty"),
              typeNotificationModal: "error",
            });
            this.setState({
              openNotificationModal: true,
              isLoadingButton: false,
            });
          }
        } else {
          this.setState({
            titleNotificationModal: t("modal.wrongFile"),
            contenNotificationModal: t("modal.fieldEmpty"),
            typeNotificationModal: "error",
          });
          this.setState({
            openNotificationModal: true,
            isLoadingButton: false,
          });
        }
      }
    });
  };

  disableGalleryEvent = (id) => {
    this.setState({
      loadingInfo: true,
    });
    this.disableNodeFunction(id);
  };

  // Modal events
  onCloseEventModal = () => {
    let photo = { ...this.state.photo };
    photo = {
      id: null,
      note: "",
      date: moment(),
      author: "",
      s3path: null,
      imageUrl: null,
      image: null,
      imageLoading: false,
    };

    this.setState({
      isAddModalVisible: false,
      isEditModalVisible: false,
      photo,
    });
  };

  handleChangeModal = (e) => {
    const { id, value } = e.target;
    const { image } = { ...this.state };

    image[id] = value;

    this.setState({
      image,
    });
  };

  handleChangeImage = async (event) => {
    const { galleryToShow, photo, country, city, organizationId, imageIndex } =
      {
        ...this.state,
      };
    var image = { ...this.state.image };
    var fileUpload = event.target.files[0];
    let extension;
    if (fileUpload !== undefined) {
      extension = fileUpload.type.replace("image/", "");
    }
    const random = uuidv4();
    const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
    if (!extension) {
      image.loading = true;
      image.file = null;
      image.image = null;

      this.setState({
        image,
        FlagclassName: true,
      });
      return;
    }

    if (key !== undefined) {
      // Function to resize and reduce image quality
      // Save image in AWS

      let flag = true;
      image = await handleChangeImageFunction(
        image,
        fileUpload,
        extension,
        key,
        400,
        400
      ).catch((res) => {
        const { t } = this.props;
        this.setState({
          titleNotificationModal: t(`${res.title}`),
          contenNotificationModal: `${t(`${res.content}`)} ${res.params}`,
          typeNotificationModal: "error",
          openNotificationModal: true,
        });
        flag = false;
      });

      if (flag !== false) {
        if (image.loading === false) {
          if (imageIndex === null) {
            //new news
            image.loading = false;
            image.modified = true;
            image.value = key;
            photo.image = key;
            photo.imageUri = image.image;
          } else {
            // edited new
            galleryToShow[imageIndex].image = key;
            galleryToShow[imageIndex].imageUri = image.image;
            galleryToShow[imageIndex].edited = true;
          }

          this.uploadFileToS3Bucket(image.file, key);
          this.setState({
            image,
            photo,
            galleryToShow,
            FlagclassName: true,
          });
          this.forceUpdate();
        }
      }
    }
  };

  changeToEdit = (e) => {
    e.stopPropagation();
    const { isReadyToEdit } = this.state;
    this.setState({ isReadyToEdit: !isReadyToEdit });
  };

  handleImageIndex = (index) => {
    this.setState({
      imageIndex: index,
    });
  };

  // Render Info
  renderComponents = (galleryToShow) => {
    const { openDeleteImage, isReadyToEdit } = this.state;
    const renderedComponents = [];
    const { t } = this.props;
    if (galleryToShow) {
      galleryToShow.forEach((element, index) => {
        renderedComponents.push(
          <div className="row" style={{ marginBottom: "2%" }}>
            <AccordionComponent
              disabled={isReadyToEdit}
              iconEdit={<Edit2 />}
              // onEditNews={(e) => this.changeToEdit(e)}
              onDeleteNews={(e) => this.handleClickOpenDelete(element.id)}
              iconDelete={<Trash2 />}
              title={
                element.note !== "" && element.note !== null
                  ? element.note
                  : t("sections.photo")
              }
              body={[
                <div className="accordionContent">
                  <div className="row ">
                    <div className="accordionCenterLine">
                      <div className="textLogo" style={{ marginLeft: "1%" }}>
                        {t("basicWords.image")}
                      </div>
                      <div className="cardImagesSquare">
                        <div className="row">
                          <div className="iconXCardImagesSquare">
                            <a
                              onClick={() =>
                                this.handleClickOpenDeleteImage(index)
                              }
                            >
                              <X className="iconImageEditInSectionSquare" />
                            </a>
                          </div>
                        </div>

                        <div className="row">
                          {/* Tooltip image */}
                          <Tooltip title={t("generalMessages.imagesFormat")}>
                            <div className="centerImage">
                              <input
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={(e) =>
                                  this.handleChangeImage(e, index)
                                }
                                hidden
                              />
                              <label htmlFor="contained-button-file">
                                <div className="imageEdit">
                                  <div>
                                    {element.image != null ? (
                                      <img
                                        src={element.imageUri}
                                        alt="avatar"
                                        className="widthAndHeightImageSectionSquare"
                                        onClick={() =>
                                          this.handleImageIndex(index)
                                        }
                                      />
                                    ) : (
                                      <Plus
                                        className="imageEdit"
                                        onClick={() =>
                                          this.handleImageIndex(index)
                                        }
                                      />
                                    )}

                                    <GeneralModal
                                      visible={openDeleteImage}
                                      title={t("basicWords.deleteItem")}
                                      footer={[
                                        <div
                                          className="row"
                                          key={1}
                                          style={{ display: "flex" }}
                                        >
                                          <div className="col-6">
                                            <ButtonComponent
                                              onClick={() =>
                                                this.handleCloseDeleteImage()
                                              }
                                              props={null}
                                              shape="round"
                                              text={t("basicWords.cancelText")}
                                            />
                                          </div>
                                          <div>
                                            <ButtonComponent
                                              onClick={() =>
                                                this.disableImage(element)
                                              }
                                              props={null}
                                              shape="round"
                                              text={t("basicWords.okText")}
                                            />
                                          </div>
                                        </div>,
                                      ]}
                                      onClick={() =>
                                        this.handleCloseDeleteImage()
                                      }
                                    />
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Tooltip>
                        </div>
                      </div>

                      {/* Author */}
                      <div
                        style={{
                          width: "100%",
                          marginLeft: "3%",
                          marginRight: "1%",
                        }}
                      >
                        <GeneralInput
                          disabled={isReadyToEdit}
                          className="textInputWidth"
                          placeholder={t("sections.author")}
                          id="author"
                          onChange={(e) => this.handleChange(e, element.id)}
                          value={element.author != null ? element.author : ""}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Nota */}
                  <div className="accordionCenterLine">
                    <div
                      className="accordionCompleteInputs"
                      style={{ marginTop: "1%" }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.note")}
                        id="note"
                        onChange={(e) => this.handleChange(e, element.id)}
                        value={element.note ? element.note : ""}
                      />
                    </div>
                  </div>
                </div>,
              ]}
            />
            <br />
          </div>
        );
      });
    }
    return renderedComponents;
  };

  renderAddImage() {
    const { photo, openDeleteImage } = this.state;
    const { t } = this.props;
    return (
      <div>
        <AccordionComponent
          title={t("basicWords.newPhoto")}
          className="accordionAdd"
          body={[
            <div className="accordionContent">
              <div className="row ">
                <div className="accordionCenterLine">
                  <div className="textLogo" style={{ marginLeft: "1%" }}>
                    {t("basicWords.image")}
                  </div>
                  <div className="cardImagesSquare">
                    <div className="row">
                      <div className="iconXCardImagesSquare">
                        <a onClick={() => this.handleClickOpenDeleteImage()}>
                          <X className="iconImageEditInSectionSquare" />
                        </a>
                      </div>
                    </div>

                    <div className="row">
                      {/* Tooltip image */}
                      <Tooltip title={t("generalMessages.imagesFormat")}>
                        <div className="centerImage">
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(e) => this.handleChangeImage(e, null)}
                            hidden
                          />
                          <label htmlFor="contained-button-file">
                            <div className="imageEdit">
                              <div>
                                {photo.image != null ? (
                                  <img
                                    src={photo.imageUri}
                                    alt="avatar"
                                    className="widthAndHeightImageSectionSquare"
                                    onClick={() => this.handleImageIndex(null)}
                                  />
                                ) : (
                                  <Plus
                                    className="imageEdit"
                                    onClick={() => this.handleImageIndex(null)}
                                  />
                                )}

                                <GeneralModal
                                  visible={openDeleteImage}
                                  title={t("basicWords.deleteItem")}
                                  footer={[
                                    <div
                                      className="row"
                                      key={1}
                                      style={{ display: "flex" }}
                                    >
                                      <div className="col-6">
                                        <ButtonComponent
                                          onClick={() =>
                                            this.handleCloseDeleteImage()
                                          }
                                          props={null}
                                          shape="round"
                                          text={t("basicWords.cancelText")}
                                        />
                                      </div>
                                      <div>
                                        <ButtonComponent
                                          onClick={() =>
                                            this.disableImage(null)
                                          }
                                          props={null}
                                          shape="round"
                                          text={t("basicWords.okText")}
                                        />
                                      </div>
                                    </div>,
                                  ]}
                                  onClick={() => this.handleCloseDeleteImage()}
                                />
                              </div>
                            </div>
                          </label>
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  {/* Author */}
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "3%",
                      marginRight: "1%",
                    }}
                  >
                    <GeneralInput
                      className="textInputWidth"
                      placeholder={t("sections.author")}
                      id="author"
                      onChange={(e) => this.handleChange(e, null)}
                      value={photo.author}
                    />
                  </div>
                </div>
              </div>
              {/* Nota */}
              <div className="accordionCenterLine">
                <div
                  className="accordionCompleteInputs"
                  style={{ marginTop: "1%" }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.note")}
                    id="note"
                    onChange={(e) => this.handleChange(e, null)}
                    value={photo.note}
                  />
                </div>
              </div>
            </div>,
          ]}
        />
        <br />
      </div>
    );
  }

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  handleClickOpenDeleteImage = () => {
    this.setState({ openDeleteImage: true });
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDelete = (id) => {
    this.setState({ openDelete: true, elementToDeleteId: id });
  };

  handleCloseDelete = () => {
    this.setState({ openDelete: false });
  };

  disableElementEvent = (id) => {
    this.setState({
      loadingInfo: true,
      openDeleteNews: false,
    });
    this.disableNodeFunction(id);
  };

  handleChangeLayout = (e, id) => {
    this.setState({
      saveButtonDisabled: false,
      FlagclassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  addImage = (flag) => {
    if (flag !== this.state.addImage) {
      this.setState({ addImage: flag });
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("modal.sectionUnselected"),
      contenNotificationModal: t("modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({ openNotificationModal: true });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  }

  //-----------Update page after modal------------
  updatePage = (lpSectionId, previousPath) => {
    const { landingPageId } = this.state;
    this.getGalleryInformation(landingPageId, lpSectionId);
    this.setState({
      previousPath,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  render() {
    const {
      redirectBack,
      sectionName,
      galleryText,
      galleryToShow,
      organizationId,
      organizationName,
      name,
      subpageId,
      landingPageId,
      FlagclassName,
      layoutData,
      lpSectionLayoutStyle,
      galleryTitle,
      lpUrl,

      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
      isLoadingButton,
      //Modal delete item
      openDelete,
      elementToDeleteId,

      openModalToChangeName,
      customName,
      goToWithoutSaveModalVisible,
    } = this.state;
    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/myWebPage" />;
    }

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      customName ? decodeURIComponent(customName) : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <DeleteItemModal
          visible={openDelete}
          onCancel={this.handleCloseDelete}
          onClick={(e) => this.disableElementEvent(elementToDeleteId)}
        />

        <Card className="card">
          {isLoadingButton === true ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(800%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row componentSpace spaceBreadcrumb">
                <BreadcrumbSection
                  principal={t("sections.gallery")}
                  secondary={organizationName}
                  route="./myWebPage"
                  propsComponent={{
                    organizationId,
                    sectionName: sectionName,
                    nameSubpage: name ? name : null,
                    lpId: landingPageId,
                    subpageId: subpageId ? subpageId : null,
                    lpUrl: lpUrl,
                    customName,
                  }}
                  handleClickOpenModalToChangeName={
                    this.handleClickOpenModalToChangeName
                  }
                  FlagclassName={FlagclassName}
                  onClick={(e) => this.saveAboutEvent(e)}
                  GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                  onCancelGotoWithoutMModal={(e) =>
                    this.onCancelGotoWithoutMModal(e)
                  }
                  goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                />
              </div>

              <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                <Title
                  title={t("basicWords.completeInformationOfYourSection")}
                />
              </div>

              {/* Layout */}
              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                <SelectLayoutModal
                  layoutData={layoutData}
                  lpLayoutId={
                    lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                  }
                  handleChange={this.handleChangeLayout}
                />
                <br />
              </div>

              {/* First accordion */}
              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                <AccordionComponent
                  title={t("generalUser.sectionContent")}
                  body={[
                    <div className="accordionContent">
                      {/* First Line */}
                      <div className="accordionCenterLine">
                        <div
                          style={{
                            width: "70%",
                            marginLeft: "1%",
                            marginRight: "0.7%",
                          }}
                        >
                          <GeneralInput
                            className="textInputWidth"
                            placeholder={t("basicWords.enterTitle")}
                            id={galleryTitle ? galleryTitle.nodeTypeName : null}
                            onChange={(e, id) => this.handleChange(e, id)}
                            value={galleryTitle ? galleryTitle.value : null}
                          />
                          {galleryTitle.value && galleryTitle.maxLength ? (
                            <span>
                              {galleryTitle.length} / {galleryTitle.maxLength}
                            </span>
                          ) : null}
                        </div>
                        <div
                          style={{
                            width: "30%",
                            marginRight: "1%",
                            marginLeft: "0.7%",
                          }}
                        >
                          <GeneralInput
                            className="textInputWidth"
                            placeholder={t("basicWords.enterText")}
                            id={galleryText ? galleryText.nodeTypeName : null}
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            onChange={(e, id) => this.handleChange(e, id)}
                            value={galleryText ? galleryText.value : null}
                          />
                        </div>
                      </div>
                    </div>,
                  ]}
                />
                <br />
              </div>

              {/* Accordion to add event */}
              {landingPageId ? (
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  {this.renderAddImage()}
                </div>
              ) : null}

              <div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <Title title={t("basicWords.listImages")} />
                </div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  {this.renderComponents(galleryToShow)}
                </div>
              </div>
            </>
          )}
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
          }}
          FlagclassName={FlagclassName}
          onClick={(e) => this.onSaveEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(GallerySection);
