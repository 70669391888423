import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Card,
  Checkbox,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";

import { Edit, Trash2, Grid } from "react-feather";

// Queries
import {
  getOrganizationById,
  getGroupTypes,
  getUsersByOrganizationId,
  insertGroup,
} from "../../actions/index";

// Components
import {
  Title,
  Breadcrumb,
  NotificationModal,
  ButtonsFooterComponent
} from "../../components/index";

import GroupForm from "./components/groupForm.jsx";
//Localization
import { withTranslation } from 'react-i18next';

// const { Column } = Table;
const tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;
// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

 class AddGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,

      organizationTypeId: null,
      organizationId: null,
      organizationName: null,

      groupTypes: [],
      users: [],

      groupToAdd: {
        id: null,
        name: "",
        description: "",
        groupTypeId: null,
        organizationId: null,
        userids: [],
      },

      addButtonDisabled: true,
      isLoadingButton: false,
      loadingInfo: false,
      registers: [],

      // Checkbox
      selected:[],  

      // pagination
      registersAmount: 0,
      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "ASC",

      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    const { page, pageSize, attribute, order } = this.state;
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { location } = this.props;

    const organizationId = location
      ? location.props
        ? location.props.organizationId
        : null
      : null;
    const organizationName = location.props
      ? location.props.organizationName
      : null;

    const { props } = location;

    if (organizationName == null) {
      this.getOrganizationByIdFunction(organizationId);
    }

    if (organizationId != null) {
      this.setState({
        loadingInfo: true,
      });
      const { organizationTypeId } = props;
      const { organizationId } = props;
      const { organizationName } = props;

      const groupToAdd = { ...this.state.groupToAdd };
      groupToAdd.organizationId = organizationId;

      this.getGroupTypesFunction(organizationTypeId, organizationId);
      this.getUsersByOrganizationIdFunction(organizationId);

      this.setState({
        organizationTypeId,
        organizationId,
        organizationName,
        groupToAdd,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getOrganizationByIdFunction(id) {
    const response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        this.setState({
          organizationName: response.data.data.name,
        });
      }
    }
  }

  async getGroupTypesFunction(organizationTypeId, organizationId) {
    let groupTypes = [...this.state.groupTypes];
    const response = await getGroupTypes(organizationTypeId, organizationId);

    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        groupTypes = response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }
      this.setState({
        groupTypes,
        loadingInfo: false,
      });
    }
  }

  async getUsersByOrganizationIdFunction(organizationId) {
    let users = [...this.state.users];
    const response = await getUsersByOrganizationId(organizationId);

    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        users = response.data.data;
      }
      this.setState({
        users,
        loadingUsers: false,
      });
    }
  }

  async insertGroupFunction(groupToAdd) {
    const { organizationId, groupTypeId } = this.state;
    const response = await insertGroup(groupToAdd);
    const {t} = this.props
    if (response) {
      if (String(response).includes("Error:")) {
        if (groupToAdd.description == null) {
          this.setState({
            organizationToAdd: {
              description: "",
            },
          });
        }
        this.setState({
          titleNotificationModal: t('modal.internalServerError'),
          contenNotificationModal:
            t('modal.internalServerErrorContactSupport'),
          typeNotificationModal: "error",
        });
        this.setState({
          openNotificationModal: true,
        });
      } else {
        this.setState({
          titleNotificationModal: t('modal.successfulCreation'),
          contenNotificationModal: t('modal.groupSuccesfullCreated'),
          typeNotificationModal: "success",
        });
        this.getUsersByOrganizationIdFunction(organizationId);
        this.setState({
          loadingAssignedUsers: false,
          isLoadingButton: false,
          groupToAdd: {
            id: null,
            name: "",
            description: "",
            groupTypeId,
            organizationId,
            userids: [],
          },
        });
      }
      this.setState({
        loadingAssignedUsers: false,
        openNotificationModal: true,
        isLoadingButton: false,
      });
    }
  }

  // Events
  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { groupToAdd } = { ...this.state };

    groupToAdd[id] = value;

    this.setState({
      groupToAdd,
    });
  };

  handleFormSelect = (option, event) => {
    const { value } = option;
    const { groupToAdd } = { ...this.state };

    groupToAdd[event.name] = value;

    this.setState({
      groupToAdd,
    });
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const groupToAdd = { ...this.state.groupToAdd };

      if (selectedRows.length > 0) {
        this.setState({
          addButtonDisabled: false,
        });
      } else {
        this.setState({
          addButtonDisabled: true,
        });
      }
      groupToAdd.userids = selectedRows;

      this.setState({
        groupToAdd,
      });
    },
  };

  saveRoleEvent = () => {
    const groupToAdd = { ...this.state.groupToAdd };
    const users = [];
    this.setState({
      isLoadingButton: true,
      loadingAssignedUsers: true,
      users: [],
    });
    const {t} = this.props

    groupToAdd.name = groupToAdd.name.trim();
    groupToAdd.description = groupToAdd.description.trim();

    if (groupToAdd.name.length === 0) {
      groupToAdd.name = "";
    }
    if (groupToAdd.description.length === 0) {
      groupToAdd.description = "";
    }

    this.state.groupToAdd.userids.forEach((obj) => {
      users.push(obj.id);
    });

    groupToAdd.userids = users;

    if (groupToAdd.name === "" || groupToAdd.userids.length === 0) {
      this.setState({
        isLoadingButton: false,
      });
      this.setState({
        titleNotificationModal: t('modal.wrongFile'),
        contenNotificationModal: t('modal.fieldEmpty'),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      if (groupToAdd.description.length === 0) {
        groupToAdd.description = null;
      }
      this.insertGroupFunction(groupToAdd);
    }
  };

  warning = () => {
    const {t} = this.props
    this.setState({
      titleNotificationModal: t('modal.sectionUnselected'),
      contenNotificationModal: t('modal.noOrganizationSelected'),
      typeNotificationModal: "warning",
    });
    this.setState({
      isLoadingButton: false,
      openNotificationModal: true,
    });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  // Checkbox
  // Select all
  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedAll = this.state.users.map((item) => item.name);
      this.setState({
        selected: selectedAll,
      });
    } else {
      this.setState({
        selected: [],
      });
    }
    return this.state.selected;
  };

  // Select one
  handleSelectOneClick = (event, name) => {
    const selected = this.state.selected;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selected: newSelected,
    });
  }
  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  render() {
    const {
      redirectBack,
      organizationName,
      groupTypes,
      groupToAdd,
      users,
      page,
      pageSize,
      isLoadingButton,
      selected,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const {t} = this.props

    if (redirectBack) {
      return <Redirect to="/groupsList" />;
    }
    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Card className="card">
          <Backdrop
            style={{ color: "#510f8a", zIndex: "5" }}
            open={isLoadingButton}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="row componentSpace">
            <Breadcrumb
              principal={t('generalUser.newGroup')}
              icon={<Grid />}
              secondary={t('generalUser.groups')}
              third={t('sections.home')}
            />
          </div>

          {/* Form */}
          <GroupForm
            options={groupTypes}
            group={groupToAdd}
            name={organizationName}
            onChange={this.handleFormChange}
            onSelect={this.handleFormSelect}
          />
          <div className="row componentSpace">
            <Title title={t('generalUser.usersList')} />
          </div>

          <div className="row componentSpace">
            <div className="col-12">
              <TableContainer style={{ maxHeight: "440" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          onChange={(e) => this.handleSelectAllClick(e)}
                          inputProps={{ "aria-label": "select all" }}
                        />
                      </TableCell>
                      <TableCell>{t('basicWords.name')}</TableCell>
                      <TableCell>{t('generalUser.username')}</TableCell>
                      <TableCell>{t('generalUser.userEmail')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          key={row.description}
                          onClick={(event) => {
                            this.handleSelectOneClick(event, row.name)
                          }}
                          selected={isItemSelected}
                        >
                          <TableCell>
                          <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-label": `${labelId}` }}
                            ></Checkbox>
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.username}</TableCell>
                          <TableCell>{row.email}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={users.length}
                rowsPerPage={pageSize}
                page={page}
              />
            </div>
          </div>

        </Card>
        <ButtonsFooterComponent
          propsComponent={null}
          FlagclassName={true}
          onClick={(e) => this.saveRoleEvent(e)}
          path="./groupsList"
        />
      </>
    );
  }
}
export default withTranslation()(AddGroup)