import React from "react";

import { GeneralInput, SelectSingle } from "../../../components/index";

//Localization
import { useTranslation } from 'react-i18next';

const GroupForm = (props) => {
  const { group, options, name } = props;
  const { t, i18n } = useTranslation();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        className="col-md-4"
        style={{
          width: "20vw",
          paddingLeft: "1.1%",
        }}
      >
        <GeneralInput
          text={t('basicWords.name')}
          id="organizationType"
          value={name}
          placeholder={t('organization.organization')}
          isReq
          onChange={(e) => props.onChange(e)}
        />
      </div>
      <div className="col-md-6 componentSpace" style={{
          width: "20vw",
          paddingLeft: "1.5%",
        }}>
        <GeneralInput
          text={t('basicWords.description')}
          id="name"
          value={group.name}
          placeholder={t('basicWords.name')}
          isReq={false}
          onChange={(e) => props.onChange(e)}
        />
      </div>
      <div
        className="col-md-6 componentSpace"
        style={{
          width: "15%",
          paddingLeft: "1.2%",
        }}
      >
        <SelectSingle
          defaultValue={group.groupTypeId}
          text={t('basicWords.typeofGroup') + "*"}
          isReq
          isDisabled={false}
          isLoading={false}
          name="groupTypeId"
          onChange={(option, event) => props.onSelect(option, event)}
          options={options}
        />
      </div>
      <div className="col-md-6 componentSpace" style={{
          width: "15%",
          paddingLeft: "1.2%",
        }}>
        <GeneralInput
          text={t('basicWords.description') + "*"}
          id="description"
          value={group.description}
          placeholder={t('basicWords.description') + "*"}
          isReq={false}
          onChange={(e) => props.onChange(e)}
        />
      </div>
    </div>
  );
};

export default GroupForm;
