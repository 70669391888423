import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import "../css/sections.scss";

import { Trash2, Plus, Edit2, X } from "react-feather";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Components
import {
  Title,
  ButtonComponent,
  BreadcrumbSection,
  GeneralInput,
  NotificationModal,
  DeleteItemModal,
  GeneralModal,
  AccordionComponent,
  DatePickerComponent,
  ButtonsFooterComponent,
} from "../../../../components/index";

import ButtonsContainer from "../general/buttons/ButtonsContainer.jsx";

import {
  Card,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@material-ui/core";

import SelectLayoutModal from "../../modalComponents/SelectLayoutModal.jsx";

// Queries
import {
  insertSectionComponent,
  disableLpSectionComponent,
  updateSectionComponent,
  getDonationSectionByActiveState,
  getFile,
  updateLpSectionComponentNodeImage,
  getSectionLayoutStyle,
  updateLpSectionComponentNode,
  updateLpSectionLayout,
} from "../../../../actions/index";

import { backendDateFormat } from "../../../../reactUtils/systemVariables/generalVariables";
import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Language localization
import { withTranslation } from "react-i18next";
import editorConfiguration from "../../../../components/textEditorConfig";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class DonationsSection extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      height: 0,

      lpSectionId: null,
      sectionName: null,
      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,

      activeState: 0,
      pageIdx: 1,
      pageSize: 4,
      totalElements: 0,
      donations: [],
      donationsToShow: [],

      loadingSection: true,
      loadingInfo: true,

      section: null,

      isAddModalVisible: false,
      isEditModalVisible: false,

      donationContainerTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      lpUrl: "",
      newDonation: {
        id: null,
        title: "",
        text: "",
        endDate: "-1",
        fixedValue: "-1",
        image: null,
        imageUri: null,
        imageLoading: false,
        buttons: [
          {
            name: t("basicWords.donate"),
            externalLink: "PAYMENT_PLATFORM", //This indicates to buttonContainer component redirection to payment platforms
            isEnabled: 1,
          },
        ],
      },
      newDonationImage: {
        id: null,
        status: 0,
        s3path: null,
        imageUrl: null,
        image: null,
        loading: false,
        modified: false,
      },
      parentLPSectionComponentId: null,
      donationsToAdd: [],
      isLoadingButton: false,
      lpLayoutImg: null,
      FlagclassName: false,
      goToWithoutSaveModalVisible: false,
      donationTypes: [],

      //Modal notification
      haveChangeInLayout: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",

      //Modal general
      openDeleteImage: false,
      openDeleteNews: false,
      elementToDeleteId: null,

      //LayoutSelector
      newLayoutId: null,
      oldLayoutId: null,

      isReadyToEdit: false,
      addDonation: false,
      deleteFlag: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const { location } = this.props;
    const { props } = location;
    const { activeState } = this.state;

    const { t } = this.props;

    let donationTypes = [
      { value: 0, label: t("donations.types").all },
      { value: 1, label: t("donations.types").open },
      { value: 2, label: t("donations.types").closed },
      { value: 3, label: t("donations.types").unlimited },
    ];
    if (props) {
      this.getDonationsInformation(
        props.landingPageId,
        props.sectionId,
        activeState
      );

      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        donationTypes,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getDonationsInformation(lpId, lpSectionId, activeState) {
    this.setState({ loadingInfo: true });
    const response = await getDonationSectionByActiveState(
      lpId,
      lpSectionId,
      activeState
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({
        isLoadingButton: false,
        openNotificationModal: true,
      });
    } else {
      const sectionInfo = response.data.data;
      const donationContainerComponent = sectionInfo.components.find(
        (comp) => comp.name === "DonationsContainer"
      );
      const parentLPSectionComponentId = donationContainerComponent.id;
      const donationContainerTitle = {
        id: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).id,
        value: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).value,
        nodeTypeName: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).nodeType,
        minLength: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).minLength,
        maxLength: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).maxLength,
        length: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).value.length,
      };

      let donations = sectionInfo.components.filter(
        (comp) => comp.name === "DonationComponent"
      );
      donations = donations.sort(this.compareDonationDates);

      const totalElements = donations.length;

      this.setChildComponentsInfoPaged(donations);

      //Get layout image
      let supgage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        supgage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        supgage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(supgage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle;
      let layoutImg = null;
      if (layout) {
        layoutImg = await getFile(
          "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
        );
      }
      this.setState({
        donationContainerTitle,
        donations,
        totalElements,
        parentLPSectionComponentId,
        loadingInfo: false,
        lpSectionLayoutStyle: layout,
        lpLayoutImg: layoutImg,
      });
    }
  }

  async setChildComponentsInfoPaged(donations) {
    const donationsList = [];
    for (let i = 0; i < donations.length; i++) {
      if (!donations[i].img) {
        const imgNode = donations[i].nodes.find(
          (node) => node.nodeType === "DonationComponentImage"
        );
        if (imgNode) {
          if (imgNode.value != null && imgNode.value !== "") {
            let regex = /somosaura-cms.s3.amazonaws.com/;
            let haveHttp = regex.test(imgNode.value);
            if (!haveHttp) {
              // Get image
              let img = await getFile(
                "https://somosaura-cms.s3.amazonaws.com/" + imgNode.value
              );
              donations[i].img = img;
            } else {
              donations[i].img = imgNode.value;
            }
          } else {
            donations[i].img = null;
          }
        }
      }
      donationsList.push(donations[i]);
    }
    const donationsToShow = [];
    donationsList.forEach((element) => {
      const donation = {
        id: element.id,
        titleId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentTitle"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentTitle"
            ).id
          : null,
        title: element.nodes.find(
          (node) => node.nodeType === "DonationComponentTitle"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentTitle"
            ).value
          : null,
        textId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentText"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentText"
            ).id
          : null,
        text: element.nodes.find(
          (node) => node.nodeType === "DonationComponentText"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentText"
            ).value
          : null,
        endDateId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentEndDate"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentEndDate"
            ).id
          : null,
        endDate: element.nodes.find(
          (node) => node.nodeType === "DonationComponentEndDate"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentEndDate"
            ).value
          : null,
        fixedValueId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentFixedValue"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentFixedValue"
            ).id
          : null,
        fixedValue: element.nodes.find(
          (node) => node.nodeType === "DonationComponentFixedValue"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentFixedValue"
            ).value
          : null,
        imageId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentImage"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentImage"
            ).id
          : null,
        image: element.img,
        imageUri: element.img,
        buttons: element.buttons,
        edited: false,
      };
      donationsToShow.push(donation);
    });
    donationsToShow.forEach((element, index) => {
      if (element.endDate !== "-1") {
        donationsToShow[index].endDate = moment(element.endDate).subtract(
          5,
          "hours"
        );
        donationsToShow[index].endDate = moment(
          donationsToShow[index].endDate._d
        ).format("YYYY-MM-DD HH:mm:ss");
      }
    });
    this.setState({
      donationsToShow,
      loadingSection: false,
    });
    this.cleanStateVariables();
  }

  async uploadFileToS3Bucket(imageUrl, key) {
    await updateLpSectionComponentNodeImage(imageUrl, key);
  }

  async insertSectionComponentFunction(nodesToUpdate) {
    const { landingPageId, lpSectionId, activeState } = this.state;
    const response = await insertSectionComponent(nodesToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.successfulCreation"),
        contenNotificationModal: t("modal.donationSuccesfullCreated"),
        typeNotificationModal: "success",
      });
      this.cleanStateVariables();
      this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    }

    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async updateSectionComponentFunction(componentToUpdate) {
    const { landingPageId, lpSectionId, activeState } = this.state;
    const response = await updateSectionComponent(componentToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.editionSuccess"),
        contenNotificationModal: t("modal.donationSuccesfullEdited"),
        typeNotificationModal: "success",
      });

      this.cleanStateVariables();
      this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    }

    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async disableComponentFunction(id) {
    const { landingPageId, lpSectionId, activeState } = this.state;
    const response = await disableLpSectionComponent(id);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
        isLoadingButton: false,
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.disablingSuccessful"),
        contenNotificationModal: t("modal.donationSuccesfullDisabled"),
        typeNotificationModal: "success",
      });

      this.getDonationsInformation(landingPageId, lpSectionId, activeState);
      this.setState({
        openNotificationModal: true,
        isLoadingButton: false,
      });
    }
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 12);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
              responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateNode(nodesToUpdate) {
    const { landingPageId, lpSectionId, activeState } = this.state;

    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.editionSuccess"),
        contenNotificationModal: t("modal.donationSuccesfullEdited"),
        typeNotificationModal: "success",
      });
      this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    }
    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
    });
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
    }
    this.setState({ openNotificationModal: true, isLoadingButton: false });
  }

  compareDonationDates(a, b) {
    const aDateStr = a.nodes.find(
      (node) => node.nodeType === "DonationComponentEndDate"
    ).value;
    const bDateStr = b.nodes.find(
      (node) => node.nodeType === "DonationComponentEndDate"
    ).value;
    const aDate = moment(aDateStr, backendDateFormat);
    const bDate = moment(bDateStr, backendDateFormat);
    if (bDate.isAfter(aDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }
    return 0;
  }

  addDonationEvent = () => {
    let { newDonation } = this.state;
    this.setState({
      isAddModalVisible: true,
      newDonation,
    });
  };

  editDonationEvent = (e, row) => {
    const newDonation = { ...row };

    const newDonationImage = {
      id: newDonation.imageId,
      status: 0,
      s3path: null,
      imageUrl: null,
      image: newDonation.image,
      loading: false,
    };
    this.setState({
      isEditModalVisible: true,
      newDonation,
      newDonationImage,
    });
  };

  handleChangeLayout = (e, id) => {
    this.setState({
      saveButtonDisabled: false,
      FlagclassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  renderInputImage = (element, index) => {
    const { openDeleteImage } = this.state;
    const { t } = this.props;
    return (
      <>
        <div className="cardImagesSquare">
          <div className="row">
            <div className="iconXCardImagesSquare">
              <a>
                <X
                  className="iconImageEditInSectionSquare"
                  onClick={this.handleClickOpenDialog}
                />
              </a>
            </div>
          </div>

          <div className="row">
            {/* Tooltip image */}
            <Tooltip title={t("generalMessages.imagesFormat")}>
              <div className="centerImage">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => this.handleChangeImage(e, index)}
                  hidden
                />
                <label htmlFor="contained-button-file">
                  <div className="imageEdit">
                    <div>
                      {element.image != null ? (
                        <img
                          src={element.imageUri}
                          alt="avatar"
                          className="widthAndHeightImageSectionSquare"
                          onClick={() => this.handleImageIndex(index)}
                        />
                      ) : (
                        <Plus
                          className="imageEdit"
                          onClick={() => this.handleImageIndex(index)}
                        />
                      )}

                      <GeneralModal
                        visible={openDeleteImage}
                        title={t("basicWords.deleteItem")}
                        footer={[
                          <div
                            className="row"
                            key={1}
                            style={{ display: "flex" }}
                          >
                            <div className="col-6">
                              <ButtonComponent
                                onClick={() => this.handleCloseDialog()}
                                props={null}
                                shape="round"
                                text={t("basicWords.cancelText")}
                              />
                            </div>
                            <div>
                              <ButtonComponent
                                onClick={() => this.disableImage(element)}
                                props={null}
                                shape="round"
                                text={t("basicWords.okText")}
                              />
                            </div>
                          </div>,
                        ]}
                        onClick={() => this.handleCloseDialog()}
                      />
                    </div>
                  </div>
                </label>
              </div>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  renderAddDonations() {
    const { newDonation, landingPageId } = this.state;
    const { t } = this.props;
    return (
      <div>
        <AccordionComponent
          title={t("basicWords.newDonation")}
          className="accordionAdd"
          body={[
            <div className="accordionContent">
              {/* First Line */}
              <div className="accordionCenterLine">
                <div
                  style={{
                    width: "100%",
                    marginLeft: "1%",
                    marginRight: "0.7%",
                  }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.enterTitle")}
                    id="title"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={newDonation.title}
                  />
                  {newDonation.title.value && newDonation.title.maxLength ? (
                    <span>
                      {newDonation.title.length} / {newDonation.title.maxLength}
                    </span>
                  ) : null}
                </div>
              </div>

              {/* Second Line */}
              <br />
              <div className="accordionCenterLine">
                <div style={{ width: "100%", marginRight: "2%" }}>
                  <ReactQuill
                    className="accordionCompleteInputs"
                    onChange={(e) => this.handleChangeDescription(e, null)}
                    value={newDonation.text}
                    modules={editorConfiguration.modules}
                    formats={editorConfiguration.formats}
                    placeholder={t("basicWords.description")}
                  />
                </div>
              </div>
              <br />

              {/* Third line */}
              <div className="accordionCenterLine">
                <div
                  className="textLogo"
                  style={{ marginLeft: "1%", marginRight: "35%" }}
                ></div>
                <div className="textLogo">{t("basicWords.value")}</div>
              </div>

              {/* Fourth line */}

              <div className="accordionCenterLine">
                <div
                  style={{ width: "35%", marginRight: "1%", marginLeft: "1%" }}
                >
                  <DatePickerComponent
                    string={t("streaming.endDate")}
                    defaultValue={newDonation.endDate}
                    onChange={(e) => this.changeDateHandleEvent(e, null)}
                    disabled={newDonation.endDate === "-1"}
                  />
                </div>
                <div
                  style={{ width: "10%", marginRight: "1%", marginLeft: "1%" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        id="unlimitedEndDate"
                        style={{ textAlign: "center" }}
                        checked={newDonation.endDate === "-1"}
                        onChange={(e) => this.handleChange(e)}
                      />
                    }
                    label={t("basicWords.unlimited")}
                  />
                </div>
                <div
                  style={{ width: "35%", marginRight: "1%", marginLeft: "1%" }}
                >
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      id="fixedValue"
                      value={newDonation.fixedValue}
                      onChange={(e) => this.handleChange(e)}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      disabled={newDonation.fixedValue === "-1"}
                    />
                  </FormControl>
                </div>
                <div
                  style={{ width: "10%", marginRight: "1%", marginLeft: "1%" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        id="voluntaryValue"
                        style={{ textAlign: "center" }}
                        checked={newDonation.fixedValue === "-1"}
                        onChange={(e) => this.handleChange(e)}
                      />
                    }
                    label={t("basicWords.unlimited")}
                  />
                </div>
              </div>

              {/* Image */}
              <br />
              <div className="accordionCenterLine">
                <div className="textLogo">{t("basicWords.image")}</div>
                {this.renderInputImage(newDonation, null)}
              </div>

              <br />
              <div className="accordionCenterLine">
                <div className="textLogo" style={{ marginLeft: "1%" }}>
                  {t("buttons.buttons")}
                </div>
              </div>
              <br />
              <div className="accordionCenterLine">
                <div style={{ marginLeft: "1%" }}>
                  <ButtonsContainer
                    isModalVisible={true}
                    lpId={landingPageId}
                    buttonList={newDonation.buttons}
                    handleButtonListChange={(buttonList) =>
                      this.handleButtonListChange(buttonList, newDonation.id)
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
        />
        <br />
      </div>
    );
  }

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  handleClickOpenDeleteImage = () => {
    this.setState({ openDeleteImage: true });
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDeleteNews = (id) => {
    this.setState({ openDeleteNews: true, elementToDeleteId: id });
  };

  handleCloseDeleteNews = () => {
    this.setState({ openDeleteNews: false });
  };

  changeToEdit = (e) => {
    e.stopPropagation();
    const { isReadyToEdit } = this.state;
    this.setState({ isReadyToEdit: !isReadyToEdit });
  };

  disableDonationEvent = (id) => {
    this.setState({
      openDeleteNews: false,
      loadingInfo: true,
    });
    this.disableComponentFunction(id);
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("modal.sectionUnselected"),
      contenNotificationModal: t("modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({ openNotificationModal: true });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  disableImage = (element) => {
    const {
      landingPageId,
      lpSectionId,
      donationsToShow,
      imageIndex,
      activeState,
      newDonation,
    } = {
      ...this.state,
    };

    if (element !== null) {
      donationsToShow[imageIndex].image = null;
      donationsToShow[imageIndex].edited = true;
    } else {
      newDonation.image = null;
    }

    const nodesToUpdate = [];
    const node = {};
    node.id = element.id;
    node.value = "";
    node.image = "";
    node.nodeType = "IMAGE";

    nodesToUpdate.push(node);
    this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    this.updateNode(nodesToUpdate);

    this.setState({
      isLoadingButton: true,
      deleteFlag: true,
      FlagclassName: false,
    });

    this.handleCloseDialog();
  };

  // Close modal
  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  handleClickOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  // Add donation modal donations
  onCloseModal = () => {
    this.cleanStateVariables();

    this.setState({
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  };

  handleChangeDescription(html, element) {
    if (element != null) {
      this.handleChange(html, "text", "", true, element.id);
    } else {
      this.handleChange(html, "text", "", true, null);
    }
  }

  handleChange = (e, dataIndex, data, flagRichText, donationId) => {
    var id = null;
    var value = null;
    var checked = null;
    const { donationContainerTitle, newDonation, donationsToShow } = {
      ...this.state,
    };

    if (e && e.target) {
      id = e.target.id;
      value = e.target.value;
      checked = e.target.checked;
    }
    if (flagRichText) {
      id = dataIndex;
      value = e;
    }

    //Check if the changed attribute requires a particular management or is set by default

    switch (id) {
      case "unlimitedEndDate":
        if (!checked) {
          newDonation.endDate = moment().add(1, "months");
        } else {
          newDonation.endDate = "-1";
        }
        break;
      case "voluntaryValue":
        if (!checked) {
          newDonation.fixedValue = 10000;
        } else {
          newDonation.fixedValue = "-1";
        }
        break;
      case "DonationsContainerTitle":
        if (value.length <= donationContainerTitle.maxLength) {
          donationContainerTitle.value = value;
          donationContainerTitle.modified = true;
          donationContainerTitle.length = value.length;
          this.setState({
            FlagclassName: true,
          });
        }
        break;
      default:
        newDonation[id] = value;
        break;
    }
    donationsToShow.forEach((element, index) => {
      if (element.id === donationId) {
        if (id === "title") {
          donationsToShow[index].title = value;
        }
        if (id === "text") {
          donationsToShow[index].text = value;
        }
        switch (id) {
          case "unlimitedEndDate":
            if (!checked) {
              donationsToShow[index].endDate = moment().add(1, "months");
            } else {
              donationsToShow[index].endDate = "-1";
            }
            break;
          case "voluntaryValue":
            if (!checked) {
              donationsToShow[index].fixedValue = value;
            } else {
              donationsToShow[index].fixedValue = "-1";
            }
            break;

          default:
            donationsToShow[id] = value;
            break;
        }
        donationsToShow[index].edited = true;
      }
    });

    this.setState({
      newDonation,
      donationsToShow,
      donationContainerTitle,
      FlagclassName: true,
    });
  };

  handleDonationType = (e) => {
    const { landingPageId, lpSectionId } = this.state;
    const { value } = e;
    this.getDonationsInformation(landingPageId, lpSectionId, value);
    this.setState({ activeState: value });
  };

  changeDateHandleEvent = (e, donationId) => {
    const { newDonation, donationsToShow } = { ...this.state };
    const date = e.target.value;
    newDonation.date = date;
    newDonation.dateToShow = date;
    newDonation.endDate = date;

    donationsToShow.forEach((element, index) => {
      if (element.id === donationId) {
        donationsToShow[index].endDate = date;
        donationsToShow[index].dateToShow = date;
        donationsToShow[index].edited = true;
      }
    });
    this.setState({
      newDonation,
      donationsToShow,
      FlagclassName: true,
    });
  };

  handleImageIndex = (index) => {
    this.setState({
      imageIndex: index,
    });
  };

  handleChangeImage = async (event) => {
    const {
      newDonation,
      donationsToShow,
      country,
      city,
      organizationId,
      imageIndex,
    } = this.state;
    var newDonationImage = { ...this.state.newDonationImage };
    var fileUpload = event.target.files[0];
    let extension;
    if (fileUpload !== undefined) {
      extension = fileUpload.type.replace("image/", "");
    }
    const random = uuidv4();
    const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
    if (!extension) {
      newDonationImage.loading = true;
      newDonationImage.file = null;
      newDonationImage.image = null;

      this.setState({
        newDonationImage,
        FlagclassName: true,
      });
      return;
    }

    if (key !== undefined) {
      // Function to resize and reduce image quality
      // Save image in AWS

      let flag = true;
      newDonationImage = await handleChangeImageFunction(
        newDonationImage,
        fileUpload,
        extension,
        key,
        400,
        400
      ).catch((res) => {
        const { t } = this.props;
        this.setState({
          titleNotificationModal: t(`${res.title}`),
          contenNotificationModal: `${t(`${res.content}`)} ${res.params}`,
          typeNotificationModal: "error",
          openNotificationModal: true,
        });
        flag = false;
      });
      if (flag !== false) {
        if (newDonationImage.loading === false) {
          if (imageIndex === null) {
            //new news
            newDonationImage.loading = false;
            newDonationImage.modified = true;
            newDonationImage.value = key;
            newDonation.image = key;
            newDonation.imageUri = newDonationImage.image;
          } else {
            // edited new
            donationsToShow[imageIndex].image = key;
            donationsToShow[imageIndex].imageUri = newDonationImage.image;
            donationsToShow[imageIndex].edited = true;
          }

          this.uploadFileToS3Bucket(newDonationImage.file, key);
          this.setState({
            newDonationImage,
            donationsToShow,
            FlagclassName: true,
          });
          this.forceUpdate();
        }
      }
    }
  };

  handleButtonListChange = (buttonList) => {
    let { newDonation } = this.state;
    newDonation.buttons = buttonList;
    this.setState({ newDonation });
  };

  saveDonationTitleEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagclassName: false,
    });
    const nodesToUpdate = [];
    const {
      donationContainerTitle,
      haveChangeInLayout,
      lpSectionId,
      lpSectionLayoutStyle,
    } = { ...this.state };
    let errorInput = false;

    // Verify each one of the values of the component to add
    if (donationContainerTitle) {
      if (donationContainerTitle.value === "") {
        errorInput = true;
      }
      delete donationContainerTitle.modified;
      nodesToUpdate.push(donationContainerTitle);
    }

    if (nodesToUpdate.length > 0) {
      const { t } = this.props;
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          titleNotificationModal: t("modal.wrongFile"),
          contenNotificationModal: t("modal.fieldEmpty"),
          typeNotificationModal: "error",
        });
        this.setState({
          isLoadingButton: false,
          openNotificationModal: true,
        });
      }
    }

    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }

    this.onSaveDonationModal();
  };

  onSaveDonationModal = () => {
    const {
      parentLPSectionComponentId,
      landingPageId,
      donationsToShow,
      newDonation,
      deleteFlag,
    } = this.state;

    this.setState({
      isLoadingButton: true,
    });

    var donationsToValidate = donationsToShow.filter((n) => n.edited === true);
    if (newDonation.title !== "" && newDonation.title !== null) {
      donationsToValidate = donationsToValidate.concat(newDonation);
    }

    donationsToValidate.forEach((newDonation) => {
      const donationNodesToAdd = [];
      const { t } = this.props;
      let donationButtons = newDonation.buttons
        ? newDonation.buttons.map((b) => {
            const button = {
              id: b.id,
              name: b.name,
              externalLink: b.externalLink,
              internalLpSectionComponentId: b.component
                ? b.component.id
                : b.internalLpSectionComponentId
                ? b.internalLpSectionComponentId
                : null,
              isEnabled: b.isEnabled,
            };
            return button;
          })
        : [];
      const donationToAdd = {
        // --- Con esto se crea el registro lpSectionComponent ---
        // Con estos dos valores se obtiene el lpSectionId
        lpId: null, // Este valor se obtiene de la vista anterior
        lpSectionId: this.state.lpSectionId,
        sectionId: 12, // Valor estático para la sección de eventos

        sectionComponentId: 20, // Valor estático de 20, valor para el DonationComponent

        parentLPSectionComponentId, // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente

        // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
        nodesToAdd: [],
        buttons: donationButtons,
      };

      let errorInput = false;

      if (newDonation.title != null) {
        const donationNode = {};

        donationNode.id = newDonation.titleId ? newDonation.titleId : null;
        donationNode.sectionComponentNodeId = 66;
        // Required
        if (newDonation.title === "") {
          errorInput = true;
        }
        donationNode.value = newDonation.title;
        donationNode.nodeType = "DonationComponentTitle";

        donationNodesToAdd.push(donationNode);
      }
      if (newDonation.text != null) {
        const donationNode = {};

        donationNode.id = newDonation.textId ? newDonation.textId : null;
        donationNode.sectionComponentNodeId = 67;
        // Required
        if (newDonation.text === "") {
          errorInput = true;
        }
        donationNode.value = newDonation.text;
        donationNode.nodeType = "DonationComponentText";

        donationNodesToAdd.push(donationNode);
      }
      if (newDonation.endDate != null) {
        const donationNode = {};
        donationNode.id = newDonation.endDateId ? newDonation.endDateId : null;
        donationNode.sectionComponentNodeId = 69;
        if (newDonation.endDate !== "-1") {
          donationNode.value = moment(
            newDonation.endDate,
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          donationNode.value = "-1";
        }
        donationNode.nodeType = "DonationComponentEndDate";

        donationNodesToAdd.push(donationNode);
      }
      if (newDonation.fixedValue != null) {
        const donationNode = {};

        donationNode.id = newDonation.fixedValueId
          ? newDonation.fixedValueId
          : null;
        donationNode.sectionComponentNodeId = 70;

        donationNode.value = newDonation.fixedValue;
        donationNode.nodeType = "DonationComponentFixedValue";

        donationNodesToAdd.push(donationNode);
      }
      if (newDonation.image !== null) {
        const donationNode = {};
        let haveHttp = false;
        var regex = /somosaura-cms.s3.amazonaws.com/;
        haveHttp = regex.test(newDonation.image);
        if (haveHttp) {
          donationNode.value = newDonation.image.slice(
            39,
            newDonation.image.length
          ); //Cut to only save image, not link
        } else {
          donationNode.value = newDonation.image;
        }

        donationNode.id = newDonation.imageId ? newDonation.imageId : null;
        donationNode.sectionComponentNodeId = 68;
        donationNode.nodeType = "DonationComponentImage";

        donationNodesToAdd.push(donationNode);
      }
      if (deleteFlag) {
        const donationNode = {};

        donationNode.id = donationNode.imageId ? donationNode.imageId : null;
        donationNode.sectionComponentNodeId = 68;
        donationNode.value = "";
        donationNode.nodeType = "NewsImage";
        donationNodesToAdd.push(donationNode);
      }

      donationToAdd.id = newDonation.id;
      donationToAdd.lpId = landingPageId;
      donationToAdd.nodesToAdd = donationNodesToAdd;

      if (donationNodesToAdd.length > 0) {
        if (!errorInput) {
          let donationTitle = false;
          let donationText = false;
          let donationDate = false;

          donationNodesToAdd.forEach((obj) => {
            if (obj.nodeType === "DonationComponentTitle") {
              donationTitle = true;
            }
            if (obj.nodeType === "DonationComponentText") {
              donationText = true;
            }
            if (obj.nodeType === "DonationComponentEndDate") {
              donationDate = true;
            }
          });

          if (donationTitle && donationText && donationDate) {
            if (newDonation.id) {
              this.updateSectionComponentFunction(donationToAdd);
            } else {
              this.insertSectionComponentFunction(donationToAdd);
            }
          } else {
            this.setState({
              titleNotificationModal: t("modal.wrongFile"),
              contenNotificationModal: t("modal.fieldEmpty"),
              typeNotificationModal: "error",
            });
            this.setState({
              isLoadingButton: false,
              openNotificationModal: true,
            });
          }
        } else {
          this.setState({
            titleNotificationModal: t("modal.wrongFile"),
            contenNotificationModal: t("modal.fieldEmpty"),
            typeNotificationModal: "error",
          });
          this.setState({
            isLoadingButton: false,
            openNotificationModal: true,
          });
        }
      }
    });
  };

  // Functions
  cleanStateVariables = () => {
    const { t } = this.props;
    let newDonationImage = {
      id: null,
      status: 0,
      s3path: null,
      imageUrl: null,
      image: null,
      loading: false,
    };

    let newDonation = {
      id: null,
      title: "",
      text: "",
      endDate: "-1",
      fixedValue: "-1",
      image: null,
      imageLoading: false,
      buttons: [
        {
          name: t("basicWords.donate"),
          externalLink: "PAYMENT_PLATFORM", //This indicates to buttonContainer component redirection to payment platforms
          isEnabled: 1,
        },
      ],
    };

    this.setState({
      newDonationImage,
      newDonation,
    });
  };

  renderDonations(donationsToShow) {
    let renderedComponents = [];
    const { t } = this.props;
    const { landingPageId, isReadyToEdit } = this.state;
    if (donationsToShow && donationsToShow.length) {
      donationsToShow.forEach((element, index) => {
        renderedComponents.push(
          <div>
            <AccordionComponent
              disabled={isReadyToEdit}
              iconEdit={<Edit2 />}
              // onEditNews={(e) => this.changeToEdit(e)}
              onDeleteNews={(e) => this.handleClickOpenDeleteNews(element.id)}
              iconDelete={<Trash2 />}
              title={element.title}
              body={[
                <div className="accordionContent">
                  {/* First Line */}
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "1%",
                        marginRight: "0.7%",
                      }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.enterTitle")}
                        id="title"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.title}
                      />
                      {element.title.value && element.title.maxLength ? (
                        <span>
                          {element.title.length} / {element.title.maxLength}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  {/* Second Line */}
                  <br />
                  <div className="accordionCenterLine">
                    <div style={{ width: "100%", marginRight: "2%" }}>
                      <ReactQuill
                        readOnly={isReadyToEdit}
                        className="accordionCompleteInputs"
                        onChange={(e) =>
                          this.handleChangeDescription(e, element)
                        }
                        value={element.text}
                        modules={editorConfiguration.modules}
                        formats={editorConfiguration.formats}
                        placeholder={t("basicWords.description")}
                      />
                    </div>
                  </div>
                  <br />

                  {/* Third line */}
                  <div className="accordionCenterLine">
                    <div
                      className="textLogo"
                      style={{ marginLeft: "1%", marginRight: "35%" }}
                    ></div>
                    <div className="textLogo">{t("basicWords.value")}</div>
                  </div>

                  {/* Fourth line */}

                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "35%",
                        marginRight: "1%",
                        marginLeft: "1%",
                      }}
                    >
                      <DatePickerComponent
                        string={t("streaming.endDate")}
                        defaultValue={element.endDate}
                        onChange={(e) =>
                          this.changeDateHandleEvent(e, element.id)
                        }
                        disabled={element.endDate === "-1"}
                      />
                    </div>
                    <div
                      style={{
                        width: "10%",
                        marginRight: "1%",
                        marginLeft: "1%",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            id="unlimitedEndDate"
                            style={{ textAlign: "center" }}
                            checked={element.endDate === "-1"}
                            onChange={(e) =>
                              this.handleChange(e, "", "", "", element.id)
                            }
                          />
                        }
                        label={t("basicWords.unlimited")}
                      />
                    </div>
                    <div
                      style={{
                        width: "35%",
                        marginRight: "1%",
                        marginLeft: "1%",
                      }}
                    >
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="fixedValue">
                          {t("basicWords.value")}
                        </InputLabel>
                        <OutlinedInput
                          id="voluntaryValue"
                          value={element.fixedValue}
                          onChange={(e) =>
                            this.handleChange(e, "", "", "", element.id)
                          }
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          label={t("basicWords.value")}
                          disabled={element.fixedValue === "-1"}
                        />
                      </FormControl>
                    </div>
                    <div
                      style={{
                        width: "10%",
                        marginRight: "1%",
                        marginLeft: "1%",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            id="voluntaryValue"
                            style={{ textAlign: "center" }}
                            checked={element.fixedValue === "-1"}
                            onChange={(e) =>
                              this.handleChange(e, "", "", "", element.id)
                            }
                          />
                        }
                        label={t("basicWords.unlimited")}
                      />
                    </div>
                  </div>

                  {/* Image */}
                  <br />
                  <div className="accordionCenterLine">
                    <div className="textLogo" style={{ marginLeft: "1%" }}>
                      {t("basicWords.image")}
                    </div>
                    {this.renderInputImage(element, index)}
                  </div>

                  <br />
                  <div className="accordionCenterLine">
                    <div className="textLogo" style={{ marginLeft: "1%" }}>
                      {t("buttons.buttons")}
                    </div>
                  </div>
                  <br />
                  <div className="accordionCenterLine">
                    <div style={{ marginLeft: "1%" }}>
                      <ButtonsContainer
                        disabled={isReadyToEdit}
                        isModalVisible={true}
                        lpId={landingPageId}
                        buttonList={element.buttons}
                        handleButtonListChange={(buttonList) =>
                          this.handleButtonListChange(buttonList, element.id)
                        }
                      />
                    </div>
                  </div>
                </div>,
              ]}
            />
            <br />
          </div>
        );
      });
    }
    return renderedComponents;
  }

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  }

  setLayoutModalVisible = (isVisible) => {
    this.setState({ layoutModalVisible: isVisible });
  };

  addDonation = (flag) => {
    if (flag !== this.state.addDonation) {
      this.setState({ addDonation: flag });
    }
  };

  //-----------Update page after modal------------
  updatePage = (lpSectionId, previousPath) => {
    const { landingPageId, activeState } = this.state;
    this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    this.setState({
      previousPath,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  // Render Info
  render() {
    const {
      redirectBack,
      isLoadingButton,
      sectionName,
      donationsToShow,
      organizationId,
      organizationName,
      name,
      subpageId,
      landingPageId,
      donationContainerTitle,
      layoutData,
      lpSectionLayoutStyle,
      FlagclassName,
      goToWithoutSaveModalVisible,
      lpUrl,

      //Notification Modal
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,

      //Modal delete item
      openDeleteNews,
      elementToDeleteId,
    } = this.state;

    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/myWebPage" />;
    }

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />

        <DeleteItemModal
          visible={openDeleteNews}
          onCancel={this.handleCloseDeleteNews}
          onClick={(e) => this.disableDonationEvent(elementToDeleteId)}
        />

        <Card className="card">
          {isLoadingButton === true ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(800%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row componentSpace spaceBreadcrumb">
                <BreadcrumbSection
                  principal={t("sections.donations")}
                  secondary={organizationName}
                  route="./myWebPage"
                  propsComponent={{
                    organizationId,
                    sectionName: sectionName,
                    nameSubpage: name ? name : null,
                    lpId: landingPageId,
                    subpageId: subpageId ? subpageId : null,
                    lpUrl: lpUrl,
                  }}
                  FlagclassName={FlagclassName}
                  onClick={(e) => this.saveAboutEvent(e)}
                  GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                  onCancelGotoWithoutMModal={(e) =>
                    this.onCancelGotoWithoutMModal(e)
                  }
                  goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                />
              </div>

              <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                <Title
                  title={t("basicWords.completeInformationOfYourSection")}
                />
              </div>

              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                <SelectLayoutModal
                  layoutData={layoutData}
                  lpLayoutId={
                    lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                  }
                  handleChange={this.handleChangeLayout}
                />
              </div>

              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                <AccordionComponent
                  title={t("generalUser.sectionContent")}
                  body={[
                    <div className="row justify-content-center componentSpace">
                      <div style={{ width: "70vw", marginRight: "5%" }}>
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.enterTitle")}
                          id={
                            donationContainerTitle
                              ? donationContainerTitle.nodeTypeName
                              : null
                          }
                          onChange={(e, id) =>
                            this.handleChange(e, "", "", "", id)
                          }
                          value={
                            donationContainerTitle
                              ? donationContainerTitle.value
                              : null
                          }
                        />
                      </div>
                    </div>,
                  ]}
                />
              </div>

              {/* Accordion to add donation */}
              {landingPageId ? (
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  {this.renderAddDonations()}
                </div>
              ) : null}

              <div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <Title title={t("basicWords.listDonations")} />
                </div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  {this.renderDonations(donationsToShow)}
                </div>
              </div>
            </>
          )}
        </Card>

        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName: sectionName,
            nameSubpage: name ? name : null,
            lpId: landingPageId,
            subpageId: subpageId ? subpageId : null,
            lpUrl: lpUrl,
          }}
          FlagclassName={FlagclassName}
          onClick={(e) => this.saveDonationTitleEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(DonationsSection);
