import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";

//Queries
import { getOrganizationById } from "../../actions/index";

//Components
import { ButtonComponent, Breadcrumb } from "../../components/index";
import OrganizationDetailsForm from "./components/OrganizationDetailsForm.jsx";
import { ValidatePermissionForComponentPart } from "../../reactUtils/generalUtils/validatePermissionForComponentPart";

//Localization
import { withTranslation } from 'react-i18next';
import { Home } from "react-feather";

// Logos
import AuraLogin from "../../assets/images/loginLogo.png";
import UhooLogin from "../../assets/images/LogoUhooColor.png";

let tableSize = 64 + 68 + 15 + 62 + 87 + 64;

class Organization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,

      loadingInfo: true,
      organization: {
        id: null,
        name: null,
        description: null,
        phone: null,
        address: null,
        countryId: null,
        country: null,
        regionId: null,
        region: null,
        cityId: null,
        city: null,
      },
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({ imageLogin: AuraLogin, helmetPage: "Somos Aura | CMS" });
    } else {
      this.setState({ imageLogin: UhooLogin, helmetPage: "Uhoo | CMS" });
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKaADAAQAAAABAAAAKQAAAADAIIRfAAAFK0lEQVRYCbVZW2icRRQ+ZzaxjWml1jRpo33QCgrxQZB6QYoNGgvWF7Fg+iAWEeODPgQxyfapTyZbQVEiGgQfFDGFpohFkQYhKqKlD0JVpK1oa2torlZTbEJ2/+M38++6t3/ObNPsQPLPzLnMN3POzJw5y7TCIiPt19Ol7KMUSReRbIWadhJuR/0mIp4jlkn04Y/Pk+Fx2tBwjHsm/13JcHw1QiIHDA0NPwWZbgzeRSJNNcszX8EExsE/SgMvHmI+ENUqWzNIeXXTY1CawUB31arcz8c/gdbP+2c+9/MUKUGQ8lrbrZTNvU9CO4tiq1RjmqCG1LP8ytTvmkYVpBxseYhyPAazws/qVBj+m5InuW/2K98IxkeQodYeytF4XQHawe0CYBw3ngdM4ko6gSh61yNTv25jXuCB6ZHKAapAyuDmnUTZY/DBxkrmureZlilFXZWmLzO32ySUO6wAzBLTKXLHyQogWxBWnngxUdouDPZAjKPIUQYy3sVJmwRK2fTRlnXrOT17J93fcQMZ2guws0VVSi2eVC81b4jlH+hYT8Y8DcB/VUlZH7WnSUn539zxOSifldCKVWOega98UOyIa3CN7XCN77DyqUpaWZu5m9Mzh8r60JCDrQ9SNvoa1fLFcoy8u3COOqK7SdxBXanGtvlkEkBHSV88ge+orXsL04kkgJaf+6a/xf8jHtlMHld+Bpl3cM35bhI+7lESd3OATgE68/fJ+oEnvoLzICVn72NPkTkPId/Nf6t0oX9UOqn6sXjwBRfN2GDBVxjurZUIHqmVkLxo8txl8RkXbmnRjIgO0miDAL0EJmnYP0mLC+GgieNBbSlCNNZDrtBKhtQjXsXudgGrn5UDx4vk/CthtYYsEXIX4LNHEKJprZiEM6yEnwPmDq1kyF2ADyw25FcKB3xSFJ+yakMg1Y0DeeCz5tZjxZDjh1YyKB+YJPBZU87YCStF392hlVAU10biOQvyzwBzg0oXs6TTeVmlRxK492XSIJIJgWxTB2E+qdKN+VGlM7WqdDyL7d66EGC6WaNz+uJZTHQskYf5DN2Y+iSRVujk0OnC5w2leKLAn/gVuhtBaHMirdC5tvk5xJsfF5ruy3QcZ+TjwYSARDvK5CobSCywu7vnl6eh0A+EzR5OTyevVolSGWy7DUdOB0W5U5SeOcOs71zJbG2n6MoF79Vpg+WNjS0mP1P9kS7SU4LFW+X01G88MHWU98+eDgF0SnJLPV6AjkHGLb74NmE67B05Zu7CC3KXznN1VHmjZQvyRS8HpFxAHYO8r+MIzPSLKhDJ2/L6LRtVnhqJLuJe5PdUFyOkYpAzsiodSO6cwCvQ9OpjyDZaWhyTkXsadb4aqJnhDADuDnD2F5JaZbeJDG46andkQPhLalq3h3vPXgrwVZHdBOfPDcMPn68ilnYgR4RXaWehKzZ3oUXUC7PrzwGih2nx8s+Y0L7CQ6ko7q9JZvO9NH/umzBA5IaQxCrVVLaSlgBlnZTLfoHqdaWMiXXmHzDom8RNn3L6j6o3tLx1+xq6vLCLONoHCz2RqKO005PBqAJpZWSwZS8+H+nHQ6l2inDr/Iqe04jNFqBhLWS3wSp3ANyaMk6tUWsuqKAD5uzHAEOFdl2/Lv1iXkpKVtlxE1fSEsSG/UOtC6j4byLLeK3lWvKTlGndUX+ANtNrtldm0SrnnRgr4g5+hATZtbqVfM48vcKcuQy1dGMbfAh8iRNYMW6XWVulXx+wYUZhZiXtEoDJSC4LT6/m7zj/AexV0r66K8xIAAAAAElFTkSuQmCC";
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const organizationId = sessionStorage.getItem("organization_id");
    this.getOrganizationByIdFunction(organizationId);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  //Queries
  async getOrganizationByIdFunction(id) {
    let organization = {};
    let response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        organization = {
          id: response.data.data.id,
          name: response.data.data.name,
          description: response.data.data.description,
          phone: response.data.data.phone,
          address: response.data.data.address,
          countryId: response.data.data.countryId,
          country: response.data.data.country,
          regionId: response.data.data.regionId,
          region: response.data.data.region,
          cityId: response.data.data.cityId,
          city: response.data.data.city,
          isEnabled: response.data.data.isEnabled,
        };
      }
      this.setState({
        organization,
        loadingInfo: false,
      });
    }
  }

  render() {
    const { privileges } = this.props;
    const {t} = this.props
    return (
      <Fragment>
        <Card>
          <div className="row componentSpace">
            <br />
            <Breadcrumb
              principal={this.state.organization.name}
              icon={<Home />}
            />
            <br />
          </div>
          <div style={{ height: this.state.height }}>
            <OrganizationDetailsForm organization={this.state.organization} />
          </div>

          {ValidatePermissionForComponentPart(
            "EDIT_ORGANIZATION",
            privileges
          ) ? (
            <div className="row componentSpace buttonAtTheBottom">
              <div className="col-6 col-md-3">
                <ButtonComponent
                  path={"./editOrganization"}
                  shape="round"
                  props={{
                    organizationId: this.state.organization.id,
                    countryId: this.state.organization.countryId,
                    regionId: this.state.organization.regionId,
                    cityId: this.state.organization.cityId,
                    path: "./organization",
                  }}
                  text={t('basicWords.edit')}
                />
              </div>
            </div>
          ) : null}
        </Card>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
    userSession: state.userSession.userSession,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(Organization));
