import React, { Component } from "react";

// Queries
import { getPaymentByOrganizationId } from "../../../actions/index";

//Components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import moment from "moment";

// const { Column } = Table;

import { SelectSingle } from "../../../components/index";

//Localization
import { withTranslation } from "react-i18next";
// import GetPaymentStatusCatalog from "../../../reactUtils/systemVariables/languageLocalization/PaymentStatusLocalization.jsx";

class PaymentsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingInfo: false,

      paymentPlatformId: 0,
      paymentStatusId: 0,

      payments: [],

      // pagination
      registersAmount: 0,
      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "ASC",
      initialPage: 0,

      flagChange: false,
    };
  }
  componentDidMount() {
    const { organizationId } = this.props;
    const {
      paymentPlatformId,
      paymentStatusId,
      page,
      pageSize,
      attribute,
      order,
    } = this.state;
    this.getPaymentsByOrganizationId(
      organizationId,
      paymentPlatformId,
      paymentStatusId,
      page,
      pageSize,
      attribute,
      order
    );
  }

  async getPaymentsByOrganizationId(
    organizationId,
    paymentPlatformId,
    paymentStatusId,
    page,
    pageSize,
    attribute,
    order
  ) {
    let payments = this.state.payments;
    let registersAmount = 0;
    if (page === 0) {
      return payments;
    } else {
      let response = await getPaymentByOrganizationId(
        organizationId,
        paymentPlatformId,
        paymentStatusId,
        page,
        pageSize,
        attribute,
        order
      );
      if (String(response).includes("Error:")) {
      } else {
        let flag = this.state.flagChange;
        if (payments.length === 10 && !flag) {
          payments = [...payments, ...response.data.data.content];
          registersAmount = response.data.data.totalElements;
          this.setState({
            flagChange: true,
          });
        } else {
          payments = [...payments, ...response.data.data.content];
          registersAmount = response.data.data.totalElements;
        }
      }
    }
    this.setState({
      payments,
      registersAmount,
      loadingInfo: false,
    });
  }

  onSelect = (option, event) => {
    const { organizationId } = this.props;
    const { paymentPlatformId, page, pageSize, attribute, order } = this.state;
    const val = option.value;
    switch (event.name) {
      case "paymentStatus":
        this.getPaymentsByOrganizationId(
          organizationId,
          paymentPlatformId,
          val,
          page,
          pageSize,
          attribute,
          order
        );
        this.setState({ paymentStatusId: val });
        break;
    }
  };

  // Pagination
  onPaginationChange = (page, pageSize) => {
    const { organizationId } = this.props;
    const { paymentPlatformId, paymentStatusId, attribute, order } = this.state;
    this.setState({
      page,
      pageSize,
    });
    this.getPaymentsByOrganizationId(
      organizationId,
      paymentPlatformId,
      paymentStatusId,
      page,
      pageSize,
      attribute,
      order
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, initialPage: newPage });
    if (this.state.payments.length < this.state.registersAmount) {
      this.onPaginationChange(newPage + 1, this.state.pageSize);
    } else {
      return this.state.payments;
    }
  };

  handleChangeRowsPerPage = (event) => {
    if (event !== undefined) {
      this.setState({
        pageSize: parseInt(event.target.value, 10) + 1,
        page: 0,
      });
    }
  };

  getPaymentStatusCatalog = () => {
    const { t } = this.props;
    return [
      { value: 0, label: t("basicWords.all") },
      { value: 1, label: t("basicWords.approved") },
      { value: 2, label: t("basicWords.pending") },
      { value: 3, label: t("basicWords.rejected") },
      { value: 4, label: t("basicWords.expired") },
    ];
  };

  render() {
    const {
      paymentStatusId,
      payments,
      page,
      pageSize,
      initialPage,
      registersAmount,
    } = this.state;
    const { height } = this.props;
    const { t } = this.props;
    return (
      <div style={{ minHeight: height }}>
        <div className="componentSpace">
          <div className="col-md-6">
            <SelectSingle
              defaultValue={paymentStatusId}
              text={t("basicWords.status")}
              isReq
              isDisabled={false}
              isLoading={false}
              name="paymentStatus"
              onChange={(option, event) => this.onSelect(option, event)}
              options={this.getPaymentStatusCatalog()}
            />
          </div>
        </div>
        <div className="row contentForm">
          <div
            className="col-12 componentSpace"
            style={{ marginTop: "-15px", overflowX: true }}
          >
            <TableContainer style={{ maxHeight: "440" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("basicWords.id")}</TableCell>
                    <TableCell>{t("donations.paymentPlatform")}</TableCell>
                    <TableCell>{t("basicWords.status")}</TableCell>
                    <TableCell>{t("basicWords.name")}</TableCell>
                    <TableCell>{t("basicWords.value")}</TableCell>
                    <TableCell>{t("donations.tax")}</TableCell>
                    <TableCell> {t("generalUser.username")} </TableCell>
                    <TableCell> {t("basicWords.date")} </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(payments.length < initialPage * pageSize
                    ? payments
                    : payments.slice(
                        initialPage * pageSize,
                        initialPage * pageSize + pageSize
                      )
                  ).map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.paymentPlatformName}</TableCell>
                        <TableCell>{row.paymentStatus}</TableCell>
                        <TableCell>{row.paymentName}</TableCell>
                        <TableCell>{row.paymentValue}</TableCell>
                        <TableCell>{row.taxValue}</TableCell>
                        <TableCell>{row.buyerFullName}</TableCell>
                        <TableCell>
                          {moment(row.startDate).format("YYYY-MM-DD HH:mm:ss")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={["5", "10", "25"]}
              component="div"
              disabled={payments.length === 0}
              count={registersAmount}
              rowsPerPage={pageSize}
              page={initialPage}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PaymentsForm);
