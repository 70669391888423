// Roles

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Card,
} from "@material-ui/core";
import { Edit, Trash2, Home, Plus } from "react-feather";

// Components
import {
  SelectSingle,
  Title,
  ButtonComponent,
  Breadcrumb,
  GeneralModal,
  NotificationModal,
} from "../../components/index";

// Queries
import {
  getOrganizationTypes,
  getOrganizationsByOrganizationTypeId,
  getRolesByOrganizationIdPaged,
  disableRole,
} from "../../actions/index";

import { ValidatePermissionForComponentPart } from "../../reactUtils/generalUtils/validatePermissionForComponentPart";

//Localization
import { withTranslation } from 'react-i18next';

// Logos
import AuraLogin from "../../assets/images/loginLogo.png";
import UhooLogin from "../../assets/images/LogoUhooColor.png";

const tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;

class RolesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,

      organizationTypes: [],
      organizationTypeId: null,

      organizations: [],
      organizationId: null,
      organizationIdQuery: null,
      organizationName: null,
      registers: [],

      loadingInfo: false,

      // pagination
      registersAmount: 0,
      page: 1,
      initialPage: 0,
      pageSize: 10,
      attribute: "id",
      order: "ASC",

      openDialog: false,
      titleNotificationModal: "",
      openNotificationModal: false,
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({ imageLogin: AuraLogin, helmetPage: "Somos Aura | CMS" });
    } else {
      this.setState({ imageLogin: UhooLogin, helmetPage: "Uhoo | CMS" });
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKaADAAQAAAABAAAAKQAAAADAIIRfAAAFK0lEQVRYCbVZW2icRRQ+ZzaxjWml1jRpo33QCgrxQZB6QYoNGgvWF7Fg+iAWEeODPgQxyfapTyZbQVEiGgQfFDGFpohFkQYhKqKlD0JVpK1oa2torlZTbEJ2/+M38++6t3/ObNPsQPLPzLnMN3POzJw5y7TCIiPt19Ol7KMUSReRbIWadhJuR/0mIp4jlkn04Y/Pk+Fx2tBwjHsm/13JcHw1QiIHDA0NPwWZbgzeRSJNNcszX8EExsE/SgMvHmI+ENUqWzNIeXXTY1CawUB31arcz8c/gdbP+2c+9/MUKUGQ8lrbrZTNvU9CO4tiq1RjmqCG1LP8ytTvmkYVpBxseYhyPAazws/qVBj+m5InuW/2K98IxkeQodYeytF4XQHawe0CYBw3ngdM4ko6gSh61yNTv25jXuCB6ZHKAapAyuDmnUTZY/DBxkrmureZlilFXZWmLzO32ySUO6wAzBLTKXLHyQogWxBWnngxUdouDPZAjKPIUQYy3sVJmwRK2fTRlnXrOT17J93fcQMZ2guws0VVSi2eVC81b4jlH+hYT8Y8DcB/VUlZH7WnSUn539zxOSifldCKVWOega98UOyIa3CN7XCN77DyqUpaWZu5m9Mzh8r60JCDrQ9SNvoa1fLFcoy8u3COOqK7SdxBXanGtvlkEkBHSV88ge+orXsL04kkgJaf+6a/xf8jHtlMHld+Bpl3cM35bhI+7lESd3OATgE68/fJ+oEnvoLzICVn72NPkTkPId/Nf6t0oX9UOqn6sXjwBRfN2GDBVxjurZUIHqmVkLxo8txl8RkXbmnRjIgO0miDAL0EJmnYP0mLC+GgieNBbSlCNNZDrtBKhtQjXsXudgGrn5UDx4vk/CthtYYsEXIX4LNHEKJprZiEM6yEnwPmDq1kyF2ADyw25FcKB3xSFJ+yakMg1Y0DeeCz5tZjxZDjh1YyKB+YJPBZU87YCStF392hlVAU10biOQvyzwBzg0oXs6TTeVmlRxK492XSIJIJgWxTB2E+qdKN+VGlM7WqdDyL7d66EGC6WaNz+uJZTHQskYf5DN2Y+iSRVujk0OnC5w2leKLAn/gVuhtBaHMirdC5tvk5xJsfF5ruy3QcZ+TjwYSARDvK5CobSCywu7vnl6eh0A+EzR5OTyevVolSGWy7DUdOB0W5U5SeOcOs71zJbG2n6MoF79Vpg+WNjS0mP1P9kS7SU4LFW+X01G88MHWU98+eDgF0SnJLPV6AjkHGLb74NmE67B05Zu7CC3KXznN1VHmjZQvyRS8HpFxAHYO8r+MIzPSLKhDJ2/L6LRtVnhqJLuJe5PdUFyOkYpAzsiodSO6cwCvQ9OpjyDZaWhyTkXsadb4aqJnhDADuDnD2F5JaZbeJDG46andkQPhLalq3h3vPXgrwVZHdBOfPDcMPn68ilnYgR4RXaWehKzZ3oUXUC7PrzwGih2nx8s+Y0L7CQ6ko7q9JZvO9NH/umzBA5IaQxCrVVLaSlgBlnZTLfoHqdaWMiXXmHzDom8RNn3L6j6o3tLx1+xq6vLCLONoHCz2RqKO005PBqAJpZWSwZS8+H+nHQ6l2inDr/Iqe04jNFqBhLWS3wSp3ANyaMk6tUWsuqKAD5uzHAEOFdl2/Lv1iXkpKVtlxE1fSEsSG/UOtC6j4byLLeK3lWvKTlGndUX+ANtNrtldm0SrnnRgr4g5+hATZtbqVfM48vcKcuQy1dGMbfAh8iRNYMW6XWVulXx+wYUZhZiXtEoDJSC4LT6/m7zj/AexV0r66K8xIAAAAAElFTkSuQmCC";
    }
    const { page, pageSize, attribute, order } = this.state;
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const organizationId = sessionStorage.getItem("organization_id");

    if (parseInt(organizationId, 10) === 1) {
      this.getOrganizationTypesFunction();
    } else {
      this.getRolesByOrganizationId(
        organizationId,
        page,
        pageSize,
        attribute,
        order
      );
      this.setState({
        organizationId,
        organizationIdQuery: organizationId,
        loadingInfo: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getOrganizationTypesFunction() {
    let organizationTypes = [];
    const response = await getOrganizationTypes();

    if (String(response).includes("Error:")) {
    } else {
      organizationTypes = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ organizationTypes });
  }

  async getOrganizationByOrganizationTypeId(userTypeId) {
    let organizations = [];
    const response = await getOrganizationsByOrganizationTypeId(userTypeId);
    if (String(response).includes("Error:")) {
    } else {
      organizations = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ organizations });
  }

  async getRolesByOrganizationId(
    organizationId,
    page,
    pageSize,
    attribute,
    order
  ) {
    let registers = this.state.registers;
    let registersAmount = 0;
    if (page === 0) {
      return registers;
    } else {
      const response = await getRolesByOrganizationIdPaged(
        organizationId,
        page,
        pageSize,
        attribute,
        order
      );
      if (String(response).includes("Error:")) {
      } else {
        registers = [...registers, ...response.data.data.content];
        registersAmount = response.data.data.totalElements;
      }
    }
    this.setState({
      registers,
      registersAmount,
      loadingInfo: false,
      openDialog: false,
    });
  }

  async disableRoleFunction(roleId) {
    const { organizationIdQuery, page, pageSize, attribute, order } =
      this.state;
    const response = await disableRole(roleId);
    const { t } = this.props

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal:
          t('modal.internalServerErrorContactSupport'),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.disablingSuccessful'),
        contenNotificationModal: t('modal.roleSuccesfullyDisabled'),
        typeNotificationModal: "success",
      });
      this.getRolesByOrganizationId(
        organizationIdQuery,
        page,
        pageSize,
        attribute,
        order
      );
    }
    this.setState({
      openNotificationModal: true,
    });
  }

  // Events
  onSelect = (e, event) => {
    const { page, pageSize, attribute, order } = this.state;
    let option = e.target;

    switch (event) {
      case "organizationType":
        this.setState({
          organizationTypeId: option.value,
          registers: [],
          organizationId: null,
          organizationIdQuery: 1,
        });
        if (option.value === 1) {
          this.setState({
            loadingInfo: true,
            organizationName: option.label,
          });
          this.getRolesByOrganizationId(1, page, pageSize, attribute, order);
        } else {
          this.getOrganizationByOrganizationTypeId(option.value);
        }
        break;
      case "organization":
        this.setState({
          organizationId: option.value,
          organizationIdQuery: option.value,
          organizationName: option.label,
          loadingInfo: true,
          registers: [],
        });
        this.getRolesByOrganizationId(
          option.value,
          page,
          pageSize,
          attribute,
          order
        );
        break;
      default:
        break;
    }
  };

  disableRoleEvent = (id) => {
    this.setState({
      loadingInfo: true,
    });
    this.disableRoleFunction(id);
  };

  // Pagination
  showPagination = (page, pageSize) => {
    const { organizationIdQuery, attribute, order } = this.state;
    this.getRolesByOrganizationId(
      organizationIdQuery,
      page,
      pageSize,
      attribute,
      order
    );
    this.setState({
      loadingInfo: true,
      page,
      pageSize,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, initialPage: newPage });
    if (this.state.registers.length < this.state.registersAmount) {
      this.showPagination(newPage + 1, this.state.pageSize);
    } else {
      return this.state.registers;
    }
  };

  handleChangeRowsPerPage = (event) => {
    if (event !== undefined) {
      this.setState({
        pageSize: parseInt(event.target.value, 10) + 1,
        page: 0,
      });
    }
  };

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  // Info to render
  render() {
    const userSession = { ...this.props.userSession };
    const { privileges } = this.props;
    const {
      organizationTypeId,
      organizationTypes,
      organizationId,
      organizations,
      organizationIdQuery,
      organizationName,
      registers,
      loadingInfo,
      height,
      initialPage,
      pageSize,
      registersAmount,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const { t } = this.props

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Card className="card">
          <div className="row componentSpace">
            <Breadcrumb
              principal={t('generalUser.roles')}
              icon={<Home />}
              secondary={t('sections.home')}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {userSession.organizationId == 1 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="col-md-4"
                  style={{
                    width: "20vw",
                    paddingLeft: "20px",
                    paddingRight: "15px",
                  }}
                >
                  <SelectSingle
                    selectedOptions={organizationTypeId}
                    text={t('basicWords.typeUser') + "*"}
                    onChange={(option) =>
                      this.onSelect(option, "organizationType")
                    }
                    options={organizationTypes}
                  />
                </div>
                {organizationTypeId != null && organizationTypeId !== 1 ? (
                  <div
                    className="col-md-4"
                    style={{ width: "20vw", paddingRight: "15px" }}
                  >
                    <SelectSingle
                      selectedOptions={organizationId}
                      text={t('organization.organization') + "*"}
                      onChange={(option) =>
                        this.onSelect(option, "organization")
                      }
                      options={organizations}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            {ValidatePermissionForComponentPart("ADD_GROUPS", privileges) ? (
              <div className="col-md-4" style={{ width: "20vw" }}>
                <ButtonComponent
                  text={t('buttons.addUser')}
                  icon={<Plus />}
                  path="./addRole"
                  props={{
                    organizationTypeId,
                    organizationId: organizationIdQuery,
                    organizationName,
                  }}
                  shape="round"
                  isDisabled={organizationIdQuery == null}
                />
              </div>
            ) : null}
          </div>
          <div className="row componentSpace">
            <Title title={t('generalUser.rolesList')} />
          </div>

          <div className="row componentSpace">
            <div className="col-12">
              <TableContainer style={{ maxHeight: "440" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('basicWords.name')}</TableCell>
                      <TableCell>{t('basicWords.description')}</TableCell>
                      <TableCell></TableCell>
                      {ValidatePermissionForComponentPart(
                        "EDIT_USER",
                        privileges
                      ) ? (
                        <TableCell></TableCell>
                      ) : null}
                      {ValidatePermissionForComponentPart(
                        "DISABLE_USER",
                        privileges
                      ) ? (
                        <TableCell></TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registers
                      .slice(
                        initialPage * pageSize,
                        initialPage * pageSize + pageSize
                      )
                      .map((row) => {
                        return (
                          <TableRow key={row.name}>
                            <TableCell>{`${row.name} ${row.lastName1 ? row.lastName1 : ""
                              } ${row.lastName2 ? row.lastName2 : ""
                              }`}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>
                              <div className="containerIconsTable">
                                <div className="bgIconEdit">
                                  <Link
                                    to={{
                                      pathname: "/editGroup",
                                      props: {
                                        organizationTypeId,
                                        organizationId: organizationIdQuery,
                                        organizationName,
                                        groupId: row.id,
                                        countryId: row.countryId,
                                        regionId: row.regionId,
                                        cityId: row.cityId,
                                      },
                                    }}
                                  >
                                    <Edit className="iconEdit" />
                                  </Link>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="containerIconsTable">
                                <div className="bgIconTrash">
                                  <Trash2
                                    className="iconTrash"
                                    onClick={(e) => this.handleClickOpen()}
                                  />
                                </div>
                              </div>
                              <GeneralModal
                                visible={this.state.openDialog}
                                title={t('basicWords.deleteRegister')}
                                footer={[
                                  <div
                                    className="row"
                                    key={1}
                                    style={{ display: "flex" }}
                                  >
                                    <div className="col-6" style={{ marginRight: "5%" }}>
                                      <ButtonComponent
                                        // icon={<LeftCircleOutlined />}
                                        onClick={(e) => this.handleClose()}
                                        props={null}
                                        shape="round"
                                        text={t('basicWords.cancelText')}
                                      />
                                    </div>
                                    <div>
                                      <ButtonComponent
                                        onClick={() =>
                                          this.disableRoleEvent(row.id)
                                        }
                                        props={null}
                                        shape="round"
                                        text={t('buttons.addUser')}
                                      />
                                    </div>
                                  </div>,
                                ]}
                                onClick={(e) => this.handleClose()}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                disabled={registers.length === 0}
                count={registersAmount}
                rowsPerPage={pageSize}
                page={initialPage}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Card>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
    userSession: state.userSession.userSession,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(RolesList));
