import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import SignIn from './pages/login/SignIn.jsx'
import CMSMainContent from './pages/global/CMSMainContent.jsx'
import ForgotPass from './pages/login/ForgotPass.jsx'

// import '../../App.css';

class CMS extends Component {

   constructor(props) {
      super(props);

      this.state = {
         isLoggedIn: false
      }
   }

   static propTypes = {
      children: PropTypes.object.isRequired
   }

   componentDidMount() {
      // Verify if there is a token in the session storage
      // If not, redirect to login
      if (sessionStorage.getItem("access_token") != null) {
         this.setState({
            isLoggedIn: true
         })
      } else {
         const currPath = window.location.pathname;
         this.setState({
            isLoggedIn: false,
            isSignIn: currPath !== "/forgotpass"
         })
      }
   }

   renderCMS = (children) => (
      <CMSMainContent body={children} />
   )

   renderLogin = () => {
      return <SignIn />
   }

   renderForgotPass = () => {
      return <ForgotPass />
   }

   render() {
      const { children } = this.props;
      const { isLoggedIn, isSignIn } = this.state

      return (
         <div className="App" style={{height: "95vh"}}>
            {isLoggedIn ? this.renderCMS(children) : (isSignIn ? this.renderLogin() : this.renderForgotPass())}
         </div>
      );
   }
}

export default CMS;
