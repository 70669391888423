import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import editorConfiguration from "../../../../components/textEditorConfig";

import "../css/sections.scss";

// Queries
import {
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
  disableSection,
  getLandingPageSections,
  getFile,
  updateLpSectionComponentNodeImage,
  updateLpSection,
} from "../../../../actions/index";

import {
  Card,
  CircularProgress,
  Backdrop,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Plus, X } from "react-feather";

// Components
import {
  Title,
  ButtonComponent,
  NotificationModal,
  BreadcrumbSection,
  GeneralInput,
  AccordionComponent,
  GeneralModal,
  ButtonsFooterComponent,
} from "../../../../components/index";

import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Localization
import { withTranslation } from "react-i18next";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class AboutUsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      loadingInfo: true,

      aboutTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        minLength: null,
        maxLength: null,
        length: null,
        modified: false,
      },
      aboutText: {
        id: null,
        value: null,
        nodeTypeName: null,
        minLength: null,
        maxLength: null,
        length: null,
        modified: false,
      },
      missionTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        minLength: null,
        maxLength: null,
        length: null,
        modified: false,
      },
      missionText: {
        id: null,
        value: null,
        nodeTypeName: null,
        minLength: null,
        maxLength: null,
        length: null,
        modified: false,
      },
      missionTextValue: null,
      visionTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        minLength: null,
        maxLength: null,
        length: null,
        modified: false,
      },
      visionText: {
        id: null,
        value: null,
        nodeTypeName: null,
        minLength: null,
        maxLength: null,
        length: null,
        modified: false,
      },
      vissionTextValue: null,
      multimedia: {
        id: null,
        s3path: null,
        file: null,
        image: null,
        loading: false,
        value: null,
        imageUrl: null,
        videoUrl: null,
      },

      informationToUpdate: [],

      loading: false,
      FlagclassName: false,
      goToWithoutSaveModalVisible: false,

      lpUrl: "",

      // Modals
      isLoadingButton: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contentNotificationModal: "",
      typeNotificationModal: "",

      //Modal general
      openDialog: false,

      //Name modal
      openModalToChangeName: false,

      //Select image or video modals
      openImageOrVideoModal: false,
      openImageFileOrLinkModal: false,
      openImageFileModal: false,
      openImageLinkModal: false,
      openSelectVideoModal: false,
    };
  }

  componentDidMount() {
    // resize function
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const { props } = this.props.location;
    if (props) {
      this.getAboutUsInformation(props.landingPageId, props.sectionId);
      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
    } else {
      this.warning();
    }
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };
  async getAboutUsInformation(lpId, lpSectionId) {
    const { t } = this.props;
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "ABOUT"
    );
    if (String(response).includes("Error:")) {
      // Modal
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contentNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
    } else {
      // Get all the information about the section components
      var {
        aboutTitle,
        aboutText,
        missionTitle,
        missionText,
        missionTextValue,
        visionTitle,
        visionText,
        vissionTextValue,
        multimedia,
      } = { ...this.state };
      const sectionInfo = response.data.data;
      const aboutComponent = sectionInfo.components.find(
        (comp) => comp.name === "AboutComponent"
      );
      aboutTitle.id = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsTitle")
            .id
        : null;
      aboutTitle.value = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsTitle")
            .value
        : null;
      aboutTitle.nodeTypeName = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsTitle")
            .nodeType
        : null;
      aboutTitle.minLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsTitle")
            .minLength
        : null;
      aboutTitle.maxLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsTitle")
            .maxLength
        : null;
      aboutTitle.length = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsTitle")
            .value.length
        : null;

      aboutText.id = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsText")
            .id
        : null;
      aboutText.value = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsText")
            .value
        : null;
      aboutText.nodeTypeName = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsText")
            .nodeType
        : null;
      aboutText.minLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsText")
            .minLength
        : null;
      aboutText.maxLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsText")
            .maxLength
        : null;
      aboutText.length = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsText")
            .value.length
        : null;

      missionTitle.id = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionTitle")
            .id
        : null;
      missionTitle.value = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionTitle")
            .value
        : null;
      missionTitle.nodeTypeName = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionTitle")
            .nodeType
        : null;
      missionTitle.minLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionTitle")
            .minLength
        : null;
      missionTitle.maxLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionTitle")
            .maxLength
        : null;
      missionTitle.length = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionTitle")
            .value.length
        : null;

      missionText.id = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionText")
            .id
        : null;
      missionText.value = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionText")
            .value
        : null;
      missionTextValue = missionText.value;
      missionText.nodeTypeName = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionText")
            .nodeType
        : null;
      missionText.minLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionText")
            .minLength
        : null;
      missionText.maxLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionText")
            .maxLength
        : null;
      missionText.length = aboutComponent.nodes.find(
        (node) => node.nodeType === "MissionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "MissionText")
            .value.length
        : null;

      visionTitle.id = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionTitle")
            .id
        : null;
      visionTitle.value = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionTitle")
            .value
        : null;
      visionTitle.nodeTypeName = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionTitle")
            .nodeType
        : null;
      visionTitle.minLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionTitle")
            .minLength
        : null;
      visionTitle.maxLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionTitle")
            .maxLength
        : null;
      visionTitle.length = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionTitle"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionTitle")
            .value.length
        : null;

      visionText.id = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionText").id
        : null;
      visionText.value = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionText")
            .value
        : null;
      vissionTextValue = visionText.value;
      visionText.nodeTypeName = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionText")
            .nodeType
        : null;
      visionText.minLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionText")
            .minLength
        : null;
      visionText.maxLength = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionText")
            .maxLength
        : null;
      visionText.length = aboutComponent.nodes.find(
        (node) => node.nodeType === "VisionText"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "VisionText")
            .value.length
        : null;

      multimedia.id = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsImage"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsImage")
            .id
        : null;
      multimedia.s3path = aboutComponent.nodes.find(
        (node) => node.nodeType === "AboutUsImage"
      )
        ? aboutComponent.nodes.find((node) => node.nodeType === "AboutUsImage")
            .value
        : null;
      multimedia.file = null;
      multimedia.imageUrl = null;
      multimedia.image = null;
      multimedia.loading = true;
      let customName = response.data.data.customName
        ? response.data.data.customName
        : "";

      this.setState({
        aboutTitle,
        aboutText,
        missionTitle,
        missionText,
        visionTitle,
        visionText,
        multimedia,
        loadingInfo: false,
        FlagclassName: false,
        missionTextValue,
        vissionTextValue,
        customName,
      });

      // Get image or video
      var responseImg = null;

      if (!multimedia.s3path.includes("https://")) {
        // checks if it's an uploaded image
        responseImg = await getFile(
          "https://somosaura-cms.s3.amazonaws.com/" + multimedia.s3path
        );
        multimedia.image = responseImg;
        multimedia.loading = false;
      } else if (
        !multimedia.s3path.includes("https://www.youtube.com/watch?")
      ) {
        // checks if it's an image link
        responseImg = await getFile(multimedia.s3path);
        multimedia.imageUrl = responseImg;
        multimedia.loading = false;
      } else if (multimedia.s3path.includes("https://www.youtube.com/watch?")) {
        // checks if it's video link
        multimedia.videoUrl = multimedia.s3path;
      } else {
        multimedia.image = null;
        multimedia.loading = false;
      }

      this.setState({
        multimedia,
      });
    }
  }

  async uploadFileToS3Bucket(file, key) {
    await updateLpSectionComponentNodeImage(file, key);
  }

  async updateNode(nodesToUpdate) {
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contentNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.editionSuccess"),
        contentNotificationModal: t("modal.aboutSuccessfullyEdited"),
        typeNotificationModal: "success",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
    }
    this.setState({
      isLoadingButton: false,
    });
  }

  async getLandingPageInfoFunction(id) {
    let registers = [];
    let response = await getLandingPageSections(id);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contentNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
    } else {
      registers = response.data.data.sections;
      var positionSections = registers.map((element) => element.positionInLp);
      const lastSectionPosition = Math.max.apply(null, positionSections);
      this.setState({ posFooter: lastSectionPosition });
      registers = response.data.data.sections.sort(compare);

      function compare(a, b) {
        if (a.positionInLp === null) {
          a.positionInLp = lastSectionPosition + 1;
        }
        if (a.positionInLp > b.positionInLp) {
          return 1;
        }
        if (b.positionInLp > a.positionInLp) {
          return -1;
        }
        return 0;
      }

      this.setState({
        organizationId: id,
        organizationName: response.data.data.organizationName,
        landingPageId: response.data.data.landingPageId,
        country: response.data.data.country,
        region: response.data.data.region,
        city: response.data.data.city,
        registers,
        loadingInfo: false,
        lpThemeName: response.data.data.lpThemeName,
        lpThemeId: response.data.data.lpThemeId,
      });
    }
  }

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openNotificationModal: true,
      openModalToChangeName: false,
    });
  }

  async disableSectionFunction(id) {
    this.setState({
      loadingInfo: true,
    });
    let response = await disableSection(id);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contentNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.disablingSuccessful"),
        contentNotificationModal: t("modal.sectionDisabledSuccessful"),
        typeNotificationModal: "success",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
      this.getLandingPageInfoFunction(this.state.organizationId);
    }
    this.setState({
      loadingInfo: false,
      isLoadingButton: false,
    });
  }

  disableSectionEvent = (id) => {
    this.disableSectionFunction(id);
  };

  // Events
  handleChange = (event, dataIndex, data, flagRichText) => {
    let id = null;
    let value = null;

    if (event && event.target) {
      id = event.target.id;
      value = event.target.value;
    } else {
      id = dataIndex;
      value = data;
    }
    if (flagRichText) {
      id = dataIndex;
      value = event;
    }

    this.setState({
      FlagclassName: false,
    });

    const {
      aboutTitle,
      aboutText,
      missionTitle,
      missionText,
      visionTitle,
      visionText,
    } = { ...this.state };
    var multimedia = { ...this.state.multimedia };

    let dataToCompareMissionText = "";
    let dataToCompareVissionText = "";
    let regex = /<p>/;
    let resultMission = regex.test(missionText.value);
    let resultVission = regex.test(visionText.value);
    // let resultData = regex.test(data);
    if (resultMission) {
      // dataToCompareMissionText = parse(missionText.value);
    } else {
      dataToCompareMissionText = missionText.value;
    }

    if (resultVission) {
      // dataToCompareVissionText = parse(visionText.value);
    } else {
      dataToCompareVissionText = visionText.value;
    }

    if (dataIndex === "MissionText") {
      if (data !== dataToCompareMissionText) {
        this.setState({
          FlagclassName: true,
        });
      }
    }
    if (dataIndex === "VisionText") {
      if (data !== dataToCompareVissionText) {
        this.setState({
          FlagclassName: true,
        });
      }
    }

    if (dataIndex === "videoUrl") {
      multimedia.videoUrl = value;
      this.setState({
        multimedia,
        FlagclassName: true,
      });
    }

    if (id) {
      if (id === "AboutUsTitle") {
        if (value.length <= aboutTitle.maxLength) {
          aboutTitle.value = value;
          aboutTitle.modified = true;
          aboutTitle.length = value.length;
        }
      }
      if (id === "AboutUsText") {
        if (value.length <= aboutText.maxLength) {
          aboutText.value = value;
          aboutText.modified = true;
          aboutText.length = value.length;
        }
      }
      if (id === "MissionTitle") {
        if (value.length <= missionTitle.maxLength) {
          missionTitle.value = value;
          missionTitle.modified = true;
          missionTitle.length = value.length;
        }
      }
      if (id === "MissionText") {
        if (value.length <= missionText.maxLength) {
          missionText.value = value;
          missionText.modified = true;
          missionText.length = value.length;
        }
      }
      if (id === "VisionTitle") {
        if (value.length <= visionTitle.maxLength) {
          visionTitle.value = value;
          visionTitle.modified = true;
          visionTitle.length = value.length;
        }
      }
      if (id === "VisionText") {
        if (value.length <= visionText.maxLength) {
          visionText.value = value;
          visionText.modified = true;
          visionText.length = value.length;
        }
      }

      if (
        aboutTitle.modified === true ||
        aboutText.modified === true ||
        missionTitle.modified === true ||
        visionTitle.modified === true ||
        missionText.modified === true ||
        visionText.modified === true
      ) {
        this.setState({
          FlagclassName: true,
        });
      }

      this.setState({
        aboutTitle,
        aboutText,
        missionTitle,
        missionText,
        visionTitle,
        visionText,
      });
    }
  };

  handleChangeImage = async (event) => {
    const { country, city, organizationId } = this.state;
    var multimedia = { ...this.state.multimedia };

    if (event.target.value) {
      // Image link
      multimedia.imageUrl = event.target.value;
      multimedia.modified = true;

      this.setState({
        multimedia,
        FlagclassName: true,
      });
    }

    if (event.target.files) {
      // Image file
      var fileUpload = event.target.files[0];
      let extension;
      if (fileUpload !== undefined) {
        extension = fileUpload.type.replace("image/", "");
      }
      const random = uuidv4();
      const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
      if (!extension) {
        multimedia.loading = true;
        multimedia.file = null;
        multimedia.image = null;

        this.setState({
          multimedia,
          FlagclassName: true,
        });
        return;
      }

      if (key !== undefined) {
        // Function to resize and reduce image quality
        // Save image in AWS
        let flag = true;
        multimedia = await handleChangeImageFunction(
          multimedia,
          fileUpload,
          extension,
          key,
          400,
          400
        ).catch((res) => {
          const { t } = this.props;
          this.setState({
            titleNotificationModal: t(`${res.title}`),
            contenNotificationModal: `${t(`${res.content}`)} ${res.params}`,
            typeNotificationModal: "error",
            openNotificationModal: true,
          });
          flag = false;
        });
        if (flag !== false) {
          if (multimedia.loading === false) {
            this.uploadFileToS3Bucket(multimedia.file, key);
            this.setState({
              multimedia,
              FlagclassName: true,
            });
            this.forceUpdate();
          }
        }
      }
    }
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagclassName: true,
    });
  };

  saveChangeName = () => {
    let dto = {
      id: this.state.lpSectionId,
      customName: this.state.newCustomName,
      isEnabled: 1,
    };

    this.updateLpSection(dto);
  };

  saveAboutEvent = () => {
    const {
      aboutTitle,
      aboutText,
      missionTitle,
      missionText,
      visionTitle,
      visionText,
      multimedia,
    } = this.state;
    this.setState({
      isLoadingButton: true,
      FlagclassName: false,
    });
    const nodesToUpdate = [];
    let errorInput = false;

    const { t } = this.props;
    // Verify each one of the values of the component to add
    if (aboutTitle.modified) {
      const node = {};
      if (aboutTitle.value === "") {
        errorInput = true;
      }
      node.id = aboutTitle.id;
      node.value = aboutTitle.value;
      node.nodeType = "TEXT";
      nodesToUpdate.push(node);
    }
    if (aboutText.modified) {
      const node = {};
      node.id = aboutText.id;
      // if (aboutText.value === "") {
      //   errorInput = true;
      // }
      node.value = aboutText.value;
      node.nodeType = "TEXT";
      nodesToUpdate.push(node);
    }
    if (missionTitle.modified) {
      const node = {};
      node.id = missionTitle.id;
      node.value = missionTitle.value;
      node.nodeType = "TEXT";
      nodesToUpdate.push(node);
    }
    if (missionText.modified) {
      const node = {};
      node.id = missionText.id;
      node.value = missionText.value;
      node.nodeType = "TEXT";
      nodesToUpdate.push(node);
    }
    if (visionTitle.modified) {
      const node = {};
      node.id = visionTitle.id;
      node.value = visionTitle.value;
      node.nodeType = "TEXT";
      nodesToUpdate.push(node);
    }
    if (visionText.modified) {
      const node = {};
      node.id = visionText.id;
      node.value = visionText.value;
      node.nodeType = "TEXT";
      nodesToUpdate.push(node);
    }
    if (multimedia.modified) {
      const node = {};
      node.id = multimedia.id;
      if (multimedia.image) {
        node.value = multimedia.value;
      } else if (multimedia.imageUrl) {
        node.value = multimedia.imageUrl;
      } else if (multimedia.videoUrl) {
        node.value = multimedia.videoUrl;
      }
      node.nodeType = "IMAGE"; //Pronto cambiar tipo de nodo a video cuando se suba uno

      nodesToUpdate.push(node);
    }
    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          isLoadingButton: false,
        });
        this.setState({
          titleNotificationModal: t("modal.wrongFile"),
          contentNotificationModal: t("modal.fieldEmpty"),
          typeNotificationModal: "error",
        });
        // Open modal
        this.setState({
          openNotificationModal: true,
        });
      }
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("modal.sectionUnselected"),
      contentNotificationModal: t("modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();

    // Open modal
    this.setState({
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  disableImage = (element) => {
    this.setState({
      isLoadingButton: true,
      FlagclassName: false,
    });
    const nodesToUpdate = [];
    const node = {};
    node.id = element.id;
    node.value = "";
    node.image = "";
    node.nodeType = "IMAGE";

    nodesToUpdate.push(node);
    this.getAboutUsInformation(
      this.state.landingPageId,
      this.state.lpSectionId
    );
    this.updateNode(nodesToUpdate);
    this.handleCloseDialog();
  };

  // Close modal
  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  handleClickOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
      openImageOrVideoModal: false,
      openImageFileOrLinkModal: false,
      openImageFileModal: false,
      openImageLinkModal: false,
      openSelectVideoModal: false,
    });
  };

  handleOpenImageOrVideoModal = () => {
    this.setState({ openImageOrVideoModal: true });
  };

  handleSelectImageOrVideoModal = (imageOrVideo) => {
    this.setState({ openImageOrVideoModal: false });

    if (imageOrVideo === "image") {
      this.setState({
        openImageFileOrLinkModal: true,
        openSelectVideoModal: false,
      });
    } else if (imageOrVideo === "video") {
      this.setState({
        openSelectVideoModal: true,
        openImageFileOrLinkModal: false,
      });
    } else {
      this.setState({ openImageOrVideoModal: false });
    }
  };

  handleImageFileOrLink = (fileOrLink) => {
    this.setState({ openImageFileOrLinkModal: false });

    if (fileOrLink === "file") {
      this.setState({
        openImageFileModal: true,
        openImageLinkModal: false,
      });
    } else if (fileOrLink === "link") {
      this.setState({
        openImageLinkModal: true,
        openImageFileModal: false,
      });
    } else {
      this.setState({ openImageFileOrLinkModal: false });
    }
  };

  handleOkChangeImageType = (e, tag) => {
    var multimedia = { ...this.state.multimedia };
    multimedia.modified = true;

    switch (tag) {
      case "imageFile":
        multimedia.imageUrl = null;
        multimedia.videoUrl = null;
        break;
      case "imageLink":
        multimedia.image = null;
        multimedia.file = null;
        multimedia.videoUrl = null;
        break;
      case "video":
        multimedia.imageUrl = null;
        multimedia.image = null;
        multimedia.file = null;
        break;
      default:
        break;
    }

    this.setState({
      multimedia,
      openImageFileModal: false,
      openImageLinkModal: false,
      openSelectVideoModal: false,
    });
  };

  // React Quills
  handleChangeMissionText(html, id) {
    this.setState({
      missionText: {
        id: id,
        value: html,
        modified: true,
        length: html.length,
      },
    });
    this.handleChange(html, id, "", true);
  }

  handleChangeVisionText(html, id) {
    this.setState({
      visionText: {
        id: id,
        value: html,
        modified: true,
        length: html.length,
      },
    });
    this.handleChange(html, id, "", true);
  }

  // Render
  render() {
    const {
      redirectBack,
      aboutTitle,
      aboutText,
      missionTitle,
      missionText,
      visionTitle,
      visionText,
      isLoadingButton,
      sectionName,
      organizationId,
      lpUrl,
      landingPageId,
      multimedia,
      customName,
      name,
      subpageId,
      FlagclassName,
      goToWithoutSaveModalVisible,

      // Modal
      openNotificationModal,
      titleNotificationModal,
      contentNotificationModal,
      typeNotificationModal,
      openDialog,

      //Name modal
      openModalToChangeName,

      //Image or video selection modals
      openImageOrVideoModal,
      openImageFileOrLinkModal,
      openImageFileModal,
      openImageLinkModal,
      openSelectVideoModal,
    } = this.state;
    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/myWebPage" />;
    }

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contentNotificationModal}
          type={typeNotificationModal}
        />
        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      this.state.customName
                        ? decodeURIComponent(this.state.customName)
                        : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />
        <Card className="card">
          <Backdrop
            style={{ color: "#510f8a", zIndex: "5" }}
            open={isLoadingButton}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {isLoadingButton === true ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(800%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row componentSpace spaceBreadcrumb">
                <BreadcrumbSection
                  principal={t("sections.aboutUs")}
                  secondary={this.state.organizationName}
                  route="./myWebPage"
                  propsComponent={{
                    organizationId,
                    sectionName: sectionName,
                    lpId: landingPageId,
                    lpUrl: lpUrl,
                    customName,
                  }}
                  handleClickOpenModalToChangeName={
                    this.handleClickOpenModalToChangeName
                  }
                  FlagclassName={FlagclassName}
                  onClick={(e) => this.saveAboutEvent(e)}
                  GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                  onCancelGotoWithoutMModal={(e) =>
                    this.onCancelGotoWithoutMModal(e)
                  }
                  goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                />
              </div>

              {/* Title */}
              <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                <Title
                  title={t("basicWords.completeInformationOfYourSection")}
                />
              </div>

              {/* Accordions */}
              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                {/* First */}
                <div className="row componentSpace">
                  <AccordionComponent
                    title={t("generalUser.contentSection")}
                    secondTitle={t("basicWords.imageOrVideo")}
                    body={[
                      <div className="accordionContent">
                        <div className="accordionCenterLine">
                          <div
                            style={{
                              width: "30%",
                              marginLeft: "25px",
                              marginRight: "2%",
                            }}
                          >
                            <GeneralInput
                              text={t("basicWords.name")}
                              id={aboutTitle ? aboutTitle.nodeTypeName : null}
                              value={aboutTitle ? aboutTitle.value : null}
                              placeholder={t("generalMessages.sectionTitle")}
                              isReq
                              onChange={(e, id, value) =>
                                this.handleChange(e, id, value)
                              }
                            />
                            {aboutTitle.value && aboutTitle.maxLength ? (
                              <span>
                                {aboutTitle.length} / {aboutTitle.maxLength}
                              </span>
                            ) : null}
                          </div>
                          <div
                            style={{
                              width: "42%",
                              marginRight: "2%",
                            }}
                          >
                            <GeneralInput
                              text={t("basicWords.name")}
                              id={aboutText ? aboutText.nodeTypeName : null}
                              value={aboutText ? aboutText.value : null}
                              placeholder={t("basicWords.enterText")}
                              isReq
                              onChange={(e, id, value) =>
                                this.handleChange(e, id, value)
                              }
                            />
                            {aboutText.value && aboutText.maxLength ? (
                              <span>
                                {aboutText.length} / {aboutText.maxLength}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        {/* New feature image or video */}

                        <div
                          className="rowSections componentSpace"
                          style={{
                            margin: "2%",
                            display: "flex",
                          }}
                        >
                          {multimedia.imageUrl &&
                          !multimedia.image &&
                          !multimedia.videoUrl ? (
                            <img
                              style={{ objectFit: "contain" }}
                              src={multimedia.imageUrl}
                              alt="imagen"
                              width="100px"
                              height="100px"
                              alignItems="center"
                            ></img>
                          ) : null}

                          {multimedia.image &&
                          !multimedia.imageUrl &&
                          !multimedia.videoUrl ? (
                            <img
                              style={{ objectFit: "contain" }}
                              src={multimedia.image}
                              alt="imagen"
                              width="100px"
                              height="100px"
                              alignItems="center"
                            ></img>
                          ) : null}

                          {multimedia.videoUrl &&
                          !multimedia.image &&
                          !multimedia.imageUrl ? (
                            <img
                              style={{ objectFit: "contain" }}
                              src={
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png"
                              }
                              alt="imagen"
                              width="100px"
                              height="100px"
                              alignItems="center"
                            ></img>
                          ) : null}

                          <div
                            style={{
                              margin: "2%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ButtonComponent
                              onClick={() => this.handleOpenImageOrVideoModal()}
                              props={null}
                              shape="round"
                              text={t("basicWords.imageOrVideo")}
                            />
                          </div>
                        </div>

                        <GeneralModal //Select image or video modal
                          visible={openImageOrVideoModal}
                          title={t("generalMessages.selectFile")}
                          body={[
                            <div key={1}>
                              <div className="col-6">
                                <ButtonComponent
                                  onClick={() =>
                                    this.handleSelectImageOrVideoModal("image")
                                  }
                                  props={null}
                                  shape="round"
                                  text={t("basicWords.image")}
                                />
                              </div>
                              <br />
                              <div>
                                <ButtonComponent
                                  onClick={() =>
                                    this.handleSelectImageOrVideoModal("video")
                                  }
                                  props={null}
                                  shape="round"
                                  text={t("sections.video")}
                                />
                              </div>
                            </div>,
                          ]}
                          footer={[
                            <div
                              className="row"
                              key={1}
                              style={{ display: "flex" }}
                            >
                              <div className="col-6">
                                <ButtonComponent
                                  onClick={() => this.handleCloseDialog()}
                                  props={null}
                                  shape="round"
                                  text={t("basicWords.cancelText")}
                                />
                              </div>
                            </div>,
                          ]}
                          onClick={() => this.handleCloseDialog()}
                        />

                        <GeneralModal //Select image file or image link modal
                          visible={openImageFileOrLinkModal}
                          title={t("generalMessages.selectFile")}
                          body={[
                            <div key={1}>
                              <div className="col-6">
                                <ButtonComponent
                                  onClick={() =>
                                    this.handleImageFileOrLink("file")
                                  }
                                  props={null}
                                  shape="round"
                                  text={t("generalMessages.uploadFile")}
                                />
                              </div>
                              <br />
                              <div>
                                <ButtonComponent
                                  onClick={() =>
                                    this.handleImageFileOrLink("link")
                                  }
                                  props={null}
                                  shape="round"
                                  text={t("buttons.externalLink")}
                                />
                              </div>
                            </div>,
                          ]}
                          footer={[
                            <div
                              className="row"
                              key={1}
                              style={{ display: "flex" }}
                            >
                              <div className="col-6">
                                <ButtonComponent
                                  onClick={() => this.handleCloseDialog()}
                                  props={null}
                                  shape="round"
                                  text={t("basicWords.cancelText")}
                                />
                              </div>
                            </div>,
                          ]}
                          onClick={() => this.handleCloseDialog()}
                        />

                        <GeneralModal // Select file image modal
                          visible={openImageFileModal}
                          title={t("generalMessages.uploadFile")}
                          body={[
                            <div key={1}>
                              <div className="col-12">
                                {/* Tooltip image */}
                                <Tooltip
                                  title={t("generalMessages.imagesFormat")}
                                >
                                  <div className="cardImagesSquare">
                                    <div className="row">
                                      <div className="iconXCardImagesSquare">
                                        <a>
                                          <X
                                            className="iconImageEditInSectionSquare"
                                            onClick={this.handleClickOpenDialog}
                                          />
                                        </a>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="centerImage">
                                        <input
                                          accept="image/*"
                                          id="contained-button-file"
                                          multiple
                                          type="file"
                                          onChange={(e) =>
                                            this.handleChangeImage(e)
                                          }
                                          hidden
                                        />
                                        <label htmlFor="contained-button-file">
                                          <div className="imageEdit">
                                            <div>
                                              {multimedia.image !== null ? (
                                                <img
                                                  src={multimedia.image}
                                                  alt="avatar"
                                                  className="widthAndHeightImageSectionSquare"
                                                />
                                              ) : (
                                                <Plus className="imageEdit" />
                                              )}

                                              <GeneralModal
                                                visible={openDialog}
                                                title={t(
                                                  "basicWords.deleteItem"
                                                )}
                                                footer={[
                                                  <div
                                                    className="row"
                                                    key={1}
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <div className="col-6">
                                                      <ButtonComponent
                                                        onClick={() =>
                                                          this.handleCloseDialog()
                                                        }
                                                        props={null}
                                                        shape="round"
                                                        text={t(
                                                          "basicWords.cancelText"
                                                        )}
                                                      />
                                                    </div>
                                                    <div>
                                                      <ButtonComponent
                                                        onClick={() =>
                                                          this.disableImage(
                                                            multimedia
                                                          )
                                                        }
                                                        props={null}
                                                        shape="round"
                                                        text={t(
                                                          "basicWords.okText"
                                                        )}
                                                      />
                                                    </div>
                                                  </div>,
                                                ]}
                                                onClick={() =>
                                                  this.handleCloseDialog()
                                                }
                                              />
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            </div>,
                          ]}
                          footer={[
                            <div className="row buttonAtTheBottom">
                              <div className="col-6">
                                <ButtonComponent
                                  onClick={() => this.handleCloseDialog()}
                                  shape="round"
                                  text={t("basicWords.cancel")}
                                  className="buttonDiscard"
                                />
                              </div>
                              <div className="col-6">
                                <ButtonComponent
                                  isDisabled={false}
                                  onClick={(e) =>
                                    this.handleOkChangeImageType(e, "imageFile")
                                  }
                                  shape="round"
                                  text={t("basicWords.ok")}
                                />
                              </div>
                            </div>,
                          ]}
                          onClick={() => this.handleCloseDialog()}
                        />

                        <GeneralModal // Select link image modal
                          visible={openImageLinkModal}
                          title={t("buttons.externalLink")}
                          body={[
                            <div key={1}>
                              <div className="col-6">
                                <GeneralInput
                                  text={t("buttons.externalLink")}
                                  id={"ImageLink"}
                                  value={
                                    multimedia.imageUrl
                                      ? multimedia.imageUrl
                                      : ""
                                  }
                                  placeholder={t("modal.addImageLink")}
                                  isReq
                                  onChange={(e, id) =>
                                    this.handleChangeImage(e, id)
                                  }
                                  iconYoutube={false}
                                />
                              </div>
                              <div
                                className="col-6"
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "5px",
                                }}
                              >
                                <p>{t("modal.addImageLinkTooltip")}</p>
                                {multimedia.imageUrl ? (
                                  <img
                                    src={multimedia.imageUrl}
                                    alt="imagen"
                                    width="100px"
                                    height="100px"
                                  ></img>
                                ) : null}
                              </div>
                            </div>,
                          ]}
                          footer={[
                            <div className="row buttonAtTheBottom">
                              <div>
                                <ButtonComponent
                                  onClick={() => this.handleCloseDialog()}
                                  shape="round"
                                  text={t("basicWords.cancel")}
                                  className="buttonDiscard"
                                />
                              </div>
                              <div className="col-6">
                                <ButtonComponent
                                  isDisabled={false}
                                  onClick={(e) =>
                                    this.handleOkChangeImageType(e, "imageLink")
                                  }
                                  shape="round"
                                  text={t("basicWords.ok")}
                                />
                              </div>
                            </div>,
                          ]}
                          onClick={() => this.handleCloseDialog()}
                        />

                        <GeneralModal // Select link video modal
                          visible={openSelectVideoModal}
                          title={t("sections.video")}
                          body={[
                            <div key={1}>
                              <div className="col-6">
                                <GeneralInput
                                  text={t("basicWords.enterUrlVideo")}
                                  id={"videoUrl"}
                                  value={
                                    multimedia.videoUrl
                                      ? multimedia.videoUrl
                                      : ""
                                  }
                                  placeholder={t("basicWords.enterUrlVideo")}
                                  isReq
                                  onChange={(e, id) =>
                                    this.handleChange(e, "videoUrl")
                                  }
                                  iconYoutube={true}
                                />
                              </div>
                            </div>,
                          ]}
                          footer={[
                            <div className="row buttonAtTheBottom">
                              <div>
                                <ButtonComponent
                                  onClick={() => this.handleCloseDialog()}
                                  shape="round"
                                  text={t("basicWords.cancel")}
                                  className="buttonDiscard"
                                />
                              </div>
                              <div className="col-6">
                                <ButtonComponent
                                  isDisabled={false}
                                  onClick={(e) =>
                                    this.handleOkChangeImageType(e, "video")
                                  }
                                  shape="round"
                                  text={t("basicWords.ok")}
                                />
                              </div>
                            </div>,
                          ]}
                          onClick={() => this.handleCloseDialog()}
                        />

                        {/* -------------------- New feature image or video */}
                      </div>,
                      <GeneralModal
                        visible={openDialog}
                        title={t("basicWords.deleteItem")}
                        footer={[
                          <div
                            className="row"
                            key={1}
                            style={{ display: "flex" }}
                          >
                            <div className="col-6">
                              <ButtonComponent
                                onClick={() => this.handleCloseDialog()}
                                props={null}
                                shape="round"
                                text={t("basicWords.cancelText")}
                              />
                            </div>
                            <div>
                              <ButtonComponent
                                onClick={() => this.disableImage(multimedia)}
                                props={null}
                                shape="round"
                                text={t("basicWords.okText")}
                              />
                            </div>
                          </div>,
                        ]}
                        onClick={() => this.handleCloseDialog()}
                      />,
                    ]}
                  />
                </div>
                <br />

                {/* Second */}
                <div className="row componentSpace">
                  <AccordionComponent
                    title={missionTitle.value}
                    body={[
                      <div className="accordionContent">
                        <div className="accordionCenterLine">
                          <div className="accordionCompleteInputs">
                            <GeneralInput
                              text={t("sections.mision")}
                              id={
                                missionTitle ? missionTitle.nodeTypeName : null
                              }
                              value={missionTitle.value}
                              placeholder={t("sections.mision")}
                              isReq
                              onChange={(e, id, value) =>
                                this.handleChange(e, id, value)
                              }
                            />
                            {missionTitle.value && missionTitle.maxLength ? (
                              <span>
                                {missionTitle.length} / {missionTitle.maxLength}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <br />
                        <div className="accordionCenterLine">
                          <div className="accordionCompleteInputs">
                            <ReactQuill
                              id={missionText ? missionText.nodeTypeName : null}
                              value={missionText.value}
                              modules={editorConfiguration.modules}
                              formats={editorConfiguration.formats}
                              placeholder={t("basicWords.enterText")}
                              onChange={(e) =>
                                this.handleChangeMissionText(
                                  e,
                                  missionText ? missionText.id : null
                                )
                              }
                            />
                            {missionText.value && missionText.maxLength ? (
                              <span>
                                {missionText.length} / {missionText.maxLength}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>,
                    ]}
                  />
                  <br />
                </div>

                <div className="row componentSpace adjustPositionYBottom">
                  <AccordionComponent
                    title={visionTitle.value}
                    body={[
                      <div className="accordionContent">
                        <div className="accordionCenterLine">
                          <div className="accordionCompleteInputs">
                            <GeneralInput
                              text={t("sections.vision")}
                              id={visionTitle ? visionTitle.nodeTypeName : null}
                              value={visionTitle.value}
                              placeholder={t("sections.vision")}
                              isReq
                              onChange={(e, id, value) =>
                                this.handleChange(e, id, value)
                              }
                            />
                            {visionTitle.value && visionTitle.maxLength ? (
                              <span>
                                {visionTitle.length} / {visionTitle.maxLength}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <br />
                        <div className="accordionCenterLine">
                          <div className="accordionCompleteInputs">
                            <ReactQuill
                              id={visionText ? visionText.nodeTypeName : null}
                              value={visionText.value}
                              modules={editorConfiguration.modules}
                              formats={editorConfiguration.formats}
                              placeholder={t("basicWords.enterText")}
                              onChange={(e, id) =>
                                this.handleChangeVisionText(
                                  e,
                                  visionText ? visionText.id : null
                                )
                              }
                            />
                            {visionText.value && visionText.maxLength ? (
                              <span>
                                {visionText.length} / {visionText.maxLength}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>,
                    ]}
                  />
                  <br />
                </div>
              </div>
            </>
          )}
        </Card>
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
            customName,
          }}
          FlagclassName={FlagclassName}
          onClick={(e) => this.saveAboutEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(AboutUsSection);
