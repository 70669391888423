import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import {
  Card,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

// Components
import {
  Title,
  NotificationModal,
  BreadcrumbSection,
  GeneralInput,
  ButtonsFooterComponent,
} from "../../../../components/index";

import "../css/socialNetwork.scss";

// Queries
import { getInfoUhoo } from "../../../../actions/dataUhoo.actions";
import {
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
} from "../../../../actions/index";

// Icons
import { Facebook, Instagram, Twitter } from "react-feather";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

//Localization
import { withTranslation } from "react-i18next";

import { Post } from "./Post";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class SocialNetworksSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationId: null,
      checkedTwitter: true,
      checkedFacebook: true,
      checkedInstagram: true,
      checkedAurapp: true,
      SocialNetworksTwitterUsername: null,
      SocialNetworksFacebookUrl: null,
      SocialNetworksAurappToken: "",
      SocialNetworksInstagramPost: null,
      pageFacebook: "",
      textCheckboxTwitter: "Usuario de Twitter correcto",
      textCheckboxFacebook: "Link de la página de Facebook correcto",
      textCheckboxInstagram: "Link del post de Instagram correcto",
      textCheckboxAurapp: "Tienes habilitado Aurapp",

      loadingInfo: true,

      SocialNetworksTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      SocialNetworksAurapp: {
        id: null,
        data: null,
        nodeTypeName: null,
      },
      SocialNetworksTwitter: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      SocialNetworksFacebook: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      SocialNetworksInstagram: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      FlagclassName: false,
      goToWithoutSaveModalVisible: false,

      // Modals
      openNotificationModal: false,
      titleNotificationModal: "",
      contentNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { props } = this.props.location;

    if (props) {
      this.getSocialNetworksInformation(props.landingPageId, props.sectionId);
      this.setState({
        sectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getSocialNetworksInformation(lpId, lpSectionId) {
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "SOCIALNETWORKS"
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contentNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
    } else {
      // Get all the information about the section components
      const sectionInfo = response.data.data;
      const SocialNetworksComponent = sectionInfo.components.find(
        (comp) => comp.name === "SocialNetworksContainer"
      );
      const SocialNetworksContainerTitle = {
        id: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksContainerTitle"
        ).id,
        value: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksContainerTitle"
        ).value,
        nodeTypeName: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksContainerTitle"
        ).nodeType,
        minLength: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksContainerTitle"
        ).minLength,
        maxLength: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksContainerTitle"
        ).maxLength,
        length: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksContainerTitle"
        ).value.length,
      };

      const SocialNetworksTwitter = {
        id: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksTwitterUsername"
        ).id,
        value: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksTwitterUsername"
        ).value,
        nodeTypeName: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksTwitterUsername"
        ).nodeType,
      };

      const SocialNetworksAurapp = {
        id: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksUhooToken"
        ).id,
        value: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksUhooToken"
        ).value,
        nodeTypeName: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksUhooToken"
        ).nodeType,
      };
      const SocialNetworksFacebook = {
        id: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksFacebookUrl"
        ).id,
        value: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksFacebookUrl"
        ).value,
        nodeTypeName: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksFacebookUrl"
        ).nodeType,
      };

      const SocialNetworksInstagram = {
        id: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksInstagramPost"
        ).id,
        value: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksInstagramPost"
        ).value,
        nodeTypeName: SocialNetworksComponent.nodes.find(
          (node) => node.nodeType === "SocialNetworksInstagramPost"
        ).nodeType,
      };

      if (SocialNetworksAurapp.value !== "") {
        const dataAurApp = await getInfoUhoo(SocialNetworksAurapp.value);
        this.setState({
          SocialNetworksAurapp: dataAurApp,
        });
      } else {
        this.setState({
          SocialNetworksAurapp: "",
        });
      }
      if (
        SocialNetworksFacebook.value[
          SocialNetworksFacebook.value.length - 1
        ] === "/"
      ) {
        SocialNetworksFacebook.value = SocialNetworksFacebook.value.substring(
          0,
          SocialNetworksFacebook.value.length - 1
        );
      }

      this.setState({
        SocialNetworksContainerTitle,
        SocialNetworksTwitter: SocialNetworksTwitter,
        SocialNetworksFacebook,
        SocialNetworksInstagram,
        loadingInfo: false,
        SocialNetworksTwitterUsername: SocialNetworksTwitter.value,
        SocialNetworksFacebookUrl: `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2F${SocialNetworksFacebook.value}&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1027397741079968`,
        SocialNetworksInstagramPost: `${SocialNetworksInstagram.value}embed`,
      });
      if (this.state.SocialNetworksAurapp === "") {
        this.setState({
          SocialNetworksAurapp: "",
          checkedAurapp: false,
          textCheckboxAurapp: "No tienes habilitado Aurapp",
        });
      }

      if (SocialNetworksTwitter.value === "") {
        this.setState({
          SocialNetworksTwitterUsername: "",
          checkedTwitter: false,
          textCheckboxTwitter: "Link incorrecto",
        });
      }

      var regex1 = /www.instagram.com/;
      var result = regex1.test(SocialNetworksInstagram.value);
      if (result === false) {
        this.setState({
          SocialNetworksInstagramPost: "",
          checkedInstagram: false,
          textCheckboxInstagram: "Link incorrecto",
        });
      }

      var regex2 = /www.facebook.com/;
      result = regex2.test(SocialNetworksFacebook.value);
      if (result === false) {
        this.setState({
          SocialNetworksFacebookUrl: "",
          checkedFacebook: false,
          textCheckboxFacebook: "Link incorrecto",
        });
      }
    }
  }

  async updateNode(nodesToUpdate) {
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contentNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.editionSuccess"),
        contentNotificationModal: t("modal.contactSuccessfullyEdited"),
        typeNotificationModal: "success",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
    }
    this.setState({
      isLoadingButton: false,
    });
  }

  // Events
  handleChange = (event) => {
    const { id, value } = event.target;
    const { SocialNetworksContainerTitle } = { ...this.state };
    const { SocialNetworksTwitter } = { ...this.state };
    const { SocialNetworksFacebook } = { ...this.state };
    const { SocialNetworksInstagram } = { ...this.state };

    if (id === "SocialNetworksContainerTitle") {
      if (value.length <= SocialNetworksContainerTitle.maxLength) {
        SocialNetworksContainerTitle.value = value;
        SocialNetworksContainerTitle.modified = true;
        SocialNetworksContainerTitle.length = value.length;
      }
    }
    if (id === "SocialNetworksTwitterUsername") {
      SocialNetworksTwitter.value = value;
      SocialNetworksTwitter.modified = true;
    }

    if (id === "SocialNetworksFacebookUrl") {
      SocialNetworksFacebook.value = value;
      SocialNetworksFacebook.modified = true;
    }

    if (id === "SocialNetworksInstagramPost") {
      SocialNetworksInstagram.value = value;
      SocialNetworksInstagram.modified = true;
    }

    this.setState({
      SocialNetworksContainerTitle,
      SocialNetworksTwitter,
      SocialNetworksFacebook,
      SocialNetworksInstagram,
      FlagclassName: true,
    });
  };

  saveSocialNetworksEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagclassName: false,
    });
    const { t } = this.props;
    const nodesToUpdate = [];
    const { SocialNetworksContainerTitle } = { ...this.state };
    let SocialNetworksTwitter = this.state.SocialNetworksTwitter;
    const { SocialNetworksFacebook } = { ...this.state };
    const { SocialNetworksInstagram } = { ...this.state };
    let saveValidation = true;

    // Verify each one of the values of the component to add
    if (SocialNetworksContainerTitle.modified) {
      if (SocialNetworksContainerTitle.value === "") {
        saveValidation = false;
        // Modal
        this.setState({
          titleNotificationModal: t("modal.wrongFile"),
          contentNotificationModal: t("modal.fieldEmpty"),
          typeNotificationModal: "error",
        });
        // Open modal
        this.setState({
          openNotificationModal: true,
        });
      }
      delete SocialNetworksContainerTitle.minLength;
      delete SocialNetworksContainerTitle.maxLength;
      delete SocialNetworksContainerTitle.length;
      nodesToUpdate.push(SocialNetworksContainerTitle);
    }
    if (SocialNetworksTwitter.modified) {
      let SocialNetworksTwitter = this.state.SocialNetworksTwitter;
      this.setState({
        SocialNetworksTwitterUsername: SocialNetworksTwitter.value,
      });
      delete SocialNetworksTwitter.modified;
      if (SocialNetworksTwitter.value === "") {
        this.setState({
          SocialNetworksTwitterUsername: "",
          checkedTwitter: false,
          textCheckboxTwitter: "Link incorrecto",
        });
        saveValidation = false;
      } else {
        this.setState({
          SocialNetworksTwitterUsername: SocialNetworksTwitter.value,
          checkedTwitter: true,
          textCheckboxTwitter: "Usuario de Twitter correcto",
        });
        saveValidation = true;
      }
      nodesToUpdate.push(SocialNetworksTwitter);
    }
    if (SocialNetworksFacebook.modified) {
      delete SocialNetworksFacebook.modified;

      var regex = /www.facebook.com/;
      var result = regex.test(SocialNetworksFacebook.value);
      if (result === false) {
        this.setState({
          SocialNetworksFacebookUrl: "",
          checkedFacebook: false,
          textCheckboxFacebook: "Link incorrecto",
        });
        saveValidation = false;
      } else {
        if (
          SocialNetworksFacebook.value[
            SocialNetworksFacebook.value.length - 1
          ] === "/"
        ) {
          SocialNetworksFacebook.value = SocialNetworksFacebook.value.substring(
            0,
            SocialNetworksFacebook.value.length - 1
          );
        }

        this.setState({
          SocialNetworksFacebookUrl: `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2F${SocialNetworksFacebook.value}&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1027397741079968`,
          checkedFacebook: true,
          textCheckboxFacebook: "Link de la página de Facebook correcto",
        });
      }
      nodesToUpdate.push(SocialNetworksFacebook);
    }
    if (SocialNetworksInstagram.modified) {
      delete SocialNetworksInstagram.modified;
      var regex = /www.instagram.com/;
      var result = regex.test(SocialNetworksInstagram.value);
      if (result === false) {
        this.setState({
          SocialNetworksInstagramPost: "",
          checkedInstagram: false,
          textCheckboxInstagram: "Link incorrecto",
        });
        saveValidation = false;
      } else {
        this.setState({
          SocialNetworksInstagramPost: `${SocialNetworksInstagram.value}embed`,
          checkedInstagram: true,
          textCheckboxInstagram: "Link del post de Instagram correcto",
        });
        saveValidation = true;
      }
      nodesToUpdate.push(SocialNetworksInstagram);
    }

    if (nodesToUpdate.length > 0) {
      if (saveValidation) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          isLoadingButton: false,
        });
        this.updateNode(nodesToUpdate);
      }
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("modal.sectionUnselected"),
      contentNotificationModal: t("modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    // Open modal
    this.handleOkWarning();
    this.setState({
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  // Close modal
  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  renderPostComponents(posts) {
    let postsComponents = [];
    if (posts) {
      posts.forEach((element, index) => {
        postsComponents.push(
          <Post
            title={element.title}
            description={element.content}
            date={element.created_at}
            image={element.media}
            id={index}
            key={Math.random() * 1000}
            name={element.user.name}
            lastName={element.user.lastname}
            approved={element.approved}
            active={element.active}
            banned={element.banned}
          />
        );
      });
    }
    return postsComponents;
  }

  // Render Info
  render() {
    let count = 3;
    if (this.state.SocialNetworksTwitterUsername === "") {
      count = count + 1;
    }
    if (this.state.SocialNetworksInstagramPost === "") {
      count = count + 1;
    }
    if (this.state.SocialNetworksFacebookUrl === "") {
      count = count + 1;
    }
    if (count === 6) {
      count = 12;
    }
    if (count === 5) {
      count = 6;
    }
    const {
      redirectBack,
      sectionName,
      height,
      SocialNetworksTwitter,
      SocialNetworksFacebook,
      SocialNetworksInstagram,
      SocialNetworksAurapp,
      organizationId,
      isLoadingButton,
      name,
      subpageId,
      landingPageId,
      FlagclassName,
      goToWithoutSaveModalVisible,
      lpUrl,

      // Modal
      openNotificationModal,
      titleNotificationModal,
      contentNotificationModal,
      typeNotificationModal,
    } = this.state;
    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/myWebPage" />;
    }

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contentNotificationModal}
          type={typeNotificationModal}
        />

        <Card>
          {isLoadingButton === true ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(800%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row componentSpace">
                <BreadcrumbSection
                  principal={t("sections.socialnetworks")}
                  secondary={this.state.organizationName}
                  route="./myWebPage"
                  propsComponent={{
                    organizationId,
                    sectionName: sectionName,
                    nameSubpage: name ? name : null,
                    lpId: landingPageId,
                    subpageId: subpageId ? subpageId : null,
                    lpUrl: lpUrl,
                  }}
                  FlagclassName={FlagclassName}
                  onClick={(e) => this.saveAboutEvent(e)}
                  GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                  onCancelGotoWithoutMModal={(e) =>
                    this.onCancelGotoWithoutMModal(e)
                  }
                  goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                />
              </div>

              <div className="row adjustPositionXLeft adjustPositionYTop">
                <Title
                  title={t("basicWords.completeInformationOfYourSection")}
                />
              </div>

              {/* Social media */}
              <div
                className="row adjustPositionXLeft adjustPositionXRigth"
                style={{ minHeight: height }}
              >
                <div className="col lpSection">
                  <div className="row socialNetworkSubtitle">
                    {t("generalUser.sectionContent")}
                  </div>
                  <div className="row" style={{ display: "flex" }}>
                    <div className="col socialNetworkIcons">
                      <Twitter className="iconContact" />
                    </div>
                    <div className="col socialNetworkInput">
                      <GeneralInput
                        text={t("sections.UsernameTwitter")}
                        id={
                          SocialNetworksTwitter
                            ? SocialNetworksTwitter.nodeTypeName
                            : null
                        }
                        value={
                          SocialNetworksTwitter
                            ? SocialNetworksTwitter.value
                            : null
                        }
                        placeholder={t("sections.UsernameTwitter")}
                        isReq
                        onChange={(e, id, value) =>
                          this.handleChange(e, id, value)
                        }
                      />
                      <span className="socialNetworkCheckbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              style={{ textAlign: "center" }}
                              checked={this.state.checkedTwitter}
                              disabled
                            />
                          }
                          label={this.state.textCheckboxTwitter}
                        />
                      </span>
                    </div>

                    <div className="col socialNetworkDrag">
                      <DragIndicatorIcon />
                    </div>
                  </div>

                  {/* Facebook */}
                  <div className="row" style={{ display: "flex" }}>
                    <div className="col socialNetworkIcons">
                      <Facebook className="iconContact" />
                    </div>
                    <div className="col socialNetworkInput">
                      <GeneralInput
                        text={t("sections.linkFacebook")}
                        id={
                          SocialNetworksFacebook
                            ? SocialNetworksFacebook.nodeTypeName
                            : null
                        }
                        value={
                          SocialNetworksFacebook
                            ? SocialNetworksFacebook.value
                            : null
                        }
                        placeholder={t("sections.linkFacebook")}
                        isReq
                        onChange={(e, id, value) =>
                          this.handleChange(e, id, value)
                        }
                      />
                      <span className="socialNetworkCheckbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              style={{ textAlign: "center" }}
                              checked={this.state.checkedFacebook}
                              disabled
                            />
                          }
                          label={this.state.textCheckboxFacebook}
                        />
                      </span>
                    </div>
                    <div className="col socialNetworkDrag">
                      <DragIndicatorIcon />
                    </div>
                  </div>

                  {/* Instagram */}
                  <div className="row" style={{ display: "flex" }}>
                    <div className="col socialNetworkIcons">
                      <Instagram className="iconContact" />
                    </div>
                    <div className="col socialNetworkInput">
                      <GeneralInput
                        text={t("sections.linkPostInstagram")}
                        id={
                          SocialNetworksInstagram
                            ? SocialNetworksInstagram.nodeTypeName
                            : null
                        }
                        value={
                          SocialNetworksInstagram
                            ? SocialNetworksInstagram.value
                            : null
                        }
                        placeholder={t("sections.linkPostInstagram")}
                        isReq
                        onChange={(e, id, value) =>
                          this.handleChange(e, id, value)
                        }
                      />
                      <span className="socialNetworkCheckbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              style={{ textAlign: "center" }}
                              checked={this.state.checkedInstagram}
                              disabled
                            />
                          }
                          label={this.state.textCheckboxInstagram}
                        />
                      </span>
                    </div>
                    <div className="col socialNetworkDrag">
                      <DragIndicatorIcon />
                    </div>
                  </div>

                  {/* AurApp */}
                  <div className="row" style={{ display: "flex" }}>
                    <div className="col socialNetworkIcons">
                      {/* <img id="user" src={AurappLogo} alt="user" className="aurappLogo"/> */}
                    </div>
                    <div className="col socialNetworkInput">
                      <GeneralInput
                        text={t("basicWords.urlProfile")}
                        id={
                          SocialNetworksAurapp
                            ? SocialNetworksAurapp.nodeTypeName
                            : null
                        }
                        value={
                          SocialNetworksAurapp ? SocialNetworksAurapp.value : ""
                        }
                        placeholder={t("basicWords.urlProfile")}
                        isReq
                        onChange={(e, id, value) =>
                          this.handleChange(e, id, value)
                        }
                      />
                      <span className="socialNetworkCheckbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              style={{ textAlign: "center" }}
                              checked={this.state.checkedAurapp}
                              disabled
                            />
                          }
                          label={this.state.textCheckboxAurapp}
                        />
                      </span>
                    </div>
                    <div className="col socialNetworkDrag">
                      <DragIndicatorIcon />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
          }}
          FlagclassName={FlagclassName}
          onClick={(e) => this.saveSocialNetworksEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(SocialNetworksSection);
