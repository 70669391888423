import React from "react";
import { TextField } from "@material-ui/core";

import { X } from "react-feather";

// Components
import { ButtonComponent, GeneralModal } from "../../components/index";

//Localization
import { useTranslation } from "react-i18next";

const AddNewSubpageModal = (props) => {
  const { visible, isLoading, nameNewSubpage } = props;
  const { t, i18n } = useTranslation();
  return (
    <GeneralModal
      visible={visible}
      title={t("basicWords.addNewSubpage")}
      body={[
        <div className="col-12" style={{ margin: "5px" }}>
          <div className="row">
            <div className="col-md-12 componentSpace">
              <TextField
                variant="outlined"
                style={{ width: "100%", marginLeft: 10 }}
                label={t("basicWords.nameOfPage")}
                onChange={(e) => props.OnChangeInput(e)}
                defaultValue={nameNewSubpage}
              />
            </div>
          </div>
        </div>,
      ]}
      footer={[
        <div className="row buttonAtTheBottom">
          <div>
            <ButtonComponent
              isDisabled={isLoading}
              onClick={(e) => props.handleClose(e)}
              shape="round"
              text={t("basicWords.cancel")}
              className="buttonDiscard"
            />
          </div>
          <div>
            <ButtonComponent
              isDisabled={false}
              isLoading={isLoading}
              onClick={() => props.handleSave()}
              shape="round"
              text={t("basicWords.create")}
            />
          </div>
        </div>,
      ]}
      onClick={(e) => props.handleClose(e)}
    />
  );
};

export default AddNewSubpageModal;
