//Localization

 function localizeScheduleEventTypeBD(scheduleTypeName, t) {
  let scheduleTypeNameLocal = scheduleTypeName;
  switch (scheduleTypeName) {
    case t('scheduleEventType.mass'):
      scheduleTypeNameLocal = "MASS";
      break;
    case t('scheduleEventType.rosary'):
      scheduleTypeNameLocal = "ROSARY";
      break;
    case t('scheduleEventType.chapletOfTheDivineMercy'):
      scheduleTypeNameLocal = "CHAPLET OF THE DIVINE MERCY";
      break;
    case t('scheduleEventType.conference'):
      scheduleTypeNameLocal = "CONFERENCE";
      break;
    case t('scheduleEventType.integration'):
      scheduleTypeNameLocal = "INTEGRATION";
      break;
    case t('scheduleEventType.retreat'):
      scheduleTypeNameLocal = "RETREAT";
      break;
    case t('scheduleEventType.other'):
      scheduleTypeNameLocal = "OTHER";
      break;
      default:
        scheduleTypeNameLocal = "OTHER";
        break;   
  }
  return scheduleTypeNameLocal;
}
export default (localizeScheduleEventTypeBD)