import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Checkbox,
} from "@material-ui/core";
import { Trash2, Home } from "react-feather";

// Queries
import {
  getOrganizationById,
  getUsersByOrganizationIdAndRoleId,
  getUsersByOrganizationId,
  disableRoleByUser,
  insertRoleByUser,
} from "../../actions/index";

// Component
import {
  Title,
  GeneralInput,
  Breadcrumb,
  NotificationModal,
  ButtonsFooterComponent,
} from "../../components/index";

//Localization
import { withTranslation } from 'react-i18next';

// Images
import AuraLogin from "../../assets/images/loginLogo.png";
import UhooLogin from "../../assets/images/LogoUhooColor.png";

// const { Column } = Table;
const tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;

class RoleToUsersAssignation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      organizationId: null,
      organizationName: null,
      roleId: null,
      roleName: null,

      assignedUsers: [],
      users: [],
      usersToAdd: [],

      loadingAssignedUsers: true,
      loadingUsers: true,

      addButtonDisabled: true,
      addButtonLoading: false,

      selected: [],

      // Modals
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({ imageLogin: AuraLogin, helmetPage: "Somos Aura | CMS" });
    } else {
      this.setState({ imageLogin: UhooLogin, helmetPage: "Uhoo | CMS" });
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKaADAAQAAAABAAAAKQAAAADAIIRfAAAFK0lEQVRYCbVZW2icRRQ+ZzaxjWml1jRpo33QCgrxQZB6QYoNGgvWF7Fg+iAWEeODPgQxyfapTyZbQVEiGgQfFDGFpohFkQYhKqKlD0JVpK1oa2torlZTbEJ2/+M38++6t3/ObNPsQPLPzLnMN3POzJw5y7TCIiPt19Ol7KMUSReRbIWadhJuR/0mIp4jlkn04Y/Pk+Fx2tBwjHsm/13JcHw1QiIHDA0NPwWZbgzeRSJNNcszX8EExsE/SgMvHmI+ENUqWzNIeXXTY1CawUB31arcz8c/gdbP+2c+9/MUKUGQ8lrbrZTNvU9CO4tiq1RjmqCG1LP8ytTvmkYVpBxseYhyPAazws/qVBj+m5InuW/2K98IxkeQodYeytF4XQHawe0CYBw3ngdM4ko6gSh61yNTv25jXuCB6ZHKAapAyuDmnUTZY/DBxkrmureZlilFXZWmLzO32ySUO6wAzBLTKXLHyQogWxBWnngxUdouDPZAjKPIUQYy3sVJmwRK2fTRlnXrOT17J93fcQMZ2guws0VVSi2eVC81b4jlH+hYT8Y8DcB/VUlZH7WnSUn539zxOSifldCKVWOega98UOyIa3CN7XCN77DyqUpaWZu5m9Mzh8r60JCDrQ9SNvoa1fLFcoy8u3COOqK7SdxBXanGtvlkEkBHSV88ge+orXsL04kkgJaf+6a/xf8jHtlMHld+Bpl3cM35bhI+7lESd3OATgE68/fJ+oEnvoLzICVn72NPkTkPId/Nf6t0oX9UOqn6sXjwBRfN2GDBVxjurZUIHqmVkLxo8txl8RkXbmnRjIgO0miDAL0EJmnYP0mLC+GgieNBbSlCNNZDrtBKhtQjXsXudgGrn5UDx4vk/CthtYYsEXIX4LNHEKJprZiEM6yEnwPmDq1kyF2ADyw25FcKB3xSFJ+yakMg1Y0DeeCz5tZjxZDjh1YyKB+YJPBZU87YCStF392hlVAU10biOQvyzwBzg0oXs6TTeVmlRxK492XSIJIJgWxTB2E+qdKN+VGlM7WqdDyL7d66EGC6WaNz+uJZTHQskYf5DN2Y+iSRVujk0OnC5w2leKLAn/gVuhtBaHMirdC5tvk5xJsfF5ruy3QcZ+TjwYSARDvK5CobSCywu7vnl6eh0A+EzR5OTyevVolSGWy7DUdOB0W5U5SeOcOs71zJbG2n6MoF79Vpg+WNjS0mP1P9kS7SU4LFW+X01G88MHWU98+eDgF0SnJLPV6AjkHGLb74NmE67B05Zu7CC3KXznN1VHmjZQvyRS8HpFxAHYO8r+MIzPSLKhDJ2/L6LRtVnhqJLuJe5PdUFyOkYpAzsiodSO6cwCvQ9OpjyDZaWhyTkXsadb4aqJnhDADuDnD2F5JaZbeJDG46andkQPhLalq3h3vPXgrwVZHdBOfPDcMPn68ilnYgR4RXaWehKzZ3oUXUC7PrzwGih2nx8s+Y0L7CQ6ko7q9JZvO9NH/umzBA5IaQxCrVVLaSlgBlnZTLfoHqdaWMiXXmHzDom8RNn3L6j6o3tLx1+xq6vLCLONoHCz2RqKO005PBqAJpZWSwZS8+H+nHQ6l2inDr/Iqe04jNFqBhLWS3wSp3ANyaMk6tUWsuqKAD5uzHAEOFdl2/Lv1iXkpKVtlxE1fSEsSG/UOtC6j4byLLeK3lWvKTlGndUX+ANtNrtldm0SrnnRgr4g5+hATZtbqVfM48vcKcuQy1dGMbfAh8iRNYMW6XWVulXx+wYUZhZiXtEoDJSC4LT6/m7zj/AexV0r66K8xIAAAAAElFTkSuQmCC";
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { location } = this.props;

    const organizationId = location
      ? location.props
        ? location.props.organizationId
        : null
      : null;
    const organizationName = location.props
      ? location.props.organizationName
      : null;

    if (organizationName == null) {
      this.getOrganizationByIdFunction(organizationId);
    }

    if (organizationId != null) {
      const { roleId } = this.props.location.props.roleId;

      this.getUsersByOrganizationIdAndRoleIdFunction(organizationId, roleId);
      this.getUsersByOrganizationIdFunction(organizationId);

      this.setState({
        organizationId,
        organizationName,
        roleId: this.props.location.props.roleId,
        roleName: this.props.location.props.roleName,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getOrganizationByIdFunction(id) {
    const response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        this.setState({
          organizationName: response.data.data.name,
        });
      }
    }
  }

  async getUsersByOrganizationIdAndRoleIdFunction(organizationId, roleId) {
    let assignedUsers = [...this.state.assignedUsers];
    const response = await getUsersByOrganizationIdAndRoleId(
      organizationId,
      roleId
    );

    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        assignedUsers = response.data.data;
      }
      this.setState({
        assignedUsers,
        loadingAssignedUsers: false,
      });
    }
  }

  async getUsersByOrganizationIdFunction(organizationId) {
    let users = [...this.state.users];
    const response = await getUsersByOrganizationId(organizationId);

    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        users = response.data.data;
      }
      this.setState({
        users,
        loadingUsers: false,
      });
    }
  }

  async disableRoleByUserFunction(organizationId, roleId, userId) {
    const response = await disableRoleByUser(organizationId, roleId, userId);
    const {t} = this.props
    if (response) {
      if (String(response).includes("Error:")) {
        this.setState({
          titleNotificationModal: t('modal.internalServerError'),
          contenNotificationModal:
            t('modal.internalServerErrorContactSupport'),
          typeNotificationModal: "error",
        });
      } else {
        this.setState({
          titleNotificationModal: t('modal.successfulEdition'),
          contenNotificationModal: t('modal.userDisassignedSuccessfully'),
          typeNotificationModal: "success",
        });
        this.getUsersByOrganizationIdAndRoleIdFunction(organizationId, roleId);
        this.getUsersByOrganizationIdFunction(organizationId);
      }
      this.setState({
        openNotificationModal: true,
      });
    }
  }

  async insertRoleByUserFunction(infoToAdd) {
    const { organizationId, roleId } = this.state;
    const response = await insertRoleByUser(infoToAdd);
    const {t} = this.props
    if (response) {
      if (String(response).includes("Error:")) {
        this.setState({
          titleNotificationModal: t('modal.internalServerError'),
          contenNotificationModal: t('modal.internalServerError'),
          typeNotificationModal: "error",
        });
      } else {
        this.setState({
          titleNotificationModal: t('modal.assignmentSuccessful'),
          contenNotificationModal: t('modal.usersSuccesfullyAssigned'),
          typeNotificationModal: "success",
        });
        this.getUsersByOrganizationIdAndRoleIdFunction(organizationId, roleId);
        this.getUsersByOrganizationIdFunction(organizationId);
      }
      this.setState({
        loadingAssignedUsers: false,
        loadingUsers: false,
        openNotificationModal: true,
        addButtonLoading: false,
      });
    }
  }

  // Events
  removeUserEvent = (id) => {
    const { organizationId, roleId } = this.state;
    this.setState({
      loadingAssignedUsers: true,
      loadingUsers: true,
    });
    this.disableRoleByUserFunction(organizationId, roleId, id);
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let usersToAdd = [...this.state.usersToAdd];

      if (selectedRows.length > 0) {
        this.setState({
          addButtonDisabled: false,
        });
      } else {
        this.setState({
          addButtonDisabled: true,
        });
      }
      usersToAdd = selectedRows;

      this.setState({
        usersToAdd,
      });
    },
  };

  saveRoleByUserEvent = () => {
    const { usersToAdd, organizationId, roleId } = this.state;

    this.setState({
      addButtonLoading: true,
    });
    const infoToAdd = {
      organizationId: null,
      roleId: null,
      userIds: [],
    };

    const userIds = [];

    usersToAdd.forEach((obj) => {
      userIds.push(obj.id);
    });

    infoToAdd.organizationId = organizationId;
    infoToAdd.roleId = roleId;
    infoToAdd.userIds = userIds;

    this.insertRoleByUserFunction(infoToAdd);
  };

  warning = () => {
    const {t} = this.props
    this.setState({
      titleNotificationModal: t('modal.organizationNotSelected'),
      contenNotificationModal: t('modal.noOrganizationSelected'),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  // Checkbox
  // Select all
  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedAll = this.state.difference.map((item) => item.name);
      this.setState({
        selected: selectedAll,
      });
    } else {
      this.setState({
        selected: [],
      });
    }
    return this.state.selected;
  };

  // Select one
  handleSelectOneClick = (event, name) => {
    const selected = this.state.selected;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selected: newSelected,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  render() {
    const {
      users,
      assignedUsers,
      redirectBack,
      organizationName,
      roleName,
      selected,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;

    const {t} = this.props
    const difference = users.filter(
      ({ id: id1 }) => !assignedUsers.some(({ id: id2 }) => id2 === id1)
    );
    if (redirectBack) {
      return <Redirect to="/roleByUser" />;
    }

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Card className="card">
          {/* Header */}
          <div className="row componentSpace">
            <Breadcrumb
              principal={t('generalUser.assigningRole')}
              icon={<Home />}
              secondary={t('generalUser.roles')}
              third={t('sections.home')}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="col-md-4"
                style={{
                  width: "20vw",
                  paddingLeft: "20px",
                  paddingRight: "2%",
                }}
              >
                <GeneralInput
                  text={t('organization.organization') + "*"}
                  id="organizationType"
                  value={organizationName}
                  placeholder={t('organization.organization') + "*"}
                  isReq
                />
              </div>
              <div className="col-md-6 componentSpace">
                <GeneralInput
                  text={t('basicWords.name') + "*"}
                  id="name"
                  value={roleName ? roleName : ""}
                  placeholder={t('basicWords.role') + "*"}
                  isReq
                  onChange={(e) => this.handleFormChange(e)}
                />
              </div>
            </div>
          </div>

          <div className="row componentSpace">
            <Title title={t('generalMessages.assignedUsers')} />
          </div>

          {/* First table */}
          {/* Table */}
          <div className="row componentSpace">
            <div className="col-12">
              <TableContainer style={{ maxHeight: "440" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('basicWords.name')}</TableCell>
                      <TableCell>
                        {t('generalUser.identityCardNumber')}
                      </TableCell>
                      <TableCell>{t('generalUser.username')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((row) => {
                      return (
                        <TableRow key={row.name}>
                          <TableCell>{`${row.name} ${
                            row.lastName1 ? row.lastName1 : ""
                          } ${row.lastName2 ? row.lastName2 : ""}`}</TableCell>
                          <TableCell>{row.identityCardNumber}</TableCell>
                          <TableCell>{row.username}</TableCell>
                          <TableCell>
                            <div className="containerIconsTable">
                              <div className="bgIconTrash">
                                <Trash2
                                  className="iconTrash"
                                  onClick={(e) => this.handleClickOpen}
                                />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Second title */}
          <div className="row componentSpace">
            <Title title={t('generalUser.unassignedUsers')} />
          </div>

          {/* Second table */}
          {/* Table */}
          <div className="row componentSpace adjustPositionYBottom">
            <div className="col-12">
              <TableContainer style={{ maxHeight: "440" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <Checkbox
                        onChange={this.handleSelectAllClick}
                        inputProps={{ "aria-label": "select all" }}
                      />
                      <TableCell>{t('basicWords.name')}</TableCell>
                      <TableCell>
                        {t('generalUser.identityCardNumber')}
                      </TableCell>
                      <TableCell>{t('generalUser.username')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {difference.map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow key={row.name}>
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-label": `${labelId}` }}
                          ></Checkbox>
                          <TableCell>{`${row.name} ${
                            row.lastName1 ? row.lastName1 : ""
                          } ${row.lastName2 ? row.lastName2 : ""}`}</TableCell>
                          <TableCell>{row.identityCardNumber}</TableCell>
                          <TableCell>{row.username}</TableCell>
                          <TableCell>
                            <div className="containerIconsTable">
                              <div className="bgIconTrash">
                                <Trash2
                                  className="iconTrash"
                                  onClick={(e) => this.handleClickOpen}
                                />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={null}
          FlagclassName={true}
          onClick={(e) => this.saveRoleByUserEvent(e)}
          path="./roleByUser"
        />
      </>
    );
  }
}

export default withTranslation()(RoleToUsersAssignation)