import React from "react";

import { GeneralInput, SelectSingle } from "../../../components/index";

//Localization
import { useTranslation } from "react-i18next";

const PaymentPlatformForm = (props) => {
  const { paymentPlatform, paymentPlatforms, height } = props;
  const { t } = useTranslation();
  function renderPaymentPlatformParameter(paymentPlatform) {
    if (paymentPlatform && paymentPlatform.parameters) {
      let paramsRender = [];
      paymentPlatform.parameters.forEach((p) => {
        paramsRender.push(
          <div>
            <div className="row contentForm">
              <div className="col-12">
                <GeneralInput
                  id={p.name}
                  placeholder={t(`organization.${p.name}`)}
                  value={p.value ? p.value : ""}
                  isReq={p.isRequired === 1}
                  onChange={(e) => props.onParamChange(e.target.value, p)}
                />
              </div>
            </div>
            <br />
          </div>
        );
      });
      return paramsRender;
    }
  }

  return (
    <div style={{ height: height }}>
      <div className="row contentForm">
        <div className="col-xs-12 col-md-8 componentSpace">
          <SelectSingle
            defaultValue={paymentPlatform ? paymentPlatform.id : null}
            text={t("donations.paymentPlatform")}
            isReq={true}
            isDisabled={false}
            isLoading={false}
            onChange={(option) => props.onSelect(option)}
            options={paymentPlatforms.map((p) => {
              return { value: p, label: p.name };
            })}
          />
        </div>
        <br />
      </div>
      <div className="row contentForm">
        <div className="col-xs-12" style={{ width: "98%", paddingLeft: "1%" }}>
          {renderPaymentPlatformParameter(paymentPlatform)}
        </div>
      </div>
    </div>
  );
};

export default PaymentPlatformForm;
