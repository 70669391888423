import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Collapse from "@kunukn/react-collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ChevronDown, ChevronUp, AlertCircle, Eye, Plus } from "react-feather";
import {
  Divider,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Button,
  Tooltip,
  TextField,
} from "@material-ui/core";

//Localization
import { withTranslation } from "react-i18next";

//Queries
import {
  getLandingPageSections,
  getThemes,
  getLandingpageStrucureById,
  updateStructuresPositions,
  updateLpSubpageByLpId,
  updateLpSubpageGroupByLpId,
  createLpSubpageByLpId,
  createLpSubpageGroupByLpId,
  updateLpSection,
  getFile,
  getLandingPageInfoByUrl,
  updateLpTheme,
  disableSection,
  createNewLpSectionBySectionIdAndLpId,
} from "../../actions/index";

//Components
import {
  ButtonComponent,
  Breadcrumb,
  NotificationModal,
  ButtonsFooterComponent,
  GeneralModal,
} from "../../components/index";
import { ValidatePermissionForComponentPart } from "../../reactUtils/generalUtils/validatePermissionForComponentPart";
import AddNewModal from "./AddNewModal.jsx";
import "./MyWebPage.scss";

import HorizontalDragDrop from "./HorizontalDragDrop.jsx";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// Images
import AuraLogin from "../../assets/images/loginLogo.png";
import UhooLogin from "../../assets/images/LogoUhooColor.png";
let tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;

class MyWebPage extends Component {
  constructor(props) {
    super(props);

    this.disableSectionFunction = this.disableSectionFunction.bind(this);
    this.createLpSubpageByLpId = this.createLpSubpageByLpId.bind(this);
    this.updateNewSectionComponentFunction =
      this.updateNewSectionComponentFunction.bind(this);

    this.state = {
      height: 0,
      posFooter: null,

      organizationId: null,
      organizatioName: null,
      country: null,
      region: null,
      city: null,
      registers: [],
      sections: null,
      sectionToAdd: null,
      lpThemeName: null,
      columnSelected: null,

      loadingInfo: true,
      previousPath: false,

      //subpage management
      selectedSectionSubpage: null,
      addNewModalVisible: false,
      indexInitialDataChange: null,

      availableSections: [],

      //Themes
      themesModalVisible: false,
      goToWithoutSaveModalVisible: false,
      valueRadioGroup: 1,
      initialData: [],
      indexInitialData: null,
      typeMove: [],
      sourceDataMoved: [],
      destinationDataMoved: [],
      FlagclassName: false,
      isOpenConfigStyle: false,
      openDialog: false,
      openNotificationModal: false,
      newThemeId: null,
      oldThemeId: null,
      haveChangeInTheme: false,

      //Modals
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
      openModalToChangeName: false,
      openViewHelpModal: false,

      openNotificationMovePosition: false,
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({ imageLogin: AuraLogin, helmetPage: "Somos Aura | CMS" });
    } else {
      this.setState({ imageLogin: UhooLogin, helmetPage: "Uhoo | CMS" });
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKaADAAQAAAABAAAAKQAAAADAIIRfAAAFK0lEQVRYCbVZW2icRRQ+ZzaxjWml1jRpo33QCgrxQZB6QYoNGgvWF7Fg+iAWEeODPgQxyfapTyZbQVEiGgQfFDGFpohFkQYhKqKlD0JVpK1oa2torlZTbEJ2/+M38++6t3/ObNPsQPLPzLnMN3POzJw5y7TCIiPt19Ol7KMUSReRbIWadhJuR/0mIp4jlkn04Y/Pk+Fx2tBwjHsm/13JcHw1QiIHDA0NPwWZbgzeRSJNNcszX8EExsE/SgMvHmI+ENUqWzNIeXXTY1CawUB31arcz8c/gdbP+2c+9/MUKUGQ8lrbrZTNvU9CO4tiq1RjmqCG1LP8ytTvmkYVpBxseYhyPAazws/qVBj+m5InuW/2K98IxkeQodYeytF4XQHawe0CYBw3ngdM4ko6gSh61yNTv25jXuCB6ZHKAapAyuDmnUTZY/DBxkrmureZlilFXZWmLzO32ySUO6wAzBLTKXLHyQogWxBWnngxUdouDPZAjKPIUQYy3sVJmwRK2fTRlnXrOT17J93fcQMZ2guws0VVSi2eVC81b4jlH+hYT8Y8DcB/VUlZH7WnSUn539zxOSifldCKVWOega98UOyIa3CN7XCN77DyqUpaWZu5m9Mzh8r60JCDrQ9SNvoa1fLFcoy8u3COOqK7SdxBXanGtvlkEkBHSV88ge+orXsL04kkgJaf+6a/xf8jHtlMHld+Bpl3cM35bhI+7lESd3OATgE68/fJ+oEnvoLzICVn72NPkTkPId/Nf6t0oX9UOqn6sXjwBRfN2GDBVxjurZUIHqmVkLxo8txl8RkXbmnRjIgO0miDAL0EJmnYP0mLC+GgieNBbSlCNNZDrtBKhtQjXsXudgGrn5UDx4vk/CthtYYsEXIX4LNHEKJprZiEM6yEnwPmDq1kyF2ADyw25FcKB3xSFJ+yakMg1Y0DeeCz5tZjxZDjh1YyKB+YJPBZU87YCStF392hlVAU10biOQvyzwBzg0oXs6TTeVmlRxK492XSIJIJgWxTB2E+qdKN+VGlM7WqdDyL7d66EGC6WaNz+uJZTHQskYf5DN2Y+iSRVujk0OnC5w2leKLAn/gVuhtBaHMirdC5tvk5xJsfF5ruy3QcZ+TjwYSARDvK5CobSCywu7vnl6eh0A+EzR5OTyevVolSGWy7DUdOB0W5U5SeOcOs71zJbG2n6MoF79Vpg+WNjS0mP1P9kS7SU4LFW+X01G88MHWU98+eDgF0SnJLPV6AjkHGLb74NmE67B05Zu7CC3KXznN1VHmjZQvyRS8HpFxAHYO8r+MIzPSLKhDJ2/L6LRtVnhqJLuJe5PdUFyOkYpAzsiodSO6cwCvQ9OpjyDZaWhyTkXsadb4aqJnhDADuDnD2F5JaZbeJDG46andkQPhLalq3h3vPXgrwVZHdBOfPDcMPn68ilnYgR4RXaWehKzZ3oUXUC7PrzwGih2nx8s+Y0L7CQ6ko7q9JZvO9NH/umzBA5IaQxCrVVLaSlgBlnZTLfoHqdaWMiXXmHzDom8RNn3L6j6o3tLx1+xq6vLCLONoHCz2RqKO005PBqAJpZWSwZS8+H+nHQ6l2inDr/Iqe04jNFqBhLWS3wSp3ANyaMk6tUWsuqKAD5uzHAEOFdl2/Lv1iXkpKVtlxE1fSEsSG/UOtC6j4byLLeK3lWvKTlGndUX+ANtNrtldm0SrnnRgr4g5+hATZtbqVfM48vcKcuQy1dGMbfAh8iRNYMW6XWVulXx+wYUZhZiXtEoDJSC4LT6/m7zj/AexV0r66K8xIAAAAAElFTkSuQmCC";
    }
    const organizationId = sessionStorage.getItem("organization_id");
    //If organization is Aura, get landing page with landing page id previos selected
    if (parseInt(organizationId, 10) === 1) {
      const { props } = this.props.location;
      if (props) {
        this.getLandingPageInfoFunction(props.organizationId, props.urlPage);
        this.setState({
          previousPath: props.previousPath ? true : false,
          organizationId: props.organizationId,
          lpId: props.landingPageId,
          organizationName: props.organizationName,
          urlPage: props.urlPage,
        });
      } else {
        this.warning();
      }
    } else {
      this.getLandingPageInfoFunction(organizationId, null);

      this.setState({
        organizationId: organizationId,
      });
    }
  }

  componentWillMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  //Queries
  async getLandingPageInfoFunction(organizationId, urlPage, flagNotState) {
    let sections = [];
    let subpagesToShow = [];
    let subpagesWithSections = [];
    let groupSubpagesToShow = [];
    let response = [];
    const { t } = this.props;
    if (urlPage) {
      response = await getLandingPageInfoByUrl(urlPage);
    } else {
      response = await getLandingPageSections(organizationId);
    }
    let responseDTO = await getLandingpageStrucureById(
      response.data.data.landingPageId
    );
    if (String(responseDTO).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      let subpages = responseDTO.data.data.lpSubpages;
      let groupSubpages = responseDTO.data.data.lpSubpageGroups;
      subpages.forEach((subpage) => {
        //If subpage is not into group subpage
        if (subpage.isEnabled === 1 && subpage.subpageGroupId === null) {
          subpagesToShow.push(subpage);
          let subpageExists = [];
          subpageExists = subpagesToShow.find((item) => item.id === subpage.id);
          if (!subpage.sections) {
            subpage.sections = [];
          }
          if (subpageExists && subpageExists.length === 0) {
            subpagesToShow.push(subpage);
          }
        } else {
          if (subpage.isEnabled === 1) {
            subpagesWithSections.push(subpage);
            let subpageExists = [];
            subpageExists = subpagesWithSections.find(
              (item) => item.id === subpage.id
            );
            if (!subpage.sections) {
              subpage.sections = [];
            }
            if (subpageExists && subpageExists.length === 0) {
              subpagesWithSections.push(subpage);
            }
          }
        }
      });

      responseDTO.data.data.lpsections.forEach((section) => {
        //Find sections in mainpage
        if (
          section.mustBeSubpage !== 1 &&
          section.subpages.length === 0 &&
          section.positionInMainPage !== null
        ) {
          sections.push(section);
        } else {
          if (section.subpages.length > 0) {
            //   //If have a subpage asociated
            subpagesWithSections.forEach((subpage, index) => {
              section.subpages.forEach((idAsociatedSection, index2) => {
                let sectionSubpages = [];
                if (subpage.id === idAsociatedSection) {
                  sectionSubpages.push(section);
                }
                if (sectionSubpages.length > 0) {
                  if (section.positionsInSubpage === null) {
                  } else {
                    section.positionsInSubpage =
                      section.positionsInSubpage[index2];
                  }
                  subpagesWithSections[index].sections[
                    subpagesWithSections[index].sections.length
                  ] = section;
                }
              });
            });
            subpagesToShow.forEach((subpage, index) => {
              section.subpages.forEach((idAsociatedSection, index2) => {
                let sectionSubpages = [];
                if (subpage.id === idAsociatedSection) {
                  sectionSubpages.push(section);
                }
                if (sectionSubpages.length > 0) {
                  if (section.positionsInSubpage === null) {
                  } else {
                    section.positionsInSubpage =
                      section.positionsInSubpage[index2];
                  }
                  subpagesToShow[index].sections[
                    subpagesToShow[index].sections.length
                  ] = section;
                }
              });
            });
          }
        }
      });

      groupSubpages.forEach((group, index) => {
        //Find subpage into group subpages
        var sectionsGroupSubpages = [];
        subpages.forEach((subpage) => {
          if (
            subpage.subpageGroupId != null &&
            subpage.subpageGroupId === group.id &&
            subpage.isEnabled === 1
          ) {
            subpage.positionInHeader = null;
            sectionsGroupSubpages[sectionsGroupSubpages.length] = subpage;
            groupSubpages[index].sections = sectionsGroupSubpages;
          }
        });
      });

      var positionSections = sections.map(
        (element) => element.positionInMainPage
      );
      const lastSectionPosition = Math.max.apply(null, positionSections);
      // this.setState({ posFooter: lastSectionPosition });
      sections = sections.sort(compare);

      sections.forEach((element, index) => {
        sections[index].positionInMainPage = index;
      });

      function compare(a, b) {
        if (a.positionInMainPage === null) {
          a.positionInMainPage = lastSectionPosition + 2;
        }
        if (a.name === "FOOTER") {
          a.positionInMainPage = lastSectionPosition + 2;
        }
        if (b.name === "FOOTER") {
          b.positionInMainPage = lastSectionPosition + 2;
        }
        if (a.name === "CONTACT") {
          a.positionInMainPage = lastSectionPosition + 1;
        }
        if (b.name === "CONTACT") {
          b.positionInMainPage = lastSectionPosition + 1;
        }
        if (a.positionInMainPage > b.positionInMainPage) {
          return 1;
        }
        if (b.positionInMainPage > a.positionInMainPage) {
          return -1;
        }
        return 0;
      }

      function compareSubpageAndGroupSubpage(a, b) {
        if (a.positionInHeader > b.positionInHeader) {
          return 1;
        }
        if (b.positionInHeader > a.positionInHeader) {
          return -1;
        }
        return 0;
      }

      function compareSubapageInGroupSubpage(a, b) {
        if (a.positionInSubpageGroup > b.positionInSubpageGroup) {
          return 1;
        }
        if (b.positionInSubpageGroup > a.positionInSubpageGroup) {
          return -1;
        }
        return 0;
      }

      function compareSectionsInSubpage(a, b) {
        if (a.positionsInSubpage > b.positionsInSubpage) {
          return 1;
        }
        if (b.positionsInSubpage > a.positionsInSubpage) {
          return -1;
        }
        return 0;
      }

      var initialData = [
        {
          style: "mainpage",
          id: 10001,
          sections: sections,
          name: t("basicWords.mainpage"),
          positionInHeader: 0,
        },
      ];

      subpagesToShow = subpagesToShow.sort(compareSubpageAndGroupSubpage);
      subpagesToShow.forEach((element, index) => {
        //add subpages enable
        if (!subpagesToShow[index].sections) {
          subpagesToShow[index].sections = [];
        }

        subpagesToShow[index].style = "subpage";
        if (subpagesToShow[index].sections) {
          subpagesToShow[index].sections = subpagesToShow[index].sections.sort(
            compareSectionsInSubpage
          );
        } else {
          groupSubpages[index].sections = [];
        }
        initialData[initialData.length] = subpagesToShow[index];
      });
      groupSubpages = groupSubpages.sort(compareSubpageAndGroupSubpage);
      groupSubpages.forEach((element, index) => {
        //add group subpages enable
        if (element.isEnabled === 1) {
          groupSubpages[index].style = "groupSubpage";
          if (groupSubpages[index].sections) {
            groupSubpages[index].sections = groupSubpages[index].sections.sort(
              compareSubapageInGroupSubpage
            );
          } else {
            groupSubpages[index].sections = [];
          }
          groupSubpagesToShow[groupSubpagesToShow.length] =
            groupSubpages[index];
          initialData[initialData.length] = groupSubpages[index];
        }
      });
      initialData = initialData.sort(compareSubpageAndGroupSubpage);
      let structureSections = {};
      let sectionsIds = [];
      let columns = {};
      let columnOrder = [];

      sections.forEach((element, index) => {
        let aux = index;
        index = element.id;
        structureSections[index] = element;
        sectionsIds[aux] = index;
      });

      columns = {
        "column-10001": {
          style: "mainpage",
          id: "column-10001",
          sectionsIds: sectionsIds,
          name: t("basicWords.mainpage"),
          positionInHeader: 0,
        },
      };
      let lengthColumns = 1;
      initialData.forEach((data, indexColumns) => {
        sectionsIds = [];
        indexColumns = "column-" + data.id;
        data.sections.forEach((section, index) => {
          let auxSection = index;
          index = section.id;
          structureSections[index] = section;
          sectionsIds[auxSection] = index;
        });

        if (data.style === "subpage") {
          columns[indexColumns] = {
            style: "subpage",
            isEnabled: data.isEnabled,
            isVisible: data.isVisible,
            sections: data.sections,
            id: indexColumns,
            sectionsIds: sectionsIds,
            name: data.name,
            positionInHeader: lengthColumns,
          };
        }

        if (data.style === "groupSubpage") {
          columns[indexColumns] = {
            style: "groupSubpage",
            id: indexColumns,
            sectionsIds: sectionsIds,
            name: data.name,
            isEnabled: data.isEnabled,
            sections: data.sections,
            positionInHeader: lengthColumns,
          };
        }

        columnOrder[lengthColumns] = indexColumns;
        lengthColumns = lengthColumns + 1;
      });

      this.setState({
        organizationId: organizationId,
        organizationName: response.data.data.organizationName,
        lpId: response.data.data.landingPageId,
        country: response.data.data.country,
        region: response.data.data.region,
        city: response.data.data.city,
        registers: sections,
        loadingInfo: false,
        lpThemeName: response.data.data.lpThemeName,
        lpThemeId: response.data.data.lpThemeId,
        initialData,
        columnOrder,
        structureSections,
        columns,
        urlPage: response.data.data.lpUrl,
      });
      //Get image
      let img = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" +
          response.data.data.lpThemeThumbnailUrl
      );
      this.setState({
        lpThemeImg: img,
      });
      // if (!flagNotState) {
      //   this.handleSelectTheme();
      // }
      // else{
      //   window.location.reload(true);
      // }
      this.handleSelectTheme();
    }
  }

  async createLpSubpageOrGroupSubpage(
    lpId,
    organizationId,
    sectionToAdd,
    valueRadioGroup
  ) {
    let initialData = this.state.initialData;
    switch (valueRadioGroup) {
      case 1:
        let subpageDTO = {
          name: sectionToAdd,
          id: null,
          positionInHeader: initialData.length - 1,
          positionInSubpageGroup: null,
          subpageGroupId: null,
          displayType: 0,
          isVisible: 0,
          isEnabled: 1,
        };
        var response = await createLpSubpageByLpId(lpId, subpageDTO);
        break;
      case 2:
        let groupSubpageDTO = {
          name: sectionToAdd,
          id: null,
          positionInHeader: initialData.length - 1,
          isEnabled: 1,
        };
        var response = await createLpSubpageGroupByLpId(lpId, groupSubpageDTO);
        break;
    }
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.itemSuccesfullyCreated"),
        typeNotificationModal: "success",
      });
      this.getLandingPageInfoFunction(organizationId, this.state.urlPage);
      this.setState({ openNotificationModal: true });
    }
  }

  async createLpSubpageByLpId(lpId, name, groupId) {
    let registers = this.state.registers;
    let subpageDTO = {
      name: name,
      id: null,
      positionInHeader: null,
      positionInSubpageGroup: registers.length,
      subpageGroupId: parseInt(groupId.substring(7, groupId.length)),
      displayType: 0,
      isVisible: 1,
      isEnabled: 1,
    };
    var response = await createLpSubpageByLpId(lpId, subpageDTO);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({ openNotificationModal: true });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
      registers[registers.length] = subpageDTO;
      this.setState({ openNotificationModal: true, registers });
      this.getLandingPageInfoFunction(this.state.organizationId, null);
    }
  }

  async disableSectionFunction(LpSectionPutDto, lpsubpage_id) {
    this.setState({
      loadingInfo: true,
    });
    let response = await disableSection(LpSectionPutDto.id, lpsubpage_id);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.disablingSuccessful"),
        contenNotificationModal: t("modal.sectionDisabledSuccessful"),
        typeNotificationModal: "success",
      });
      this.getLandingPageInfoFunction(
        this.state.organizationId,
        this.state.urlPage
      );
    }
    this.setState({
      loadingInfo: false,
      isLoadingButton: false,
      openNotificationModal: true,
    });
  }

  async getThemesFunction(lpId, organizationId) {
    let response = await getThemes(lpId, organizationId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].thumbnailPath) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
              responseData[i].thumbnailPath
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        themesData: responseData,
      });
    }
  }

  async updateLpSectionPositionFunction(dataMySite) {
    const {
      typeMove,
      sourceDataMoved,
      destinationDataMoved,
      dataMoved,
      initialData,
    } = this.state;
    let newposition = [];
    let flag = 0;
    const { t } = this.props;
    typeMove.forEach(async (move, index) => {
      newposition = []; // to empty newposition in every case
      if (move.type === "vertical") {
        let data = initialData[move.index];
        var type = "";
        var level = "";
        var parentId = "";
        if (data.style === "mainpage") {
          type = 2; //Have only sections into
          level = 3;
          parentId = null;
        }
        if (data.style === "subpage") {
          type = 2; //Have only sections into
          level = 2;
          parentId = data.id;
        }
        if (data.style === "groupSubpage") {
          type = 1; //Have only subpages into
          level = 1;
          parentId = data.id;
        }
        for (var i = 0; i < data.sections.length; i++) {
          newposition[newposition.length] = {
            level: level,
            parentId: parentId,
            type: type,
            position: newposition.length,
            id: data.sections[i].id,
          };
        }
        flag = 1;
      }
      if (move.type === "horizontal") {
        dataMySite.forEach((element, index) => {
          switch (element.style) {
            case "subpage":
              newposition[newposition.length] = {
                level: 0,
                parentId: this.state.organizationId,
                type: 1,
                position: newposition.length + 1,
                id: element.id,
              };
              break;
            case "groupSubpage":
              newposition[newposition.length] = {
                level: 0,
                parentId: this.state.organizationId,
                type: 0,
                position: newposition.length + 1,
                id: element.id,
              };
              break;
          }
        });
        flag = 1;
      }
      if (move.type === "anotherList") {
        var type = "";
        var level = "";
        var parentId = "";

        // if (
        //   sourceDataMoved.style === "groupSubpage" ||
        //   destinationDataMoved.style === "groupSubpage"
        // ) {
        if (sourceDataMoved.style === "mainpage") {
          type = 2; //Have only sections into
          level = 3;
          parentId = null;
        }
        if (sourceDataMoved.style === "subpage") {
          type = 2; //Have only sections into
          level = 2;
          parentId = sourceDataMoved.id;
        }
        if (sourceDataMoved.style === "groupSubpage") {
          type = 1; //Have only subpages into
          level = 1;
          parentId = sourceDataMoved.id;
        }
        for (var i = 0; i < sourceDataMoved.sections.length; i++) {
          newposition[newposition.length] = {
            level: level,
            parentId: parentId,
            type: type,
            position: newposition.length,
            id: sourceDataMoved.sections[i].id,
          };
        }
        if (destinationDataMoved.style === "mainpage") {
          type = 2; //Have only sections into
          level = 0;
          parentId = null;
        }
        if (destinationDataMoved.style === "subpage") {
          type = 2; //Have only sections into
          level = 2;
          parentId = destinationDataMoved.id;
        }
        if (destinationDataMoved.style === "groupSubpage") {
          type = 1; //Have only subpages into
          level = 1;
          parentId = destinationDataMoved.id;
        }
        for (var i = 0; i < destinationDataMoved.sections.length; i++) {
          newposition[newposition.length] = {
            level: level,
            parentId: parentId,
            type: type,
            position: newposition.length,
            id: destinationDataMoved.sections[i].id,
          };
        }
        flag = 1;
        // }
      }

      if (
        (flag === 1 &&
          // (sourceDataMoved.style === "groupSubpage" ||
          //   destinationDataMoved.style === "groupSubpage") //If move is from group to group
          !(
            sourceDataMoved.style === "mainpage" ||
            destinationDataMoved.style === "subpage"
          ) &&
          !(
            sourceDataMoved.style === "subpage" ||
            destinationDataMoved.style === "mainpage"
          )) ||
        (flag === 1 && move.type === "horizontal") || //If move is horizontal or vertical
        move.type === "vertical"
      ) {
        let newTypeMove = [];
        this.setState({ typeMove: newTypeMove });
        if (flag === 1) {
          let response = await updateStructuresPositions(newposition);
          if (String(response).includes("Error:")) {
            this.setState({
              titleNotificationModal: t("modal.internalServerError"),
              contenNotificationModal: t(
                "modal.internalServerErrorContactSupport"
              ),
              typeNotificationModal: "error",
            });
          } else {
            this.setState({
              titleNotificationModal: t("modal.modifiedSuccesfully"),
              contenNotificationModal: t(
                "modal.positionSectionSuccessfullyModified"
              ),
              typeNotificationModal: "success",
            });
            this.getLandingPageInfoFunction(
              this.state.organizationId,
              this.state.urlPage
            );
          }
          this.setState({
            isLoadingButton: false,
          });
        }
      }

      if (
        sourceDataMoved.style === "mainpage" &&
        destinationDataMoved.style === "subpage"
      ) {
        let DTOMoved = {
          id: dataMoved.id,
          customName: dataMoved.customName,
          name: dataMoved.name,
          positionMainPage: null,
          positionInSubpages: dataMoved.positionsInSubpage,
          subpagesIds: dataMoved.subpages,
          isEnabled: 1,
          layoutStyleId: dataMoved.layoutStyleId,
        };

        let newTypeMove = [];
        this.setState({ typeMove: newTypeMove });
        let response = await updateLpSection(DTOMoved);
        const { t } = this.props;
        if (String(response).includes("Error:")) {
          this.setState({
            titleNotificationModal: t("modal.internalServerError"),
            contenNotificationModal: t(
              "modal.internalServerErrorContactSupport"
            ),
            typeNotificationModal: "error",
          });
        } else {
          this.setState({
            titleNotificationModal: t("modal.modifiedSuccesfully"),
            contenNotificationModal: t(
              "modal.positionSectionSuccessfullyModified"
            ),
            typeNotificationModal: "success",
            sourceDataMoved: [],
            destinationDataMoved: [],
          });
          this.getLandingPageInfoFunction(
            this.state.organizationId,
            this.state.urlPage
          );
        }
        this.setState({
          isLoadingButton: false,
        });
      }

      if (
        sourceDataMoved.style === "subpage" &&
        destinationDataMoved.style === "mainpage"
      ) {
        let DTOMoved = {
          id: dataMoved.id,
          customName: dataMoved.customName,
          name: dataMoved.name,
          positionMainPage: initialData[0].sections.length,
          positionInSubpages: [],
          subpagesIds: [],
          isEnabled: 1,
          layoutStyleId: dataMoved.layoutStyleId,
        };
        let newTypeMove = [];
        const { t } = this.props;
        this.setState({ typeMove: newTypeMove });
        let response = await updateLpSection(DTOMoved);
        if (String(response).includes("Error:")) {
          this.setState({
            titleNotificationModal: t("modal.internalServerError"),
            contenNotificationModal: t(
              "modal.internalServerErrorContactSupport"
            ),
            typeNotificationModal: "error",
          });
        } else {
          this.setState({
            titleNotificationModal: t("modal.modifiedSuccesfully"),
            contenNotificationModal: t(
              "modal.positionSectionSuccessfullyModified"
            ),
            typeNotificationModal: "success",
            sourceDataMoved: [],
            destinationDataMoved: [],
          });
          this.getLandingPageInfoFunction(
            this.state.organizationId,
            this.state.urlPage
          );
        }
        this.setState({
          isLoadingButton: false,
        });
      }

      if (
        sourceDataMoved.style === "subpage" &&
        destinationDataMoved.style === "subpage"
      ) {
        delete dataMoved.positionInMainPage;
        delete dataMoved.subpagesIds;
        delete dataMoved.positionsInSubpage;
        delete dataMoved.subpages;
        delete dataMoved.sectionsIds;
        delete dataMoved.positionMainPage;
        dataMoved.isEnabled = 1;
        dataMoved.positionMainPage = null;
        dataMoved.positionInSubpages = [destinationDataMoved.sections.length];
        dataMoved.subpagesIds = [destinationDataMoved.id];
        let DTOSectionMoved = {
          customName: dataMoved.customName,
          name: dataMoved.name,
          id: dataMoved.id,
          layoutStyleId: dataMoved.layoutStyleId,
          mustBeSubpage: dataMoved.mustBeSubpage,
          style: dataMoved.style,
          isEnabled: 1,
          positionMainPage: null,
          positionInSubpages: [destinationDataMoved.sections.length],
          subpagesIds: [destinationDataMoved.id],
        };
        let newTypeMove = [];
        this.setState({ typeMove: newTypeMove });
        let response = await updateLpSection(DTOSectionMoved);
        const { t } = this.props;
        if (String(response).includes("Error:")) {
          this.setState({
            titleNotificationModal: t("modal.internalServerError"),
            contenNotificationModal: t(
              "modal.internalServerErrorContactSupport"
            ),
            typeNotificationModal: "error",
          });
        } else {
          this.setState({
            titleNotificationModal: t("modal.modifiedSuccesfully"),
            contenNotificationModal: t(
              "modal.positionSectionSuccessfullyModified"
            ),
            typeNotificationModal: "success",
            sourceDataMoved: [],
            destinationDataMoved: [],
          });
          this.getLandingPageInfoFunction(
            this.state.organizationId,
            this.state.urlPage
          );
        }
        this.setState({
          isLoadingButton: false,
        });
      }
    });
  }

  async updateLpSubpageGroupByLpId(LpSubpageGroupDto) {
    const { t } = this.props;
    let response = await updateLpSubpageGroupByLpId(LpSubpageGroupDto);
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
    }
    this.setState({
      openNotificationModal: true,
      openModalToChangeName: false,
    });
  }

  async updateLpSubpageByLpId(LpSubpageDto, flagEditVisibilityPage) {
    const { t } = this.props;
    let response = await updateLpSubpageByLpId(LpSubpageDto);
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      if (flagEditVisibilityPage) {
        this.setState({
          titleNotificationModal: t("modal.modifiedSuccesfully"),
          contenNotificationModal: t("modal.visibilitySubpage"),
          typeNotificationModal: "success",
        });
      } else {
        this.setState({
          titleNotificationModal: t("modal.modifiedSuccesfully"),
          contenNotificationModal: t("modal.deleteElementSuccessful"),
          typeNotificationModal: "success",
        });
      }
    }
    this.setState({
      openNotificationModal: true,
      openModalToChangeName: false,
    });
  }

  async updateLpThemeFunction(lpId, lpThemeId) {
    const { previousPath, organizationId, urlPage } = this.state;
    const { t } = this.props;
    let response = await updateLpTheme(lpId, lpThemeId);
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.generalStyleSuccessfullyModified"),
        typeNotificationModal: "success",
      });
      this.updatePage(organizationId, urlPage, previousPath);
    }
    this.setState({ openNotificationModal: true, isLoadingButton: false });
  }

  async updateNewSectionComponentFunction(sectionId, lpId, subpageId) {
    let subpage_id = subpageId !== undefined ? subpageId : "";
    let response = await createNewLpSectionBySectionIdAndLpId(
      sectionId,
      lpId,
      subpage_id,
      sectionId //standard layoutid == sectionId
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({ openNotificationModal: true });
    } else {
      this.setState({
        titleNotificationModal: t("modal.itemSuccesfullyCreated"),
        contenNotificationModal: t("modal.itemSuccesfullyCreated"),
        typeNotificationModal: "success",
      });
      this.setState({ openNotificationModal: true });
      // this.setState({open: true});
      this.getLandingPageInfoFunction(
        this.state.organizationId,
        this.state.urlPage
      ); //Add info sections
    }
  }

  ongroupSubpageOrSubPageDelete = (data) => {
    let { organizationId, initialData } = this.state;
    let subpageOrgroupSubpageToUpdate = null;
    let id = data.id;
    id = id.substring(7, id.length);
    id = parseInt(id);
    initialData.forEach((element, index) => {
      if (element.id === id) {
        initialData[index].isEnabled = 0;
        subpageOrgroupSubpageToUpdate = initialData[index];
      }
    });
    if (subpageOrgroupSubpageToUpdate.style === "subpage") {
      this.updateLpSubpageByLpId(subpageOrgroupSubpageToUpdate);
    }
    if (subpageOrgroupSubpageToUpdate.style === "groupSubpage") {
      this.updateLpSubpageGroupByLpId(subpageOrgroupSubpageToUpdate);
    }
    this.getLandingPageInfoFunction(organizationId, this.state.urlPage);
    this.setState({
      openDialog: false,
      openNotificationModal: true,
    });
  };

  OnchangeIsVisible = (data) => {
    let organizationId = this.state.organizationId;
    let initialData = this.state.initialData;
    let subpageOrgroupSubpageToUpdate = null;
    let id = data.id;
    id = id.substring(7, id.length);
    id = parseInt(id);
    initialData.forEach((element, index) => {
      if (element.id === id) {
        if (element.isVisible === 1) {
          initialData[index].isVisible = 0;
        } else {
          initialData[index].isVisible = 1;
        }
        subpageOrgroupSubpageToUpdate = initialData[index];
      }
    });
    this.updateLpSubpageByLpId(subpageOrgroupSubpageToUpdate, true);
    this.setState({ openNotificationModal: true });
    this.getLandingPageInfoFunction(organizationId, this.state.urlPage);
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("modal.sectionUnselected"),
      contenNotificationModal: t("modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  getMyWebpage = () => {
    window.open("https://" + this.state.urlPage);
  };

  handleSelectTheme() {
    this.getThemesFunction(this.state.lpId, this.state.organizationId);
    this.setState({ themesModalVisible: true });
  }

  //-----------Update page after modal------------
  updatePage = (organizationId, urlPage, previousPath) => {
    // let lpId = this.state.lpId;
    this.getLandingPageInfoFunction(organizationId, urlPage);
    this.setState({
      previousPath,
    });
    this.setState({ themesModalVisible: false });
    this.forceUpdate();
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  SavePositionSection = () => {
    var { initialData, columnOrder, haveChangeInTheme, lpId, newThemeId } =
      this.state;
    columnOrder = columnOrder.slice(0, columnOrder.length);
    let orderColumns = [];
    columnOrder.forEach((element) => {
      if (element !== undefined) {
        orderColumns[orderColumns.length] = parseInt(
          element.substring(7, element.length)
        );
      }
    });
    let dataMySite = [];
    orderColumns.forEach((orderColumn) => {
      //reorder initialdata
      initialData.forEach((data) => {
        if (data.id.length > 7) {
          if (parseInt(data.id.substring(7, data.id.length)) === orderColumn) {
            data.id = parseInt(data.id.substring(7, data.id.length));
            dataMySite.push(data);
          }
        } else {
          if (data.id === orderColumn) {
            dataMySite.push(data);
          }
        }
      });
    });

    this.updateLpSectionPositionFunction(dataMySite);
    if (haveChangeInTheme) {
      this.updateLpThemeFunction(lpId, newThemeId);
    }
    this.setState({
      FlagclassName: false,
      isLoading: true,
      saveButtonDisabled: true,
      openNotificationModal: true,
    });
  };

  addNewModal = () => {
    this.setState({
      addNewModalVisible: true,
    });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  onCloseAddNewModal = () => {
    this.setState({
      addNewModalVisible: false,
    });
  };

  onSaveEditAddNewModal = () => {
    const { t } = this.props;
    const { sectionToAdd, valueRadioGroup, lpId, organizationId } = this.state;
    let typeToAdd = parseInt(valueRadioGroup);
    this.setState({
      isLoadingButton: true,
      addNewModalVisible: false,
    });
    if (sectionToAdd === null || sectionToAdd === "" || sectionToAdd === " ") {
      this.setState({
        titleNotificationModal: t("modal.notice"),
        contenNotificationModal: t("modal.nameSubpageOrGroupSubpage"),
        typeNotificationModal: "warning",
      });
    } else {
      this.createLpSubpageOrGroupSubpage(
        lpId,
        organizationId,
        encodeURIComponent(sectionToAdd),
        typeToAdd
      );
    }
  };

  OnChangeAddNewModal = (e) => {
    let { sectionToAdd } = { ...this.state };
    sectionToAdd = e.value;
    this.setState({
      sectionToAdd,
    });
  };

  handleChange = (e) => {
    let { sectionToAdd } = { ...this.state };
    sectionToAdd = e.value;
    this.setState({
      sectionToAdd,
    });
  };

  handleChangeTheme = (e, id) => {
    this.setState({
      newThemeId: id,
      saveButtonDisabled: false,
      FlagclassName: true,
      haveChangeInTheme: true,
    });
  };

  setNotificationMovePostion = () => {
    const openNotificationMovePosition = this.state;
    this.setState({
      openNotificationMovePosition: !openNotificationMovePosition,
    });
  };

  OnChangeInput = (event) => {
    const { value } = event.target;
    let { sectionToAdd } = { ...this.state };
    sectionToAdd = value;
    this.setState({
      sectionToAdd,
    });
  };

  OnChangeRadioGroup = (e) => {
    this.setState({
      valueRadioGroup: e.target.value,
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result, provided) => {
    const { t } = this.props;
    const { destination, source, draggableId, type } = result;
    var typeMove = this.state.typeMove;
    var indexInitialDataChange = this.state.indexInitialDataChange;
    const openNotificationMovePosition =
      this.state.openNotificationMovePosition;
    var initialData = this.state.initialData;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    initialData.forEach((element, index) => {
      if (
        element.id ===
        parseInt(source.droppableId.substring(7, source.droppableId.length))
      ) {
        indexInitialDataChange = index;
      }
    });

    if (type === "horizontal") {
      if (
        this.state.columnOrder[source.index] === "column-10001" ||
        this.state.columnOrder[destination.index] === "column-10001"
      ) {
        this.setState({
          titleNotificationModal: t("basicWords.movementNotAllowed"),
          contenNotificationModal: t("basicWords.mainpageAtTheTop"),
          typeNotificationModal: "warning",
          openNotificationModal: true,
        });

        return;
      }
      if (
        this.state.columnOrder[destination.index] === "column-1000" ||
        this.state.columnOrder[source.index] === "column-1000"
      ) {
        this.setState({
          titleNotificationModal: t("basicWords.movementNotAllowed"),
          contenNotificationModal: t("basicWords.mainpageAtTheTop"),
          typeNotificationModal: "warning",
          openNotificationModal: true,
        });
        return;
      }
      const newColumnOrder = Array.from(this.state.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...this.state,
        columnOrder: newColumnOrder,
      };
      this.setState(newState);
      typeMove.push({ type: type, index: indexInitialDataChange });
      this.setState({
        typeMove,
        FlagclassName: true,
      });
      this.forceUpdate();
      return;
    }

    const home = this.state.columns[source.droppableId];
    const foreign = this.state.columns[destination.droppableId];

    if (home === foreign) {
      //if move is vertical
      const newSectionsIds = Array.from(home.sectionsIds);
      newSectionsIds.splice(source.index, 1);
      newSectionsIds.splice(destination.index, 0, parseInt(draggableId));

      const newHome = {
        ...home,
        sectionsIds: newSectionsIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newHome.id]: newHome,
        },
      };
      var dataTarjet = initialData.find(
        (item) =>
          item.id ===
          parseInt(source.droppableId.substring(7, source.droppableId.length))
      );

      var sourceData = dataTarjet.sections[source.index];
      var destinationData = dataTarjet.sections[destination.index];
      if (
        sourceData.name === "HEADER" ||
        destinationData.name === "HEADER" ||
        sourceData.name === "HOME" ||
        destinationData.name === "HOME" ||
        sourceData.name === "FOOTER" ||
        destinationData.name === "FOOTER" ||
        sourceData.name === "CONTACT" ||
        destinationData.name === "CONTACT"
      ) {
        this.setState({
          titleNotificationModal: t("basicWords.movementNotAllowed"),
          contenNotificationModal: t("basicWords.sectionsStructureMainPage"),
          typeNotificationModal: "warning",
          openNotificationModal: true,
        });
        return;
      }
      typeMove.push({ type: type, index: indexInitialDataChange });
      var items = this.reorder(
        dataTarjet.sections,
        source.index,
        destination.index
      );
      items.forEach((item, index) => {
        items[index].positionInSubpageGroup = index;
      });
      initialData[indexInitialDataChange].sections = items;
      this.setState(newState);
      this.setState({
        typeMove,
        // indexInitialData,
        initialData,
        indexInitialDataChange,
        FlagclassName: true,
      });
      this.forceUpdate();
      return;
    }

    // this.setState({
    //   titleNotificationModal: "No habilitado",
    //   contenNotificationModal:
    //     "Próximamente podrás realizar movimientos entre tarjetas",
    //   typeNotificationModal: "warning",
    //   openNotificationModal: true,
    // });
    // return;

    // moving from one list to another
    const homeSectionsId = Array.from(home.sectionsIds);
    homeSectionsId.splice(source.index, 1);
    const newHome = {
      ...home,
      sectionsIds: homeSectionsId,
    };

    const foreignSectionsIds = Array.from(foreign.sectionsIds);
    foreignSectionsIds.splice(destination.index, 0, parseInt(draggableId));
    const newForeign = {
      ...foreign,
      sectionsIds: foreignSectionsIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };

    const [sourceGroup] = initialData.filter(
      (item) =>
        item.id ===
        parseInt(source.droppableId.substring(7, source.droppableId.length))
    );

    const [destinationGroup] = destination
      ? initialData.filter(
          (item) =>
            item.id ===
            parseInt(
              destination.droppableId.substring(
                7,
                destination.droppableId.length
              )
            )
        )
      : { ...sourceGroup };

    if (
      (sourceGroup.style === "mainpage" || sourceGroup.style === "subpage") &&
      destinationGroup.style === "groupSubpage"
    ) {
      this.setState({
        titleNotificationModal: t("basicWords.movementNotAllowed"),
        contenNotificationModal: t(
          "basicWords.sectionsCanOnlyInMainPageOrSubpage"
        ),
        typeNotificationModal: "warning",
        openNotificationModal: true,
      });
      return;
    }
    if (
      sourceGroup.style === "groupSubpage" &&
      (destinationGroup.style === "mainpage" ||
        destinationGroup.style === "subpage")
    ) {
      this.setState({
        titleNotificationModal: t("basicWords.movementNotAllowed"),
        contenNotificationModal: t("basicWords.subpagesCanOnlyInGroupSubpages"),
        typeNotificationModal: "warning",
        openNotificationModal: true,
      });
      return;
    }

    const movingSection = sourceGroup.sections[source.index];
    if (
      movingSection.mustBeSubpage === 1 &&
      (destinationGroup.style === "mainpage" ||
        destinationGroup.style === "groupSubpage")
    ) {
      this.setState({
        titleNotificationModal: t("basicWords.movementNotAllowed"),
        contenNotificationModal: t("basicWords.sectionMustBeInSubpage"),
        typeNotificationModal: "warning",
        openNotificationModal: true,
      });
      return;
    }

    if (
      movingSection.name === "HEADER" ||
      movingSection.name === "HOME" ||
      movingSection.name === "CONTACT" ||
      movingSection.name === "FOOTER"
    ) {
      this.setState({
        titleNotificationModal: t("basicWords.movementNotAllowed"),
        contenNotificationModal: t("basicWords.cantMoveSectionsOfMainpage"),
        typeNotificationModal: "warning",
        openNotificationModal: true,
      });
      return;
    }

    movingSection.positionInMainPage = null;
    movingSection.positionsInSubpage = [];
    movingSection.positionsInSubpage[movingSection.positionsInSubpage.length] =
      null;

    if (!movingSection.subpages) {
      movingSection.subpages = [];
      movingSection.subpages[movingSection.subpages.length] =
        destinationGroup.id;
    } else {
      movingSection.subpages[movingSection.subpages.length] =
        destinationGroup.id;
    }
    sourceGroup.sections.forEach((element, index) => {
      sourceGroup.sections[index].positionsInSubpage = [index];
    });
    const newSourceGroup = sourceGroup.sections.splice(source.index, 1);
    sourceGroup.sections.forEach((item, index) => {
      sourceGroup.sections[index].positionInMainPage = index;
    });
    const newDestinationGroup = destinationGroup.sections.splice(
      destination.index,
      0,
      movingSection
    );
    typeMove.push({ type: "anotherList", index: indexInitialDataChange });

    this.setState(newState);
    this.setState({
      typeMove,
      initialData,
      sourceDataMoved: sourceGroup,
      dataMoved: newSourceGroup[0],
      destinationDataMoved: destinationGroup,
      FlagclassName: true,
    });
    this.forceUpdate();
  };

  handleClickOpenDialog = (data) => {
    this.setState({ openDialog: true, columnSelected: data });
  };

  handleCloseDialog = (data) => {
    this.setState({ openDialog: false });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  handleOpenViewHelpModal = () => {
    this.setState({ openViewHelpModal: true });
  };

  handleCloseViewHelpModal = () => {
    this.setState({ openViewHelpModal: false });
  };

  renderThemes(themesData) {
    const { lpThemeId, newThemeId } = this.state;
    let renderedComponents = [];
    if (themesData) {
      themesData.forEach((element) => {
        let name = element.name;
        let image = element.img;
        if (image) {
          // isLoadingInfo= false,
          renderedComponents.push(
            <div
              className="col-xs-12 col-md-12 col-lg-4 themeThumbnail"
              style={{ marginRight: "10px", display: "flex" }}
            >
              <div className="cardSelectTheme">
                <img src={image} style={{ height: "160px", width: "230px" }} />
                <RadioGroup
                  name="theme"
                  value={newThemeId ? newThemeId : lpThemeId}
                  onChange={(e) => this.handleChangeTheme(e, element.id)}
                >
                  <FormControlLabel
                    value={element.id}
                    control={<Radio />}
                    label={name}
                  />
                </RadioGroup>
              </div>
            </div>
          );
        }
      });
    }
    return renderedComponents;
  }

  saveChangeName = () => {
    if (this.state.columnToEditName.style === "subpage") {
      this.updateLpSubpageByLpId(this.state.columnToEditName);
    }
    if (this.state.columnToEditName.style === "groupSubpage") {
      this.updateLpSubpageGroupByLpId(this.state.columnToEditName);
    }
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      columnToEditName: itemToChangeName,
    });
  };

  OnChangeNameSubpageOrGroupsubpage = (e) => {
    let columnToEditName = this.state.columnToEditName;
    if (columnToEditName.id.length > 7) {
      columnToEditName.id = parseInt(
        columnToEditName.id.substring(7, columnToEditName.id.length)
      );
    }
    columnToEditName.name = e.target.value;

    this.setState({
      columnToEditName,
      FlagclassName: true,
    });
  };

  //render
  render() {
    const { privileges, userSession } = this.props;
    const {
      organizationName,
      FlagclassName,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
      organizationId,
      sectionName,
      lpId,
      addNewModalVisible,
      valueRadioGroup,
      openModalToChangeName,
      availableSections,
      isOpenConfigStyle,
      themesData,
      registers,
      urlPage,
    } = this.state;

    if (this.state.redirectBack) {
      return <Redirect to="/webPagesList" />;
    }

    const { t } = this.props;
    return (
      <Card className="card">
        {/* <CardContent> */}
        <Fragment>
          <AddNewModal
            visible={addNewModalVisible}
            handleClose={this.onCloseAddNewModal}
            handleSave={this.onSaveEditAddNewModal}
            handleChange={this.OnChangeAddNewModal}
            handleChangeRadioGroup={this.OnChangeRadioGroup}
            handleChangeInput={this.OnChangeInput}
            valueRadioGroup={valueRadioGroup}
          />

          <NotificationModal
            visible={openNotificationModal}
            onClick={this.handleCloseNotificationModal}
            title={titleNotificationModal}
            content={contenNotificationModal}
            type={typeNotificationModal}
          />

          {/* <ViewHelpModal
            visible={openViewHelpModal}
            onClick={this.handleCloseViewHelpModal}
          /> */}

          <GeneralModal
            visible={openModalToChangeName}
            title={"Cambiar nombre"}
            body={[
              <div className="col-12" style={{ margin: "5px" }}>
                <div className="row">
                  <div className="col-md-12 componentSpace">
                    <TextField
                      variant="outlined"
                      style={{ width: "100%", marginLeft: 10 }}
                      label={t("basicWords.nameOfPage")}
                      onChange={(e) =>
                        this.OnChangeNameSubpageOrGroupsubpage(e)
                      }
                      defaultValue={
                        this.state.columnToEditName
                          ? decodeURIComponent(this.state.columnToEditName.name)
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>,
            ]}
            footer={[
              <div className="row buttonAtTheBottom">
                <div>
                  <ButtonComponent
                    onClick={(e) => this.handleClickOpenModalToChangeName()}
                    shape="round"
                    text={t("basicWords.cancel")}
                    className="buttonDiscard"
                  />
                </div>
                <div>
                  <ButtonComponent
                    isDisabled={false}
                    onClick={() => this.saveChangeName()}
                    shape="round"
                    text={t("basicWords.ok")}
                  />
                </div>
              </div>,
            ]}
            onClick={() => this.handleClickOpenModalToChangeName()}
          />

          <div className="spaceLeft">
            {registers.length === 0 ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div className="col-xs-12 col-md-12">
                <div className="containerHeader">
                  <div className="breadcrumb">
                    <Breadcrumb
                      principal={organizationName}
                      secondary={t("basicWords.websites")}
                      third={t("basicWords.myWebSite")}
                    />
                  </div>
                  <div className="buttonsHeader">
                    {/* <div style={{ marginRight: "10px" }}>
                      <ButtonComponent
                        icon={<Video />}
                        shape="round"
                        text={t('basicWords.showHelp')}
                        onClick={() => this.handleOpenViewHelpModal()}
                        className="viewMyWebSite"
                      />
                    </div> */}
                    <div style={{ marginLeft: "10px" }}>
                      <ButtonComponent
                        icon={<Eye />}
                        shape="round"
                        text={t("basicWords.goToMyWebPage")}
                        onClick={() => this.getMyWebpage()}
                        className="viewMyWebSite"
                      />
                    </div>
                  </div>
                </div>

                {availableSections.length > 0 ? (
                  ValidatePermissionForComponentPart(
                    "ADD_WEB_PAGES",
                    privileges
                  ) ? (
                    <div className="row componentSpace">
                      <div className="col-6" />
                      <div className="col-6">
                        <ButtonComponent
                          text={t("generalMessages.addSection")}
                          path={"./addWebPage"}
                          props={null}
                          urlPage={urlPage}
                        />
                      </div>
                    </div>
                  ) : null
                ) : null}
                <div className="col-xs-12 col-md-12">
                  <div className="row componentSpace">
                    <div className="col-12">
                      <div
                        onClick={() =>
                          this.setState({
                            isOpenConfigStyle: !isOpenConfigStyle,
                          })
                        }
                      >
                        {isOpenConfigStyle === true ? (
                          <h4
                            className="titleStyleConfig"
                            style={{ marginBottom: "10px" }}
                          >
                            {t("generalUser.styleConfig")}
                            <ChevronUp
                              size={30}
                              style={{ transform: "translateY(30%)" }}
                            />
                          </h4>
                        ) : (
                          <h4
                            className="titleStyleConfig"
                            style={{ marginBottom: "10px" }}
                          >
                            {t("generalUser.styleConfig")}
                            <ChevronDown
                              size={30}
                              style={{ transform: "translateY(30%)" }}
                            />
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                  <Collapse isOpen={isOpenConfigStyle} transition="450ms">
                    <div className="row">
                      <div className="col-xs-12 col-md-12">
                        <div className="spaceLeft">
                          <div style={{ display: "flex" }}>
                            {this.renderThemes(themesData)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <Divider />
                <div className="col-xs-12 col-md-12 text-center">
                  <div className="row componentSpace">
                    <div className="col-12">
                      <Grid container>
                        <Grid item style={{ marginRight: "1%" }}>
                          <h4 className="titleStyleConfig">
                            {t("generalUser.contentMyPage")}
                          </h4>
                        </Grid>
                        <Grid
                          item
                          style={{
                            alignSelf: "center",
                            width: "150px",
                            textAlign: "left",
                          }}
                        >
                          <Tooltip title={<h3>{t("generalUser.tooltipMainpage")}</h3>}>
                            <Button
                              className="tagMainpage"
                              style={{ fontSize: "14px !important" }}
                            >
                              {t("basicWords.mainpage")}{" "}
                              <AlertCircle
                                style={{ paddingLeft: "5%" }}
                                size={18}
                              />{" "}
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          style={{
                            alignSelf: "center",
                            width: "150px",
                            textAlign: "left",
                          }}
                        >
                          <Tooltip title={<h3>{t("generalUser.tooltipGroupsubpage")}</h3>}>
                            <Button className="tagGroupsubpage">
                              {t("basicWords.groupSubpages")}{" "}
                              <AlertCircle
                                style={{ paddingLeft: "5%" }}
                                size={18}
                              />{" "}
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          style={{
                            alignSelf: "center",
                            width: "150px",
                            textAlign: "left",
                          }}
                        >
                          <Tooltip title={<h3>{t("generalUser.tooltipSubpage")}</h3>}>
                            <Button
                              className="tagSubpage"
                              style={{ fontSize: "14px !important" }}
                            >
                              {t("basicWords.subpage")}{" "}
                              <AlertCircle
                                style={{ paddingLeft: "5%" }}
                                size={18}
                              />{" "}
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          style={{
                            alignSelf: "center",
                            width: "150px",
                            textAlign: "left",
                          }}
                        >
                          <Tooltip title={<h3>{t("generalUser.tooltipGroupsubpage")}</h3>}>
                            <Button
                              className="tagSection"
                              style={{ fontSize: "14px !important" }}
                            >
                              {t("basicWords.sections")}{" "}
                              <AlertCircle
                                style={{ paddingLeft: "5%" }}
                                size={18}
                              />{" "}
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>

                      <div className="col-xs-12 col-md-12">
                        <div>
                          <div className="row componentSpace justify">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                              <Droppable
                                droppableId="all-columns"
                                direction="horizontal"
                                type="horizontal"
                                key="horizontal"
                              >
                                {(provided) => (
                                  <div
                                    className="row"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-xs-12 col-md-10"
                                      style={{
                                        display: "flex",
                                        width: "min-content",
                                      }}
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {this.state.columnOrder.map(
                                        (columnId, index) => {
                                          const column =
                                            this.state.columns[columnId];
                                          if (column !== undefined) {
                                            const sectionMap =
                                              this.state.structureSections;
                                            const state = this.state;
                                            const structureSections =
                                              column.sectionsIds.map(
                                                (sectionId) =>
                                                  sectionMap[sectionId]
                                              );
                                            return (
                                              <div>
                                                <HorizontalDragDrop
                                                  column={column}
                                                  structureSections={
                                                    structureSections
                                                  }
                                                  urlPage={urlPage}
                                                  index={index}
                                                  key={index}
                                                  state={state}
                                                  openDialog={
                                                    this.state.openDialog
                                                  }
                                                  columnSelected={
                                                    this.state.columnSelected
                                                  }
                                                  updateNewSectionComponentFunction={
                                                    this
                                                      .updateNewSectionComponentFunction
                                                  }
                                                  disableSectionFunction={
                                                    this.disableSectionFunction
                                                  }
                                                  createLpSubpageByLpId={
                                                    this.createLpSubpageByLpId
                                                  }
                                                  getLandingPageInfoFunction={
                                                    this
                                                      .getLandingPageInfoFunction
                                                  }
                                                  ongroupSubpageOrSubPageDelete={
                                                    this
                                                      .ongroupSubpageOrSubPageDelete
                                                  }
                                                  OnchangeIsVisible={
                                                    this.OnchangeIsVisible
                                                  }
                                                  handleClickOpenDialog={
                                                    this.handleClickOpenDialog
                                                  }
                                                  handleCloseDialog={
                                                    this.handleCloseDialog
                                                  }
                                                  changePageOrGroupSubpage={
                                                    this
                                                      .changePageOrGroupSubpage
                                                  }
                                                  handleClickOpenModalToChangeName={
                                                    this
                                                      .handleClickOpenModalToChangeName
                                                  }
                                                />
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                      {provided.placeholder}
                                    </div>
                                    <div className="col-xs-12 col-md-2">
                                      <div
                                        className="conteinerTitleColumnAdd"
                                        onClick={(e) => this.addNewModal(e)}
                                      >
                                        <div className="iconTitle">
                                          <Plus
                                            style={{
                                              paddingRight: "15px",
                                            }}
                                            size={18}
                                            style={{ marginBottom: "90%" }}
                                            onClick={this.handleClickOpen}
                                          />
                                        </div>
                                        <div className="titleColumn">
                                          <p className="addGroupSubpageOrSubpage">
                                            {t(
                                              "basicWords.addGroupSubpageOrSubpage"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {ValidatePermissionForComponentPart(
                    "ADD_WEB_PAGES",
                    privileges
                  ) ? (
                    <>
                      {userSession.organizationId === 1 ? (
                        <ButtonsFooterComponent
                          propsComponent={{
                            organizationId,
                            sectionName: sectionName,
                            lpId: lpId,
                            urlPage: urlPage,
                          }}
                          FlagclassName={FlagclassName}
                          path={"./webPagesList"}
                          onClick={(e) => this.SavePositionSection(e)}
                        />
                      ) : (
                        <ButtonsFooterComponent
                          propsComponent={{
                            organizationId,
                            sectionName: sectionName,
                            lpId: lpId,
                            urlPage: urlPage,
                          }}
                          FlagclassName={FlagclassName}
                          path={"./"}
                          onClick={(e) => this.SavePositionSection(e)}
                        />
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </Fragment>
        {/* </CardContent> */}
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
    userSession: state.userSession.userSession,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(MyWebPage));
