import React, { Component } from "react";
import { TextField, Button } from "@material-ui/core";
import { User } from "react-feather";

import { forgotPass } from "../../actions/Login.actions";
import AlertMessage from "../../components/Alert";
import { ButtonComponent } from "../../components/Button";

// CSS
import "./css/ForgotPass.scss";

// Images
import AuraLogin from "../../assets/images/loginLogo.png";
import UhooLogin from "../../assets/images/LogoUhooColor.png";

//Localized
import { withTranslation } from "react-i18next";

class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      showSendEmail: false,
      userName: "",
      imageLogin: "",
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({ imageLogin: AuraLogin });
    } else {
      this.setState({ imageLogin: UhooLogin });
    }
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
  }

  updateWindowDimensions = () => {
    let containerLogin100 = window.innerHeight;
    let height = 160;
    let margin = "0px 0px 24px 0px";
    if ((window.innerWidth > window.innerHeight) & (window.innerWidth < 850)) {
      height = 80;
      margin = "0px 0px 12px 0px";
    }
    this.setState({
      containerLogin100,
      height,
      margin,
    });
  };

  async queryForgotPass(username) {
    let response = await forgotPass(username);
    if (
      String(response).includes("Error:") ||
      response.data.httpErrorCode !== 201
    ) {
      this.setState({
        showAlert: true,
        showSendEmail: false,
      });
    } else {
      this.setState({
        showSendEmail: true,
        showAlert: false,
      });
    }
    this.setState({
      isLoadingButton: false,
    });
  }

  handleFormChange = (event) => {
    this.setState({
      userName: event.target.value,
    });
  };

  onFinish = () => {
    this.setState({
      isLoadingButton: true,
    });
    this.queryForgotPass(this.state.userName);
  };

  render() {
    const {
      containerLogin100,
      height,
      isLoadingButton,
      showAlert,
      showSendEmail,
      imageLogin,
    } = this.state;
    const { t } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-sm-12 container-login100"
            style={{ height: containerLogin100 }}
          >
            <div className="wrap-login100 p-l-45 p-r-45 p-t-55 p-b-44">
              <form
                name="normal_login"
                className="login100-form"
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
              >
                <div>
                  {showAlert && (
                    <>
                      <AlertMessage
                        // message="Login error"
                        description={t('modal.notValidUsername')}
                        type="error"
                      />
                      <br />
                    </>
                  )}
                </div>
                <div>
                  {showSendEmail && (
                    <>
                      <AlertMessage
                        // message="Login error"
                        description={t('login.successfulProcess')}
                        type="success"
                      />
                      <br />
                    </>
                  )}
                </div>
                <div className="centerHorizontalItem">
                  <img src={imageLogin} alt="logo" className="logo100-form" />
                </div>
                <p className="recoveryPasswordTitle">
                  {t('login.recoveryPassword')}
                </p>
                <p className="instructions">{t('login.enterUserAndPressOk')}</p>
                <p className="instructions-two">
                  {t('login.linkSentToResetPassword')}
                </p>

                <div className="centerHorizontalItem">
                  <TextField
                    variant="outlined"
                    onChange={(event) => this.handleFormChange(event)}
                    label={t('generalUser.inputUserName')}
                    className="wrap-input100 validate-input form-control"
                    id="username"
                  />
                </div>
                <br />
                <div style={{ paddingBottom: "10px" }}>
                  <ButtonComponent
                    text={t('basicWords.accept')}
                    isLoading={isLoadingButton}
                    onClick={this.onFinish}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ForgotPass);
