import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Card,
} from "@material-ui/core";

import { Edit, Trash2, CheckCircle, Users } from "react-feather";

import {
  Title,
  Breadcrumb,
  SelectSingle,
  ButtonComponent,
  GeneralModal,
  NotificationModal,
} from "../../components/index";

// Queries
import {
  getOrganizationTypes,
  getOrganizationsByOrganizationTypeId,
  getUsersPaginated,
  disableUser,
} from "../../actions/index";

// Components
import { ValidatePermissionForComponentPart } from "../../reactUtils/generalUtils/validatePermissionForComponentPart";

//Localization
import { withTranslation } from 'react-i18next';

// Logos
import AuraLogin from "../../assets/images/loginLogo.png";
import UhooLogin from "../../assets/images/LogoUhooColor.png";

const tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,

      organizationTypes: [],
      organizationTypeId: null,

      organizations: [],
      organizationId: null,
      organizationIdQuery: null,
      organizationName: null,
      registers: [],
      identityCardNumber: "",
      loadingInfo: false,

      // pagination
      registersAmount: 0,
      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "ASC",
      initialPage: 0,

      openDialog: false,
      titleNotificationModal: "",
      openNotificationModal: false,
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({ imageLogin: AuraLogin, helmetPage: "Somos Aura | CMS" });
    } else {
      this.setState({ imageLogin: UhooLogin, helmetPage: "Uhoo | CMS" });
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKaADAAQAAAABAAAAKQAAAADAIIRfAAAFK0lEQVRYCbVZW2icRRQ+ZzaxjWml1jRpo33QCgrxQZB6QYoNGgvWF7Fg+iAWEeODPgQxyfapTyZbQVEiGgQfFDGFpohFkQYhKqKlD0JVpK1oa2torlZTbEJ2/+M38++6t3/ObNPsQPLPzLnMN3POzJw5y7TCIiPt19Ol7KMUSReRbIWadhJuR/0mIp4jlkn04Y/Pk+Fx2tBwjHsm/13JcHw1QiIHDA0NPwWZbgzeRSJNNcszX8EExsE/SgMvHmI+ENUqWzNIeXXTY1CawUB31arcz8c/gdbP+2c+9/MUKUGQ8lrbrZTNvU9CO4tiq1RjmqCG1LP8ytTvmkYVpBxseYhyPAazws/qVBj+m5InuW/2K98IxkeQodYeytF4XQHawe0CYBw3ngdM4ko6gSh61yNTv25jXuCB6ZHKAapAyuDmnUTZY/DBxkrmureZlilFXZWmLzO32ySUO6wAzBLTKXLHyQogWxBWnngxUdouDPZAjKPIUQYy3sVJmwRK2fTRlnXrOT17J93fcQMZ2guws0VVSi2eVC81b4jlH+hYT8Y8DcB/VUlZH7WnSUn539zxOSifldCKVWOega98UOyIa3CN7XCN77DyqUpaWZu5m9Mzh8r60JCDrQ9SNvoa1fLFcoy8u3COOqK7SdxBXanGtvlkEkBHSV88ge+orXsL04kkgJaf+6a/xf8jHtlMHld+Bpl3cM35bhI+7lESd3OATgE68/fJ+oEnvoLzICVn72NPkTkPId/Nf6t0oX9UOqn6sXjwBRfN2GDBVxjurZUIHqmVkLxo8txl8RkXbmnRjIgO0miDAL0EJmnYP0mLC+GgieNBbSlCNNZDrtBKhtQjXsXudgGrn5UDx4vk/CthtYYsEXIX4LNHEKJprZiEM6yEnwPmDq1kyF2ADyw25FcKB3xSFJ+yakMg1Y0DeeCz5tZjxZDjh1YyKB+YJPBZU87YCStF392hlVAU10biOQvyzwBzg0oXs6TTeVmlRxK492XSIJIJgWxTB2E+qdKN+VGlM7WqdDyL7d66EGC6WaNz+uJZTHQskYf5DN2Y+iSRVujk0OnC5w2leKLAn/gVuhtBaHMirdC5tvk5xJsfF5ruy3QcZ+TjwYSARDvK5CobSCywu7vnl6eh0A+EzR5OTyevVolSGWy7DUdOB0W5U5SeOcOs71zJbG2n6MoF79Vpg+WNjS0mP1P9kS7SU4LFW+X01G88MHWU98+eDgF0SnJLPV6AjkHGLb74NmE67B05Zu7CC3KXznN1VHmjZQvyRS8HpFxAHYO8r+MIzPSLKhDJ2/L6LRtVnhqJLuJe5PdUFyOkYpAzsiodSO6cwCvQ9OpjyDZaWhyTkXsadb4aqJnhDADuDnD2F5JaZbeJDG46andkQPhLalq3h3vPXgrwVZHdBOfPDcMPn68ilnYgR4RXaWehKzZ3oUXUC7PrzwGih2nx8s+Y0L7CQ6ko7q9JZvO9NH/umzBA5IaQxCrVVLaSlgBlnZTLfoHqdaWMiXXmHzDom8RNn3L6j6o3tLx1+xq6vLCLONoHCz2RqKO005PBqAJpZWSwZS8+H+nHQ6l2inDr/Iqe04jNFqBhLWS3wSp3ANyaMk6tUWsuqKAD5uzHAEOFdl2/Lv1iXkpKVtlxE1fSEsSG/UOtC6j4byLLeK3lWvKTlGndUX+ANtNrtldm0SrnnRgr4g5+hATZtbqVfM48vcKcuQy1dGMbfAh8iRNYMW6XWVulXx+wYUZhZiXtEoDJSC4LT6/m7zj/AexV0r66K8xIAAAAAElFTkSuQmCC";
    }
    const { page, pageSize, attribute, order } = this.state;
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const organizationId = sessionStorage.getItem("organization_id");

    if (parseInt(organizationId, 10) === 1) {
      this.getOrganizationTypesFunction()
        .then((response) => {
          if (String(response).includes("Error:")) {
          }
          let organizationTypes = [];
          organizationTypes = response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));

          this.setState({ organizationTypes });
        })
        .catch((e) => {});
    } else {
      this.getUsersByOrganizationId(
        parseInt(organizationId, 10),
        page,
        pageSize,
        attribute,
        order
      );
      this.setState({
        organizationId,
        organizationIdQuery: organizationId,
        loadingInfo: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  getOrganizationTypesFunction = async () => {
    const response = await getOrganizationTypes();
    return response;
  };
  // async getOrganizationTypesFunction() {
  //    let organizationTypes = [];
  //    let response = await getOrganizationTypes();

  //    if (String(response).includes("Error:")) {

  //    } else {
  //       organizationTypes = response.data.data.map(item => ({ value: item.id, label: item.name }));
  //    }

  //    this.setState({ organizationTypes: organizationTypes })
  // }

  async getOrganizationByOrganizationTypeIdFunction(organizationTypeId) {
    let organizations = [];
    const response = await getOrganizationsByOrganizationTypeId(
      organizationTypeId
    );
    if (String(response).includes("Error:")) {
    } else {
      organizations = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ organizations });
  }

  async getUsersByOrganizationId(
    organizationId,
    page,
    pageSize,
    attribute,
    order
  ) {
    const { identityCardNumber } = this.state;
    let registers = this.state.registers;
    let registersAmount = 0;
    if (page === 0) {
      return registers;
    } else {
      const response = await getUsersPaginated(
        organizationId,
        identityCardNumber,
        page,
        pageSize,
        attribute,
        order
      );
      if (String(response).includes("Error:")) {
      } else {
        registers = [...registers, ...response.data.data.content];
        registers.forEach((obj) => {
          const groupList = [];
          if (obj.groups.length > 0) {
            obj.groups.forEach((element) => {
              groupList.push(element.name);
              groupList.push(" ");
            });
            groupList.toString();
            obj.groupList = groupList;
          }
        });
        registersAmount = response.data.data.totalElements;
      }
    }

    this.setState({
      registers,
      registersAmount,
      loadingInfo: false,
    });
  }

  async disableUserFunction(userId) {
    const {
      organizationIdQuery,
      identityCardNumber,
      page,
      pageSize,
      attribute,
      order,
    } = this.state;

    let registers = [];
    let registersAmount = 0;
    const response = await disableUser(userId);
    const {t} = this.props
    
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal:
          t('modal.internalServerErrorContactSupport'),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.disablingSuccessful'),
        contenNotificationModal: t('modal.userSuccesfullyDisabled'),
        typeNotificationModal: "success",
      });
      this.setState({
        openNotificationModal: true,
      });
      const response = await getUsersPaginated(
        organizationIdQuery,
        identityCardNumber,
        page,
        pageSize,
        attribute,
        order
      );

      if (String(response).includes("Error:")) {
      } else {
        registers = response.data.data.content;
        registersAmount = response.data.data.totalElements;
      }

      this.setState({
        registers,
        registersAmount,
        loadingInfo: false,
        openNotificationModal: true,
        openDialog: false,
      });
    }
  }

  // Events
  onSelect = (e, event) => {
    let option = e.target;
    const { page, pageSize, attribute, order } = this.state;
    switch (event) {
      case "organizationType":
        this.setState({
          organizationTypeId: option.value,
          registers: [],
          organizationId: null,
        });
        if (option.value === 1) {
          this.setState({
            loadingInfo: true,
            organizationIdQuery: 1,
            organizationName: option.label,
          });
          this.getUsersByOrganizationId(1, page, pageSize, attribute, order);
        } else {
          this.setState({
            organizationIdQuery: null,
          });
          this.getOrganizationByOrganizationTypeIdFunction(option.value);
        }
        break;
      case "organization":
        this.setState({
          organizationId: option.value,
          organizationIdQuery: option.value,
          organizationName: option.label,
          loadingInfo: true,
          registers: [],
        });
        this.getUsersByOrganizationId(
          option.value,
          page,
          pageSize,
          attribute,
          order
        );
        break;
      default:
        break;
    }
  };

  disableUserEvent = (id) => {
    this.disableUserFunction(id);
  };

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  showPagination = (page, pageSize) => {
    const { organizationIdQuery, attribute, order } = this.state;
    this.setState({
      loadingInfo: true,
      page,
      pageSize,
    });
    this.getUsersByOrganizationId(
      organizationIdQuery,
      page,
      pageSize,
      attribute,
      order
    );
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  // Pagination
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, initialPage: newPage });
    if (this.state.registers.length < this.state.registersAmount) {
      this.showPagination(newPage + 1, this.state.pageSize);
    } else {
      return this.state.registers;
    }
  };

  handleChangeRowsPerPage = (event) => {
    if (event !== undefined) {
      this.setState({
        pageSize: parseInt(event.target.value, 10) + 1,
        page: 0,
      });
    }
  };

  render() {
    const userSession = { ...this.props.userSession };
    const { privileges } = this.props;
    const {
      organizationTypeId,
      organizationTypes,
      organizationId,
      organizations,
      organizationIdQuery,
      organizationName,
      registers,
      pageSize,
      initialPage,
      registersAmount,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const {t} = this.props

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Card className="card">
          <div className="row componentSpace spaceBreadcrumb">
            <Breadcrumb
              principal={t('generalUser.myUsers')}
              icon={<Users />}
              secondary={t('sections.home')}
            />
          </div>
          <div className="spaceFromBreadcrumb">
            {userSession.organizationId === 1 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="col-md-4"
                  style={{
                    width: "30vh",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <SelectSingle
                    selectedOptions={organizationTypeId}
                    text={t('basicWords.typeUser') + "*"}
                    onChange={(option, event) =>
                      this.onSelect(option, "organizationType")
                    }
                    options={organizationTypes}
                  />
                </div>
                <div
                  className="col-md-4"
                  style={{ width: "30vh", paddingRight: "15px" }}
                >
                  <SelectSingle
                    selectedOptions={organizationId}
                    text={t('organization.organization')}
                    onChange={(option, event) =>
                      this.onSelect(option, "organization")
                    }
                    options={organizations}
                  />
                </div>
                {ValidatePermissionForComponentPart("ADD_USER", privileges) ? (
                  <div className="col-md-4" style={{ width: "30vh" }}>
                    <ButtonComponent
                      text={t('basicWords.createUser')}
                      icon={<CheckCircle />}
                      path="./addUser"
                      props={{
                        organizationId: organizationIdQuery,
                        organizationName,
                      }}
                      shape="round"
                      isDisabled={organizationIdQuery == null}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="row componentSpace" style={{ paddingBottom: "0px" }}>
            <Title title={t('basicWords.listUsers')} />
          </div>
          <div className="row componentSpace">
            <div className="col-12">
              <TableContainer style={{ maxHeight: "440" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('basicWords.name')}</TableCell>
                      <TableCell>
                        {t('generalUser.identityCardNumber')}
                      </TableCell>
                      <TableCell>{t('generalUser.username')}</TableCell>
                      <TableCell>
                        {t('generalUser.apostolicGroupBelong')}
                      </TableCell>
                      {ValidatePermissionForComponentPart(
                        "EDIT_USER",
                        privileges
                      ) ? (
                        <TableCell></TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registers
                      .slice(
                        initialPage * pageSize,
                        initialPage * pageSize + pageSize
                      )
                      .map((row) => {
                        return (
                          <TableRow key={row.name}>
                            <TableCell>
                              {row.name} {row.lastName1 ? row.lastName1 : null}{" "}
                              {row.lastName2 ? row.lastName2 : null}
                            </TableCell>
                            <TableCell>{row.identityCardNumber}</TableCell>
                            <TableCell>{row.username}</TableCell>
                            <TableCell>{row.url}</TableCell>
                            <TableCell>
                              <div className="containerIconsTable">
                                <div className="bgIconEdit">
                                  <Link
                                    to={{
                                      pathname: "/editUser",
                                      props: {
                                        previousPath: "/users",
                                        organizationId: organizationIdQuery,
                                        organizationName,
                                        userId: row.id,
                                        countryId: row.countryId,
                                        regionId: row.regionId,
                                        cityId: row.cityId,
                                      },
                                    }}
                                  >
                                    <Edit className="iconEdit" />
                                  </Link>
                                </div>
                                <div
                                  className="bgIconTrash"
                                  onClick={() => this.handleClickOpen()}
                                >
                                  <Trash2 className="iconTrash" />
                                </div>
                              </div>
                            </TableCell>
                            <GeneralModal
                              visible={this.state.openDialog}
                              title={t('basicWords.deleteRegister')}
                              footer={[
                                <div
                                  className="row"
                                  key={1}
                                  style={{ display: "flex" }}
                                >
                                  <div className="col-6" style={{ marginRight: "5%" }}>
                                    <ButtonComponent
                                      // icon={<LeftCircleOutlined />}
                                      onClick={() => this.handleClose()}
                                      props={null}
                                      shape="round"
                                      text={t('basicWords.cancelText')}
                                    />
                                  </div>
                                  <div>
                                    <ButtonComponent
                                      onClick={() =>
                                        this.disableUserEvent(row.id)
                                      }
                                      props={null}
                                      shape="round"
                                      text={t('basicWords.okText')}
                                    />
                                  </div>
                                </div>,
                              ]}
                              onClick={() => this.handleClose()}
                            />
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                disabled={registers.length === 0}
                count={registersAmount}
                rowsPerPage={pageSize}
                page={initialPage}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Card>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
    userSession: state.userSession.userSession,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(UsersList));
