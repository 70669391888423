// Dependencies
import React from "react";
import { Route, Switch } from "react-router";

// Components
import CMS from "../../CMS.jsx";

import Home from "../../pages/global/HomeComponent.jsx";

import UsersList from "../../pages/users/UsersList.jsx";
import UserAccount from "../../pages/users/UserAccount";
import AddUser from "../../pages/users/AddUser";
import EditUser from "../../pages/users/EditUser";

import Organization from "../../pages/organizations/Organization.jsx";
import OrganizationsList from "../../pages/organizations/OrganizationsList.jsx";
import EditOrganization from "../../pages/organizations/EditOrganization";
import AddOrganization from "../../pages/organizations/AddOrganization.jsx";

import RolesList from "../../pages/rolesAndPrivileges/RolesList.jsx";
import EditRole from "../../pages/rolesAndPrivileges/EditRole.jsx";
import AddRole from "../../pages/rolesAndPrivileges/AddRole.jsx";

import RoleByUser from "../../pages/roleByUser/RoleByUser.jsx";
import RoleToUsersAssignation from "../../pages/roleByUser/RoleToUsersAssignation.jsx";

import GroupsList from "../../pages/groups/groupsList.jsx";
import EditGroup from "../../pages/groups/editGroup.jsx";
import AddGroup from "../../pages/groups/addGroup.jsx";

import aurApp from "../../pages/aurApp/auraApp.jsx";
import WebPagesList from "../../pages/webpages/WebPagesList.jsx";
import MyGroupSubpage from "../../pages/webpages/MyGroupSubpage.jsx";
import MyWebPage from "../../pages/webpages/MyWebPage.jsx";
// import { AddWebPage } from '../../components/cms/cms/components/webPages/index'

// Sections
import AboutUsSection from "../../pages/webpages/sections/about/aboutUsSection.jsx";
import ContactSection from "../../pages/webpages/sections/contact/contactSection.jsx";
import CoursesSection from "../../pages/webpages/sections/courses/coursesSection.jsx";
import DonationsSection from "../../pages/webpages/sections/donations/donationsSection.jsx";
import EventsSection from "../../pages/webpages/sections/events/eventsSection.jsx";
import FooterSection from "../../pages/webpages/sections/footer/footerSection.jsx";
import GallerySection from "../../pages/webpages/sections/gallery/gallerySection.jsx";
import HeaderSection from "../../pages/webpages/sections/header/headerSection.jsx";
import HomeSection from "../../pages/webpages/sections/home/homeSection.jsx";
import LinkTreeSection from "../../pages/webpages/sections/linktree/linktreeSection.jsx";
import NewsSection from "../../pages/webpages/sections/news/newsSection.jsx";
import SocialNetworksSection from "../../pages/webpages/sections/socialNetworks/socialNetworksSection.jsx";
import StreamingSection from "../../pages/webpages/sections/streaming/streamingSections.jsx";
import TeamSection from "../../pages/webpages/sections/teams/teamSection.jsx";

// Error interface
// import Error401 from '../../components/genericComponents/errors/Error401.js';
// import Error404 from '../../components/genericComponents/errors/Error404.js';
// import Error500 from '../../components/genericComponents/errors/Error500.js';

export const AppRoutes = () => (
  <CMS>
    <Switch>
      <Route exact path="/" component={Home} />

      <Route exact path="/users" component={UsersList} />
      <Route exact path="/addUser" component={AddUser} />
      <Route exact path="/editUser" component={EditUser} />
      <Route exact path="/account" component={UserAccount} />

      <Route exact path="/organizations" component={OrganizationsList} />
      <Route exact path="/organization" component={Organization} />
      <Route exact path="/addOrganization" component={AddOrganization} />
      <Route exact path="/editOrganization" component={EditOrganization} />

      <Route exact path="/roles" component={RolesList} />
      <Route exact path="/addRole" component={AddRole} />
      <Route exact path="/editRole" component={EditRole} />

      <Route exact path="/roleByUser" component={RoleByUser} />
      <Route
        exact
        path="/asignUsersToRole"
        component={RoleToUsersAssignation}
      />

      <Route exact path="/groupsList" component={GroupsList} />
      <Route exact path="/addGroup" component={AddGroup} />
      <Route exact path="/editGroup" component={EditGroup} />

      <Route exact path="/aurapp" component={aurApp} />
      <Route exact path="/webPagesList" component={WebPagesList} />
      <Route exact path="/editMyGroupSubpage" component={MyGroupSubpage} />
      {/* <Route exact path="/addWebPage" component={AddWebPage} /> */}
      <Route exact path="/myWebPage" component={MyWebPage} />

      <Route exact path="/editHome" component={HomeSection} />
      <Route exact path="/editHeader" component={HeaderSection} />
      <Route exact path="/editAbout" component={AboutUsSection} />

      <Route exact path="/editEvents" component={EventsSection} />
      <Route exact path="/editNews" component={NewsSection} />
      <Route exact path="/editCourses" component={CoursesSection} />
      <Route exact path="/editTeam" component={TeamSection} />
      <Route exact path="/editStreaming" component={StreamingSection} />
      <Route exact path="/editGallery" component={GallerySection} />
      <Route exact path="/editContact" component={ContactSection} />
      <Route exact path="/editFooter" component={FooterSection} />
      <Route
        exact
        path="/editSocialNetworks"
        component={SocialNetworksSection}
      />
      <Route exact path="/editDonations" component={DonationsSection} />
      <Route exact path="/editLinkTree" component={LinkTreeSection} />
    </Switch>
  </CMS>
);
