import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  MenuList,
  MenuItem,
  ListItemIcon,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { loginURL } from "../../reactUtils/systemVariables/CmsServerInformation";
import {
  Flag,
  Layout,
  Users,
  Home,
  Star,
  Monitor,
  LogOut,
  Shield,
} from "react-feather";

// CSS
import "./css/MenuComponent.scss";

// // Components-functions
import { ValidatePermissionForComponentPart } from "../../reactUtils/generalUtils/validatePermissionForComponentPart";

//Localization
import { useTranslation } from 'react-i18next';

const MenuComponent = ({ userSessionInfo, privileges }) => {
  const [path, updatePath] = useState("");
  const [backgroundClass, updateBackground] = useState("backGroundUnSelected");
  const [menuItemClass, updateMenuItem] = useState("menuItem");

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = loginURL;
  };

  useEffect(() => {
    let currentPath = window.location.pathname;
    updatePath(currentPath);
  });

  // const updatePath = () => {
  //   let path = window.location.pathname;
  // };

  const { t, i18n } = useTranslation();

  return (
    <div className={"rootMenu"}>
      <MenuList class="menuList">
        <MenuItem
          class={
            window.location.pathname === "/"
              ? "backGroundSelected"
              : "backGroundUnSelected"
          }
        >
          <ListItemIcon
            class={
              window.location.pathname === "/" ? "menuItemSelected" : "menuItem"
            }
            onClick={() => updatePath(window.location.pathname)}
          >
            <Link to="/" className={"link"}>
              <Flag />
              <label class={"label"}>{t('sections.home')}</label>
            </Link>
          </ListItemIcon>
        </MenuItem>

        {ValidatePermissionForComponentPart("WEB_PAGES_MENU", privileges) ? (
          <MenuItem
            class={
              window.location.pathname === "/webPagesList" ||
              window.location.pathname === "/myWebPage"
                ? "backGroundSelected"
                : "backGroundUnSelected"
            }
          >
            {userSessionInfo.organizationId === 1 ? (
              <ListItemIcon
                class={
                  window.location.pathname === "/webPagesList"
                    ? "menuItemSelected"
                    : "menuItem"
                }
                onClick={() => updatePath(window.location.pathname)}
              >
                <Link to="/webPagesList" className={"link"}>
                  <Layout />
                  <label class={"label"}>{t('generalUser.pages')}</label>
                </Link>
              </ListItemIcon>
            ) : (
              <ListItemIcon
                class={
                  window.location.pathname === "/myWebPage"
                    ? "menuItemSelected"
                    : "menuItem"
                }
              >
                <Link to="/myWebPage" className={"link"}>
                  <Layout />
                  <label class={"label"}>{t('generalUser.myPage')}</label>
                </Link>
              </ListItemIcon>
            )}
          </MenuItem>
        ) : null}

        {ValidatePermissionForComponentPart("USER_MENU", privileges) ? (
          <MenuItem
            class={
              window.location.pathname === "/users"
                ? "backGroundSelected"
                : "backGroundUnSelected"
            }
          >
            <ListItemIcon
              class={
                window.location.pathname === "/users"
                  ? "menuItemSelected"
                  : "menuItem"
              }
              onClick={() => updatePath(window.location.pathname)}
            >
              <Link to="/users" className={"link"}>
                <Users />
                {userSessionInfo.organitazionId === 0 ? (
                  <label class={"label"}>{t('generalUser.myUsers')}</label>
                ) : (
                  <label class={"label"}>{t('generalUser.user')}</label>
                )}
              </Link>
            </ListItemIcon>
          </MenuItem>
        ) : null}

        {ValidatePermissionForComponentPart("ORGANIZATION_MENU", privileges) ? (
          <MenuItem
            class={
              window.location.pathname === "/organizations" ||
              window.location.pathname === "/organization"
                ? "backGroundSelected"
                : "backGroundUnSelected"
            }
          >
            {userSessionInfo.organizationId === 1 ? (
              <ListItemIcon
                class={
                  window.location.pathname === "/organizations"
                    ? "menuItemSelected"
                    : "menuItem"
                }
                onClick={() => updatePath(window.location.pathname)}
              >
                <Link to="/organizations" className={"link"}>
                  <Home />
                  <label class={"label"}>
                  {t('organization.organizations')}
                  </label>
                </Link>
              </ListItemIcon>
            ) : (
              <ListItemIcon
                class={
                  window.location.pathname === "/organization"
                    ? "menuItemSelected"
                    : "menuItem"
                }
                onClick={() => updatePath(window.location.pathname)}
              >
                <Link to="/organization" className={"link"}>
                  <Home />
                  <label class={"label"}>
                  {t('organization.myOrganization')}
                  </label>
                </Link>
              </ListItemIcon>
            )}
          </MenuItem>
        ) : null}

        {ValidatePermissionForComponentPart("ROLES_MENU", privileges) ? (
          ValidatePermissionForComponentPart(
            "USERS_ROLE_ASSIGNATION_MENU",
            privileges
          ) ? (
            <Accordion
              class={
                window.location.pathname === "/roles"
                  ? "backGroundSelected"
                  : "backGroundUnSelected"
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                class={
                  window.location.pathname === "/roles"
                    ? "backGroundSelected"
                    : "backGroundUnSelected"
                }
                onClick={() => updatePath(window.location.pathname)}
              >
                <Link to="/roles" className={"link"}>
                  <Star />
                  <label class={"label"}>{t('generalUser.roles')}</label>
                </Link>
              </AccordionSummary>

              <MenuItem
                class={
                  window.location.pathname === "/roleByUser"
                    ? "backGroundSelected"
                    : "backGroundUnSelected"
                }
              >
                <ListItemIcon
                  class={"menuItem"}
                  onClick={() => updatePath(window.location.pathname)}
                >
                  <Link to="/roleByUser" className={"link"}>
                    <Shield />
                    <label class={"label"}>
                    {t('generalUser.assignation')}
                    </label>
                  </Link>
                </ListItemIcon>
              </MenuItem>
            </Accordion>
          ) : (
            <MenuItem
              class={
                window.location.pathname === "/roles"
                  ? "backGroundSelected"
                  : "backGroundUnSelected"
              }
            >
              <ListItemIcon
                class={"menuItem"}
                onClick={() => updatePath(window.location.pathname)}
              >
                <Link to="/roles" className={"link"}>
                  <Star />
                  {t('generalUser.roles')}
                </Link>
              </ListItemIcon>
            </MenuItem>
          )
        ) : null}

        {ValidatePermissionForComponentPart("AURAPP_ACCESS", privileges) && process.env.REACT_APP_ISAURA === "true" ? (
          <MenuItem
            class={
              window.location.pathname === "/aurapp"
                ? "backGroundSelected"
                : "backGroundUnSelected"
            }
          >
            <ListItemIcon
              class={"menuItem"}
              onClick={() => updatePath(window.location.pathname)}
            >
              <Link to="/aurapp" className={"link"}>
                <Monitor />
                <label class={"label"}>{t('generalUser.auraApp')}</label>
              </Link>
            </ListItemIcon>
          </MenuItem>
        ) : null}

        <MenuItem class={"backGroundUnSelected"}>
          <ListItemIcon
            class={"menuItem"}
            onClick={() => updatePath(window.location.pathname)}
          >
            <Link to="" className={"link"}>
              <LogOut />
              <label class={"label"} onClick={(e) => logout(e)}>
              {t('generalUser.logOut')}
              </label>
            </Link>
          </ListItemIcon>
        </MenuItem>
      </MenuList>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
  };
}

export default connect(mapStateToProps)(MenuComponent);
