import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import "../css/sections.scss";

import SelectLayoutModal from "../../modalComponents/SelectLayoutModal.jsx";

// Queries
import {
  getNewsSectionInformationByTopLimit,
  updateLpSectionComponentNode,
  insertSectionComponent,
  disableLpSectionComponent,
  updateSectionComponent,
  getSectionLayoutStyle,
  getFile,
  updateLpSectionComponentNodeImage,
  updateLpSectionLayout,
  updateLpSection,
} from "../../../../actions/index";

import { Trash2, Plus, Edit2, X } from "react-feather";

// Components
import {
  Title,
  ButtonComponent,
  BreadcrumbSection,
  GeneralInput,
  NotificationModal,
  DeleteItemModal,
  GeneralModal,
  AccordionComponent,
  DatePickerComponent,
  ButtonsFooterComponent,
} from "../../../../components/index";
import ButtonsContainer from "../general/buttons/ButtonsContainer.jsx";

import { Card, CircularProgress, TextField, Tooltip } from "@material-ui/core";

import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Localization
import { withTranslation } from "react-i18next";
import editorConfiguration from "../../../../components/textEditorConfig";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class NewsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,
      lpSectionId: null,
      sectionName: null,

      totalElements: 0,

      loadingSection: true,
      loadingInfo: true,

      date: moment().format(),

      newsContainerTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      newsQuanty: {
        id: null,
        value: 0,
        nodeTypeName: null,
        modified: false,
      },
      news: {
        id: null,
        title: "",
        text: "",
        date: "",
        dateToShow: "",
        author: "",
        image: null,
        buttons: [],
      },
      newsToShow: {
        id: null,
        title: null,
        text: null,
        date: moment(),
        dateToShow: moment(),
        author: null,
        image: null,
        imageUri: null,
        buttons: [],
      },

      newNewsImage: {
        id: null,
        s3path: null,
        file: null,
        image: null,
        loading: false,
        modified: false,
      },
      parentLPSectionComponentId: null,
      isLoadingButton: false,
      FlagclassName: false,
      goToWithoutSaveModalVisible: false,

      lpLayoutImg: null,
      layoutModalVisible: false,
      deleteFlag: false,

      //Modal notification
      haveChangeInLayout: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",

      //Modal general
      openDeleteImage: false,
      openDeleteNews: false,
      elementToDeleteId: null,

      //LayoutSelector
      newLayoutId: null,
      oldLayoutId: null,

      isReadyToEdit: false,
      addNotice: false,

      //Name modal
      openModalToChangeName: false,
      customName: "",
    };
  }

  // useEffect(){
  // const [newToEdit, setNewToEdit] = useState([])
  // }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const { props } = this.props.location;
    if (props) {
      this.getNewsInformation(props.sectionId, null);
      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getNewsInformation(lpSectionId, topLimit) {
    const newsNumber =
      topLimit != null ? topLimit : this.state.newsQuanty.value;
    const response = await getNewsSectionInformationByTopLimit(
      lpSectionId,
      newsNumber
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({ openNotificationModal: true });
    } else {
      const sectionInfo = response.data.data;
      const newsContainerComponent = sectionInfo.components.find(
        (comp) => comp.name === "NewsContainerComponent"
      );
      const parentLPSectionComponentId = newsContainerComponent.id;
      const newsContainerTitle = {
        id: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsContainerTitle"
        ).id,
        value: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsContainerTitle"
        ).value,
        nodeTypeName: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsContainerTitle"
        ).nodeType,
        minLength: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsContainerTitle"
        ).minLength,
        maxLength: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsContainerTitle"
        ).maxLength,
        length: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsContainerTitle"
        ).value.length,
      };
      const newsQuanty = {
        id: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsQuantity"
        ).id,
        value: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsQuantity"
        ).value,
        nodeTypeName: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsQuantity"
        ).nodeType,
        minLength: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsQuantity"
        ).minLength,
        maxLength: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsQuantity"
        ).maxLength,
        length: newsContainerComponent.nodes.find(
          (node) => node.nodeType === "NewsQuantity"
        ).value.length,
      };
      const news = sectionInfo.components.filter(
        (comp) => comp.name === "NewsComponent"
      );
      for (let i = 0; i < news.length; i++) {
        if (!news[i].img) {
          const imgNode = news[i].nodes.find(
            (node) => node.nodeType === "NewsImage"
          );
          if (imgNode) {
            if (imgNode.value != null && imgNode.value !== "") {
              let regex = /somosaura-cms.s3.amazonaws.com/;
              let haveHttp = regex.test(imgNode.value);
              if (!haveHttp) {
                // Get image
                let img = await getFile(
                  "https://somosaura-cms.s3.amazonaws.com/" + imgNode.value
                );
                news[i].img = img;
              } else {
                news[i].img = imgNode.value;
              }
            } else {
              news[i].img = null;
            }
          }
        }
      }

      const newsToShow = [];

      news.forEach((element) => {
        const newsElement = {
          id: element.id,
          titleId: element.nodes.find((node) => node.nodeType === "NewsTitle")
            ? element.nodes.find((node) => node.nodeType === "NewsTitle").id
            : null,
          title: element.nodes.find((node) => node.nodeType === "NewsTitle")
            ? element.nodes.find((node) => node.nodeType === "NewsTitle").value
            : null,
          textId: element.nodes.find((node) => node.nodeType === "NewsText")
            ? element.nodes.find((node) => node.nodeType === "NewsText").id
            : null,
          text: element.nodes.find((node) => node.nodeType === "NewsText")
            ? element.nodes.find((node) => node.nodeType === "NewsText").value
            : null,
          dateId: element.nodes.find((node) => node.nodeType === "NewsDate")
            ? element.nodes.find((node) => node.nodeType === "NewsDate").id
            : null,
          date: element.nodes.find((node) => node.nodeType === "NewsDate")
            ? element.nodes.find((node) => node.nodeType === "NewsDate").value
            : null,
          authorId: element.nodes.find((node) => node.nodeType === "NewsAuthor")
            ? element.nodes.find((node) => node.nodeType === "NewsAuthor").id
            : null,
          author: element.nodes.find((node) => node.nodeType === "NewsAuthor")
            ? element.nodes.find((node) => node.nodeType === "NewsAuthor").value
            : null,
          imageId: element.nodes.find((node) => node.nodeType === "NewsImage")
            ? element.nodes.find((node) => node.nodeType === "NewsImage").id
            : null,
          image: element.img,
          imageUri: element.img,
          buttons: element.buttons,
          edited: false,
        };
        newsToShow.push(newsElement);
      });

      newsToShow.forEach((element, index) => {
        newsToShow[index].date = moment(element.date).subtract(5, "hours");
        newsToShow[index].date = moment(newsToShow[index].date._d);
      });

      //Get layout image
      let supgage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        supgage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        supgage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(supgage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle
          .sectionLayoutId;
      let layoutImg = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
      );

      let customName =
        response.data.data.customName !== null
          ? response.data.data.customName
          : this.state.sectionName;

      this.setState({
        customName,
        newsContainerTitle,
        newsQuanty,
        newsToShow,
        loadingInfo: false,
        loadingSection: false,
        parentLPSectionComponentId,
        lpSectionLayoutStyle: layout,
        lpLayoutImg: layoutImg,
      });
    }
  }

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openNotificationModal: true,
      openModalToChangeName: false,
    });
  }

  async uploadFileToS3Bucket(imageUrl, key) {
    await updateLpSectionComponentNodeImage(imageUrl, key);
  }

  async insertSectionComponentFunction(componentToUpdate) {
    const { lpSectionId } = this.state;
    const response = await insertSectionComponent(componentToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.wrongFile"),
        contenNotificationModal: t("modal.fieldEmpty"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.successfulCreation"),
        contenNotificationModal: t("modal.newsSuccessfullyCreated"),
        typeNotificationModal: "success",
      });
      this.cleanStateVariables();
      this.getNewsInformation(lpSectionId, null);
    }

    this.setState({
      isLoadingButton: false,
      openNotificationModal: true,
    });
  }

  async updateSectionComponentFunction(componentToUpdate) {
    const { lpSectionId } = this.state;
    const response = await updateSectionComponent(componentToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      if (this.state.openNotificationModal === false) {
        this.setState({
          titleNotificationModal: t("modal.updateSuccessful"),
          contenNotificationModal: t("modal.newsSuccessfullyUpdate"),
          typeNotificationModal: "success",
        });
      }
      this.cleanStateVariables();

      this.getNewsInformation(lpSectionId, null);
    }

    this.setState({
      isLoadingButton: false,
      openNotificationModal: true,
    });
  }

  async updateNode(nodesToUpdate) {
    const lpSectionId = this.state.lpSectionId;
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.editionSuccess"),
        contenNotificationModal: t("modal.newsSuccessfullyEdited"),
        typeNotificationModal: "success",
      });
      this.getNewsInformation(lpSectionId, null);
    }
    this.setState({
      isLoadingButton: false,
      openNotificationModal: true,
    });
  }

  async disableComponentFunction(id) {
    const { lpSectionId } = this.state;

    const response = await disableLpSectionComponent(id);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerError"),
        typeNotificationModal: "error",
      });
      this.setState({
        isLoadingButton: false,
        loadingInfo: false,
        openNotificationModal: true,
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.disablingSuccessful"),
        contenNotificationModal: t("modal.newsSuccessfullyDisabled"),
        typeNotificationModal: "success",
      });
      this.getNewsInformation(lpSectionId, null);
      this.setState({
        isLoadingButton: false,
        loadingInfo: false,
        openNotificationModal: true,
      });
    }
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 5);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
              responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
    }
    this.setState({ openNotificationModal: true, isLoadingButton: false });
  }

  //   EVENTS

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("modal.sectionUnselected"),
      contenNotificationModal: t("modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({ openNotificationModal: true });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleChangeDescription(html, element) {
    if (element != null) {
      this.handleChange(html, "text", "", true, element.id);
    } else {
      this.handleChange(html, "text", "", true, null);
    }
  }

  handleChange = (event, dataIndex, data, flagRichText, newsId) => {
    let id = null;
    let value = null;

    if (event && event.target) {
      id = event.target.id;
      value = event.target.value;
    }
    if (flagRichText) {
      id = dataIndex;
      value = event;
    }

    const { newsContainerTitle, newsToShow, news, newsQuanty } = {
      ...this.state,
    };
    if (newsId === null) {
      news[id] = value; // new news
    }

    if (id === "NewsContainerTitle") {
      if (value.length <= newsContainerTitle.maxLength) {
        newsContainerTitle.value = value;
        newsContainerTitle.modified = true;
        newsContainerTitle.length = value.length;
      }
    }
    if (id === "NewsQuantity") {
      if (value.length <= newsQuanty.maxLength) {
        newsQuanty.value = value;
        newsQuanty.modified = true;
        newsQuanty.length = value.length;
      }
    }
    newsToShow.forEach((element, index) => {
      //news edit
      if (element.id === newsId) {
        if (id === "title") {
          newsToShow[index].title = value;
        }
        if (id === "author") {
          newsToShow[index].author = value;
        }
        if (id === "text") {
          newsToShow[index].text = value;
        }
        newsToShow[index].edited = true;
      }
    });
    this.setState({
      news,
      newsToShow,
      newsContainerTitle,
      newsQuanty,
      FlagclassName: true,
    });
  };

  changeDateHandleEvent = (e, newsId) => {
    const { news, newsToShow } = { ...this.state };
    const date = e.target.value;

    let dateToSave = date;
    news.date = dateToSave;
    news.dateToShow = dateToSave;
    newsToShow.forEach((element, index) => {
      if (element.id === newsId) {
        newsToShow[index].dateToShow = date;
        newsToShow[index].date = date;
        newsToShow[index].edited = true;
      }
    });
    this.setState({
      news,
      newsToShow,
      FlagclassName: true,
    });
  };

  handleChangeImage = async (event) => {
    const { country, city, organizationId, newsToShow, imageIndex, news } =
      this.state;
    var newNewsImage = { ...this.state.newNewsImage };
    var fileUpload = event.target.files[0];
    let extension;
    if (fileUpload !== undefined) {
      extension = fileUpload.type.replace("image/", "");
    }
    const random = uuidv4();
    const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
    if (!extension) {
      newNewsImage.loading = true;
      newNewsImage.file = null;
      newNewsImage.image = null;

      this.setState({
        newNewsImage,
        FlagclassName: true,
      });
      return;
    }

    if (key !== undefined) {
      // Function to resize and reduce image quality
      // Save image in AWS
      let flag = true;
      newNewsImage = await handleChangeImageFunction(
        newNewsImage,
        fileUpload,
        extension,
        key,
        400,
        400
      ).catch((res) => {
        const { t } = this.props;
        this.setState({
          titleNotificationModal: t(`${res.title}`),
          contenNotificationModal: `${t(`${res.content}`)} ${res.params}`,
          typeNotificationModal: "error",
          openNotificationModal: true,
        });
        flag = false;
      });
      if (flag !== false) {
        if (newNewsImage.loading === false) {
          if (imageIndex === null) {
            //new news
            newNewsImage.loading = false;
            newNewsImage.modified = true;
            newNewsImage.value = key;
            news.image = key;
            news.imageUri = newNewsImage.image;
          } else {
            // edited new
            newsToShow[imageIndex].image = key;
            newsToShow[imageIndex].imageUri = newNewsImage.image;
            newsToShow[imageIndex].edited = true;
          }

          this.uploadFileToS3Bucket(newNewsImage.file, key);
          this.setState({
            newNewsImage,
            newsToShow,
            FlagclassName: true,
          });
          this.forceUpdate();
        }
      }
    }
  };

  disableImage(elementToChange) {
    const { newsToShow, news, imageIndex } = {
      ...this.state,
    };
    if (elementToChange !== null) {
      newsToShow[imageIndex].image = null;
      newsToShow[imageIndex].edited = true;
    } else {
      news.image = null;
    }

    this.setState({
      isLoadingButton: true,
      FlagclassName: true,
      deleteFlag: true,
      newsToShow,
      news,
    });
    this.handleCloseDeleteImage();
  }

  handleButtonListChange = (buttonList, idNew) => {
    let { newsToShow, news } = this.state;
    newsToShow.forEach((element, index) => {
      if (element.id === idNew) {
        newsToShow[index].buttons = buttonList;
        newsToShow[index].edited = true;
      }
    });
    news.buttons = buttonList;
    this.setState({ newsToShow, news, FlagclassName: true });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagclassName: true,
    });
  };

  saveChangeName = () => {
    let dto = {
      id: this.state.lpSectionId,
      customName: this.state.newCustomName,
      isEnabled: 1,
    };

    this.updateLpSection(dto);
  };

  onSaveNews = (e) => {
    const {
      parentLPSectionComponentId,
      landingPageId,
      newsToShow,
      news,
      deleteFlag,
    } = this.state;
    this.setState({
      isLoadingButton: true,
      FlagclassName: false,
    });
    const { t } = this.props;

    var newsToValidate = newsToShow.filter((n) => n.edited === true);
    if (news.title !== "" && news.title !== null) {
      newsToValidate = newsToValidate.concat(news);
    }

    newsToValidate.forEach((newNews) => {
      const newsNodesToAdd = [];

      let newsButtons = newNews.buttons
        ? newNews.buttons.map((b) => {
            const button = {
              id: b.id,
              name: b.name,
              externalLink: b.externalLink,
              internalLpSectionComponentId: b.component
                ? b.component.id
                : b.internalLpSectionComponentId
                ? b.internalLpSectionComponentId
                : null,
              isEnabled: b.isEnabled,
            };
            return button;
          })
        : [];
      const newsToAdd = {
        // --- Con esto se crea el registro lpSectionComponent ---
        lpId: null, // Este valor se obtiene de la vista anterior
        sectionId: 5, // Valor estático para la sección de eventos
        lpSectionId: this.state.lpSectionId,
        sectionComponentId: 7, // Valor estático de 5, valor para el EventComponent

        parentLPSectionComponentId, // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente

        // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
        nodesToAdd: [],
        buttons: newsButtons,
      };
      let errorInput = false;
      if (newNews.title != null) {
        const newsNode = {};

        newsNode.id = newNews.titleId ? newNews.titleId : null;
        newsNode.sectionComponentNodeId = 23;
        if (newNews.title === "") {
          errorInput = true;
        }
        newsNode.value = newNews.title;
        newsNode.nodeType = "NewsTitle";

        newsNodesToAdd.push(newsNode);
      }
      if (newNews.text != null) {
        const newsNode = {};

        newsNode.id = newNews.textId ? newNews.textId : null;
        newsNode.sectionComponentNodeId = 25;
        if (newNews.text === "") {
          errorInput = true;
        }
        newsNode.value = newNews.text;
        newsNode.nodeType = "NewsText";

        newsNodesToAdd.push(newsNode);
      }
      if (newNews.date != null) {
        const newsNode = {};
        newsNode.id = newNews.dateId ? newNews.dateId : null;
        newsNode.sectionComponentNodeId = 24;
        newsNode.value = moment(newNews.date, "YYYY-MM-DD HH:mm:ss");
        newsNode.nodeType = "NewsDate";

        newsNodesToAdd.push(newsNode);
      }
      if (newNews.author != null) {
        const newsNode = {};

        newsNode.id = newNews.authorId ? newNews.authorId : null;
        newsNode.sectionComponentNodeId = 27;
        // if (newNews.author === "") {
        //   errorInput = true;
        // }
        newsNode.value = newNews.author;
        newsNode.nodeType = "NewsAuthor";

        newsNodesToAdd.push(newsNode);
      }
      if (newNews.image !== null) {
        const newsNode = {};
        newsNode.id = newNews.imageId ? newNews.imageId : null;
        newsNode.sectionComponentNodeId = 26;
        let haveHttp = false;
        var regex = /somosaura-cms.s3.amazonaws.com/;
        haveHttp = regex.test(newNews.image);
        if (haveHttp) {
          newsNode.value = newNews.image.slice(39, newNews.image.length); //Cut to only save image, not link
        } else {
          newsNode.value = newNews.image;
        }
        newsNode.nodeType = "NewsImage";

        newsNodesToAdd.push(newsNode);
      }
      if (deleteFlag) {
        const newsNode = {};

        newsNode.id = newNews.imageId ? newNews.imageId : null;
        newsNode.sectionComponentNodeId = 26;
        newsNode.value = "";
        newsNode.nodeType = "NewsImage";
        newsNodesToAdd.push(newsNode);
      }
      newsToAdd.id = newNews.id;
      newsToAdd.lpId = landingPageId;
      newsToAdd.nodesToAdd = newsNodesToAdd;

      if (newsNodesToAdd.length > 0) {
        if (!errorInput) {
          let newsTitle = false;
          let newsText = false;
          let newsDate = false;
          let newsAuthor = false;

          newsNodesToAdd.forEach((obj) => {
            if (obj.nodeType === "NewsTitle") {
              newsTitle = true;
            }
            if (obj.nodeType === "NewsText") {
              newsText = true;
            }
            if (obj.nodeType === "NewsDate") {
              newsDate = true;
            }
            if (obj.nodeType === "NewsAuthor") {
              newsAuthor = true;
            }
          });
          if (newsTitle && newsText && newsDate && newsAuthor) {
            if (newNews.id) {
              this.updateSectionComponentFunction(newsToAdd);
            } else {
              this.insertSectionComponentFunction(newsToAdd);
            }
          } else {
            this.setState({
              titleNotificationModal: t("modal.wrongFile"),
              contenNotificationModal: t("modal.fieldEmpty"),
              typeNotificationModal: "error",
            });
            this.setState({
              isLoadingButton: false,
              openNotificationModal: true,
            });
          }
        } else {
          this.setState({
            titleNotificationModal: t("modal.wrongFile"),
            contenNotificationModal: t("modal.fieldEmpty"),
            typeNotificationModal: "error",
          });
          this.setState({
            isLoadingButton: false,
            openNotificationModal: true,
          });
        }
      }
    });
  };

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  }

  setLayoutModalVisible = (isVisible) => {
    this.setState({ layoutModalVisible: isVisible });
  };

  //Functions
  cleanStateVariables = () => {
    let news = {
      id: null,
      title: "",
      text: "",
      date: moment(),
      dateToShow: moment(),
      image: null,
      author: "",
      imageLoading: false,
      buttons: [],
      topLimit: null,
    };

    let newNewsImage = {
      id: null,
      s3path: null,
      file: null,
      image: null,
      loading: false,
    };

    this.setState({
      news,
      newNewsImage,
    });
  };

  saveNewsEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagclassName: false,
    });
    const nodesToUpdate = [];
    var {
      newsContainerTitle,
      haveChangeInLayout,
      news,
      newsToShow,
      newsQuanty,
      lpSectionId,
      lpSectionLayoutStyle,
    } = {
      ...this.state,
    };
    const { t } = this.props;
    let errorInput = false;

    // Verify each one of the values of the component to add
    if (newsContainerTitle) {
      if (newsContainerTitle.value === "") {
        errorInput = true;
      }
      delete newsContainerTitle.modified;
      nodesToUpdate.push(newsContainerTitle);
    }
    if (newsQuanty.modified) {
      if (newsQuanty.value === "") {
        errorInput = true;
      }
      delete newsQuanty.modified;
      nodesToUpdate.push(newsQuanty);
    }
    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }
    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          titleNotificationModal: t("modal.wrongFile"),
          contenNotificationModal: t("modal.fieldEmpty"),
          typeNotificationModal: "error",
        });
        this.setState({
          isLoadingButton: false,
          openNotificationModal: true,
        });
      }
    }
    if (news.title !== "") {
      newsToShow.push(news);
    }

    this.setState({ newsToShow });
    this.onSaveNews();
    news = {
      id: null,
      title: "",
      text: "",
      date: moment(),
      dateToShow: moment(),
      author: "",
      image: null,
      buttons: [],
    };
    this.setState({ news, addNotice: false });
  };

  handleChangeLayout = (e, id) => {
    this.setState({
      // newLayoutId: id,
      saveButtonDisabled: false,
      FlagclassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  handleClickOpenDeleteImage = (index) => {
    if (index) {
      this.handleImageIndex(index);
      this.setState({ openDeleteImage: true });
    } else {
      this.setState({ openDeleteImage: true });
    }
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDeleteNews = (id) => {
    this.setState({ openDeleteNews: true, elementToDeleteId: id });
  };

  handleCloseDeleteNews = () => {
    this.setState({ openDeleteNews: false });
  };

  changeToEdit = (e) => {
    e.stopPropagation();
    const { isReadyToEdit } = this.state;
    this.setState({ isReadyToEdit: !isReadyToEdit });
  };

  disableNewsEvent = (id) => {
    this.setState({
      loadingInfo: true,
      openDeleteNews: false,
    });
    this.disableComponentFunction(id);
  };

  handleImageIndex = (index) => {
    this.setState({
      imageIndex: index,
    });
  };

  renderNews(newsToShow) {
    let renderedComponents = [];
    const { t } = this.props;

    const { openDeleteImage, landingPageId, isReadyToEdit } = this.state;

    if (newsToShow && newsToShow.length) {
      newsToShow.forEach((element, index) => {
        renderedComponents.push(
          <div>
            <AccordionComponent
              disabled={isReadyToEdit}
              iconEdit={<Edit2 />}
              // onEditNews={(e) => this.changeToEdit(e)}
              onDeleteNews={(e) => this.handleClickOpenDeleteNews(element.id)}
              iconDelete={<Trash2 />}
              title={element.title}
              body={[
                <div className="accordionContent">
                  {/* First Line */}
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "50%",
                        marginLeft: "1%",
                        marginRight: "0.7%",
                      }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.enterTitle")}
                        id="title"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.title}
                      />
                      {element.title.value && element.title.maxLength ? (
                        <span>
                          {element.title.length} / {element.title.maxLength}
                        </span>
                      ) : null}
                    </div>
                    <div
                      style={{
                        width: "50%",
                        marginRight: "1%",
                        marginLeft: "0.7%",
                      }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("sections.author")}
                        id="author"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.author}
                      />
                    </div>
                  </div>

                  <div className="rowSection componentSpace">
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "0.5%",
                        marginRight: "0.5%",
                      }}
                    >
                      <ReactQuill
                        readOnly={isReadyToEdit}
                        className="textInputWidth"
                        onChange={(e) =>
                          this.handleChangeDescription(e, element)
                        }
                        value={element.text}
                        modules={editorConfiguration.modules}
                        formats={editorConfiguration.formats}
                        placeholder={t("basicWords.description")}
                      />
                    </div>
                  </div>

                  <div className="rowSection componentSpace">
                    <div className="textLogo">{t("basicWords.image")}</div>
                    <div className="cardImagesSquare">
                      <div className="row">
                        <div className="iconXCardImagesSquare">
                          <a
                            onClick={() =>
                              this.handleClickOpenDeleteImage(index)
                            }
                          >
                            <X
                              className={
                                isReadyToEdit
                                  ? "iconImageEditInSectionSquareDisabled"
                                  : "iconImageEditInSectionSquare"
                              }
                            />
                          </a>
                        </div>
                      </div>

                      <div className="row">
                        {/* Tooltip image */}
                        <Tooltip title={t("generalMessages.imagesFormat")}>
                          <div className="centerImage">
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={(e) => this.handleChangeImage(e, index)}
                              hidden
                              disabled={isReadyToEdit}
                            />
                            <label htmlFor="contained-button-file">
                              <div className="imageEdit">
                                <div>
                                  {element.image != null ? (
                                    <img
                                      src={element.imageUri}
                                      alt="avatar"
                                      className={
                                        isReadyToEdit
                                          ? "widthAndHeightImageSectionSquareDisabled"
                                          : "widthAndHeightImageSectionSquare"
                                      }
                                      onClick={() =>
                                        this.handleImageIndex(index)
                                      }
                                    />
                                  ) : (
                                    <Plus
                                      className="imageEdit"
                                      onClick={() =>
                                        this.handleImageIndex(index)
                                      }
                                    />
                                  )}

                                  <GeneralModal
                                    visible={openDeleteImage}
                                    title={t("basicWords.deleteItem")}
                                    footer={[
                                      <div
                                        className="row"
                                        key={1}
                                        style={{ display: "flex" }}
                                      >
                                        <div
                                          className="col-6"
                                          style={{ marginRight: "2%" }}
                                        >
                                          <ButtonComponent
                                            onClick={() =>
                                              this.handleCloseDeleteImage()
                                            }
                                            props={null}
                                            shape="round"
                                            text={t("basicWords.cancelText")}
                                          />
                                        </div>
                                        <div>
                                          <ButtonComponent
                                            onClick={() =>
                                              this.disableImage(element)
                                            }
                                            props={null}
                                            shape="round"
                                            text={t("basicWords.okText")}
                                          />
                                        </div>
                                      </div>,
                                    ]}
                                    onClick={() =>
                                      this.handleCloseDeleteImage()
                                    }
                                  />
                                </div>
                              </div>
                            </label>
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div style={{ width: "20%" }}>
                      <DatePickerComponent
                        disabled={isReadyToEdit}
                        string={t("basicWords.date")}
                        defaultValue={element.date}
                        onChange={(e) =>
                          this.changeDateHandleEvent(e, element.id)
                        }
                      />
                    </div>
                  </div>
                  <div className="rowSection componentSpace">
                    <div className="textLogo">{t("buttons.buttons")}</div>
                  </div>
                  <div
                    className="rowSection componentSpace"
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <ButtonsContainer
                      disabled={isReadyToEdit}
                      isModalVisible={true}
                      lpId={landingPageId}
                      buttonList={element.buttons}
                      handleButtonListChange={(buttonList) =>
                        this.handleButtonListChange(buttonList, element.id)
                      }
                    />
                  </div>
                </div>,
              ]}
            />
            <br />
          </div>
        );
      });
    }

    return renderedComponents;
  }

  renderAddNews() {
    const { news, openDeleteImage, landingPageId } = this.state;
    const { t } = this.props;
    return (
      <div>
        <AccordionComponent
          title={t("basicWords.newNew")}
          className="accordionAdd"
          body={[
            <div className="accordionContent">
              {/* First Line */}
              <div className="accordionCenterLine">
                <div
                  style={{
                    width: "50%",
                    marginLeft: "1%",
                    marginRight: "0.7%",
                  }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.enterTitle")}
                    id="title"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={news.title}
                  />
                  {news.value && news.maxLength ? (
                    <span>
                      {news.length} / {news.maxLength}
                    </span>
                  ) : null}
                </div>
                <div
                  style={{
                    width: "50%",
                    marginRight: "1%",
                    marginLeft: "0.7%",
                  }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("sections.author")}
                    id="author"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={news.author}
                  />
                </div>
              </div>
              {/* Second Line */}
              <div className="rowSection componentSpace">
                <div
                  style={{
                    width: "100%",
                    marginLeft: "0.5%",
                    marginRight: "0.5%",
                  }}
                >
                  <ReactQuill
                    className="textInputWidth"
                    onChange={(e) => this.handleChangeDescription(e, null)}
                    value={news.text}
                    modules={editorConfiguration.modules}
                    formats={editorConfiguration.formats}
                    placeholder={t("basicWords.description")}
                  />
                </div>
              </div>

              {/* Third Line */}
              <div className="rowSection componentSpace">
                <div className="textLogo" style={{ marginLeft: "1%" }}>
                  {t("basicWords.image")}
                </div>
                {/* Tooltip image */}
                <Tooltip title={t("generalMessages.imagesFormat")}>
                  <div className="cardImagesSquare">
                    <div className="row">
                      <div className="iconXCardImagesSquare">
                        <a>
                          <X
                            className="iconImageEditInSectionSquare"
                            onClick={() => this.handleClickOpenDeleteImage()}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="row">
                      <div className="centerImage">
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => this.handleChangeImage(e, null)}
                          hidden
                        />
                        <label htmlFor="contained-button-file">
                          <div className="imageEdit">
                            <div>
                              {news.image != null ? (
                                <img
                                  src={news.imageUri}
                                  alt="avatar"
                                  className="widthAndHeightImageSectionSquare"
                                  onClick={() => this.handleImageIndex(null)}
                                />
                              ) : (
                                <Plus
                                  className="imageEdit"
                                  onClick={() => this.handleImageIndex(null)}
                                />
                              )}

                              <GeneralModal
                                visible={openDeleteImage}
                                title={t("basicWords.deleteItem")}
                                footer={[
                                  <div
                                    className="row"
                                    key={1}
                                    style={{ display: "flex" }}
                                  >
                                    <div className="col-6">
                                      <ButtonComponent
                                        onClick={() =>
                                          this.handleCloseDeleteImage()
                                        }
                                        props={null}
                                        shape="round"
                                        text={t("basicWords.cancelText")}
                                      />
                                    </div>
                                    <div>
                                      <ButtonComponent
                                        onClick={() => this.disableImage(null)}
                                        props={null}
                                        shape="round"
                                        text={t("basicWords.okText")}
                                      />
                                    </div>
                                  </div>,
                                ]}
                                onClick={() => this.handleCloseDeleteImage()}
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <div style={{ width: "20%" }}>
                  <DatePickerComponent
                    string={t("basicWords.date")}
                    defaultValue={news.date}
                    onChange={(e) => this.changeDateHandleEvent(e, null)}
                  />
                </div>
              </div>

              {/* Fourth Line */}
              <div className="rowSections componentSpace">
                <div className="textLogo">{t("buttons.buttons")}</div>
              </div>
              {/* Fifth Line */}

              <div
                className="rowSections componentSpace"
                style={{
                  marginLeft: "2%",
                }}
              >
                <ButtonsContainer
                  isModalVisible={true}
                  lpId={landingPageId}
                  buttonList={news.buttons}
                  handleButtonListChange={(buttonList) =>
                    this.handleButtonListChange(buttonList, news.id)
                  }
                />
              </div>
            </div>,
          ]}
        />
        <br />
      </div>
    );
  }

  addNotice = (flag) => {
    if (flag !== this.state.addNotice) {
      this.setState({ addNotice: flag });
    }
  };

  //-----------Update page after modal------------
  updatePage = (lpSectionId, previousPath) => {
    this.getNewsInformation(lpSectionId);
    this.setState({
      previousPath,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  // Render Info
  render() {
    const {
      redirectBack,
      sectionName,
      newsQuanty,
      newsContainerTitle,
      newsToShow,
      organizationId,
      organizationName,
      name,
      subpageId,
      landingPageId,
      FlagclassName,
      layoutData,
      lpSectionLayoutStyle,
      lpUrl,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
      isLoadingButton,

      //Modal delete item
      openDeleteNews,
      elementToDeleteId,

      openModalToChangeName,
      customName,
      goToWithoutSaveModalVisible,
    } = this.state;
    const { t } = this.props;
    if (redirectBack) {
      return <Redirect to="/myWebPage" />;
    }

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      customName ? decodeURIComponent(customName) : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <DeleteItemModal
          visible={openDeleteNews}
          onCancel={this.handleCloseDeleteNews}
          onClick={(e) => this.disableNewsEvent(elementToDeleteId)}
        />

        <Card className="card">
          {isLoadingButton === true ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(800%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row componentSpace spaceBreadcrumb">
                <BreadcrumbSection
                  principal={t("sections.news")}
                  secondary={organizationName}
                  route="myWebPage"
                  propsComponent={{
                    organizationId,
                    sectionName: sectionName,
                    nameSubpage: name ? name : null,
                    lpId: landingPageId,
                    subpageId: subpageId ? subpageId : null,
                    lpUrl: lpUrl,
                    customName,
                  }}
                  handleClickOpenModalToChangeName={
                    this.handleClickOpenModalToChangeName
                  }
                  FlagclassName={FlagclassName}
                  onClick={(e) => this.saveAboutEvent(e)}
                  GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                  onCancelGotoWithoutMModal={(e) =>
                    this.onCancelGotoWithoutMModal(e)
                  }
                  goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                />
              </div>
              <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                <Title
                  title={t("basicWords.completeInformationOfYourSection")}
                />
              </div>

              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                <SelectLayoutModal
                  layoutData={layoutData}
                  lpLayoutId={
                    lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                  }
                  handleChange={this.handleChangeLayout}
                />
              </div>
              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                <AccordionComponent
                  title={t("generalUser.sectionContent")}
                  body={[
                    <div className="accordionContent">
                      <div className="rowSection componentSpace">
                        <div
                          style={{
                            width: "70%",
                            marginRight: "1%",
                            marginLeft: "1%",
                          }}
                        >
                          <GeneralInput
                            className="textInputWidth"
                            placeholder={t("basicWords.enterTitle")}
                            id={
                              newsContainerTitle
                                ? newsContainerTitle.nodeTypeName
                                : null
                            }
                            onChange={(e, id) =>
                              this.handleChange(e, "", "", "", id)
                            }
                            value={
                              newsContainerTitle
                                ? newsContainerTitle.value
                                : null
                            }
                          />
                        </div>
                        <div style={{ width: "30%" }}>
                          <GeneralInput
                            className="textInputWidth"
                            placeholder={t("generalMessages.numberNews")}
                            id={newsQuanty ? newsQuanty.nodeTypeName : null}
                            onChange={(e, id) =>
                              this.handleChange(e, "", "", "", id)
                            }
                            value={newsQuanty ? newsQuanty.value : null}
                          />
                        </div>
                      </div>
                    </div>,
                  ]}
                />
              </div>

              {/* Accordion to add notice */}
              {landingPageId ? (
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  {this.renderAddNews()}
                </div>
              ) : null}

              <div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <Title title={t("basicWords.listNews")} />
                </div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  {this.renderNews(newsToShow)}
                </div>
              </div>
            </>
          )}
        </Card>
        <br />

        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName: sectionName,
            nameSubpage: name ? name : null,
            lpId: landingPageId,
            subpageId: subpageId ? subpageId : null,
            lpUrl: lpUrl,
          }}
          FlagclassName={FlagclassName}
          onClick={(e) => this.saveNewsEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(NewsSection);
