import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/scheduleventtype";

export async function getAvailableScheduleEventTypes() {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}