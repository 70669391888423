import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/roles";

export async function getRoleById(id) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            id: id
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function getRolesByOrganizationId(organizationId) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            organization_id: organizationId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function getRolesByOrganizationIdPaged(
   organizationId,
   page,
   size,
   attribute,
   order
) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            organization_id: organizationId,
            page: page,
            size: size,
            attribute: attribute,
            order: order
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function insertRole(roleToAdd) {
   try {
      const data = JSON.stringify(roleToAdd)
      let response = await axios({
         method: 'POST',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function updateRole(roleToUpdate) {
   try {
      const data = JSON.stringify(roleToUpdate)
      let response = await axios({
         method: 'PUT',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function disableRole(roleId) {
   try {
      let response = await axios({
         method: 'DELETE',
         url: host + port + route + datasource + resource,
         params: {
            role_id: roleId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}