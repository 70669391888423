import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";

// Queries
import {
  getOrganizationById,
  getRoleById,
  getPrivileges,
  disablePrivilegeByRole,
  updateRole,
} from "../../actions/index";

// Component
import { Card, CircularProgress, Backdrop } from "@material-ui/core";
import {
  Title,
  ButtonComponent,
  GeneralInput,
  Breadcrumb,
  NotificationModal,
} from "../../components/index";

//Localization
import { withTranslation } from 'react-i18next';

const tableSize = 64 + 68 + 15 + 20 + 68 + 115 + 64;

 class EditRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      organizationName: null,
      role: {
        id: null,
        name: "",
        description: "",
        privileges: [],
      },

      roleToUpdate: {
        id: null,
        name: "",
        description: "",
        privilegeIds: [],
      },

      privileges: [],

      loadingPrivilegesByRole: true,
      loadingPrivileges: true,

      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { location } = this.props;

    const { props } = location;

    const organizationId = location
      ? location.props
        ? location.props.organizationId
        : null
      : null;
    const organizationName = location.props
      ? location.props.organizationName
      : null;

    if (organizationName == null) {
      this.getOrganizationByIdFunction(organizationId);
    }

    if (props) {
      const { organizationName } = props;
      this.getRoleByIdFunction(props.roleId);
      this.getPrivilegesFunction();
      this.setState({
        organizationName,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getOrganizationByIdFunction(id) {
    const response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        this.setState({
          organizationName: response.data.data.name,
        });
      }
    }
  }

  async getRoleByIdFunction(roleId) {
    const role = { ...this.state.role };
    const roleToUpdate = { ...this.state.roleToUpdate };
    const response = await getRoleById(roleId);

    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        role.id = response.data.data.id;
        role.name = response.data.data.name;
        role.description = response.data.data.description;
        role.privileges = response.data.data.privileges;

        roleToUpdate.id = response.data.data.id;
      }
      this.setState({
        role,
        roleToUpdate,
        loadingPrivilegesByRole: false,
      });
    }
  }

  async getPrivilegesFunction() {
    let privileges = [];
    const response = await getPrivileges();
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        privileges = response.data.data;
      }
      this.setState({
        privileges,
        loadingPrivileges: false,
      });
    }
  }

  async disablePrivilegeByRoleFunction(roleId, privilegeId) {
    const { role } = this.state;
    const response = await disablePrivilegeByRole(roleId, privilegeId);
    const {t} = this.props
    if (response) {
      if (String(response).includes("Error:")) {
        this.setState({
          titleNotificationModal: t('modal.internalServerError'),
          contenNotificationModal:
            t('modal.internalServerErrorContactSupport'),
          typeNotificationModal: "error",
        });
      } else {
        this.setState({
          titleNotificationModal: t('modal.disablingSuccessful'),
          contenNotificationModal:
            t('modal.privilegeSuccessfullyDisengaged'),
          typeNotificationModal: "success",
        });
        this.getRoleByIdFunction(role.id);
        this.getPrivilegesFunction();
      }
      this.setState({
        openNotificationModal: true,
      });
    }
  }

  async updateRoleFunction(roleToUpdate) {
    const { role } = this.state;
    const response = await updateRole(roleToUpdate);
    const {t} = this.props
    if (response) {
      if (String(response).includes("Error:")) {
        this.setState({
          titleNotificationModal: t('modal.internalServerError'),
          contenNotificationModal: t('modal.internalServerError'),
          typeNotificationModal: "error",
        });
      } else {
        this.setState({
          titleNotificationModal: t('modal.editionSuccess'),
          contenNotificationModal: t('modal.roleSuccesfullyEdited'),
          typeNotificationModal: "success",
        });

        const roleToUpdate = { ...this.state.roleToUpdate };

        roleToUpdate.name = "";
        roleToUpdate.description = "";
        roleToUpdate.privilegeId = [];

        this.setState({
          roleToUpdate,
        });

        this.getRoleByIdFunction(role.id);
        this.getPrivilegesFunction();
      }
      this.setState({
        loadingPrivileges: false,
        loadingPrivilegesByRole: false,
        openNotificationModal: true,
        isLoadingButton: false,
      });
    }
  }

  // Events
  handleFormChange = (event) => {
    const { id, value } = event.target;
    const role = { ...this.state.role };
    const roleToUpdate = { ...this.state.roleToUpdate };

    role[id] = value;
    roleToUpdate[id] = value;

    this.setState({
      role,
      roleToUpdate,
    });
  };

  removePrivilege = (id) => {
    const { role } = this.state;
    this.setState({
      loadingPrivileges: true,
      loadingPrivilegesByRole: true,
    });
    this.disablePrivilegeByRoleFunction(role.id, id);
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const roleToUpdate = { ...this.state.roleToUpdate };
      roleToUpdate.privilegeIds = [];

      selectedRows.forEach((obj) => {
        roleToUpdate.privilegeIds.push(obj.id);
      });

      this.setState({
        roleToUpdate,
      });
    },
  };

  saveRoleEvent = () => {
    const { roleToUpdate } = { ...this.state };
    this.setState({
      loadingPrivileges: true,
      loadingPrivilegesByRole: true,
      isLoadingButton: true,
    });
    const {t} = this.props

    roleToUpdate.name = roleToUpdate.name.trim();
    roleToUpdate.description = roleToUpdate.description.trim();

    if (roleToUpdate.name.length === 0) {
      roleToUpdate.name = "";
    }
    if (roleToUpdate.description.length === 0) {
      roleToUpdate.description = null;
    }

    if (roleToUpdate.name === "" || roleToUpdate.privilegeIds.length === 0) {
      this.setState({
        titleNotificationModal: t('modal.wrongFile'),
        contenNotificationModal: t('modal.fieldEmpty'),
        typeNotificationModal: "error",
      });
    } else {
      this.updateRoleFunction(roleToUpdate);
    }
    this.setState({
      openNotificationModal: true,
    });
  };

  warning = () => {
    const {t} = this.props
    this.setState({
      titleNotificationModal: t('modal.organizationNotSelected'),
      contenNotificationModal: t('modal.noOrganizationSelected'),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  render() {
    const {
      privileges,
      role,
      redirectBack,
      organizationName,
      loadingPrivilegesByRole,
      loadingPrivileges,
      isLoadingButton,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const {t} = this.props

    const difference = privileges.filter(
      ({ id: id1 }) => !role.privileges.some(({ id: id2 }) => id2 === id1)
    );
    if (redirectBack) {
      return <Redirect to="/roles" />;
    }
    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <div className="row componentSpace">
          <Title title={t('generalUser.editRoleAndPrivileges')} />
        </div>

        <div className="row componentSpace">
          <div className="col-6">
            {/* <Divider orientation="left">
                     <h4>{t('organization.organization')}</h4>
                  </Divider> */}
            <h4>{organizationName}</h4>
          </div>
        </div>

        <div className="row componentSpace">
          <div className="col-6">
            {/* <Divider orientation="left">
                     <h4>{t('generalUser.generalParameters')}</h4>
                  </Divider> */}
          </div>
        </div>

        {/* <Spin
               tip={t('generalUser.loading')}
               indicator={antIcon}
               spinning={loadingPrivilegesByRole && loadingPrivileges}
            > */}
        <div className="row contentForm">
          <div className="col-md-6 componentSpace">
            <GeneralInput
              text={t('basicWords.name') + "*"}
              id="name"
              value={role.name}
              placeholder={t('basicWords.name') + "*"}
              isReq
              onChange={(e) => this.handleFormChange(e)}
            />
          </div>
          <div className="col-md-6 componentSpace">
            <GeneralInput
              text={t('basicWords.description') + "*"}
              id="description"
              value={role.description}
              placeholder={t('basicWords.description') + "*"}
              isReq
              onChange={(e) => this.handleFormChange(e)}
            />
          </div>
        </div>

        <div className="row componentSpace">
          <div className="col-6">
            {/* <Divider orientation="left">
                        <h4>{t('generalUser.assignedPrivileges')}</h4>
                     </Divider> */}
          </div>
        </div>

        <div className="row componentSpace">
          <div className="col">
            {/* <Table
                        dataSource={role.privileges}
                        bordered
                        loading={false}
                        pagination={false}
                        scroll={{ y: '300px', x: 'true' }}
                        size="middle"
                        rowKey='id'
                     >
                        <Column
                           title={<DeleteOutlined />}
                           width='50px'
                           render={row => (
                              <>
                                 <Popconfirm
                                    title={t('basicWords.deleteRegister')}
                                    onConfirm={() => this.removePrivilege(row.id)}
                                    okText={t('basicWords.okText')}
                                    cancelText={t('basicWords.cancelText')}
                                 >
                                    <DeleteTwoTone/>
                                 </Popconfirm>
                              </>
                           )}
                        />
                        <Column
                           title={t('basicWords.name')}
                           dataIndex='name'
                        />
                        <Column
                           title={t('basicWords.description')}
                           dataIndex='description'
                        />
                     </Table> */}
          </div>
        </div>

        <div className="row componentSpace">
          <div className="col-6">
            {/* <Divider orientation="left">
                        <h4>{t('generalUser.availablePrivileges')}</h4>
                     </Divider> */}
          </div>
        </div>

        <div className="row componentSpace">
          <div className="col">
            {/* <Table
                        dataSource={difference}
                        bordered
                        loading={false}
                        pagination={false}
                        scroll={{ y: '300px', x: 'true' }}
                        size="middle"
                        rowKey='id'
                        rowSelection={this.rowSelection}
                     >
                        <Column
                           title={t('basicWords.name')}
                           dataIndex='name'
                        />
                        <Column
                           title={t('basicWords.description')}
                           dataIndex='description'
                        />
                     </Table> */}
          </div>
        </div>
        {/* </Spin> */}

        <div className="row componentSpace justify-content-end">
          <div className="col-6 col-md-3">
            <ButtonComponent
              // icon={<LeftCircleOutlined />}
              path="./roles"
              shape="round"
              text={t('basicWords.back')}
            />
          </div>
          <div className="col-6 col-md-3">
            <ButtonComponent
              // icon={<SaveOutlined />}
              isLoading={isLoadingButton}
              onClick={(e) => this.saveRoleEvent(e)}
              shape="round"
              text={t('basicWords.save')}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(EditRole)