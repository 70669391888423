import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";

import "../css/sections.scss";

// Queries
import {
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
} from "../../../../actions/index";

import { Card, CircularProgress } from "@material-ui/core";

// Components
import {
  Title,
  NotificationModal,
  BreadcrumbSection,
  GeneralInput,
  ButtonsFooterComponent,
} from "../../../../components/index";

//Localization
import { withTranslation } from "react-i18next";

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class FooterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      landingPageId: null,
      organizationId: null,
      organizationName: null,
      lpSectionId: null,
      sectionName: null,
      country: null,
      region: null,
      city: null,

      loadingInfo: true,

      footerPrayer: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
        minLength: null,
        maxLength: null,
        length: null,
      },
      FlagclassName: false,
      goToWithoutSaveModalVisible: false,

      // Modals
      isLoadingButton: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contentNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { props } = location;

    if (props) {
      this.getFooterInformation(props.landingPageId, props.sectionId);

      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        previousPath: props.previousPath,
        lpUrl: props.lpUrl,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getFooterInformation(lpId, lpSectionId) {
    const { footerPrayer } = { ...this.state };
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "FOOTER"
    );
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contentNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
      });
    } else {
      const sectionInfo = response.data.data;
      const footerComponent = sectionInfo.components.find(
        (comp) => comp.name === "FooterComponent"
      );
      footerPrayer.id = footerComponent.nodes.find(
        (node) => node.nodeType === "FooterPrayer"
      ).id;
      footerPrayer.value = footerComponent.nodes.find(
        (node) => node.nodeType === "FooterPrayer"
      ).value;
      footerPrayer.nodeTypeName = footerComponent.nodes.find(
        (node) => node.nodeType === "FooterPrayer"
      ).nodeType;
      footerPrayer.minLength = footerComponent.nodes.find(
        (node) => node.nodeType === "FooterPrayer"
      ).minLength;
      footerPrayer.maxLength = footerComponent.nodes.find(
        (node) => node.nodeType === "FooterPrayer"
      ).maxLength;
      footerPrayer.length = footerComponent.nodes.find(
        (node) => node.nodeType === "FooterPrayer"
      ).value.length;

      this.setState({ footerPrayer, loadingInfo: false });
    }
  }

  async updateNode(nodesToUpdate) {
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contentNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
        isLoadingButton: false,
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.editionSuccess"),
        contentNotificationModal: t("modal.footerSuccessfullyEdited"),
        typeNotificationModal: "success",
      });
      // Open modal
      this.setState({
        openNotificationModal: true,
        isLoadingButton: false,
      });
    }
  }

  // Events
  handleChange = (event) => {
    const { id, value } = event.target;
    const { footerPrayer } = { ...this.state };
    if (id === "FooterPrayer") {
      if (value.length <= footerPrayer.maxLength) {
        footerPrayer.value = value;
        footerPrayer.modified = true;
        footerPrayer.length = value.length;
      }
    }
    this.setState({
      footerPrayer,
      FlagclassName: true,
    });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  saveFooterEvent = () => {
    this.setState({
      FlagclassName: false,
    });
    const { t } = this.props;

    const nodesToUpdate = [];
    const { footerPrayer } = { ...this.state };
    let errorInput = false;

    if (footerPrayer.modified) {
      if (footerPrayer.value === "") {
        errorInput = true;
      }
      nodesToUpdate.push(footerPrayer);
    }

    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          titleNotificationModal: t("modal.wrongFile"),
          contentNotificationModal: t("modal.fieldEmpty"),
          typeNotificationModal: "error",
        });
        // Open modal
        this.setState({
          openNotificationModal: true,
          isLoadingButton: false,
        });
      }
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("modal.sectionUnselected"),
      contentNotificationModal: t("modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    // Open modal
    this.handleOkWarning();
    this.setState({
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  // Close modal
  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  // Render Info
  render() {
    const {
      redirectBack,
      height,
      footerPrayer,
      isLoadingButton,
      organizationId,
      sectionName,
      landingPageId,
      lpUrl,

      FlagclassName,

      // Modal
      openNotificationModal,
      titleNotificationModal,
      contentNotificationModal,
      typeNotificationModal,
      goToWithoutSaveModalVisible,
    } = this.state;
    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/myWebPage" />;
    }

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contentNotificationModal}
          type={typeNotificationModal}
        />

        <Card className="card">
          {isLoadingButton === true ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(800%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row componentSpace">
                <BreadcrumbSection
                  principal={t("sections.footer")}
                  secondary={this.state.organizationName}
                  route="./myWebPage"
                  propsComponent={{
                    organizationId,
                    sectionName: sectionName,
                    lpId: landingPageId,
                    lpUrl: lpUrl,
                  }}
                  FlagclassName={FlagclassName}
                  onClick={(e) => this.saveAboutEvent(e)}
                  GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                  onCancelGotoWithoutMModal={(e) =>
                    this.onCancelGotoWithoutMModal(e)
                  }
                  goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                />
              </div>

              <div className="row adjustPositionXLeft adjustPositionYTop">
                <Title
                  title={t("basicWords.completeInformationOfYourSection")}
                />
              </div>

              <div
                className="row adjustPositionXLeft adjustPositionXRigth"
                style={{ minHeight: height }}
              >
                <div className="col lpSection">
                  <div className="row" style={{ display: "flex" }}>
                    <div
                      className="col text-lg-left text-center"
                      style={{
                        marginTop: "2%",
                        marginLeft: "2%",
                        width: "30%",
                      }}
                    >
                      <div className="copyright">
                        &copy; Copyright{" "}
                        <strong>
                          <a href="https://somosaura.org/">Aura Software</a>
                        </strong>
                        . {t("footer.rightsReserved")}
                      </div>
                    </div>
                    <div
                      className="col"
                      style={{
                        width: "60%",
                        marginTop: "1%",
                      }}
                    >
                      <GeneralInput
                        text={t("sections.addText")}
                        id={footerPrayer ? footerPrayer.nodeTypeName : null}
                        value={footerPrayer ? footerPrayer.value : null}
                        placeholder={t("sections.addText")}
                        isReq
                        onChange={(e, id, value) =>
                          this.handleChange(e, id, value)
                        }
                      />
                      <span
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {footerPrayer.length} / {footerPrayer.maxLength}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            landingPageId,
            lpUrl,
          }}
          FlagclassName={FlagclassName}
          onClick={(e) => this.saveFooterEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(FooterSection);
