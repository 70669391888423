import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
// import { Divider, Modal, Table, Popconfirm, Spin } from 'antd';
// import {
//    LoadingOutlined,
//    DeleteOutlined,
//    DeleteTwoTone,
//    LeftCircleOutlined,
//    SaveOutlined
// } from '@ant-design/icons';

// Queries
import {
  getOrganizationById,
  getGroupById,
  getGroupTypes,
  getUsersByGroup,
  getUsersByOrganizationId,
  updateGroup,
  disableUserByGroup,
} from "../../actions/index";

import { Card, CircularProgress, Backdrop } from "@material-ui/core";

// Components
import { Title, ButtonComponent, NotificationModal } from "../../components/index";
import GroupForm from "./components/groupForm.jsx";

//Localization
import { withTranslation } from 'react-i18next';

const tableSize = 64 + 68 + 15 + 20 + 68 + 115 + 64;

class EditGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,

      organizationId: null,
      organizationName: null,

      groupTypes: [],
      usersByGroup: [],
      users: [],
      usersToAdd: [],

      groupToUpdate: {
        id: null,
        name: "",
        description: "",
        groupTypeId: null,
        organizationId: null,
        userids: [],
      },

      loadingGroupInfo: true,
      loadingUsersByGroup: true,
      loadingUsers: true,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { location } = this.props;
    const { props } = location;

    if (props) {
      const { organizationTypeId } = props;
      const { organizationId } = props;
      const { organizationName } = props;
      const { groupId } = props;

      const groupToUpdate = { ...this.state.groupToUpdate };
      groupToUpdate.organizationId = organizationId;

      this.getOrganizationByIdFunction(organizationId);
      this.getGroupByIdFunction(groupId);
      this.getGroupTypesFunction(organizationTypeId, organizationId);
      this.getUsersByOrganizationIdFunction(organizationId);
      this.getUsersByGroupIdFunction(groupId);

      this.setState({
        organizationTypeId,
        organizationId,
        organizationName,
        groupToUpdate,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getOrganizationByIdFunction(id) {
    const response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        this.setState({
          organizationId: response.data.data.id,
          organizationName: response.data.data.name,
        });
      }
      this.setState({
        loadingInfo: false,
      });
    }
  }

  async getGroupByIdFunction(groupId) {
    const { organizationId } = this.state;
    const groupToUpdate = { ...this.state.groupToUpdate };
    const response = await getGroupById(groupId);

    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        groupToUpdate.id = response.data.data.id;
        groupToUpdate.name = response.data.data.name;
        groupToUpdate.description = response.data.data.description
          ? response.data.data.description != null
            ? response.data.data.description
            : ""
          : "";
        groupToUpdate.groupTypeId = response.data.data.groupTypeId;
        groupToUpdate.organizationId = organizationId;
      }
      this.setState({
        groupToUpdate,
        loadingInfo: false,
      });
    }
  }

  async getGroupTypesFunction(organizationTypeId, organizationId) {
    let groupTypes = [...this.state.groupTypes];
    const response = await getGroupTypes(organizationTypeId, organizationId);

    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        groupTypes = response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }
      this.setState({
        groupTypes,
        loadingInfo: false,
      });
    }
  }

  async getUsersByGroupIdFunction(groupId) {
    let usersByGroup = [...this.state.usersByGroup];
    const response = await getUsersByGroup(groupId);

    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        usersByGroup = response.data.data;
      }

      this.setState({
        usersByGroup,
        loadingUsers: false,
      });
    }
  }

  async getUsersByOrganizationIdFunction(organizationId) {
    let users = [...this.state.users];
    const response = await getUsersByOrganizationId(organizationId);

    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        users = response.data.data;
      }
      this.setState({
        users,
        loadingUsers: false,
      });
    }
  }

  async disableUserByGroupFunction(id) {
    const { organizationId, groupToUpdate } = this.state;
    const response = await disableUserByGroup(id);
    const { t } = this.props
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        this.setState({
          titleNotificationModal: t('modal.disablingSuccessful'),
          contenNotificationModal: t('modal.userDisassignedSuccessfully'),
          typeNotificationModal: "success",
        });
        this.getUsersByOrganizationIdFunction(organizationId);
        this.getUsersByGroupIdFunction(groupToUpdate.id);
      }
      this.setState({
        loadingAssignedUsers: false,
        openNotificationModal: true,
        loadingUsers: false,
      });
    }
  }

  async updateGroupFunction(groupToUpdate) {
    const { organizationId } = this.state;
    const response = await updateGroup(groupToUpdate);
    const { t } = this.props

    if (response) {
      if (String(response).includes("Error:")) {
        if (groupToUpdate.description == null) {
          groupToUpdate.description = "";
        }

        this.setState({
          groupToUpdate,
          isLoadingButton: false,
        });
        this.setState({
          titleNotificationModal: t('modal.internalServerError'),
          contenNotificationModal: t('modal.internalServerError'),
          typeNotificationModal: "error",
        });
      } else {
        this.setState({
          titleNotificationModal: t('modal.editionSuccess'),
          contenNotificationModal: t('modal.groupSuccesfullEdited'),
          typeNotificationModal: "success",
        });

        if (groupToUpdate.description == null) {
          groupToUpdate.description = "";
        }
        this.getUsersByOrganizationIdFunction(organizationId);
        this.getUsersByGroupIdFunction(groupToUpdate.id);

        this.setState({
          groupToUpdate,
          isLoadingButton: false,
        });
      }
      this.setState({
        openNotificationModal: true,
      });
    }
  }

  // Events
  handleFormChange = (event) => {
    const { id, value } = event.target;
    const groupToUpdate = { ...this.state.groupToUpdate };

    groupToUpdate[id] = value;

    this.setState({
      groupToUpdate,
    });
  };

  handleFormSelect = (option, event) => {
    const { value } = option;
    const { groupToUpdate } = { ...this.state.groupToUpdate };

    groupToUpdate[event.name] = value;

    this.setState({
      groupToUpdate,
    });
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const groupToUpdate = { ...this.state.groupToUpdate };
      groupToUpdate.userids = [];

      selectedRows.forEach((obj) => {
        groupToUpdate.userids.push(obj.id);
      });

      this.setState({
        groupToUpdate,
      });
    },
  };

  disableUserByGroupEvent = (id) => {
    this.setState({
      loadingAssignedUsers: true,
      loadingUsers: true,
    });
    this.disableUserByGroupFunction(id);
  };

  saveGroupEvent = () => {
    const { groupToUpdate } = { ...this.state };
    this.setState({
      isLoadingButton: true,
    });
    const { t } = this.props

    groupToUpdate.name = groupToUpdate.name.trim();
    groupToUpdate.description = groupToUpdate.description.trim();

    if (groupToUpdate.name.length === 0) {
      groupToUpdate.name = "";
    }

    if (
      groupToUpdate.name === "" ||
      groupToUpdate.groupTypeId === null ||
      groupToUpdate.userids.length === 0
    ) {
      this.setState({
        isLoadingButton: false,
      });
      this.setState({
        titleNotificationModal: t('modal.wrongFile'),
        contenNotificationModal: t('modal.fieldEmpty'),
        typeNotificationModal: "error",
      });
    } else {
      if (groupToUpdate.description.length === 0) {
        groupToUpdate.description = null;
      }
      this.updateGroupFunction(groupToUpdate);
    }
    this.setState({
      openNotificationModal: true,
    });
  };

  warning = () => {
    const { t } = this.props
    this.setState({
      titleNotificationModal: t('modal.organizationNotSelected'),
      contenNotificationModal: t('modal.noOrganizationSelected'),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  render() {
    const {
      users,
      usersByGroup,
      redirectBack,
      organizationName,
      loadingGroupInfo,
      loadingUsersByGroup,
      loadingUsers,
      groupTypes,
      groupToUpdate,
      isLoadingButton,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const difference = users.filter(
      ({ id: id1 }) => !usersByGroup.some(({ id: id2 }) => id2 === id1)
    );
    const { t } = this.props

    if (redirectBack) {
      return <Redirect to="/groupsList" />;
    }
    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Backdrop
          style={{ color: "#510f8a", zIndex: "5" }}
          open={isLoadingButton}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="row componentSpace">
          <Title title={t('basicWords.editGroup')} />
        </div>

        <div className="row componentSpace">
          <div className="col-6">
            {/* <Divider orientation="left">
                     <h4>{t('organization.organization')}</h4>
                  </Divider> */}
            <h4>{organizationName}</h4>
          </div>
        </div>

        {/* <Spin
               tip={t('generalMessages.loading')}
               indicator={antIcon}
               spinning={loadingGroupInfo && loadingUsersByGroup && loadingUsers}
            > */}

        <GroupForm
          options={groupTypes}
          group={groupToUpdate}
          onChange={this.handleFormChange}
          onSelect={this.handleFormSelect}
        />

        <div className="row componentSpace">
          <div className="col-6">
            {/* <Divider orientation="left">
                        <h4>{t('generalMessages.assignedUsers')}</h4>
                     </Divider> */}
          </div>
        </div>

        <div className="row componentSpace">
          <div className="col">
            {/* <Table
                        dataSource={usersByGroup}
                        bordered
                        loading={false}
                        pagination={false}
                        scroll={{ x: true }}
                        size="middle"
                        rowKey='id'
                     >
                        <Column
                           title={<DeleteOutlined />}
                           width='50px'
                           render={row => (
                              <>
                                 <Popconfirm
                                    title={t('basicWords.deleteRegister')}
                                    onConfirm={() => this.disableUserByGroupEvent(row.id)}
                                    okText={t('basicWords.okText')}
                                    cancelText={t('basicWords.cancelText')}
                                 >
                                    <DeleteTwoTone />
                                 </Popconfirm>
                              </>
                           )}
                        />
                        <Column
                           title={t('basicWords.name')}
                           render={row => (
                              <span>
                                 {row.name} {row.lastName1 ? row.lastName1 : null}  {row.lastName2 ? row.lastName2 : null}
                              </span>
                           )}
                        />
                        <Column
                           title={t('generalMessages.identityCardNumber')}
                           dataIndex='identityCardNumber'
                        />
                        <Column
                           title={t('generalUser.username')}
                           dataIndex='username'
                        />
                     </Table> */}
          </div>
        </div>

        <div className="row componentSpace">
          <div className="col-6">
            {/* <Divider orientation="left">
                        <h4>{t('generalUser.availableUsers')}</h4>
                     </Divider> */}
          </div>
        </div>

        <div className="row componentSpace">
          <div className="col">
            {/* <Table
                        dataSource={difference}
                        bordered
                        loading={false}
                        pagination={false}
                        scroll={{ x: true }}
                        size="middle"
                        rowKey='id'
                        rowSelection={this.rowSelection}
                     >
                        <Column
                           title={t('basicWords.name')}
                           render={row => (
                              <span>
                                 {row.name} {row.lastName1 ? row.lastName1 : null}  {row.lastName2 ? row.lastName2 : null}
                              </span>
                           )}
                        />
                        <Column
                           title={t('generalUser.identityCardNumber')}
                           dataIndex='identityCardNumber'
                        />
                        <Column
                           title={t('generalUser.username')}
                           dataIndex='username'
                        />
                     </Table> */}
          </div>
        </div>
        {/* </Spin> */}
        <div className="row componentSpace buttonAtTheBottom">
          <div className="col-6 col-md-3">
            <ButtonComponent
              className="buttonDiscard"
              path="./groupsList"
              props={null}
              shape="round"
              text={t('basicWords.discard')}
            />
          </div>
          <div className="col-6 col-md-3">
            <ButtonComponent
              // icon={<SaveOutlined />}
              isLoading={isLoadingButton}
              onClick={(e) => this.saveGroupEvent(e)}
              props={null}
              shape="round"
              text={t('basicWords.save')}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(EditGroup)