import { resizeImage } from "./resizeImages";
import Resizer from "react-image-file-resizer";

const maxWidth = 6000;
const maxHeight = 6000;
let imageQuality;
const maxSizeMb = 7;

export function handleChangeImageFunction(
  originalImage,
  fileUpload,
  extension,
  key,
  imageHeight,
  imageWidth,
  minHeight,
  minWidth
) {
  // Function to resize and improve image quality
  if (minWidth === undefined && minHeight === undefined) {
    minWidth = 150;
    minHeight = 150;
  }
  // File reader
  let reader = new FileReader();

  return new Promise((resolve, reject) => {
    // Detect when one file is uploaded
    reader.readAsDataURL(fileUpload);
    reader.onload = function (e) {
      var image = new Image();
      image.src = e.target.result;
      return (image.onload = function () {
        // Verify the dimensions
        let height = this.height;
        let width = this.width;
        // Size in MB
        const sizeMb = Math.trunc(fileUpload.size / 1024 / 1024);
        if (sizeMb > 4) {
          imageQuality = 90;
        } else {
          imageQuality = 100;
        }
        // Format
        const typeMatch = fileUpload.name.match(/\.([^.]*)$/);
        const format = typeMatch[1].toLowerCase();
        // Validation
        if (format === "jpg" || format === "jpeg" || format === "png") {
          //Format
          //Dimensions
          if (sizeMb < maxSizeMb) {
            //Format
            if (height <= maxHeight && width <= maxWidth) {
              if (height >= minHeight && width >= minWidth) {
                var image = [];
                Resizer.imageFileResizer(
                  fileUpload,
                  imageHeight,
                  imageWidth,
                  extension,
                  imageQuality,
                  0,
                  (uri) => {
                    const file = resizeImage(uri, fileUpload.type, extension);

                    image.id = originalImage.id ? originalImage.id : null;
                    image.file = file;
                    image.image = uri;
                    image.loading = false;
                    image.modified = true;
                    image.value = key;

                    resolve(image);
                  },
                  "base64"
                );
              } else {
                const resSmaller = {
                  title: "modal.imageLittle",
                  content: "modal.anotherImageLarger",
                  params: `${minWidth}px`,
                };
                reject(resSmaller);
              }
            } else {
              const resBigger = {
                title: "modal.imageBig",
                content: "modal.anotherImageSmaller",
                params: `${maxWidth}px`,
              };
              reject(resBigger);
            }
          } else {
            const resHeavy = {
              title: "modal.imageHeavy",
              content: "modal.anotherImageLighter",
              params: `${maxSizeMb}MB`,
            };
            reject(resHeavy);
          }
        } else {
          const formatError = {
            title: "modal.formatError",
            content: "modal.anotherFormat",
            params: "JPG, JPEG, PNG",
          };
          reject(formatError);
        }
      });
    };
  });
}
