import React, { Component } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import PropTypes from "prop-types";

//CSS
import "./css/AlertMessage.css";

class AlertMessage extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  };
  render() {
    const { message, description, type } = this.props;

    return (
      <Alert variant="outlined" severity={type}>
        <AlertTitle>{message}</AlertTitle>
        {description}
      </Alert>
      //   <Alert message={message} description={description} type={type} showIcon />
    );
  }
}

export default AlertMessage;
