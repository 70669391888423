import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

import { ButtonComponent, GeneralModal } from "../../components/index";

//Localization
import { useTranslation } from "react-i18next";

const AddNewModal = (props) => {
  // var { visible, isLoading, sections, sectionToAdd, valueRadioGroup } = props;
  var { visible, isLoading, valueRadioGroup } = props;
  const { t, i18n } = useTranslation();
  return (
    <GeneralModal
      visible={visible}
      title={t("generalMessages.addNewElement")}
      body={[
        <div className="col-12" style={{ margin: "5px" }}>
          <div className="row">
            <div className="col-md-12 componentSpace">
              <div className="col-md-12 componentSpace">
                <RadioGroup
                  aria-label="gender"
                  name="subpageOrGroupSubpage"
                  value={valueRadioGroup}
                  onChange={(e) => props.handleChangeRadioGroup(e)}
                >
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    label={t("basicWords.subpage")}
                  />
                  <FormControlLabel
                    value={"2"}
                    control={<Radio />}
                    label={t("basicWords.groupSubpages")}
                  />
                </RadioGroup>
              </div>
              <div className="col-md-12 componentSpace">
                <TextField
                  variant="outlined"
                  // endAdornment={`${t('basicWords.name')} ${selection}`}
                  endAdornment={`${t("basicWords.name")}`}
                  style={{ width: "100%", marginLeft: 10 }}
                  // label={`${t('basicWords.name')} ${selection}`}
                  label={`${t("basicWords.name")}`}
                  onChange={(e) => props.handleChangeInput(e)}
                />
              </div>
            </div>
          </div>
        </div>,
      ]}
      footer={[
        <div className="row buttonAtTheBottom" key={1}>
          <div>
            <ButtonComponent
              isDisabled={isLoading}
              onClick={(e) => props.handleClose(e)}
              shape="round"
              text={t("basicWords.cancel")}
              className="buttonDiscard"
            />
          </div>
          <div>
            <ButtonComponent
              isDisabled={false}
              isLoading={isLoading}
              onClick={() => props.handleSave()}
              shape="round"
              text={t("basicWords.create")}
            />
          </div>
        </div>,
      ]}
      onClick={(e) => props.handleClose(e)}
    />
  );
};

export default AddNewModal;
