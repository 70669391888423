import React, { Component, Fragment } from "react";
// import { Divider, Modal, Input } from 'antd';
// import {
//    LeftCircleOutlined,
//    SaveOutlined
// } from '@ant-design/icons';

//Queries
import {
  getCountries,
  getRegionsByCountryId,
  getCitiesByRegionId,
  insertOrganization,
  getOrganizationTypes,
  getLpTypes,
} from "../../actions/index";
import { Card, CircularProgress, Backdrop } from "@material-ui/core";

//Components
import {
  Title,
  Breadcrumb,
  NotificationModal,
  ButtonsFooterComponent,
} from "../../components/index";
import OrganizationForm from "./components/OrganitazionForm.jsx";
import { Home } from "react-feather";

//Localization
import { withTranslation } from "react-i18next";

class AddOrganization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizationToAdd: {
        name: "",
        description: "",
        countryId: null,
        regionId: null,
        cityId: null,
        phone: "",
        address: "",
        parentOrganizationId: null,
        organizationTypeId: 2,
        url: "",
        lptypeId: null,
      },

      organizationTypes: [],
      lpTypes: [],
      countries: [],
      regions: [],
      cities: [],

      loadingInfo: false,
      isLoadingButton: false,

      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    this.getCountries();
    this.getOrganizationTypesFunction();
    this.getLPTypesFunction();
  }

  //Queries
  async getOrganizationTypesFunction() {
    let organizationTypes = [];
    let response = await getOrganizationTypes();

    if (String(response).includes("Error:")) {
    } else {
      organizationTypes = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ organizationTypes });
  }
  async getLPTypesFunction() {
    let lpTypes = [];
    let response = await getLpTypes();

    if (String(response).includes("Error:")) {
    } else {
      lpTypes = response.data.data.map((item) => ({
        value: item.id,
        label: item.nameString,
      }));
    }

    this.setState({ lpTypes });
  }
  async getCountries() {
    let countries = [];
    let response = await getCountries();

    if (String(response).includes("Error:")) {
    } else {
      countries = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ countries });
  }
  async getRegions(countryId) {
    let regions = [];
    let response = await getRegionsByCountryId(countryId);
    const {t} = this.props

    if (String(response).includes("Error:")) {
    } else {
      regions = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ regions });
  }
  async getCities(regionId) {
    let cities = [];
    let response = await getCitiesByRegionId(regionId);

    if (String(response).includes("Error:")) {
    } else {
      cities = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ cities });
  }

  async postOrganization(organizationToAdd) {
    let response = await insertOrganization(organizationToAdd);
    const {t} = this.props

    if (String(response).includes("Error:")) {
      if (organizationToAdd.description == null) {
        this.setState({
          organizationToAdd: {
            description: "",
          },
        });
      }
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal:
          t('modal.internalServerErrorContactSupport'),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
        isLoadingButton: false,
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.successfulCreation'),
        contenNotificationModal: t('modal.organizationSuccesfullCreated'),
        typeNotificationModal: "success",
      });
      this.setState({
        organizationToAdd: {
          name: "",
          description: "",
          countryId: null,
          regionId: null,
          cityId: null,
          phone: "",
          address: "",
          parentOrganizationId: null,
          organizationTypeId: null,
          url: "",
        },
        openNotificationModal: true,
        isLoadingButton: false,
      });
    }
  }

  //Events
  handleFormChange = (event) => {
    let { id, value } = event.target;
    const { organizationToAdd } = { ...this.state };

    if (id === "url") {
      value = value.toLowerCase().trim();
    }

    organizationToAdd[id] = value;

    this.setState({
      organizationToAdd,
    });
  };

  handleFormSelect = (e, event) => {
    let option = e.target;
    const { value } = option;
    const { organizationToAdd } = { ...this.state };
    organizationToAdd[event] = value;

    this.setState({
      organizationToAdd,
    });

    switch (event) {
      case "countryId":
        this.setState({
          countryId: option.value,
          regionId: null,
          cityId: null,
        });
        this.getRegions(option.value);
        break;
      case "regionId":
        this.setState({
          regionId: option.value,
          cityId: null,
        });
        this.getCities(option.value);
        break;
    }
  };

  saveOrganizationEvent = () => {
    let { organizationToAdd } = { ...this.state };
    let isValidUrl = true;
    this.setState({
      isLoadingButton: true,
    });
    const {t} = this.props
    organizationToAdd.name = organizationToAdd.name.trim();
    organizationToAdd.description = organizationToAdd.description.trim();
    organizationToAdd.phone = organizationToAdd.phone.trim();
    organizationToAdd.address = organizationToAdd.address.trim();
    organizationToAdd.url = organizationToAdd.url.trim();

    if (organizationToAdd.name.length === 0) {
      organizationToAdd.name = "";
    }
    if (organizationToAdd.description.length === 0) {
      organizationToAdd.description = "";
    }
    if (organizationToAdd.phone.length === 0) {
      organizationToAdd.phone = "";
    }
    if (organizationToAdd.address.length === 0) {
      organizationToAdd.address = "";
    }
    if (organizationToAdd.url.length === 0) {
      organizationToAdd.url = "";
    } else {
      const regerUrl = /^[a-z]+$/;
      isValidUrl = regerUrl.test(organizationToAdd.url);
    }

    if (
      organizationToAdd.name === "" ||
      organizationToAdd.phone === "" ||
      organizationToAdd.address === "" ||
      organizationToAdd.url === "" ||
      // isValidUrl ||
      organizationToAdd.organizationTypeId === null ||
      organizationToAdd.lptypeId === null ||
      organizationToAdd.countryId === null ||
      organizationToAdd.regionId === null ||
      organizationToAdd.cityId === null
    ) {
      this.setState({
        titleNotificationModal: t('modal.wrongFile'),
        contenNotificationModal: t('modal.fieldEmpty'),
        typeNotificationModal: "error",
      });
      this.setState({
        isLoadingButton: false,
        openNotificationModal: true,
      });
    } else {
      if (organizationToAdd.description.length === 0) {
        organizationToAdd.description = null;
      }
      this.postOrganization(organizationToAdd);
    }
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  //Render
  render() {
    const {
      isLoadingButton,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Card className="card">
          <Backdrop
            style={{ color: "#510f8a", zIndex: "5" }}
            open={isLoadingButton}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="row componentSpace">
            <Breadcrumb
              principal={t("basicWords.createOrganization")}
              icon={<Home />}
              secondary={t("organization.organizations")}
              third={t("sections.home")}
            />
          </div>
          <div className="row" style={{ paddingLeft: "4.5%" }}>
            <Title title={t("organization.infoOrganization")} />
          </div>
          <div className="row" style={{ paddingLeft: "3%" }}>
            <OrganizationForm
              organization={this.state.organizationToAdd}
              organizationTypes={this.state.organizationTypes}
              countries={this.state.countries}
              regions={this.state.regions}
              cities={this.state.cities}
              lpTypes={this.state.lpTypes}
              onChange={this.handleFormChange}
              onSelect={this.handleFormSelect}
            />
          </div>
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={null}
          FlagclassName={true}
          onClick={(e) => this.saveOrganizationEvent(e)}
          path="./organizations"
        />
      </Fragment>
    );
  }
}

export default withTranslation()(AddOrganization);
