import React, { Component, Fragment } from "react";
// import CmsWelcomeLandscape from "../../assets/images/CmsWelcomeLandscape.jpg";
// import CmsWelcomePortrait from "../../assets/images/CmsWelcomePortrait.jpg";
import AuraHome from "../../assets/images/loginHubAura.png";
import UhooHome from "../../assets/images/loginHubUhoo.png";
import { Helmet } from "react-helmet";
import { Card } from "@material-ui/core";
class HomeComponent extends Component {
  constructor(props) {
    super(props);

    this.isAura = false;

    this.state = {
      // State to change image depending on screen orientation
      imageOrientation: "",
      helmetPage: "",
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({
        imageOrientation: AuraHome,
        helmetPage: "Somos Aura | CMS",
      });
    } else {
      this.setState({ imageOrientation: UhooHome, helmetPage: "Uhoo | CMS" });
    }
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    let imageOrientation;
    if (this.isAura === true) {
      if (window.innerHeight > window.innerWidth) {
        // imageOrientation = CmsWelcomePortrait;
      }
      if (window.innerWidth > window.innerHeight) {
        // imageOrientation = CmsWelcomeLandscape;
      }
    } else {
      if (process.env.REACT_APP_ISAURA === "true") {
        imageOrientation = AuraHome;
      } else {
        const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
        favicon.href =
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKaADAAQAAAABAAAAKQAAAADAIIRfAAAFK0lEQVRYCbVZW2icRRQ+ZzaxjWml1jRpo33QCgrxQZB6QYoNGgvWF7Fg+iAWEeODPgQxyfapTyZbQVEiGgQfFDGFpohFkQYhKqKlD0JVpK1oa2torlZTbEJ2/+M38++6t3/ObNPsQPLPzLnMN3POzJw5y7TCIiPt19Ol7KMUSReRbIWadhJuR/0mIp4jlkn04Y/Pk+Fx2tBwjHsm/13JcHw1QiIHDA0NPwWZbgzeRSJNNcszX8EExsE/SgMvHmI+ENUqWzNIeXXTY1CawUB31arcz8c/gdbP+2c+9/MUKUGQ8lrbrZTNvU9CO4tiq1RjmqCG1LP8ytTvmkYVpBxseYhyPAazws/qVBj+m5InuW/2K98IxkeQodYeytF4XQHawe0CYBw3ngdM4ko6gSh61yNTv25jXuCB6ZHKAapAyuDmnUTZY/DBxkrmureZlilFXZWmLzO32ySUO6wAzBLTKXLHyQogWxBWnngxUdouDPZAjKPIUQYy3sVJmwRK2fTRlnXrOT17J93fcQMZ2guws0VVSi2eVC81b4jlH+hYT8Y8DcB/VUlZH7WnSUn539zxOSifldCKVWOega98UOyIa3CN7XCN77DyqUpaWZu5m9Mzh8r60JCDrQ9SNvoa1fLFcoy8u3COOqK7SdxBXanGtvlkEkBHSV88ge+orXsL04kkgJaf+6a/xf8jHtlMHld+Bpl3cM35bhI+7lESd3OATgE68/fJ+oEnvoLzICVn72NPkTkPId/Nf6t0oX9UOqn6sXjwBRfN2GDBVxjurZUIHqmVkLxo8txl8RkXbmnRjIgO0miDAL0EJmnYP0mLC+GgieNBbSlCNNZDrtBKhtQjXsXudgGrn5UDx4vk/CthtYYsEXIX4LNHEKJprZiEM6yEnwPmDq1kyF2ADyw25FcKB3xSFJ+yakMg1Y0DeeCz5tZjxZDjh1YyKB+YJPBZU87YCStF392hlVAU10biOQvyzwBzg0oXs6TTeVmlRxK492XSIJIJgWxTB2E+qdKN+VGlM7WqdDyL7d66EGC6WaNz+uJZTHQskYf5DN2Y+iSRVujk0OnC5w2leKLAn/gVuhtBaHMirdC5tvk5xJsfF5ruy3QcZ+TjwYSARDvK5CobSCywu7vnl6eh0A+EzR5OTyevVolSGWy7DUdOB0W5U5SeOcOs71zJbG2n6MoF79Vpg+WNjS0mP1P9kS7SU4LFW+X01G88MHWU98+eDgF0SnJLPV6AjkHGLb74NmE67B05Zu7CC3KXznN1VHmjZQvyRS8HpFxAHYO8r+MIzPSLKhDJ2/L6LRtVnhqJLuJe5PdUFyOkYpAzsiodSO6cwCvQ9OpjyDZaWhyTkXsadb4aqJnhDADuDnD2F5JaZbeJDG46andkQPhLalq3h3vPXgrwVZHdBOfPDcMPn68ilnYgR4RXaWehKzZ3oUXUC7PrzwGih2nx8s+Y0L7CQ6ko7q9JZvO9NH/umzBA5IaQxCrVVLaSlgBlnZTLfoHqdaWMiXXmHzDom8RNn3L6j6o3tLx1+xq6vLCLONoHCz2RqKO005PBqAJpZWSwZS8+H+nHQ6l2inDr/Iqe04jNFqBhLWS3wSp3ANyaMk6tUWsuqKAD5uzHAEOFdl2/Lv1iXkpKVtlxE1fSEsSG/UOtC6j4byLLeK3lWvKTlGndUX+ANtNrtldm0SrnnRgr4g5+hATZtbqVfM48vcKcuQy1dGMbfAh8iRNYMW6XWVulXx+wYUZhZiXtEoDJSC4LT6/m7zj/AexV0r66K8xIAAAAAElFTkSuQmCC";
        imageOrientation = UhooHome;
      }
    }
    this.setState({
      imageOrientation,
      imageHeight: window.innerHeight - 64 - 70 - 15,
    });
  };

  render() {
    const { imageOrientation, helmetPage } = this.state;
    return (
      <>
        <Helmet>
          {/* <title> helmetPage </title> */}
          <meta
            name="description"
            content="Somos Aura - Conectamos la comunidad católica"
          />
          <meta
            name="image"
            content="https://somosaura.org/wp-content/uploads/2020/07/Aura-Verical-1-295x300.png"
          />
        </Helmet>
        <Card className="card">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              transform: "translateY(15%)",
            }}
          >
            <img
              id="welcome"
              src={imageOrientation}
              alt="welcome"
              style={{
                width: "calc(100vh - 100px)",
                maxWidth: "100%",
              }}
            />
          </div>
        </Card>
      </>
    );
  }
}

export default HomeComponent;
