import React, { Component, Fragment } from "react";

//Localization
import { withTranslation } from 'react-i18next';
import "../../components/css/FooterComponent.scss";

class FooterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {t} = this.props
    return (
      <Fragment>
        <br />
        <div className="containerFooterComponent">
          <div className="textComponentFooter">
            <a href="https://www.bcodesolutions.com/" target="_blank">
              {t('footer.develpoedByBCode')}
            </a>
          </div>
          <div className="textComponentFooter">
            {t('footer.policityPrivacity')}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation() (FooterComponent);
