import React from "react"
//Localization
import { useTranslation, withTranslation } from 'react-i18next';

 function LocalizeScheduleEventType(scheduleTypeName, t) {
  let scheduleTypeNameLocal = scheduleTypeName;
  switch (scheduleTypeName) {
    case "MASS":
      scheduleTypeNameLocal = t('scheduleEventType.mass');
      break;
    case "ROSARY":
      scheduleTypeNameLocal = t('scheduleEventType.rosary');
      break;
    case "CHAPLET OF THE DIVINE MERCY":
      scheduleTypeNameLocal = t('scheduleEventType.chapletOfTheDivineMercy');
      break;
    case "CONFERENCE":
      scheduleTypeNameLocal = t('scheduleEventType.conference');
      break;
    case "INTEGRATION":
      scheduleTypeNameLocal = t('scheduleEventType.integration');
      break;
    case "RETREAT":
      scheduleTypeNameLocal = t('scheduleEventType.retreat');
      break;
    case "OTHER":
      scheduleTypeNameLocal = t('scheduleEventType.other');
      break;
  }
  return scheduleTypeNameLocal;
}
export default (LocalizeScheduleEventType)