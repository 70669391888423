import React from "react";
import { ButtonComponent, GeneralModal } from "../../../components/index";

//Localization
import { useTranslation } from "react-i18next";

const GoToWithoutSaveModal = (props) => {
  var { visible, handleClose, path, propsComponent } = props;
  const { t } = useTranslation();
  return (
    <GeneralModal
      visible={visible}
      title={t("basicWords.beCareful")}
      body={[<p>{t("basicWords.GoToWithoutSave")}</p>]}
      footer={[
        <div className="row buttonAtTheBottom" key={1}>
          <div>
            <ButtonComponent
              onClick={(e) => handleClose(e)}
              shape="round"
              text={t("basicWords.no")}
              className="buttonDiscard"
            />
          </div>
          <div>
            <ButtonComponent
              isDisabled={false}
              text={t("basicWords.yes")}
              path={path ? path : "./myWebPage"}
              props={propsComponent}
              shape="round"
              // className="buttonDiscard"
            />
          </div>
        </div>,
      ]}
      onClick={(e) => props.handleClose(e)}
    />
  );
};

export default GoToWithoutSaveModal;
