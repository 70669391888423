import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/paymentplatform";

export async function getPaymentPlatformsByOrganizationId(organizationId) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: {
                organization_id: organizationId
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
            }
        })
        return response;
    } catch (error) {
        return error;
    }
}

export async function putPaymentPlatformParams(payPlatform) {
    try {
        const data = JSON.stringify(payPlatform)
        let response = await axios({
           method: 'PUT',
           url: host + port + route + datasource + resource,
           data,
           headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
           }
        })
        return response;
     } catch (error) {
        return error;
     }
}