import React, { Component, Fragment } from "react";

import "../../css/buttonsContainer.scss";

import { Button } from "@material-ui/core";

//Components
import AddButtonModal from "./AddButtonModal.jsx";
import {
  NotificationModal,
} from "../../../../../components/index";

//Localization
import { withTranslation } from "react-i18next";
import { LocalizeSection } from "../../../../../reactUtils/systemVariables/languageLocalization/SectionsLocalization.jsx";

//Queries
import { getLandingpageStrucureById } from "../../../../../actions/index";
import { Edit, Trash2, MapPin } from "react-feather";

const linkTrimVal = 45;

const sectionsAvailableForLinkButton = [
  "NEWS",
  "EVENTS",
  "COURSES",
  "DONATIONS",
];

class ButtonsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonList: [],

      //Modal notification
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    this.getLpSections(this.props.lpId);
  }

  static getDerivedStateFromProps(props) {
    const { t } = props;
    if (
      !props.isModalVisible ||
      !props.buttonList ||
      props.buttonList.length === 0
    ) {
      return { buttonList: [] };
    }

    if (props.buttonList) {
      //Map button list to required object list
      let buttonList = props.buttonList.map((b) => {
        let section = null;
        let component = null;
        if (b.internalLpSectionId) {
          section = {
            name: LocalizeSection(b.internalLpSectionName, t),
            id: b.internalLpSectionId,
          };
          component = {
            name: b.internalLpSectionComponentName,
            id: b.internalLpSectionComponentId,
          };
        }
        let button = {
          id: b.id,
          name: b.name,
          externalLink: b.externalLink,
          isEnabled: b.isEnabled,
          section: b.section ? b.section : section,
          component: b.component ? b.component : component,
          isPinned: b.isPinned,
        };
        return button;
      });
      return { buttonList };
    }
    return null;
  }

  async getLpSections(lpId) {
    const { t } = this.props;
    let response = await getLandingpageStrucureById(lpId);
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({ openNotificationModal: true });
    } else {
      let sections = response.data.data.lpsections;
      sections = sections.filter((s) => s.positionInMainPage !== null || s.positionsInSubpage.length !== 0 ||  s.subpages.length !== 0)

      //Filter only sections that can be linked and map to value/label
      sections = sections
        .filter((s) =>
          sectionsAvailableForLinkButton.some((sa) => s.name === sa)
        )
        .map((s) => ({ value: s.id, label: LocalizeSection(s.customName? s.customName : s.name, t) }));
      this.setState({ sections });
    }
  }

  onPinButton = (buttonName) => {
    let { buttonList } = this.state;
    let button = buttonList.filter((b) => b.name === buttonName)[0];
    if (button.isPinned === 1) {
      button.isPinned = 0;
    } else {
      button.isPinned = 1;
    }

    if (button.id) {
      button.pinnedChanged = 1;
    }

    this.setState({ button, buttonList });
    this.props.handleButtonListChange(buttonList);
  };

  onButtonDelete = (buttonName) => {
    let { buttonList } = this.state;

    //Remove non existing buttons
    buttonList = buttonList.filter((b) => b.name !== buttonName || b.id);

    //Disable existing buttons
    buttonList.forEach((b) => {
      if (b.name === buttonName && b.id) {
        b.isEnabled = 0;
      }
    });
    this.setState({ buttonList });
    this.props.handleButtonListChange(buttonList);
  };

  onButtonEdit = (buttonName) => {
    let { buttonList } = this.state;
    let button = buttonList.filter((b) => b.name === buttonName)[0];
    this.setState({ button, isModalVisible: true, buttonList });
  };

  renderButtons = (buttonList) => {
    const { linkTreeFlag } = this.props;
    if (buttonList && buttonList.length > 0) {
      let buttonsRender = [];

      buttonList.forEach((button) => {
        if (button.isEnabled === 1) {
          let buttonRender;
          if (
            button.externalLink &&
            button.externalLink != null &&
            button.externalLink.length > 0
          ) {
            let linkVal =
              button.externalLink.length > linkTrimVal
                ? button.externalLink.substring(0, linkTrimVal) + "..."
                : button.externalLink;
            buttonRender = (
              <div style={{ paddingRight: "10px" }}>
                <Button
                  className="buttonComponent"
                  size={"large"}
                  type="primary"
                  disabled={this.props.disabled}
                >
                  {button.name}
                </Button>
                <div className="editButtonIcons">
                  {linkTreeFlag ? (
                    <MapPin
                      key={1}
                      className={
                        button.isPinned
                          ? "buttonPinnedButtons"
                          : "iconBottomButtom"
                      }
                      onClick={() => this.onPinButton(button.name)}
                    />
                  ) : (
                    ""
                  )}
                  {linkVal !== "PAYMENT_PLATFORM" ? (
                    <Trash2
                      key={3}
                      className="iconBottomButtom"
                      onClick={() => this.onButtonDelete(button.name)}
                    />
                  ) : (
                    ""
                  )}
                  <Edit
                    key={2}
                    className="iconBottomButtom"
                    onClick={() => this.onButtonEdit(button.name)}
                  />
                </div>
              </div>
            );
          } else {
            if (button.component) {
              buttonRender = (
                <div style={{ paddingRight: "10px" }}>
                  <Button
                    className="buttonComponent"
                    size={"large"}
                    type="primary"
                    disabled={this.props.disabled}
                  >
                    {button.name}
                  </Button>
                  <div className="editButtonIcons">
                    {linkTreeFlag ? (
                      <MapPin
                        key={1}
                        className={
                          button.isPinned
                            ? "buttonPinnedButtons"
                            : "iconBottomButtom"
                        }
                        onClick={() => this.onPinButton(button.name)}
                      />
                    ) : (
                      ""
                    )}
                    <Trash2
                      key={3}
                      className="iconBottomButtom"
                      onClick={() => this.onButtonDelete(button.name)}
                    />
                    <Edit
                      key={2}
                      className="iconBottomButtom"
                      onClick={() => this.onButtonEdit(button.name)}
                    />
                  </div>
                </div>
              );
            }
          }
          buttonsRender.push(buttonRender);
        }
      });
      if (buttonsRender.length > 0) {
        return buttonsRender;
      }
    }
  };

  onAddButton = () => {
    this.setState({ isModalVisible: true, button: null });
  };

  onAddButtonModalCancel = () => {
    this.setState({ isModalVisible: false });
  };

  onAddButtonModalOk = (newButton) => {
    const { button } = this.state;
    let buttonList = this.state.buttonList;

    //Update button (Delete old buton)
    if (button) {
      // buttonList = buttonList.filter((b) => b.id !== button.id);
      buttonList.forEach((element, index) => {
        if (
          (element.externalLink === newButton.externalLink &&
            newButton.externalLink !== undefined) ||
          element.name === newButton.name ||
          (element.component === newButton.component &&
            element.section === newButton.section)
        ) {
          buttonList[index].externalLink = newButton.externalLink;
          buttonList[index].isEnabled = newButton.isEnabled;
          buttonList[index].isPinned = newButton.isPinned;
          buttonList[index].name = newButton.name;
          buttonList[index].component = newButton.component;
          buttonList[index].section = newButton.section;
        }
      });
    } else {
      //Add button
      buttonList.push(newButton);
    }

    this.setState({
      isModalVisible: false,
      buttonList,
    });
    this.forceUpdate();
    this.props.handleButtonListChange(buttonList);
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  render() {
    const {
      buttonList,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;

    return (
      <Fragment>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <AddButtonModal
          button={this.state.button}
          isModalVisible={this.state.isModalVisible}
          onModalCancel={this.onAddButtonModalCancel}
          onModalOk={this.onAddButtonModalOk}
          sections={this.state.sections}
        />
        <div className="buttonsContainer">
          {this.renderButtons(buttonList)}
          <div className="bgAddButton">
            <Button
              type="primary"
              shape="circle"
              className="addButton"
              onClick={() => this.onAddButton()}
              disabled={this.props.disabled}
            >
              +
            </Button>
            {/* </Tooltip> */}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withTranslation()(ButtonsContainer);
