import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/payment";

export async function getPaymentByOrganizationId(organizationId, payPlatformId, payStatusId, page, size, attributeName, order) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: {
                organization_id: organizationId,
                payplatform_id: payPlatformId,
                paystatus_id: payStatusId,
                page: page,
                size: size,
                attribute_name: attributeName,
                order: order
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
            }
        })
        return response;
    } catch (error) {
        return error;
    }
}