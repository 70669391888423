import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/organizations";

export async function getOrganizationsByOrganizationTypeId(organizationTypeId) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            organizationtype_id: organizationTypeId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function getOrganizationsPaged(regionId, cityId, organizationTypeId, name, page, size, attribute, order) {
   try {
      let response = await axios.get(

         host + port + route + datasource + resource, {
         params: {
            region_id: regionId,
            city_id: cityId,
            organization_type_id: organizationTypeId,
            name: name,
            page: page,
            size: size,
            attribute: attribute,
            order: order
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function getOrganizationById(organizationId) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            organization_id: organizationId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function insertOrganization(organizationToAdd) {
   try {
      const data = JSON.stringify(organizationToAdd)
      let response = await axios({
         method: 'post',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function updateOrganization(organizationToEdit) {
   try {
      const data = JSON.stringify(organizationToEdit)
      let response = await axios({
         method: 'PUT',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function disableOrganization(organizationId) {
   try {
      let response = await axios({
         method: 'DELETE',
         url: host + port + route + datasource + resource,
         params: {
            organization_id: organizationId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}