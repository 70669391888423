import React, { Component } from "react";
import {
  TextField,
  OutlinedInput,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Button,
} from "@material-ui/core";
import { ButtonComponent } from "../../components/Button";
import { IconButton, InputAdornment } from "@material-ui/core";
// Components
import AlertMessage from "../../components/Alert";
import { loginURL } from "../../reactUtils/systemVariables/CmsServerInformation";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// CSS
import "./css/SignIn.scss";

// Queries
import { loginValidateUser } from "../../actions/Login.actions";

// Images
import AuraLogin from "../../assets/images/loginLogo.png";
import UhooLogin from "../../assets/images/LogoUhooColor.png";

//Localized
import { withTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: {
        username: "",
        password: "",
      },
      isLoadingButton: false,
      showAlert: false,
      checked: false,
      showPassword: false,
      imageLogin: "",
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({ imageLogin: AuraLogin, helmetPage: "Somos Aura | CMS" });
    } else {
      this.setState({ imageLogin: UhooLogin, helmetPage: "Uhoo | CMS" });
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKaADAAQAAAABAAAAKQAAAADAIIRfAAAFK0lEQVRYCbVZW2icRRQ+ZzaxjWml1jRpo33QCgrxQZB6QYoNGgvWF7Fg+iAWEeODPgQxyfapTyZbQVEiGgQfFDGFpohFkQYhKqKlD0JVpK1oa2torlZTbEJ2/+M38++6t3/ObNPsQPLPzLnMN3POzJw5y7TCIiPt19Ol7KMUSReRbIWadhJuR/0mIp4jlkn04Y/Pk+Fx2tBwjHsm/13JcHw1QiIHDA0NPwWZbgzeRSJNNcszX8EExsE/SgMvHmI+ENUqWzNIeXXTY1CawUB31arcz8c/gdbP+2c+9/MUKUGQ8lrbrZTNvU9CO4tiq1RjmqCG1LP8ytTvmkYVpBxseYhyPAazws/qVBj+m5InuW/2K98IxkeQodYeytF4XQHawe0CYBw3ngdM4ko6gSh61yNTv25jXuCB6ZHKAapAyuDmnUTZY/DBxkrmureZlilFXZWmLzO32ySUO6wAzBLTKXLHyQogWxBWnngxUdouDPZAjKPIUQYy3sVJmwRK2fTRlnXrOT17J93fcQMZ2guws0VVSi2eVC81b4jlH+hYT8Y8DcB/VUlZH7WnSUn539zxOSifldCKVWOega98UOyIa3CN7XCN77DyqUpaWZu5m9Mzh8r60JCDrQ9SNvoa1fLFcoy8u3COOqK7SdxBXanGtvlkEkBHSV88ge+orXsL04kkgJaf+6a/xf8jHtlMHld+Bpl3cM35bhI+7lESd3OATgE68/fJ+oEnvoLzICVn72NPkTkPId/Nf6t0oX9UOqn6sXjwBRfN2GDBVxjurZUIHqmVkLxo8txl8RkXbmnRjIgO0miDAL0EJmnYP0mLC+GgieNBbSlCNNZDrtBKhtQjXsXudgGrn5UDx4vk/CthtYYsEXIX4LNHEKJprZiEM6yEnwPmDq1kyF2ADyw25FcKB3xSFJ+yakMg1Y0DeeCz5tZjxZDjh1YyKB+YJPBZU87YCStF392hlVAU10biOQvyzwBzg0oXs6TTeVmlRxK492XSIJIJgWxTB2E+qdKN+VGlM7WqdDyL7d66EGC6WaNz+uJZTHQskYf5DN2Y+iSRVujk0OnC5w2leKLAn/gVuhtBaHMirdC5tvk5xJsfF5ruy3QcZ+TjwYSARDvK5CobSCywu7vnl6eh0A+EzR5OTyevVolSGWy7DUdOB0W5U5SeOcOs71zJbG2n6MoF79Vpg+WNjS0mP1P9kS7SU4LFW+X01G88MHWU98+eDgF0SnJLPV6AjkHGLb74NmE67B05Zu7CC3KXznN1VHmjZQvyRS8HpFxAHYO8r+MIzPSLKhDJ2/L6LRtVnhqJLuJe5PdUFyOkYpAzsiodSO6cwCvQ9OpjyDZaWhyTkXsadb4aqJnhDADuDnD2F5JaZbeJDG46andkQPhLalq3h3vPXgrwVZHdBOfPDcMPn68ilnYgR4RXaWehKzZ3oUXUC7PrzwGih2nx8s+Y0L7CQ6ko7q9JZvO9NH/umzBA5IaQxCrVVLaSlgBlnZTLfoHqdaWMiXXmHzDom8RNn3L6j6o3tLx1+xq6vLCLONoHCz2RqKO005PBqAJpZWSwZS8+H+nHQ6l2inDr/Iqe04jNFqBhLWS3wSp3ANyaMk6tUWsuqKAD5uzHAEOFdl2/Lv1iXkpKVtlxE1fSEsSG/UOtC6j4byLLeK3lWvKTlGndUX+ANtNrtldm0SrnnRgr4g5+hATZtbqVfM48vcKcuQy1dGMbfAh8iRNYMW6XWVulXx+wYUZhZiXtEoDJSC4LT6/m7zj/AexV0r66K8xIAAAAAElFTkSuQmCC";
    }
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  // Queries
  async loginFunction() {
    const { credentials } = this.state;
    const response = await loginValidateUser(credentials);
    return response;
  }

  onFinish = () => {
    this.setState({
      isLoadingButton: true,
    });
    this.loginFunction()
      .then((response) => {
        if (String(response).includes("Error:")) {
          this.setState({
            showAlert: true,
          });
        } else {
          window.location.href = loginURL;
        }
        this.setState({
          isLoadingButton: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingButton: false,
        });
      });
  };

  handleFormChange = (event, id) => {
    let { value } = event.target;
    const { credentials } = { ...this.state };

    if (value === "" || value.ength === 0) {
      value = null;
    }

    credentials[id] = value;

    this.setState({
      credentials,
      showAlert: false,
    });
  };

  handleChangeChecked = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };

  handleClickShowPassword = (event) => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  updateWindowDimensions = () => {
    const containerLogin100 = window.innerHeight;
    let height = 160;
    let margin = "0px 0px 24px 0px";
    if (window.innerWidth > window.innerHeight && window.innerWidth < 850) {
      height = 80;
      margin = "0px 0px 12px 0px";
    }
    this.setState({
      containerLogin100,
      height,
      margin,
    });
  };

  render() {
    const {
      showAlert,
      isLoadingButton,
      showPassword,
      credentials,
      imageLogin,
      helmetPage,
    } = this.state;
    const { t } = this.props;
    return (
      <div className="container-fluid">
        <Helmet>
          {/* <title> helmetPage </title> */}
          <meta
            name="description"
            content="Somos Aura - Conectamos la comunidad católica"
          />
          <meta
            name="image"
            content="https://somosaura.org/wp-content/uploads/2020/07/Aura-Verical-1-295x300.png"
          />
        </Helmet>
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-xs-12 container-login100">
            <div className="wrap-login100">
              <div>
                {showAlert && (
                  <>
                    <AlertMessage
                      // message="Login error"
                      description={t("login.invalidPasswordOrUser")}
                      type="error"
                    />
                    <br />
                  </>
                )}
              </div>
              <div>
                <FormControl
                  id="login"
                  className="login100-form"
                  initialValues={{ remember: true }}
                  onFinish={this.onFinish}
                >
                  <div className="centerHorizontalItem">
                    <img src={imageLogin} alt="logo" className="logo100-form" />
                  </div>
                  <TextField
                    style={{ width: "100% !important" }}
                    id="username"
                    className="login-input"
                    variant="outlined"
                    label={t("login.enterUser")}
                    onChange={(event) =>
                      this.handleFormChange(event, "username")
                    }
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  // style={{ width: "81% !important" }}
                  sx={{ m: 1, width: "25ch" }}
                >
                  <InputLabel htmlFor="passwordOut">
                    {t("login.password")}
                  </InputLabel>
                  <OutlinedInput
                    id="passwordOut"
                    type={showPassword ? "text" : "password"}
                    value={credentials.password}
                    onChange={(event) =>
                      this.handleFormChange(event, "password")
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>

                <div
                  className="rowForgotPassword"
                  style={{ width: "81% !important" }}
                >
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.checked}
                        onChange={(event) => this.handleChangeChecked(event)}
                        name="Recordarme"
                      />
                    }
                    label={<div className="login-rememberMe">Recordarme</div>}
                  /> */}

                  <a className="login-form-forgot" href="/forgotpass">
                    {t("login.forgotMyPassword")}
                  </a>
                </div>
                <div style={{ paddingBottom: "10px" }}>
                  {/* <ButtonComponent
                    text={t("login.logIn")}
                    isLoading={isLoadingButton} */}
                  <Button
                    classes={{ label: "buttonLogin", root: "button" }}
                    loading={isLoadingButton}
                    onClick={this.onFinish}
                    size={"large"}
                    type="primary"
                  >
                    {t("login.logIn")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SignIn);
