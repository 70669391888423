import React from "react";

import { GeneralInput, SelectSingle } from "../../../components/index";

//Localization
import { useTranslation } from "react-i18next";

import "../../../assets/styles/forms.scss";

const OrganizationForm = (props) => {
  let display = "flex";
  const { t, i18n } = useTranslation();
  return (
    <>
      <div
        className="col-12"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="row containerField" style={{ display: display }}>
          <div className="fieldLeft">
            <GeneralInput
              id="name"
              value={props.organization.name}
              placeholder={t("basicWords.name") + "*"}
              isReq
              onChange={(e) => props.onChange(e)}
              maxLength={200}
            />
          </div>
          <div className="fieldRigth">
            <SelectSingle
              selectedOptions={props.organization.organizationTypeId}
              text={t("basicWords.organizationType") + "*"}
              onChange={(option) =>
                props.onSelect(option, "organizationTypeId")
              }
              options={props.organizationTypes}
            />
          </div>
        </div>
        <div className="row containerField" style={{ display: display }}>
          <div className="fieldLeft">
            <GeneralInput
              id="description"
              value={props.organization.description}
              placeholder={t("basicWords.description") + "*"}
              isReq={false}
              onChange={(e) => props.onChange(e)}
              maxLength={200}
            />
          </div>
          <div className="fieldRigth">
            <SelectSingle
              selectedOptions={props.organization.lptypeId}
              text={t("organization.landingPageType") + "*"}
              onChange={(option) => props.onSelect(option, "lptypeId")}
              options={props.lpTypes}
            />
          </div>
        </div>
        <div className="row containerField" style={{ display: display }}>
          <div
            className="fieldLeft"
            style={{ width: "40%", paddingRight: "4%", alignSelf: "center" }}
          >
            <GeneralInput
              id="phone"
              value={props.organization.phone ? props.organization.phone : ""}
              placeholder={t("basicWords.telephone") + "*"}
              isReq
              onChange={(e) => props.onChange(e)}
              maxLength={45}
            />
          </div>
          <div
            className="fieldRigth"
            style={{ width: "40%", alignSelf: "center" }}
          >
            <GeneralInput
              id="address"
              value={props.organization.address}
              placeholder={t("basicWords.address") + "*"}
              isReq
              onChange={(e) => props.onChange(e)}
              maxLength={200}
            />
          </div>
        </div>
        <div className="row containerField" style={{ display: display }}>
          <div className="fieldLeft">
            <div
              className="fieldLeft"
              style={{
                display: display,
                width: "100%",
                paddingRight: "5%",
                alignSelf: "center",
                paddingLeft: "0%",
              }}
            >
              <div
                style={{
                  width: "47%",
                  paddingRight: "5%",
                  alignSelf: "center",
                }}
              >
                <SelectSingle
                  selectedOptions={props.organization.countryId}
                  text={t("basicWords.country") + "*"}
                  onChange={(option) => props.onSelect(option, "countryId")}
                  options={props.countries}
                />
              </div>
              <div style={{ width: "48%", alignSelf: "center" }}>
                <SelectSingle
                  selectedOptions={props.organization.regionId}
                  text={t("basicWords.region") + "*"}
                  onChange={(option) => props.onSelect(option, "regionId")}
                  options={props.regions}
                />
              </div>
            </div>
            <div
              className="row"
              style={{
                width: "100%",
                alignSelf: "center",
                marginBottom: "7%",
                marginTop: "7%",
              }}
            >
              <SelectSingle
                selectedOptions={props.organization.cityId}
                text={t("basicWords.city") + "*"}
                onChange={(option) => props.onSelect(option, "cityId")}
                options={props.cities}
              />
            </div>
          </div>
          <div className="fieldRigth">
            {!props.isEdit ? (
              <div>
                <div className="row textBold">{t("basicWords.publicUrl")}</div>
                <br />
                <div
                  className="row textUrl"
                  style={{
                    display: display,
                    alignItems: "center",
                    marginBottom: "3%",
                  }}
                >
                  <div style={{ paddingRight: "2%" }}>www.</div>
                  <div>
                    <GeneralInput
                      id={"url"}
                      value={props.organization.url}
                      placeholder={t("generalMessages.url") + "*"}
                      isReq
                      onChange={(e) => props.onChange(e)}
                      maxLength={100}
                    />
                  </div>
                  <div>.somosaura.org*</div>
                </div>
                <div
                  className="row"
                  style={{ display: display, alignItems: "center" }}
                >
                  <div className="textMiniBold" style={{ paddingRight: "2%" }}>
                    {t("basicWords.exam")}
                    {". "}
                  </div>
                  <div className="miniText"> www.</div>
                  <div className="urlExample">parroquialaestrellamanizales</div>
                  <div className="miniText">.somosaura.org</div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationForm;
