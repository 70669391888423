import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";

// Queries
import {
  getOrganizationById,
  getUserById,
  getCountries,
  getRegionsByCountryId,
  getCitiesByRegionId,
  getRolesByOrganizationId,
  putUser,
} from "../../actions/index";

import { Card, CircularProgress, Backdrop } from "@material-ui/core";

// Components
import {
  Title,
  ButtonsFooterComponent,
  Breadcrumb,
  NotificationModal,
} from "../../components/index";

import UserForm from "./components/UserForm.jsx";

import { Users } from "react-feather";

//Localization
import { withTranslation } from 'react-i18next';

const tableSize = 64 + 68 + 15 + 20 + 68 + 115 + 64;
// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      organizationName: null,
      userToEdit: {
        organizationId: null,
        userId: null,
        name: "",
        lastName1: "",
        lastName2: "",
        documentIdNumber: "",
        countryId: null,
        regionId: null,
        cityId: null,
        email: "",
        phone: "",
        username: "",
        dateOfBirth: null,
        roleIds: [],
      },

      countries: [],
      regions: [],
      cities: [],
      roles: [],

      loadingInfo: true,
      isLoadingButton: false,
      redirectBack: false,
      previousPath: null,
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { location } = this.props;
    const { props } = location;
    if (props) {
      const organizationName = location.props
        ? location.props.organizationName
        : null;

      const organizationId = location
        ? location.props
          ? location.props.organizationId
          : null
        : null;

      if (organizationName == null) {
        this.getOrganizationByIdFunction(organizationId);
      }

      this.getUserById(props.userId);
      this.getCountries();
      this.getRegions(props.countryId);
      this.getCities(props.regionId);
      this.getRolesByOrganizationIdFunction(props.organizationId);

      this.setState({
        organizationName,
        previousPath: props.previousPath,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getOrganizationByIdFunction(id) {
    const response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        this.setState({
          organizationName: response.data.data.name,
        });
      }
    }
  }

  async getUserById(id) {
    const { location } = this.props;
    let userToEdit = {};
    const response = await getUserById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        userToEdit = {
          organizationId: location.props ? location.props.organizationId : null,
          userId: response.data.data.id,
          name: response.data.data.name,
          lastName1: response.data.data.lastName1,
          lastName2: response.data.data.lastName2
            ? response.data.data.lastName2
            : "",
          documentIdNumber: response.data.data.identityCardNumber,
          countryId: response.data.data.countryId,
          regionId: response.data.data.regionId,
          cityId: response.data.data.cityId,
          email: response.data.data.email,
          phone: response.data.data.phone ? response.data.data.phone : "",
          username: response.data.data.username,
          dateOfBirth: response.data.data.dateOfBirth,
          roleIds: response.data.data.roles,
        };
      }
      this.setState({
        userToEdit,
        loadingInfo: false,
      });
    }
  }

  async getCountries() {
    let countries = [];
    const response = await getCountries();

    if (String(response).includes("Error:")) {
    } else {
      countries = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ countries });
  }

  async getRegions(countryId) {
    let regions = [];
    const response = await getRegionsByCountryId(countryId);

    if (String(response).includes("Error:")) {
    } else {
      regions = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ regions });
  }

  async getCities(regionId) {
    let cities = [];
    const response = await getCitiesByRegionId(regionId);

    if (String(response).includes("Error:")) {
    } else {
      cities = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ cities });
  }

  async getRolesByOrganizationIdFunction(organizationId) {
    let roles = [];
    const response = await getRolesByOrganizationId(organizationId);

    if (String(response).includes("Error:")) {
    } else {
      roles = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ roles });
  }

  async updateUserFunction(userToEdit) {
    userToEdit.dateOfBirth = moment(userToEdit.dateOfBirth);
    const response = await putUser(userToEdit);
    const {t} = this.props

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal:
          t('modal.internalServerErrorContactSupport'),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.editionSuccess'),
        contenNotificationModal: t('modal.userSuccesfullyEdited'),
        typeNotificationModal: "success",
      });
    }
    this.setState({
      isLoadingButton: false,
      openNotificationModal: true,
    });
  }

  // Events
  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { userToEdit } = { ...this.state };
    userToEdit[id] = value;

    this.setState({
      userToEdit,
    });
  };

  handleFormSelect = (option, event) => {
    const { value } = option;
    const { userToEdit } = { ...this.state };

    userToEdit[event.name] = value;

    this.setState({
      userToEdit,
    });

    switch (event.name) {
      case "countryId":
        this.setState({
          countryId: option.value,
          regionId: null,
          cityId: null,
        });
        this.getRegions(option.value);
        break;
      case "regionId":
        this.setState({
          regionId: option.value,
          cityId: null,
        });
        this.getCities(option.value);
        break;
      default:
        break;
    }
  };

  handleFormSelectMulti = (options, source, name) => {
    const { userToEdit } = { ...this.state };

    userToEdit[name] = options.map((option) => {
      return parseInt(option, 10);
    });

    this.setState({
      userToEdit,
    });
  };

  handleOnDateChange = (date, dateString) => {
    const { userToEdit } = { ...this.state };

    userToEdit.dateOfBirth = dateString;

    this.setState({
      userToEdit,
    });
  };

  saveUserEvent = () => {
    const { userToEdit } = { ...this.state };
    this.setState({
      isLoadingButton: true,
    });
    const {t} = this.props

    userToEdit.name = userToEdit.name.trim();
    userToEdit.lastName1 = userToEdit.lastName1.trim();
    userToEdit.lastName2 = userToEdit.lastName2.trim();
    userToEdit.documentIdNumber = userToEdit.documentIdNumber.trim();
    userToEdit.email = userToEdit.email.trim();
    userToEdit.phone = userToEdit.phone.trim();
    userToEdit.username = userToEdit.username.trim();

    // Build error message
    let errorMessage = "";
    if (userToEdit.name.length === 0 || userToEdit.name === "") {
      userToEdit.name = "";
      errorMessage = `${t('basicWords.name')},`;
    } else if (
      userToEdit.lastName1.length === 0 ||
      userToEdit.lastName1 === ""
    ) {
      userToEdit.lastName1 = "";
      errorMessage = `${t('generalUser.lastName1')}, ${errorMessage}`;
    } else if (
      userToEdit.documentIdNumber.length === 0 ||
      userToEdit.documentIdNumber === ""
    ) {
      userToEdit.documentIdNumber = "";
      errorMessage = `${t('generalUser.documentIdNumber')}, ${errorMessage}`;
    } else if (userToEdit.phone.length === 0 || userToEdit.phone === "") {
      userToEdit.phone = "";
      errorMessage = `${t('basicWords.telephone')}, ${errorMessage}`;
    } else if (userToEdit.username.length === 0 || userToEdit.username === "") {
      userToEdit.username = "";
      errorMessage = `${t('generalUser.username')}, ${errorMessage}`;
    } else if (userToEdit.roleIds.length === 0) {
      errorMessage = `${t('generalUser.userRole')}, ${errorMessage}`;
    }

    const regexEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regexEmail.test(userToEdit.email)) {
      userToEdit.email = "";
      errorMessage = `${t('generalUser.userEmail')}, ${errorMessage}`;
    }

    if (userToEdit.lastName2.length === 0) {
      userToEdit.lastName2 = "";
    }

    if (errorMessage) {
      errorMessage = errorMessage.substring(
        0,
        errorMessage.length - 2
      );
      this.setState({
        titleNotificationModal: t('modal.fieldEmpty'),
        contenNotificationModal: ` ${t('streaming.modalErrorBody')}: ${errorMessage} `,
        typeNotificationModal: "error",
      });
      this.setState({
        isLoadingButton: false,
        openNotificationModal: true,
      });
    } else {
      this.updateUserFunction(userToEdit);
    }
  };

  warning = () => {
    const {t} = this.props
    this.setState({
      titleNotificationModal: t('modal.organizationNotSelected'),
      contenNotificationModal: t('modal.noOrganizationSelected'),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  // Render
  render() {
    const {
      redirectBack,
      organizationName,
      userToEdit,
      loadingInfo,
      height,
      countries,
      cities,
      regions,
      roles,
      previousPath,
      isLoadingButton,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const {t} = this.props

    if (redirectBack) {
      return <Redirect to="/users" />;
    }
    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Card className="card">
          <div className="row componentSpace">
            <Breadcrumb
              principal={t('generalUser.myUsers')}
              icon={<Users />}
              secondary={t('generalUser.myUsers')}
              third={t('sections.home')}
            />
          </div>
          <div className="row componentSpace" style={{ paddingLeft: "5%" }}>
            <Title title={t('basicWords.editUser')} />
          </div>

          <div
            style={{
              minHeight: height,
              padding: "0px",
              paddingLeft: "3%",
              margin: "0px",
            }}
          >
            <Backdrop
              style={{ color: "#510f8a", zIndex: "5" }}
              open={isLoadingButton}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <UserForm
              user={userToEdit}
              countries={countries}
              regions={regions}
              cities={cities}
              roles={roles}
              organizationName={organizationName}
              onChange={this.handleFormChange}
              onSelect={this.handleFormSelect}
              onMultiSelect={this.handleFormSelectMulti}
              onDateChange={this.handleOnDateChange}
            />
          </div>
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          isLoading={isLoadingButton}
          propsComponent={null}
          FlagclassName={true}
          onClick={(e) => this.saveUserEvent(e)}
          path={previousPath}
        />
      </>
    );
  }
}
export default withTranslation()(EditUser)