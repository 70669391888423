import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import "../css/sections.scss";

import SelectLayoutModal from "../../modalComponents/SelectLayoutModal.jsx";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Queries
import {
  getSectionInformationBetweenDates,
  updateLpSectionComponentNode,
  insertSectionComponent,
  disableLpSectionComponent,
  updateSectionComponent,
  getFile,
  updateLpSectionComponentNodeImage,
  getSectionLayoutStyle,
  updateLpSectionLayout,
  updateLpSection,
} from "../../../../actions/index";

import { Trash2, Plus, Edit2, X } from "react-feather";

// Components
import {
  Title,
  ButtonComponent,
  BreadcrumbSection,
  GeneralInput,
  NotificationModal,
  DeleteItemModal,
  GeneralModal,
  AccordionComponent,
  DateTimePickerComponent,
  ButtonsFooterComponent,
} from "../../../../components/index";

import ButtonsContainer from "../general/buttons/ButtonsContainer.jsx";

import { Card, CircularProgress, TextField, Tooltip } from "@material-ui/core";

import { backendDateFormat } from "../../../../reactUtils/systemVariables/generalVariables";
import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Localization
import { withTranslation } from "react-i18next";
import editorConfiguration from "../../../../components/textEditorConfig";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class CoursesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      lpSectionId: null,
      sectionName: null,
      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,

      startDate: moment().startOf("month"),
      endDate: moment().startOf("month").add(1, "months"),
      pageIdx: 1,
      pageSize: 4,
      totalElements: 0,

      loadingSection: true,
      loadingInfo: true,

      isAddModalVisible: false,
      isEditModalVisible: false,

      courseContainerTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },

      course: {
        id: null,
        title: "",
        text: "",
        date: "",
        dateToShow: "",
        place: "",
        responsible: "",
        image: null,
        imageLoading: false,
      },
      coursesToShow: {
        id: null,
        title: "",
        text: "",
        date: moment(),
        dateToShow: moment(),
        author: "",
        image: null,
        imageUri: null,
        buttons: [],
      },
      newCourseImage: {
        id: null,
        s3path: null,
        imageUrl: null,
        image: null,
        loading: false,
        modified: false,
      },
      lpLayoutImg: null,
      FlagclassName: false,

      parentLPSectionComponentId: null,
      courseToAdd: [],
      isLoadingButton: false,
      goToWithoutSaveModalVisible: false,

      //Modal notification
      haveChangeInLayout: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",

      //Modal general
      openDeleteImage: false,
      openDeleteNews: false,
      elementToDeleteId: null,

      //LayoutSelector
      newLayoutId: null,
      oldLayoutId: null,

      isReadyToEdit: false,
      deleteFlag: false,

      //Name modal
      openModalToChangeName: false,
      customName: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { props } = this.props.location;
    const { startDate, endDate } = this.state;
    if (props) {
      this.getCoursesInformation(
        props.landingPageId,
        props.sectionId,
        startDate,
        endDate
      );

      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getCoursesInformation(lpId, lpSectionId, startDate, endDate) {
    const { pageIdx, pageSize } = this.state;
    const response = await getSectionInformationBetweenDates(
      lpId,
      lpSectionId,
      startDate.format(),
      endDate.format()
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({ openNotificationModal: true });
    } else {
      const sectionInfo = response.data.data;
      // Get all the information about the section components
      const courseContainerComponent = sectionInfo.components.find(
        (comp) => comp.name === "CoursesContainerComponent"
      );
      const courseContainerTitle = {
        id: courseContainerComponent.nodes.find(
          (node) => node.nodeType === "CourseContainerTitle"
        ).id,
        value: courseContainerComponent.nodes.find(
          (node) => node.nodeType === "CourseContainerTitle"
        ).value,
        nodeTypeName: courseContainerComponent.nodes.find(
          (node) => node.nodeType === "CourseContainerTitle"
        ).nodeType,
        minLength: courseContainerComponent.nodes.find(
          (node) => node.nodeType === "CourseContainerTitle"
        ).minLength,
        maxLength: courseContainerComponent.nodes.find(
          (node) => node.nodeType === "CourseContainerTitle"
        ).maxLength,
        length: courseContainerComponent.nodes.find(
          (node) => node.nodeType === "CourseContainerTitle"
        ).value.length,
      };
      let courses = sectionInfo.components.filter(
        (comp) => comp.name === "CoursesComponent"
      );
      courses = courses.sort(this.compareEventDates);

      const parentLPSectionComponentId = sectionInfo.id;

      const totalElements = courses.length;

      this.setChildComponentsInfoPaged(courses, pageIdx, pageSize);

      //Get layout image
      let supgage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        supgage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        supgage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(supgage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle
          .sectionLayoutId;
      let layoutImg = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
      );

      let customName =
        response.data.data.customName !== null
          ? response.data.data.customName
          : this.state.sectionName;

      this.setState({
        customName,
        courseContainerTitle,
        courses,
        totalElements,
        parentLPSectionComponentId,
        lpSectionLayoutStyle: layout,
        lpLayoutImg: layoutImg,
      });
    }
  }

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openNotificationModal: true,
      openModalToChangeName: false,
    });
  }

  async setChildComponentsInfoPaged(courses, pageIdx, pageSize) {
    // Get information of each of the courses
    const coursesList = [];
    for (let i = 0; i < courses.length; i++) {
      if (!courses[i].img) {
        const imgNode = courses[i].nodes.find(
          (node) => node.nodeType === "CourseImage"
        );
        if (imgNode) {
          if (imgNode.value != null && imgNode.value !== "") {
            let regex = /somosaura-cms.s3.amazonaws.com/;
            let haveHttp = regex.test(imgNode.value);
            if (!haveHttp) {
              // Get image
              let img = await getFile(
                "https://somosaura-cms.s3.amazonaws.com/" + imgNode.value
              );
              courses[i].img = img;
            } else {
              courses[i].img = imgNode.value;
            }
          }
        }
      }
      coursesList.push(courses[i]);
    }
    const coursesToShow = [];
    coursesList.forEach((element) => {
      const course = {
        id: element.id,
        titleId: element.nodes.find((node) => node.nodeType === "CourseTitle")
          ? element.nodes.find((node) => node.nodeType === "CourseTitle").id
          : null,
        title: element.nodes.find((node) => node.nodeType === "CourseTitle")
          ? element.nodes.find((node) => node.nodeType === "CourseTitle").value
          : null,
        titleMinLength: element.nodes.find(
          (node) => node.nodeType === "CourseTitle"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseTitle")
              .minLength
          : null,
        titleMaxLength: element.nodes.find(
          (node) => node.nodeType === "CourseTitle"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseTitle")
              .maxLength
          : null,
        titleLength: element.nodes.find(
          (node) => node.nodeType === "CourseTitle"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseTitle").value
              .length
          : null,

        textId: element.nodes.find((node) => node.nodeType === "CourseText")
          ? element.nodes.find((node) => node.nodeType === "CourseText").id
          : null,
        text: element.nodes.find((node) => node.nodeType === "CourseText")
          ? element.nodes.find((node) => node.nodeType === "CourseText").value
          : null,
        textMinLength: element.nodes.find(
          (node) => node.nodeType === "CourseText"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseText")
              .minLength
          : null,
        textMaxLength: element.nodes.find(
          (node) => node.nodeType === "CourseText"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseText")
              .maxLength
          : null,
        textLength: element.nodes.find((node) => node.nodeType === "CourseText")
          ? element.nodes.find((node) => node.nodeType === "CourseText").value
              .length
          : null,

        dateId: element.nodes.find((node) => node.nodeType === "CourseDate")
          ? element.nodes.find((node) => node.nodeType === "CourseDate").id
          : null,
        date: element.nodes.find((node) => node.nodeType === "CourseDate")
          ? element.nodes.find((node) => node.nodeType === "CourseDate").value
          : null,

        placeId: element.nodes.find((node) => node.nodeType === "CoursePlace")
          ? element.nodes.find((node) => node.nodeType === "CoursePlace").id
          : null,
        place: element.nodes.find((node) => node.nodeType === "CoursePlace")
          ? element.nodes.find((node) => node.nodeType === "CoursePlace").value
          : null,
        placeMinLength: element.nodes.find(
          (node) => node.nodeType === "CoursePlace"
        )
          ? element.nodes.find((node) => node.nodeType === "CoursePlace")
              .minLength
          : null,
        placeMaxLength: element.nodes.find(
          (node) => node.nodeType === "CoursePlace"
        )
          ? element.nodes.find((node) => node.nodeType === "CoursePlace")
              .maxLength
          : null,
        placeLength: element.nodes.find(
          (node) => node.nodeType === "CoursePlace"
        )
          ? element.nodes.find((node) => node.nodeType === "CoursePlace").value
              .length
          : null,

        responsibleId: element.nodes.find(
          (node) => node.nodeType === "CourseResponsible"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
              .id
          : null,
        responsible: element.nodes.find(
          (node) => node.nodeType === "CourseResponsible"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
              .value
          : null,
        responsibleMinLength: element.nodes.find(
          (node) => node.nodeType === "CourseResponsible"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
              .minLength
          : null,
        responsibleMaxLength: element.nodes.find(
          (node) => node.nodeType === "CourseResponsible"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
              .maxLength
          : null,
        responsibleLength: element.nodes.find(
          (node) => node.nodeType === "CourseResponsible"
        )
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
              .value.length
          : null,

        imageId: element.nodes.find((node) => node.nodeType === "CourseImage")
          ? element.nodes.find((node) => node.nodeType === "CourseImage").id
          : null,
        image: element.img,
        imageUri: element.img,
        buttons: element.buttons,
        edited: false,
      };
      coursesToShow.push(course);
    });

    coursesToShow.forEach((element, index) => {
      coursesToShow[index].date = moment(element.date).subtract(5, "hours");
      coursesToShow[index].date = moment(coursesToShow[index].date._d);
    });

    this.setState({
      coursesToShow,
      loadingInfo: false,
      loadingSection: false,
    });
    this.cleanStateVariables();
  }

  async uploadFileToS3Bucket(imageUrl, key) {
    await updateLpSectionComponentNodeImage(imageUrl, key);
  }

  async insertSectionComponentFunction(nodesToUpdate) {
    const { landingPageId, lpSectionId, startDate, endDate } = this.state;
    const response = await insertSectionComponent(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.successfulCreation"),
        contenNotificationModal: t("modal.courseSuccessfullyCreated"),
        typeNotificationModal: "success",
      });
      this.cleanStateVariables();

      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        startDate,
        endDate
      );
    }

    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async updateSectionComponentFunction(componentToUpdate) {
    const { landingPageId, lpSectionId, startDate, endDate } = this.state;
    const response = await updateSectionComponent(componentToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      if (this.state.openNotificationModal === false) {
        this.setState({
          titleNotificationModal: t("modal.editionSuccess"),
          contenNotificationModal: t("modal.eventSuccessfullyEdited"),
          typeNotificationModal: "success",
        });
      }

      this.cleanStateVariables();

      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        startDate,
        endDate
      );
    }

    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async disableNodeFunction(id) {
    const { landingPageId, lpSectionId, startDate, endDate } = this.state;
    const response = await disableLpSectionComponent(id);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
        isLoadingButton: false,
        loadingInfo: false,
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.disablingSuccessful"),
        contenNotificationModal: t("modal.eventSuccessfullyDisabled"),
        typeNotificationModal: "success",
      });

      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        startDate,
        endDate
      );
      this.setState({
        openNotificationModal: true,
        isLoadingButton: false,
        loadingInfo: false,
      });
    }
  }

  compareCourseDates(a, b) {
    const aDateStr = a.nodes.find(
      (node) => node.nodeType === "CourseDate"
    ).value;
    const bDateStr = b.nodes.find(
      (node) => node.nodeType === "CourseDate"
    ).value;
    const aDate = moment(aDateStr, backendDateFormat);
    const bDate = moment(bDateStr, backendDateFormat);
    if (bDate.isAfter(aDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }
    return 0;
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 10);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
              responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateNode(nodesToUpdate) {
    const { landingPageId, lpSectionId, startDate, endDate } = this.state;

    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.editionSuccess"),
        contenNotificationModal: t("modal.courseSuccessfullyEdited"),
        typeNotificationModal: "success",
      });
      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        startDate,
        endDate
      );
    }
    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
    });
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.modifiedSuccesfully"),
        contenNotificationModal: t("modal.positionSectionSuccessfullyModified"),
        typeNotificationModal: "success",
      });
    }
    this.setState({ openNotificationModal: true, isLoadingButton: false });
  }

  // Events
  dateOnChangeEvent = (date) => {
    const { landingPageId, lpSectionId } = this.state;
    if (date == null) {
      this.setState({
        startDate: moment().startOf("month"),
        endDate: moment().startOf("month").add(1, "months"),
        loadingInfo: true,
      });
      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        moment().startOf("month"),
        moment().startOf("month").add(1, "months")
      );
    } else {
      this.setState({
        startDate: date[0],
        endDate: date[1],
        loadingInfo: true,
      });
      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        date[0],
        date[1].add(1, "months")
      );
    }
  };

  addCourseEvent = () => {
    this.setState({
      isAddModalVisible: true,
    });
  };

  editCourseEvent = (e, row) => {
    // Make a copy of the state to send it to the modal to edit
    const course = { ...row };

    const newCourseImage = {
      id: course.imageId,
      s3path: null,
      imageUrl: null,
      image: course.image,
      loading: false,
    };
    this.setState({
      isEditModalVisible: true,
      course,
      newCourseImage,
    });
  };

  handleChangeLayout = (e, id) => {
    this.setState({
      saveButtonDisabled: false,
      FlagclassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  handleClickOpenDeleteImage = (index) => {
    if (index) {
      this.handleImageIndex(index);
      this.setState({ openDeleteImage: true });
    } else {
      this.setState({ openDeleteImage: true });
    }
  };

  handleImageIndex = (index) => {
    this.setState({
      imageIndex: index,
    });
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDeleteNews = (id) => {
    this.setState({ openDeleteNews: true, elementToDeleteId: id });
  };

  handleCloseDeleteNews = () => {
    this.setState({ openDeleteNews: false });
  };

  changeToEdit = (e) => {
    e.stopPropagation();
    const { isReadyToEdit } = this.state;
    this.setState({ isReadyToEdit: !isReadyToEdit });
  };

  disableCourseEvent = (id) => {
    this.setState({
      loadingInfo: true,
      openDeleteNews: false,
    });
    this.disableNodeFunction(id);
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("modal.sectionUnselected"),
      contenNotificationModal: t("modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({ openNotificationModal: true });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  // Add course modal.
  onCloseCourseModal = () => {
    this.cleanStateVariables();

    this.setState({
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  };

  handleChangeDescription(html, element) {
    if (element != null) {
      this.handleChange(html, "text", "", true, element.id);
    } else {
      this.handleChange(html, "text", "", true, null);
    }
  }

  handleChange = (event, dataIndex, data, flagRichText, courseId) => {
    var id = null;
    var value = null;

    if (event && event.target) {
      id = event.target.id;
      value = event.target.value;
    }
    if (flagRichText) {
      id = dataIndex;
      value = event;
    }

    const { courseContainerTitle, course, coursesToShow } = { ...this.state };
    if (courseId === null) {
      course[id] = value;
    }

    if (id === "CourseContainerTitle") {
      if (value.length <= courseContainerTitle.maxLength) {
        courseContainerTitle.value = value;
        courseContainerTitle.modified = true;
        courseContainerTitle.length = value.length;
      }
    }
    coursesToShow.forEach((element, index) => {
      if (element.id === courseId) {
        if (id === "title") {
          coursesToShow[index].title = value;
        }
        if (id === "place") {
          coursesToShow[index].place = value;
        }
        if (id === "responsible") {
          coursesToShow[index].responsible = value;
        }
        if (id === "text") {
          coursesToShow[index].text = value;
        }
        coursesToShow[index].edited = true;
      }
    });

    this.setState({
      course,
      coursesToShow,
      courseContainerTitle,
      FlagclassName: true,
    });
  };

  changeDateHandleEvent = (e, courseId) => {
    const { course, coursesToShow } = { ...this.state };
    const date = e.target.value;
    let dateToSave = date;
    course.date = dateToSave;
    course.dateToShow = dateToSave;
    coursesToShow.forEach((element, index) => {
      if (element.id === courseId) {
        coursesToShow[index].dateToShow = dateToSave;
        coursesToShow[index].date = dateToSave;
        coursesToShow[index].edited = true;
      }
    });
    this.setState({
      FlagclassName: true,
      coursesToShow,
      course,
    });
  };

  handleChangeImage = async (event) => {
    const { course, coursesToShow, country, city, organizationId, imageIndex } =
      this.state;
    var newCourseImage = { ...this.state.newCourseImage };
    var fileUpload = event.target.files[0];
    let extension;
    if (fileUpload !== undefined) {
      extension = fileUpload.type.replace("image/", "");
    }
    const random = uuidv4();
    const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
    if (!extension) {
      newCourseImage.loading = true;
      newCourseImage.file = null;
      newCourseImage.image = null;

      this.setState({
        newCourseImage,
        FlagclassName: true,
      });
      return;
    }

    if (key !== undefined) {
      // Function to resize and reduce image quality
      // Save image in AWS

      let flag = true;
      newCourseImage = await handleChangeImageFunction(
        newCourseImage,
        fileUpload,
        extension,
        key,
        400,
        400
      ).catch((res) => {
        const { t } = this.props;
        this.setState({
          titleNotificationModal: t(`${res.title}`),
          contenNotificationModal: `${t(`${res.content}`)} ${res.params}`,
          typeNotificationModal: "error",
          openNotificationModal: true,
        });
        flag = false;
      });
      if (flag !== false) {
        if (newCourseImage.loading === false) {
          if (imageIndex === null) {
            //new news
            newCourseImage.loading = false;
            newCourseImage.modified = true;
            newCourseImage.value = key;
            course.image = key;
            course.imageUri = newCourseImage.image;
          } else {
            // edited new
            coursesToShow[imageIndex].image = key;
            coursesToShow[imageIndex].imageUri = newCourseImage.image;
            coursesToShow[imageIndex].edited = true;
          }

          this.uploadFileToS3Bucket(newCourseImage.file, key);
          this.setState({
            newCourseImage,
            coursesToShow,
            FlagclassName: true,
          });
          this.forceUpdate();
        }
      }
    }
  };

  disableImage(elementToChange) {
    const { coursesToShow, course, imageIndex } = {
      ...this.state,
    };
    if (elementToChange !== null && imageIndex) {
      coursesToShow[imageIndex].image = null;
      coursesToShow[imageIndex].edited = true;
    } else {
      course.image = null;
    }
    this.setState({
      isLoadingButton: false,
      FlagclassName: true,
      deleteFlag: true,
      coursesToShow,
      course,
    });
    this.handleCloseDeleteImage();
  }

  handleButtonListChange = (buttonList, courseId) => {
    let { course, coursesToShow } = this.state;
    coursesToShow.forEach((element, index) => {
      if (element.id === courseId) {
        coursesToShow[index].buttons = buttonList;
        coursesToShow[index].edited = true;
      }
    });
    course.buttons = buttonList;
    this.setState({ course, coursesToShow, FlagclassName: true });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagclassName: true,
    });
  };

  saveChangeName = () => {
    let dto = {
      id: this.state.lpSectionId,
      customName: this.state.newCustomName,
      isEnabled: 1,
    };

    this.updateLpSection(dto);
  };

  saveButtonEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagclassName: false,
    });
    const nodesToUpdate = [];
    var {
      courseContainerTitle,
      course,
      coursesToShow,
      haveChangeInLayout,
      lpSectionId,
      lpSectionLayoutStyle,
    } = { ...this.state };

    let errorInput = false;

    const { t } = this.props;

    // Verify each one of the values of the component to add
    if (courseContainerTitle) {
      if (courseContainerTitle.value === "") {
        errorInput = true;
      }
      delete courseContainerTitle.modified;
      nodesToUpdate.push(courseContainerTitle);
    }
    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }
    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          titleNotificationModal: t("modal.wrongFile"),
          contenNotificationModal: t("modal.fieldEmpty"),
          typeNotificationModal: "error",
        });
        this.setState({
          isLoadingButton: false,
          openNotificationModal: true,
        });
      }
    }

    if (course.title !== "") {
      coursesToShow.push(course);
      this.setState({ coursesToShow });
    }
    this.onSaveEvent();

    course = {
      id: null,
      title: "",
      text: "",
      date: moment(),
      dateToShow: moment(),
      author: "",
      image: null,
      buttons: [],
    };
    this.setState({ course, addCourse: false });
  };

  onSaveEvent = (e) => {
    const {
      parentLPSectionComponentId,
      landingPageId,
      coursesToShow,
      course,
      deleteFlag,
    } = this.state;
    this.setState({
      isLoadingButton: true,
      FlagclassName: false,
    });

    const { t } = this.props;

    var coursesToValidate = coursesToShow.filter((n) => n.edited === true);
    if (course.title !== "" && course.title !== null) {
      coursesToValidate = coursesToValidate.concat(course);
    }

    coursesToValidate.forEach((newCourse) => {
      const nodeCoursesToAdd = [];
      let errorInput = false;
      let courseButtons = newCourse.buttons
        ? newCourse.buttons.map((b) => {
            const button = {
              id: b.id,
              name: b.name,
              externalLink: b.externalLink,
              internalLpSectionComponentId: b.component
                ? b.component.id
                : b.internalLpSectionComponentId
                ? b.internalLpSectionComponentId
                : null,
              isEnabled: b.isEnabled,
            };
            return button;
          })
        : [];

      const courseToAdd = {
        // --- With this object the lpSectionComponent is created ---
        // With these two values, the lpsectionId is obtained
        lpId: null, // this value is filled with the information passed from the parent
        lpSectionId: this.state.lpSectionId,
        sectionId: 10, // Static value to the courses section

        sectionComponentId: 15, // Static value to the CourseComponent 15 in sectionComponentId

        parentLPSectionComponentId, // Static value to the CourseContainer 14 in sectionComponentId

        // --- With the created lpSectionComponent id, the nodes are created
        nodesToAdd: [],
        buttons: courseButtons,
      };

      // Course nodes array to add to the db
      if (newCourse.title != null) {
        const course = {};

        course.id = newCourse.titleId ? newCourse.titleId : null;
        course.sectionComponentNodeId = 50;
        // Required
        if (newCourse.title === "") {
          errorInput = true;
        }
        course.value = newCourse.title;
        course.nodeType = "CourseTitle";

        nodeCoursesToAdd.push(course);
      }
      if (newCourse.text != null) {
        const course = {};

        course.id = newCourse.textId ? newCourse.textId : null;
        course.sectionComponentNodeId = 51;
        // if (newCourse.text === "") {
        //   errorInput = true;
        // }
        course.value = newCourse.text;
        course.nodeType = "CourseText";

        nodeCoursesToAdd.push(course);
      }
      if (newCourse.date != null) {
        const course = {};

        course.id = newCourse.dateId ? newCourse.dateId : null;
        course.sectionComponentNodeId = 53;
        course.value = moment(newCourse.date, "YYYY-MM-DD HH:mm");
        if(course.value._isValid === false){
          course.value = "";
        }
        course.nodeType = "CourseDate";

        nodeCoursesToAdd.push(course);
      }
      if (newCourse.place != null) {
        const course = {};
        course.id = newCourse.placeId ? newCourse.placeId : null;
        course.sectionComponentNodeId = 54;
        // if (newCourse.place === "") {
        //   errorInput = true;
        // }
        course.value = newCourse.place;
        course.nodeType = "CoursePlace";

        nodeCoursesToAdd.push(course);
      }
      if (newCourse.responsible != null) {
        const course = {};

        course.id = newCourse.responsibleId ? newCourse.responsibleId : null;
        course.sectionComponentNodeId = 55;
        // if (newCourse.responsible === "") {
        //   errorInput = true;
        // }
        course.value = newCourse.responsible;
        course.nodeType = "CourseResponsible";

        nodeCoursesToAdd.push(course);
      }
      if (newCourse.image !== null) {
        const course = {};
        course.id = newCourse.imageId ? newCourse.imageId : null;
        course.sectionComponentNodeId = 52;
        let haveHttp = false;
        var regex = /somosaura-cms.s3.amazonaws.com/;
        haveHttp = regex.test(newCourse.image);
        if (haveHttp) {
          course.value = newCourse.image.slice(39, newCourse.image.length); //Cut to only save image, not link
        } else {
          course.value = newCourse.image;
        }
        course.nodeType = "CourseImage";

        nodeCoursesToAdd.push(course);
      }
      if (deleteFlag) {
        const course = {};

        course.id = newCourse.imageId ? newCourse.imageId : null;
        course.sectionComponentNodeId = 52;
        course.value = "";
        course.nodeType = "CourseImage";

        nodeCoursesToAdd.push(course);
      }

      courseToAdd.id = newCourse.id;
      courseToAdd.lpId = landingPageId;
      courseToAdd.nodesToAdd = nodeCoursesToAdd;

      if (nodeCoursesToAdd.length > 0) {
        if (!errorInput) {
          let courseTitle = false;
          let courseText = false;
          let coursePlace = false;

          nodeCoursesToAdd.forEach((obj) => {
            if (obj.nodeType === "CourseTitle") {
              courseTitle = true;
            }
            if (obj.nodeType === "CourseText") {
              courseText = true;
            }
            if (obj.nodeType === "CoursePlace") {
              coursePlace = true;
            }
          });

          if (courseTitle && courseText && coursePlace) {
            if (newCourse.id) {
              this.updateSectionComponentFunction(courseToAdd);
            } else {
              this.insertSectionComponentFunction(courseToAdd);
            }
          } else {
            this.setState({
              titleNotificationModal: t("modal.wrongFile"),
              contenNotificationModal: t("modal.fieldEmpty"),
              typeNotificationModal: "error",
            });
            this.setState({
              isLoadingButton: false,
              openNotificationModal: true,
            });
          }
        } else {
          this.setState({
            titleNotificationModal: t("modal.wrongFile"),
            contenNotificationModal: t("modal.fieldEmpty"),
            typeNotificationModal: "error",
          });
          this.setState({
            isLoadingButton: false,
            openNotificationModal: true,
          });
        }
      }
    });
  };

  cleanStateVariables = () => {
    let newCourseImage = {
      id: null,
      s3path: null,
      imageUrl: null,
      image: null,
      loading: false,
      modified: false,
    };
    let course = {
      id: null,
      title: "",
      text: "",
      date: moment(),
      dateToShow: moment(),
      place: "",
      responsible: "",
      image: null,
      imageLoading: false,
    };

    this.setState({
      newCourseImage,
      course,
    });
  };

  renderCourses(coursesToShow) {
    let renderedComponents = [];
    const { openDeleteImage, landingPageId, isReadyToEdit } = this.state;
    const { t } = this.props;
    if (coursesToShow && coursesToShow.length) {
      coursesToShow.forEach((element, index) => {
        renderedComponents.push(
          <div>
            <AccordionComponent
              disabled={isReadyToEdit}
              iconEdit={<Edit2 />}
              // onEditNews={(e) => this.changeToEdit(e)}
              onDeleteNews={(e) => this.handleClickOpenDeleteNews(element.id)}
              iconDelete={<Trash2 />}
              title={element.title}
              body={[
                <div className="accordionContent">
                  <div className="rowSection componentSpace">
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "100%" }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.enterTitle")}
                        id="title"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.title}
                      />
                      {element.title.value && element.title.maxLength ? (
                        <span>
                          {element.title.length} / {element.title.maxLength}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="rowSection componentSpace">
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "100%" }}
                    >
                      <ReactQuill
                        readOnly={isReadyToEdit}
                        className="textInputWidth"
                        onChange={(e) =>
                          this.handleChangeDescription(e, element)
                        }
                        value={element.text}
                        modules={editorConfiguration.modules}
                        formats={editorConfiguration.formats}
                        placeholder={t("basicWords.description")}
                      />
                    </div>
                  </div>

                  <div className="rowSection componentSpace">
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "32%" }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.place")}
                        id="place"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.place}
                      />
                    </div>
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "32%" }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.responsible")}
                        id="responsible"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.responsible}
                      />
                    </div>
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "34%" }}
                    >
                      <DateTimePickerComponent
                        disabled={isReadyToEdit}
                        string={t("basicWords.date")}
                        defaultValue={element.date}
                        onChange={(e) =>
                          this.changeDateHandleEvent(e, element.id)
                        }
                      />
                    </div>
                  </div>

                  <div className="rowSection componentSpace">
                    <div className="textLogo">{t("basicWords.image")}</div>

                    {/* Tooltip image */}
                    <Tooltip title={t("generalMessages.imagesFormat")}>
                      <div className="cardImagesSquare">
                        <div className="row">
                          <div className="iconXCardImagesSquare">
                            <a
                              onClick={() =>
                                this.handleClickOpenDeleteImage(index)
                              }
                            >
                              <X className="iconImageEditInSectionSquare" />
                            </a>
                          </div>
                        </div>

                        <div className="row">
                          <div className="centerImage">
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={(e) => this.handleChangeImage(e, index)}
                              hidden
                            />
                            <label htmlFor="contained-button-file">
                              <div className="imageEdit">
                                <div>
                                  {element.image != null ? (
                                    <img
                                      src={element.imageUri}
                                      alt="avatar"
                                      className="widthAndHeightImageSectionSquare"
                                      onClick={() =>
                                        this.handleImageIndex(index)
                                      }
                                    />
                                  ) : (
                                    <Plus
                                      className="imageEdit"
                                      onClick={() =>
                                        this.handleImageIndex(index)
                                      }
                                    />
                                  )}

                                  <GeneralModal
                                    visible={openDeleteImage}
                                    title={t("basicWords.deleteItem")}
                                    footer={[
                                      <div
                                        className="row"
                                        key={1}
                                        style={{ display: "flex" }}
                                      >
                                        <div className="col-6">
                                          <ButtonComponent
                                            onClick={() =>
                                              this.handleCloseDeleteImage()
                                            }
                                            props={null}
                                            shape="round"
                                            text={t("basicWords.cancelText")}
                                          />
                                        </div>
                                        <div>
                                          <ButtonComponent
                                            onClick={() =>
                                              this.disableImage(element)
                                            }
                                            props={null}
                                            shape="round"
                                            text={t("basicWords.okText")}
                                          />
                                        </div>
                                      </div>,
                                    ]}
                                    onClick={() =>
                                      this.handleCloseDeleteImage()
                                    }
                                  />
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="rowSection componentSpace">
                    <div className="textLogo">{t("buttons.buttons")}</div>
                  </div>
                  <div
                    className="rowSection componentSpace"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "2%",
                    }}
                  >
                    <ButtonsContainer
                      disabled={isReadyToEdit}
                      isModalVisible={true}
                      lpId={landingPageId}
                      buttonList={element.buttons}
                      handleButtonListChange={(buttonList) =>
                        this.handleButtonListChange(buttonList, element.id)
                      }
                    />
                  </div>
                </div>,
              ]}
            />
            <br />
          </div>
        );
      });
    }
    return renderedComponents;
  }

  renderAddCourse() {
    const { course, openDeleteImage, landingPageId } = this.state;
    const { t } = this.props;

    return (
      <div>
        <AccordionComponent
          title={t("basicWords.newCourse")}
          className="accordionAdd"
          body={[
            <div className="accordionContent">
              <div className="rowSection componentSpace">
                <div style={{ width: "100%" }}>
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.enterTitle")}
                    id="title"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={course.title}
                  />
                  {course.value && course.maxLength ? (
                    <span>
                      {course.length} / {course.maxLength}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="rowSection componentSpace">
                <div style={{ width: "100%" }}>
                  <ReactQuill
                    className="textInputWidth"
                    onChange={(e) => this.handleChangeDescription(e, null)}
                    value={course.text}
                    modules={editorConfiguration.modules}
                    formats={editorConfiguration.formats}
                    placeholder={t("basicWords.description")}
                  />
                </div>
              </div>
              <div className="rowSection componentSpace">
                <div
                  className="accordionCompleteInputs"
                  style={{ marginLeft: "0%", width: "32%" }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.place")}
                    id="place"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={course.place}
                  />
                </div>
                <div
                  className="accordionCompleteInputs"
                  style={{ marginLeft: "0%", width: "32%" }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.responsible")}
                    id="responsible"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={course.responsible}
                  />
                </div>
                <div style={{ width: "34%" }}>
                  <DateTimePickerComponent
                    string={t("basicWords.date")}
                    defaultValue={course.date}
                    onChange={(e) => this.changeDateHandleEvent(e, null)}
                  />
                </div>
              </div>

              <div
                className="rowSection componentSpace"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="textLogo">{t("basicWords.image")}</div>
                <div className="cardImagesSquare">
                  <div className="row">
                    <div className="iconXCardImagesSquare">
                      <a>
                        <X
                          className="iconImageEditInSectionSquare"
                          onClick={() => this.handleClickOpenDeleteImage()}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    {/* Tooltip image */}
                    <Tooltip title={t("generalMessages.imagesFormat")}>
                      <div className="centerImage">
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => this.handleChangeImage(e, null)}
                          hidden
                        />
                        <label htmlFor="contained-button-file">
                          <div className="imageEdit">
                            <div>
                              {course.image != null ? (
                                <img
                                  src={course.imageUri}
                                  alt="avatar"
                                  className="widthAndHeightImageSectionSquare"
                                  onClick={() => this.handleImageIndex(null)}
                                />
                              ) : (
                                <Plus
                                  className="imageEdit"
                                  onClick={() => this.handleImageIndex(null)}
                                />
                              )}

                              <GeneralModal
                                visible={openDeleteImage}
                                title={t("basicWords.deleteItem")}
                                footer={[
                                  <div
                                    className="row"
                                    key={1}
                                    style={{ display: "flex" }}
                                  >
                                    <div className="col-6">
                                      <ButtonComponent
                                        onClick={() =>
                                          this.handleCloseDeleteImage()
                                        }
                                        props={null}
                                        shape="round"
                                        text={t("basicWords.cancelText")}
                                      />
                                    </div>
                                    <div>
                                      <ButtonComponent
                                        onClick={() => this.disableImage(null)}
                                        props={null}
                                        shape="round"
                                        text={t("basicWords.okText")}
                                      />
                                    </div>
                                  </div>,
                                ]}
                                onClick={() => this.handleCloseDeleteImage()}
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div
                className="rowSection componentSpace"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="textLogo">{t("buttons.buttons")}</div>
              </div>
              <div
                className="rowSection componentSpace"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "2%",
                }}
              >
                <ButtonsContainer
                  isModalVisible={true}
                  lpId={landingPageId}
                  buttonList={course.buttons}
                  handleButtonListChange={(buttonList) =>
                    this.handleButtonListChange(buttonList, course.id)
                  }
                />
              </div>
            </div>,
          ]}
        />
        <br />
      </div>
    );
  }

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  }

  setLayoutModalVisible = (isVisible) => {
    this.setState({ layoutModalVisible: isVisible });
  };

  addCourse = (flag) => {
    if (flag !== this.state.addCourse) {
      this.setState({ addCourse: flag });
    }
  };

  //-----------Update page after modal------------
  updatePage = (lpSectionId, lpUrl) => {
    const { landingPageId, startDate, endDate } = this.state;
    this.getCoursesInformation(landingPageId, lpSectionId, startDate, endDate);
    this.setState({
      // previousPath,
      lpUrl,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  // Render Info
  render() {
    const {
      redirectBack,
      sectionName,
      coursesToShow,
      organizationId,
      organizationName,
      name,
      subpageId,
      landingPageId,
      FlagclassName,
      courseContainerTitle,
      lpSectionLayoutStyle,
      layoutData,
      lpUrl,

      //Notification Modal
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,

      //Modal delete item
      openDeleteNews,
      elementToDeleteId,
      isLoadingButton,

      openModalToChangeName,
      customName,
      goToWithoutSaveModalVisible,
    } = this.state;

    if (redirectBack) {
      return <Redirect to="/myWebPage" />;
    }

    const { t } = this.props;

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      customName ? decodeURIComponent(customName) : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <DeleteItemModal
          visible={openDeleteNews}
          onCancel={this.handleCloseDeleteNews}
          onClick={(e) => this.disableCourseEvent(elementToDeleteId)}
        />

        <Card className="card">
          {isLoadingButton === true ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(800%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row componentSpace spaceBreadcrumb">
                <BreadcrumbSection
                  principal={t("sections.courses")}
                  secondary={organizationName}
                  route="./myWebPage"
                  propsComponent={{
                    organizationId,
                    sectionName: sectionName,
                    nameSubpage: name ? name : null,
                    lpId: landingPageId,
                    subpageId: subpageId ? subpageId : null,
                    lpUrl: lpUrl,
                    customName,
                  }}
                  handleClickOpenModalToChangeName={
                    this.handleClickOpenModalToChangeName
                  }
                  FlagclassName={FlagclassName}
                  onClick={(e) => this.saveButtonEvent(e)}
                  GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                  onCancelGotoWithoutMModal={(e) =>
                    this.onCancelGotoWithoutMModal(e)
                  }
                  goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                />
              </div>

              <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                <Title
                  title={t("basicWords.completeInformationOfYourSection")}
                />
              </div>
              <div className="componentSpace adjustPositionXLeft adjustPositionXRigth">
                <SelectLayoutModal
                  layoutData={layoutData}
                  lpLayoutId={
                    lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                  }
                  handleChange={this.handleChangeLayout}
                />
              </div>
              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                <AccordionComponent
                  title={t("generalUser.sectionContent")}
                  body={[
                    <div className="rowSection componentSpace">
                      <div style={{ width: "70vh", marginRight: "5%" }}>
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.enterTitle")}
                          id={
                            courseContainerTitle
                              ? courseContainerTitle.nodeTypeName
                              : null
                          }
                          onChange={(e, id) =>
                            this.handleChange(e, "", "", "", id)
                          }
                          value={
                            courseContainerTitle
                              ? courseContainerTitle.value
                              : null
                          }
                        />
                      </div>
                    </div>,
                  ]}
                />
              </div>
              {/* Accordion to add course */}
              {landingPageId ? (
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  {this.renderAddCourse()}
                </div>
              ) : null}

              <div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <Title title={t("basicWords.listCourses")} />
                </div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  {this.renderCourses(coursesToShow)}
                </div>
              </div>
            </>
          )}
        </Card>
        {/* Buttons */}
        <br />
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
          }}
          FlagclassName={FlagclassName}
          onClick={(e) => this.saveButtonEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(CoursesSection);
