import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import InternalDragDrop from "./InternalDragDrop";
// Localization
import { withTranslation } from "react-i18next";

import AddNewSectionModal from "./AddNewSectionModal.jsx";
import AddNewSubpageModal from "./AddNewSubpageModal.jsx";
import { getSectionsAvailableByLp } from "../../actions/index";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { LocalizeSection } from "../../reactUtils/systemVariables/languageLocalization/SectionsLocalization.jsx";
import { Plus, Eye, EyeOff, Trash2, Edit2 } from "react-feather";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {
  ButtonComponent,
  GeneralModal,
  NotificationModal,
} from "../../components/index";

class HorizontalDragDrop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addNewSectionModalVisible: false,
      addNewSubpageModalVisible: false,
      sections: null,
      sectionToAdd: null,
      lpId: this.props.state.lpId,
      registers: this.props.state.registers,
      organizationId: this.props.state.organizationId,
      column: this.props.column,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
      sectionToDelete: null,
    };
  }

  async getDataSections(lpId, page) {
    let response = await getSectionsAvailableByLp(lpId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({ openNotificationModal: true });
    } else {
      if (page.style === "subpage") {
        let sections = [];
        response.data.data.forEach((item, index) => {
          if (
            item.name !== "HEADER" &&
            item.name !== "HOME" &&
            item.name !== "CONTACT" &&
            item.name !== "FOOTER"
          ) {
            sections[sections.length] = {
              label: LocalizeSection(item.name, t),
              value: item.id,
            };
          }
        });
        this.setState({ sections });
      } else {
        let sections = [];
        response.data.data.forEach((item, index) => {
          if (
            item.name !== "HEADER" &&
            item.name !== "HOME" &&
            item.name !== "CONTACT" &&
            item.name !== "FOOTER" &&
            item.name !== "DONATIONS" &&
            item.name !== "LINKTREE"
          ) {
            sections[sections.length] = {
              label: LocalizeSection(item.name, t),
              value: item.id,
            };
          }
        });
        this.setState({ sections });
      }
    }
  }

  addNewSection = (page) => {
    this.setState({
      addNewSectionModalVisible: true,
    });
    let subpageId = parseInt(page.id.substring(7, page.id.length));
    if (subpageId !== 10001) {
      this.setState({ subpageId });
    }
    this.getDataSections(this.props.state.lpId, page);
  };

  onCloseAddNewSectionModal = () => {
    this.setState({
      addNewSectionModalVisible: false,
    });
  };

  onSaveEditAddNewSectionModal = () => {
    const { sectionToAdd, lpId, subpageId } = this.state;
    this.setState({
      addNewSectionModalVisible: false,
    });
    this.props.updateNewSectionComponentFunction(sectionToAdd, lpId, subpageId);
  };

  AddNewSubpage = () => {
    this.setState({
      addNewSubpageModalVisible: true,
      nameNewSubpage: "",
    });
  };

  onSaveAddNewSubpageModal = () => {
    const { lpId, column, nameNewSubpage } = this.state;
    this.setState({
      isLoadingButton: true,
      addNewSubpageModalVisible: false,
    });
    this.props.createLpSubpageByLpId(lpId, nameNewSubpage, column.id);
  };

  onCloseAddNewSubpageModal = () => {
    this.setState({
      addNewSubpageModalVisible: false,
      nameNewSubpage: "",
    });
  };

  OnChangeInput = (event) => {
    const { value } = event.target;
    this.setState({
      nameNewSubpage: value,
    });
  };

  addNewSubpage = () => {
    const { t } = this.props;
    if (this.props.state.initialData.length > 8) {
      this.setState({
        titleNotificationModal: "Info header",
        contenNotificationModal: t("basicWords.cantMoreEigthSections"),
        typeNotificationModal: "warning",
      });
      this.setState({
        openNotificationModal: true,
      });
      return;
    } else {
      this.setState({
        addNewSubpageModalVisible: true,
      });
    }
    this.setState({
      addNewSubpageModalVisible: true,
    });
  };
  onCloseAddNewSubpageModal = () => {
    this.setState({
      addNewSubpageModalVisible: false,
    });
  };

  OnChangeAddNewSectionModal = (e) => {
    let { sectionToAdd } = { ...this.state };
    sectionToAdd = e.target.value;
    this.setState({
      sectionToAdd,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  disableSectionEvent = () => {
    let LpSectionPutDto = this.state.sectionToDelete;
    let lpsubpage_id = parseInt(
      this.props.column.id.substring(7, this.props.column.id.length)
    );
    if (lpsubpage_id === 10001) {
      lpsubpage_id = -1;
    }
    this.props.disableSectionFunction(LpSectionPutDto, lpsubpage_id);
    this.setState({ openDialogDeleteSection: false });
  };

  handleClickOpenDeleteSection = (data) => {
    this.setState({ openDialogDeleteSection: true, sectionToDelete: data });
  };

  handleCloseDeleteSection = () => {
    this.setState({ openDialogDeleteSection: false });
  };

  render() {
    let className = "containerCard";
    var isDragDisabledValue = false;
    const { t } = this.props;

    if (!this.props.column.style && this.props.Issubpage) {
      className = "tarjetBasic-basic";
    }

    if (!this.props.column.style && this.props.flagTarjet) {
      className = "tarjetBasic" + "-" + this.props.flagTarjet + " text-center";
    }

    if (className === "containerCard" || this.props.column.style === "add") {
      isDragDisabledValue = true;
    }

    var id = this.props.column.id;
    if (id === null) {
      id = 1000;
    }
    if (
      this.props.column.name === "HEADER" ||
      this.props.column.name === "HOME" ||
      this.props.column.name === "CONTACT" ||
      this.props.column.name === "FOOTER"
    ) {
      className = "containerCard-blockMove";
    }

    if (this.props.state.initialData && id.length > 7) {
      id = parseInt(id.substring(7, id.length));
    } else {
      id = parseInt(id);
    }

    const addComponent = (
      <div>
        {this.props.column.style === "mainpage" ||
        this.props.column.style === "subpage" ? (
          <div
            className="conteinerTitleColumnAdd"
            onClick={() => this.addNewSection(this.props.column)}
          >
            <div className="iconTitle">
              <Plus
                size={18}
                style={{ marginBottom: "80%" }}
                onClick={() => this.props.handleClickOpenDialog()}
              />
            </div>
            <div className="titleColumn">
              <p className="addGroupSubpageOrSubpage">
                {t("basicWords.addSection")}
              </p>
            </div>
          </div>
        ) : null}
        {this.props.column.style === "groupSubpage" ? (
          <div
            className="conteinerTitleColumnAdd"
            onClick={(e) => this.addNewSubpage(e)}
          >
            <div className="iconTitle">
              <Plus
                size={18}
                style={{ marginBottom: "80%" }}
                onClick={() => this.props.handleClickOpenDialog()}
              />
            </div>
            <div className="titleColumn">
              <p className="addGroupSubpageOrSubpage">
                {t("basicWords.addSubpage")}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );

    return (
      <>
        <AddNewSectionModal
          visible={this.state.addNewSectionModalVisible}
          handleClose={this.onCloseAddNewSectionModal}
          handleSave={this.onSaveEditAddNewSectionModal}
          handleChange={this.OnChangeAddNewSectionModal}
          landingPageId={this.state.lpId}
          sections={this.state.sections}
          sectionToAdd={this.state.sectionToAdd}
        />

        <AddNewSubpageModal
          visible={this.state.addNewSubpageModalVisible}
          nameNewSubpage={this.state.nameNewSubpage}
          handleClose={this.onCloseAddNewSubpageModal}
          handleSave={this.onSaveAddNewSubpageModal}
          OnChangeInput={this.OnChangeInput}
        />

        <NotificationModal
          visible={this.state.openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={this.state.titleNotificationModal}
          content={this.state.contenNotificationModal}
          type={this.state.typeNotificationModal}
        />

        <GeneralModal
          visible={this.state.openDialogDeleteSection}
          title={t("basicWords.deleteItem")}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleCloseDeleteSection()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div>
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.disableSectionEvent()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleCloseDeleteSection()}
        />

        {/* Render */}

        <Draggable
          key={`${this.props.column.id}`}
          draggableId={`${this.props.column.id}`}
          index={this.props.index}
          isdragdisabled={isDragDisabledValue ? isDragDisabledValue : null}
        >
          {(provided, snapshot) => (
            <div
              {...provided.draggableProps}
              ref={provided.innerRef}
              isdraggingover={
                snapshot.isDraggingOver
                  ? snapshot.isDraggingOver.toString()
                  : null
              }
              isdragdisabled={
                isDragDisabledValue ? isDragDisabledValue.toString() : null
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {this.props.column.style === "mainpage" ? (
                  <div className="backgroundTagMainpage">
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "15px !important",
                      }}
                      className="tagMainpage"
                      {...provided.dragHandleProps}
                    >
                      {LocalizeSection(this.props.column.name, t)}
                    </span>
                  </div>
                ) : null}
                {this.props.Issubpage ||
                this.props.column.style === "subpage" ? (
                  <div className="conteinerTitleColumn">
                    <div className="titleColumn">
                      <div className="backgroundTagSubpage">
                        <span
                          style={{
                            cursor: "pointer",
                            paddingLeft: "8px",
                            paddingRight: "8px;",
                            borderRadius: "4px",
                          }}
                          className="tagSubpage"
                          {...provided.dragHandleProps}
                        >
                          <div className="limitCharacters">
                            {decodeURIComponent(this.props.column.name)}
                          </div>
                          <DragIndicatorIcon style={{ width: "18px" }} />
                        </span>
                      </div>
                    </div>
                    <div className="iconTitle">
                      <Trash2
                        style={{
                          paddingRight: "5px",
                        }}
                        size={18}
                        onClick={(e) =>
                          this.props.handleClickOpenDialog(this.props.column)
                        }
                      />
                      {this.props.column.isVisible === 1 ? (
                        <Tooltip title={t("basicWords.visibleSubpage")}>
                          <Eye
                            size={18}
                            style={{
                              paddingRight: "5px",
                            }}
                            onClick={(e) =>
                              this.props.OnchangeIsVisible(this.props.column)
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title={t("basicWords.visibleSubpage")}>
                          <EyeOff
                            size={18}
                            style={{
                              paddingRight: "5px",
                            }}
                            onClick={(e) =>
                              this.props.OnchangeIsVisible(this.props.column)
                            }
                          />
                        </Tooltip>
                      )}
                      <Edit2
                        size={18}
                        onClick={() =>
                          this.props.handleClickOpenModalToChangeName(
                            this.props.column
                          )
                        }
                      />
                    </div>
                    <GeneralModal
                      visible={this.props.state.openDialog}
                      title={t("basicWords.deleteItem")}
                      footer={[
                        <div
                          className="row"
                          key={1}
                          style={{ display: "flex" }}
                        >
                          <div className="col-6">
                            <ButtonComponent
                              onClick={() =>
                                this.props.handleCloseDialog(
                                  this.props.columnSelected
                                )
                              }
                              props={null}
                              shape="round"
                              text={t("basicWords.cancelText")}
                            />
                          </div>
                          <div>
                            <ButtonComponent
                              onClick={() =>
                                this.props.ongroupSubpageOrSubPageDelete(
                                  this.props.columnSelected
                                )
                              }
                              props={null}
                              shape="round"
                              text={t("basicWords.okText")}
                            />
                          </div>
                        </div>,
                      ]}
                      onClick={() =>
                        this.props.handleCloseDialog(this.props.column)
                      }
                    />
                  </div>
                ) : null}
                {this.props.column.style === "groupSubpage" ? (
                  <div className="conteinerTitleColumn">
                    <Link
                      to={{
                        pathname: "/editMyGroupSubpage",
                        props: {
                          id: this.props.column.id,
                          sectionName: decodeURIComponent(
                            this.props.column.name
                          ),
                          sectionId: this.props.column.id,
                          registers: this.props.column.sections,
                          landingPageId: this.props.state.lpId,
                          organizationId: this.state.organizationId,
                          organizationName: this.state.organizationName,
                          country: this.state.country,
                          region: this.state.region,
                          city: this.state.city,
                          groupId: this.props.column.id,
                          previousPath: "/myWebPage",
                          lpUrl: this.state.lpUrl,
                        },
                      }}
                    >
                      <div className="titleColumn">
                        <div className="backgroundTagGroupsubpage">
                          <span
                            style={{
                              cursor: "pointer",
                              paddingLeft: "8px",
                              paddingRight: "8px;",
                            }}
                            className="tagGroupsubpage"
                            {...provided.dragHandleProps}
                          >
                            <div className="limitCharacters">
                              {decodeURIComponent(
                                LocalizeSection(this.props.column.name, t)
                              )}
                            </div>
                            <DragIndicatorIcon style={{ width: "18px" }} />
                          </span>
                        </div>
                      </div>
                    </Link>

                    <div className="iconTitle">
                      <Trash2
                        size={18}
                        style={{ paddingRight: "10px" }}
                        onClick={() =>
                          this.props.handleClickOpenDialog(this.props.column)
                        }
                      />
                      <Edit2
                        size={18}
                        onClick={() =>
                          this.props.handleClickOpenModalToChangeName(
                            this.props.column
                          )
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              {!this.props.column.style &&
              !this.props.Issubpage &&
              !this.props.isSectionInSubpage &&
              this.props.column.style !== "mainpage" ? ( // if a card type section
                <div {...provided.dragHandleProps}>
                  <h3
                    className="textP"
                    {...provided.dragHandleProps}
                    style={{ cursor: "pointer" }}
                  >
                    {LocalizeSection(this.props.column.name, t)}
                  </h3>
                </div>
              ) : null}

              {!this.props.column.style &&
              !this.props.Issubpage &&
              this.props.isSectionInSubpage ? ( // if a card type section in subpage
                <div {...provided.dragHandleProps}>
                  <h3
                    className="textP"
                    {...provided.dragHandleProps}
                    style={{ cursor: "pointer" }}
                  >
                    {LocalizeSection(this.props.column.name, t)}
                  </h3>
                </div>
              ) : null}

              <div className={className}>
                <Droppable
                  droppableId={this.props.column.id}
                  key={this.props.column.id}
                  type="vertical"
                >
                  {(provided, snapshot) => (
                    <div
                      className="TaskList"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      isdraggingover={
                        snapshot.isDraggingOver
                          ? snapshot.isDraggingOver.toString()
                          : null
                      }
                    >
                      {this.props.structureSections.map((section, index) => {
                        return (
                          <InternalDragDrop
                            key={section.id}
                            section={section}
                            subpage={this.props.column}
                            registers={this.props.column.sections}
                            state={this.props.state}
                            previousPath={"myWebPage"}
                            index={index}
                            lpUrl={this.props.state.lpUrl}
                            handleClickOpenDeleteSection={
                              this.handleClickOpenDeleteSection
                            }
                          />
                        );
                      })}
                      {addComponent}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          )}
        </Draggable>
      </>
    );
  }
}
export default withTranslation()(HorizontalDragDrop);
