import React, { Component, Fragment } from "react";

// Queries
import {
  getUserById,
  getOrganizationById,
  changePassword,
  getCountries,
  getRegionsByCountryId,
  getCitiesByRegionId,
  getRolesByOrganizationId,
} from "../../actions/index";

// Components
import {
  Breadcrumb,
  GeneralInput,
  AccordionComponent,
  ButtonsFooterComponent,
  DatePickerComponent,
  SelectSingle,
  // SelectMulti,
} from "../../components/index";

// Material
import {
  Card,
  CircularProgress,
  Avatar,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import "../webpages/sections/css/sections.scss";

//Localization
import { withTranslation } from "react-i18next";
import { User } from "react-feather";

const tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;
// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class UserAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,

      user: {
        organizationId: null,
        userId: null,
        name: null,
        lastName1: null,
        lastName2: null,
        documentIdNumber: null,
        email: null,
        phone: null,
        username: null,
        dateOfBirth: null,
        roleIds: [],
      },

      password: {
        userId: null,
        currentPass: null,
        newPass: null,
        repeatPass: null,
      },

      loadingInfo: true,
      loadingPasswordChange: false,
      changePassFieldsFilled: true,
      showPassword: false,

      isLoadingButton: false,
      isReadyToEdit: false,

      countries: [],
      regions: [],
      cities: [],
      roles: [],
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const accessToken = sessionStorage.getItem("access_token");

    const base64Url = accessToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join("")
    );

    const payload = JSON.parse(jsonPayload);

    this.getOrganizationByIdFunction(payload.organization_id);
    this.getUserByIdFunction(payload.user_id);
    this.getCountries();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getUserByIdFunction(id) {
    let user = { ...this.state };
    let password = { ...this.state };
    const response = await getUserById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        user = {
          id: response.data.data.id,
          name: response.data.data.name,
          lastName1: response.data.data.lastName1,
          lastName2: response.data.data.lastName2
            ? response.data.data.lastName2
            : "",
          identityCardNumber: response.data.data.identityCardNumber,
          countryId: response.data.data.countryId,
          regionId: response.data.data.regionId,
          cityId: response.data.data.cityId,
          email: response.data.data.email,
          phone: response.data.data.phone ? response.data.data.phone : "",
          username: response.data.data.username,
          dateOfBirth: response.data.data.dateOfBirth,
          roleIds: response.data.data.roles,
        };
        password = {
          userId: response.data.data.id,
        };
      }
      this.setState({
        user,
        password,
        loadingInfo: false,
      });
    }
  }

  async getOrganizationByIdFunction(id) {
    const response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        this.setState({
          organizationId: response.data.data.id,
          organizationName: response.data.data.name,
        });
      }
    }
  }

  async getCountries() {
    let countries = [];
    const response = await getCountries();

    if (String(response).includes("Error:")) {
    } else {
      countries = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ countries });
  }

  async getRegions(countryId) {
    let regions = [];
    const response = await getRegionsByCountryId(countryId);

    if (String(response).includes("Error:")) {
    } else {
      regions = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ regions });
  }

  async getCities(regionId) {
    let cities = [];
    const response = await getCitiesByRegionId(regionId);

    if (String(response).includes("Error:")) {
    } else {
      cities = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ cities });
  }

  async getRolesByOrganizationIdFunction(organizationId) {
    let roles = [];
    const response = await getRolesByOrganizationId(organizationId);

    if (String(response).includes("Error:")) {
    } else {
      roles = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ roles });
  }

  async changePass() {
    const { password } = this.state;
    this.setState({ loadingPasswordChange: true });
    const response = await changePassword(password);
    if (response) {
      this.setState({ loadingPasswordChange: false });
      if (String(response).includes("Error:")) {
        // message.warning(Strings.generalMessages.errorChangePassword);
      } else {
        const ans = response.data;
        if (ans.httpErrorCode === 200 || ans.httpErrorCode === 201) {
          // message.success(Strings.generalMessages.sucessChangePassword);
        } else {
          // message.warning(ans.description);
        }
      }
    }
  }

  // Events
  handleInputChange = (event) => {
    const { password } = this.state;
    const { id, value } = event.target;

    password[id] = value;

    let changePassFieldsFilled = true;

    switch (id) {
      case "oldPassword":
        changePassFieldsFilled = !(
          password.repeatNewPassword && password.newPassword
        );
        break;
      case "newPassword":
        changePassFieldsFilled = !(
          password.repeatNewPassword && password.oldPassword
        );
        break;
      case "repeatNewPassword":
        changePassFieldsFilled = !(
          password.newPassword && password.oldPassword
        );
        break;
      default:
        break;
    }

    this.setState({
      password,
      changePassFieldsFilled,
    });
  };

  handleFormSelect = (option, event) => {
    switch (event.name) {
      case "countryId":
        this.setState({
          countryId: option.value,
          regionId: null,
          cityId: null,
        });
        this.getRegions(option.value);
        break;
      case "regionId":
        this.setState({
          regionId: option.value,
          cityId: null,
        });
        this.getCities(option.value);
        break;
      default:
        break;
    }
  };

  onPasswordChange = () => {
    const { password } = this.state;
    if (password.newPassword === password.repeatNewPassword) {
      this.changePass();
    } else {
      // message.warning(Strings.generalMessages.passwordNoMatch);
    }
  };

  handleClickShowPassword = (event) => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { user } = { ...this.state };

    user[id] = value;

    this.setState({
      user,
    });
  };

  render() {
    const {
      user,
      password,
      showPassword,
      isLoadingButton,
      isReadyToEdit,

      countries,
      regions,
      cities,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <Card className="card">
          {isLoadingButton === true ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(800%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row componentSpace spaceBreadcrumb">
                <Breadcrumb
                  principal={t("generalUser.myaccount")}
                  icon={<User />}
                  route="./myWebPage"
                  propsComponent={{
                    user,
                  }}
                />
              </div>

              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                <AccordionComponent
                  disabled={isReadyToEdit}
                  title={t("generalUser.personalInformation")}
                  body={[
                    <div className="accordionContent">
                      {/* First Line */}
                      <div className="accordionCenterLine">
                        <div
                          style={{
                            marginLeft: "1.5%",
                            marginRight: "1%",
                            display: "flex",
                            width: "45%",
                          }}
                        >
                          <GeneralInput
                            disabled={isReadyToEdit}
                            className="textInputWidth"
                            placeholder={t("basicWords.name")}
                            id="name"
                            value={user.name}
                            onChange={(e) => this.handleFormChange(e)}
                          />
                        </div>
                        <div
                          style={{
                            marginRight: "1%",
                            marginLeft: "1%",
                            display: "flex",
                            width: "46%",
                          }}
                          className="col-xs-12 col-md-6"
                        >
                          <GeneralInput
                            disabled={isReadyToEdit}
                            className="textInputWidth"
                            placeholder={t("generalUser.cellphoneNumber")}
                            id="phone"
                            onChange={(e) => this.handleFormChange(e)}
                            value={user.phone}
                          />
                        </div>
                      </div>

                      {/* Second Line */}
                      <br />
                      <div className="accordionCenterLine">
                        <div
                          className="col-xs-12 col-md-6"
                          style={{
                            display: "flex",
                            width: "47%",
                            marginLeft: "1%",
                          }}
                        >
                          <div
                            style={{
                              marginLeft: "1%",
                              marginRight: "1%",
                              width: "47%",
                            }}
                          >
                            <GeneralInput
                              disabled={isReadyToEdit}
                              className="textInputWidth"
                              t
                              placeholder={t("generalUser.lastName1")}
                              id="lastName1"
                              onChange={(e) => this.handleFormChange(e)}
                              value={user.lastName1 ? user.lastName1 : null}
                            />
                          </div>
                          <div
                            style={{
                              marginRight: "1%",
                              marginLeft: "1%",
                              width: "47%",
                            }}
                          >
                            <GeneralInput
                              disabled={isReadyToEdit}
                              className="textInputWidth"
                              placeholder={t("generalUser.lastName2")}
                              id="lastName2"
                              onChange={(e) => this.handleFormChange(e)}
                              value={user.lastName2 ? user.lastName2 : null}
                            />
                          </div>
                        </div>
                        <div
                          className="col-xs-12 col-md-6"
                          // style={{ display: "flex" }}
                        >
                          <div
                            style={{
                              marginRight: "1%",
                              marginLeft: "5%",
                              width: "100%",
                              // display: "flex",
                            }}
                          >
                            <GeneralInput
                              disabled={isReadyToEdit}
                              className="textInputWidth"
                              placeholder={t("generalUser.userEmail")}
                              id="email"
                              onChange={(e) => this.handleFormChange(e)}
                              value={user.email}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Third Line */}
                      <br />
                      <div className="accordionCenterLine">
                        <div
                          className="col-xs-12 col-md-6"
                          style={{ display: "flex", marginLeft: "1%" }}
                        >
                          <div
                            style={{
                              marginLeft: "3%",
                              width: "100%",
                            }}
                          >
                            <GeneralInput
                              disabled={isReadyToEdit}
                              className="textInputWidth"
                              t
                              placeholder={t("generalUser.documentIdNumber")}
                              id="documentIdNumber"
                              onChange={(e) => this.handleFormChange(e)}
                              value={
                                user.identityCardNumber
                                  ? user.identityCardNumber
                                  : null
                              }
                            />
                          </div>
                        </div>
                        <div
                          className="col-xs-12 col-md-6"
                          style={{ display: "flex" }}
                        >
                          <div
                            style={{
                              marginRight: "5%",
                              marginLeft: "10%",
                            }}
                          >
                            <p>{t("basicWords.userImage")}</p>
                          </div>
                          <div
                            style={{
                              marginRight: "1%",
                              marginLeft: "1%",
                            }}
                          >
                            <Avatar className="profilePhoto">
                              {user && user.name ? user.name[0] : ""}
                            </Avatar>
                          </div>
                        </div>
                      </div>

                      {/* Fourth Line */}
                      <br />
                      <div
                        className="accordionCenterLine"
                        style={{ marginLeft: "0.3%" }}
                      >
                        <div
                          style={{
                            width: "48%",
                            marginLeft: "1%",
                            marginRight: "1%",
                          }}
                        >
                          <DatePickerComponent
                            onChange={(e) => this.handleFormChange(e)}
                            text={t("basicWords.birthDate")}
                            defaultValue={
                              user.dateOfBirth ? user.dateOfBirth : null
                            }
                            string={t("basicWords.birthDate")}
                          />
                        </div>
                      </div>

                      {/* Country Line */}
                      <br />
                      <div className="accordionCenterLine">
                        <div
                          style={{
                            width: "48%",
                            marginLeft: "1.2%",
                            marginRight: "1%",
                          }}
                        >
                          <SelectSingle
                            text={t("basicWords.country")}
                            isReq
                            isDisabled={false}
                            isLoading={false}
                            name="countryId"
                            onChange={(option, event) =>
                              this.handleFormSelect(option, event)
                            }
                            defaultValue={user.countryId}
                            options={countries}
                          />
                        </div>
                      </div>

                      {/* Region and City Line */}
                      <br />
                      <div className="accordionCenterLine">
                        <div
                          style={{
                            width: "23%",
                            marginLeft: "1.2%",
                            marginRight: "1%",
                          }}
                        >
                          <SelectSingle
                            text={t("basicWords.region")}
                            isReq
                            isDisabled={false}
                            isLoading={false}
                            name="regionId"
                            onChange={(option, event) =>
                              this.handleFormSelect(option, event)
                            }
                            defaultValue={user.regionId}
                            options={regions}
                          />
                        </div>
                        <div
                          style={{
                            width: "23%",
                            marginLeft: "1%",
                            marginRight: "1%",
                          }}
                        >
                          <SelectSingle
                            text={t("basicWords.city")}
                            isReq
                            isDisabled={false}
                            isLoading={false}
                            name="cityId"
                            onChange={(option, event) =>
                              this.handleFormSelect(option, event)
                            }
                            defaultValue={user.cityId}
                            options={cities}
                          />
                        </div>
                      </div>
                    </div>,
                  ]}
                />
              </div>

              {/* Password accordion */}
              <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth adjustPositionYBottom">
                <AccordionComponent
                  disabled={isReadyToEdit}
                  title={t("generalUser.changePassword")}
                  body={[
                    <div className="accordionContent">
                      {/* First Line */}
                      <div className="accordionCenterLine">
                        {/* Actual */}
                        <div
                          style={{
                            width: "32%",
                            marginLeft: "1%",
                            marginRight: "1%",
                          }}
                        >
                          <FormControl
                            variant="outlined"
                            sx={{ m: 1, width: "25ch" }}
                          >
                            <InputLabel htmlFor="passwordOut">
                              {t("generalUser.currentPassword")}
                            </InputLabel>
                            <OutlinedInput
                              id="oldPassword"
                              value={password.oldPassword}
                              type={showPassword ? "text" : "password"}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              labelWidth={70}
                            />
                          </FormControl>
                        </div>

                        {/* New */}
                        <div
                          style={{
                            width: "32%",
                            marginRight: "1%",
                            marginLeft: "1%",
                          }}
                        >
                          <FormControl
                            variant="outlined"
                            sx={{ m: 1, width: "25ch" }}
                          >
                            <InputLabel htmlFor="passwordOut">
                              {t("generalUser.newPassword")}
                            </InputLabel>
                            <OutlinedInput
                              id="newPassword"
                              value={password.newPassword}
                              type={showPassword ? "text" : "password"}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              labelWidth={70}
                            />
                          </FormControl>
                        </div>

                        {/* Repeat */}
                        <div
                          style={{
                            width: "32%",
                            marginRight: "1%",
                            marginLeft: "1%",
                          }}
                        >
                          <FormControl
                            variant="outlined"
                            sx={{ m: 1, width: "25ch" }}
                          >
                            <InputLabel htmlFor="passwordOut">
                              {t("generalUser.repeatNewPassword")}
                            </InputLabel>
                            <OutlinedInput
                              id="repeatNewPassword"
                              value={password.repeatNewPassword}
                              type={showPassword ? "text" : "password"}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              labelWidth={70}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>,
                  ]}
                />
              </div>
            </>
          )}
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={{}}
          FlagclassName={true}
          onClick={() => this.onPasswordChange()}
        />
      </>
    );
  }
}
export default withTranslation()(UserAccount);
