import React from "react";
import Iframe from "react-iframe";
import { Card } from "@material-ui/core";
const aurApp = () => (
  <div className="col-lg-12 mx-auto d-block">
    <br />
    <Card style={{height: "700px"}}>
      <Iframe
        url="https://aurapp.org/"
        width="100%"
        height="700px"
        id="hubAurApp"
        display="initial"
        position="relative"
        style={{height: "100vh !important"}}
      />
    </Card>
  </div>
);
export default aurApp;
