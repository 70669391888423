import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Card,
} from "@material-ui/core";
//Queries
import {
  getCountries,
  getRegionsByCountryId,
  getCitiesByRegionId,
  getOrganizationsPaged,
  disableOrganization,
} from "../../actions/index";
//Components
import {
  SelectSingle,
  Title,
  Breadcrumb,
  ButtonComponent,
  GeneralModal,
  NotificationModal,
} from "../../components/index";
import { Edit, Trash2, CheckCircle, Home } from "react-feather";
import { ValidatePermissionForComponentPart } from "../../reactUtils/generalUtils/validatePermissionForComponentPart";
//Localization
import { withTranslation } from 'react-i18next';

// Logos
import AuraLogin from "../../assets/images/loginLogo.png";
import UhooLogin from "../../assets/images/LogoUhooColor.png";

let tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;

class OrganizationsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,

      countries: [],
      countryId: null,
      regions: [],
      regionId: null,
      regionQueryId: null,
      cities: [],
      cityId: null,
      cityQueryId: null,
      organizationName: "",

      loadingInfo: false,
      registers: [],

      //pagination
      pageSize: 10,
      registersAmount: 0,
      page: 1,
      attribute: "id",
      order: "ASC",
      initialPage: 0,

      flagChange: false
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({ imageLogin: AuraLogin, helmetPage: "Somos Aura | CMS" });
    } else {
      this.setState({ imageLogin: UhooLogin, helmetPage: "Uhoo | CMS" });
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKaADAAQAAAABAAAAKQAAAADAIIRfAAAFK0lEQVRYCbVZW2icRRQ+ZzaxjWml1jRpo33QCgrxQZB6QYoNGgvWF7Fg+iAWEeODPgQxyfapTyZbQVEiGgQfFDGFpohFkQYhKqKlD0JVpK1oa2torlZTbEJ2/+M38++6t3/ObNPsQPLPzLnMN3POzJw5y7TCIiPt19Ol7KMUSReRbIWadhJuR/0mIp4jlkn04Y/Pk+Fx2tBwjHsm/13JcHw1QiIHDA0NPwWZbgzeRSJNNcszX8EExsE/SgMvHmI+ENUqWzNIeXXTY1CawUB31arcz8c/gdbP+2c+9/MUKUGQ8lrbrZTNvU9CO4tiq1RjmqCG1LP8ytTvmkYVpBxseYhyPAazws/qVBj+m5InuW/2K98IxkeQodYeytF4XQHawe0CYBw3ngdM4ko6gSh61yNTv25jXuCB6ZHKAapAyuDmnUTZY/DBxkrmureZlilFXZWmLzO32ySUO6wAzBLTKXLHyQogWxBWnngxUdouDPZAjKPIUQYy3sVJmwRK2fTRlnXrOT17J93fcQMZ2guws0VVSi2eVC81b4jlH+hYT8Y8DcB/VUlZH7WnSUn539zxOSifldCKVWOega98UOyIa3CN7XCN77DyqUpaWZu5m9Mzh8r60JCDrQ9SNvoa1fLFcoy8u3COOqK7SdxBXanGtvlkEkBHSV88ge+orXsL04kkgJaf+6a/xf8jHtlMHld+Bpl3cM35bhI+7lESd3OATgE68/fJ+oEnvoLzICVn72NPkTkPId/Nf6t0oX9UOqn6sXjwBRfN2GDBVxjurZUIHqmVkLxo8txl8RkXbmnRjIgO0miDAL0EJmnYP0mLC+GgieNBbSlCNNZDrtBKhtQjXsXudgGrn5UDx4vk/CthtYYsEXIX4LNHEKJprZiEM6yEnwPmDq1kyF2ADyw25FcKB3xSFJ+yakMg1Y0DeeCz5tZjxZDjh1YyKB+YJPBZU87YCStF392hlVAU10biOQvyzwBzg0oXs6TTeVmlRxK492XSIJIJgWxTB2E+qdKN+VGlM7WqdDyL7d66EGC6WaNz+uJZTHQskYf5DN2Y+iSRVujk0OnC5w2leKLAn/gVuhtBaHMirdC5tvk5xJsfF5ruy3QcZ+TjwYSARDvK5CobSCywu7vnl6eh0A+EzR5OTyevVolSGWy7DUdOB0W5U5SeOcOs71zJbG2n6MoF79Vpg+WNjS0mP1P9kS7SU4LFW+X01G88MHWU98+eDgF0SnJLPV6AjkHGLb74NmE67B05Zu7CC3KXznN1VHmjZQvyRS8HpFxAHYO8r+MIzPSLKhDJ2/L6LRtVnhqJLuJe5PdUFyOkYpAzsiodSO6cwCvQ9OpjyDZaWhyTkXsadb4aqJnhDADuDnD2F5JaZbeJDG46andkQPhLalq3h3vPXgrwVZHdBOfPDcMPn68ilnYgR4RXaWehKzZ3oUXUC7PrzwGih2nx8s+Y0L7CQ6ko7q9JZvO9NH/umzBA5IaQxCrVVLaSlgBlnZTLfoHqdaWMiXXmHzDom8RNn3L6j6o3tLx1+xq6vLCLONoHCz2RqKO005PBqAJpZWSwZS8+H+nHQ6l2inDr/Iqe04jNFqBhLWS3wSp3ANyaMk6tUWsuqKAD5uzHAEOFdl2/Lv1iXkpKVtlxE1fSEsSG/UOtC6j4byLLeK3lWvKTlGndUX+ANtNrtldm0SrnnRgr4g5+hATZtbqVfM48vcKcuQy1dGMbfAh8iRNYMW6XWVulXx+wYUZhZiXtEoDJSC4LT6/m7zj/AexV0r66K8xIAAAAAElFTkSuQmCC";
    }
    this.getCountries();
  }

  componentWillMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };
  handleClose = () => {
    this.setState({ openDialog: false });
  };

  //Queries
  async getCountries() {
    let countries = [];
    let response = await getCountries();

    if (String(response).includes("Error:")) {
    } else {
      countries = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ countries });
  }
  async getRegions(countryId) {
    let regions = [];
    let response = await getRegionsByCountryId(countryId);

    if (String(response).includes("Error:")) {
    } else {
      regions = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ regions });
  }

  async getCities(regionId) {
    let cities = [];
    let response = await getCitiesByRegionId(regionId);

    if (String(response).includes("Error:")) {
    } else {
      cities = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ cities });
  }

  async getOrganizations(regionId, cityId, name, page, size, attribute, order) {
    let registers = this.state.registers;
    let registersAmount = 0;
    if (page === 0) {
      return registers;
    } else {
      let response = await getOrganizationsPaged(
        regionId,
        cityId,
        2,
        name,
        page,
        size,
        attribute,
        order
      );

      if (String(response).includes("Error:")) {
      } else {
        let flag = this.state.flagChange;
        if(registers.length === 10 && !flag && cityId !== -1) {
          registers = response.data.data.content;
          registersAmount = response.data.data.totalElements;
          this.setState({
            flagChange: true
          });
        } else {
          registers = [...registers, ...response.data.data.content];
          registersAmount = response.data.data.totalElements;
        }
      }
    }
    this.setState({
      registers,
      registersAmount,
      loadingInfo: false,
    });
  }

  async disableOrganization(organizationId) {
    let registers = [];
    let registersAmount = 0;
    let response = await disableOrganization(organizationId);
    const {t} = this.props

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal:
          t('modal.internalServerErrorContactSupport'),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.disablingSuccessful'),
        contenNotificationModal: t('modal.organizationSuccesfullDisabled'),
        typeNotificationModal: "success",
      });

      let response = await getOrganizationsPaged(
        this.state.regionQueryId,
        this.state.cityQueryId,
        this.state.organizationName,
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order
      );

      if (String(response).includes("Error:")) {
      } else {
        registers = response.data.data.content;
        registersAmount = response.data.data.totalElements;
      }

      this.setState({
        registers,
        registersAmount,
        loadingInfo: false,
        openNotificationModal: true,
        openDialog: false,
      });
    }
  }

  //Events
  onSelect = (e, event) => {
    let option = e.target;
    switch (event) {
      case "country":
        this.setState({
          countryId: option.value,
          regionId: null,
          cityId: null,
          registers: [],
          registersAmount: 0,
        });
        this.getRegions(option.value);
        break;
      case "region":
        this.setState({
          regionId: option.value,
          regionQueryId: option.value,
          cityQueryId: -1,
          cityId: null,
          registers: [],
          registersAmount: 0,
          loadingInfo: true,
        });
        this.getCities(option.value);
        this.getOrganizations(
          option.value,
          -1,
          this.state.organizationName,
          this.state.page,
          this.state.pageSize,
          this.state.attribute,
          this.state.order
        );
        break;
      case "city":
        this.setState({
          regionQueryId: -1,
          cityId: option.value,
          cityQueryId: option.value,
          loadingInfo: true,
          registers: [],
          registersAmount: 0,
        });
        this.getOrganizations(
          -1,
          option.value,
          this.state.organizationName,
          this.state.page,
          this.state.pageSize,
          this.state.attribute,
          this.state.order
        );
        break;
    }
  };

  disableOrganizationEvent = (id) => {
    this.disableOrganization(id);
  };

  // Pagination
  showPagination = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
      loadingInfo: true,
    });
    this.getOrganizations(
      this.state.regionQueryId,
      this.state.cityQueryId,
      this.state.organizationName,
      page,
      pageSize,
      this.state.attribute,
      this.state.order
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, initialPage: newPage });
    if (this.state.registers.length < this.state.registersAmount) {
      this.showPagination(newPage + 1, this.state.pageSize);
    } else {
      return this.state.registers;
    }
  };

  handleChangeRowsPerPage = (event) => {
    if (event !== undefined) {
      this.setState({
        pageSize: parseInt(event.target.value, 10) + 1,
        page: 0,
      });
    }
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  //Info to render
  render() {
    const { privileges } = this.props;
    const {
      registers,
      pageSize,
      initialPage,
      registersAmount,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const {t} = this.props
    return (
      <>
        <Fragment>
          <NotificationModal
            visible={openNotificationModal}
            onClick={this.handleCloseNotificationModal}
            title={titleNotificationModal}
            content={contenNotificationModal}
            type={typeNotificationModal}
          />
          <Card className="card">
            <div className="row componentSpace">
              <Breadcrumb
                principal={t('organization.organizations')}
                icon={<Home />}
                secondary={t('sections.home')}
              />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="col-md-6 componentSpace"
                style={{
                  marginLeft: "5px",
                  width: "20vw",
                  paddingRight: "15px",
                }}
              >
                <SelectSingle
                  selectedOptions={this.state.countryId}
                  text={t('basicWords.country') + "*"}
                  onChange={(option) => this.onSelect(option, "country")}
                  options={this.state.countries}
                />
              </div>

              <div
                className="col-md-6 componentSpace"
                style={{ marginLeft: "0%", width: "20vw" }}
              >
                <SelectSingle
                  selectedOptions={this.state.regionId}
                  text={t('basicWords.region') + "*"}
                  onChange={(option) => this.onSelect(option, "region")}
                  options={this.state.regions}
                />
              </div>

              <div
                className="col-md-6 componentSpace"
                style={{ marginLeft: "0%", width: "20vw" }}
              >
                <SelectSingle
                  selectedOptions={this.state.cityId}
                  text={t('basicWords.city')}
                  onChange={(option) => this.onSelect(option, "city")}
                  options={this.state.cities}
                />
              </div>
              {ValidatePermissionForComponentPart(
                "ADD_ORGANIZATION",
                privileges
              ) ? (
                <div
                  className="row componentSpace"
                  style={{ marginLeft: "0%" }}
                >
                  <div className="col-6" />
                  <div className="col-md-6" style={{ width: "20vw" }}>
                    <ButtonComponent
                      text={t('basicWords.createOrganization')}
                      icon={<CheckCircle />}
                      path={"./addOrganization"}
                      props={null}
                      shape="round"
                      isDisabled={false}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row componentSpace">
              <Title title={t('organization.organizationList')} />
            </div>
            <div className="row componentSpace">
              <TableContainer style={{ maxHeight: "440" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('basicWords.name')}</TableCell>
                      <TableCell>{t('basicWords.telephone')}</TableCell>
                      <TableCell>{t('basicWords.address')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registers
                      .slice(
                        initialPage * pageSize,
                        initialPage * pageSize + pageSize
                      )
                      .map((row) => {
                        return (
                          <TableRow key={row.name}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell>
                              <div
                                className="containerIconsTable"
                                style={{ justifyContent: "space-evenly" }}
                              >
                                <div className="bgIconEdit">
                                  <Link
                                    to={{
                                      pathname: "/editOrganization",
                                      props: {
                                        organizationId: row.id,
                                        countryId: row.countryId,
                                        regionId: row.regionId,
                                        cityId: row.cityId,
                                        path: "./organizations",
                                      },
                                    }}
                                  >
                                    <Edit className="iconEdit" />
                                  </Link>
                                </div>
                                <div className="bgIconTrash">
                                  <Trash2
                                    onClick={() => this.handleClickOpen()}
                                    className="iconTrash"
                                  />
                                </div>
                              </div>
                            </TableCell>
                            <GeneralModal
                              visible={this.state.openDialog}
                              title={t('basicWords.deleteRegister')}
                              footer={[
                                <div
                                  className="row"
                                  key={1}
                                  style={{ display: "flex" }}
                                >
                                  <div className="col-6" style={{ marginRight: "5%" }}>
                                    <ButtonComponent
                                      onClick={(e) => this.handleClose()}
                                      props={null}
                                      shape="round"
                                      text={t('basicWords.cancelText')}
                                    />
                                  </div>
                                  <div>
                                    <ButtonComponent
                                      onClick={() =>
                                        this.disableOrganization(row.id)
                                      }
                                      props={null}
                                      shape="round"
                                      text={t('basicWords.okText')}
                                    />
                                  </div>
                                </div>,
                              ]}
                              onClick={(e) => this.handleClose()}
                            />
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={['5', '10', '25']}
                component="div"
                disabled={registers.length === 0}
                count={registersAmount}
                rowsPerPage={pageSize}
                page={initialPage}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          </Card>
        </Fragment>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
    userSession: state.userSession.userSession,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(OrganizationsList));
