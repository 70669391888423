import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Card, CircularProgress } from "@material-ui/core";
import { Instagram, Facebook, Twitter, Youtube } from "react-feather";

import "../css/sections.scss";

// Queries
import {
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
} from "../../../../actions/index";

// Components
import {
  GeneralInput,
  BreadcrumbSection,
  NotificationModal,
  TitleCard,
  ButtonsFooterComponent,
} from "../../../../components/index";

//Localization
import { withTranslation } from "react-i18next";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class ContactSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      landingPageId: null,
      organizationId: null,
      organizationName: null,
      sectionId: null,
      sectionName: null,
      country: null,
      region: null,
      city: null,

      loadingInfo: true,

      contactUsTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      contactUsText: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      contactUsAddress: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      contactUsEmail: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      contactUsPhone: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      contactUsTwitter: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      contactUsFacebook: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      contactUsInstagram: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      contactUsYoutube: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },
      FlagclassName: false,
      goToWithoutSaveModalVisible: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { props } = this.props.location;

    if (props) {
      this.getContactInformation(props.landingPageId, props.sectionId);

      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getContactInformation(lpId, lpSectionId) {
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "CONTACT"
    );
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      // Get all the information about the section components
      const sectionInfo = response.data.data;
      const contactComponent = sectionInfo.components.find(
        (comp) => comp.name === "ContactContainerComponent"
      );
      const contactUsTitle = {
        id: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsTitle"
        ).id,
        value: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsTitle"
        ).value,
        nodeTypeName: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsTitle"
        ).nodeType,
        minLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsTitle"
        ).minLength,
        maxLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsTitle"
        ).maxLength,
        length: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsTitle"
        ).value.length,
      };
      const contactUsText = {
        id: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsText"
        ).id,
        value: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsText"
        ).value,
        nodeTypeName: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsText"
        ).nodeType,
        minLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsText"
        ).minLength,
        maxLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsText"
        ).maxLength,
        length: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsText"
        ).value.length,
      };
      const contactUsAddress = {
        id: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsAddress"
        ).id,
        value: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsAddress"
        ).value,
        nodeTypeName: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsAddress"
        ).nodeType,
        minLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsAddress"
        ).minLength,
        maxLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsAddress"
        ).maxLength,
        length: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsAddress"
        ).value.length,
      };
      const contactUsEmail = {
        id: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsEmail"
        ).id,
        value: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsEmail"
        ).value,
        nodeTypeName: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsEmail"
        ).nodeType,
        minLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsEmail"
        ).minLength,
        maxLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsEmail"
        ).maxLength,
        length: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsEmail"
        ).value.length,
      };
      const contactUsPhone = {
        id: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsPhone"
        ).id,
        value: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsPhone"
        ).value,
        nodeTypeName: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsPhone"
        ).nodeType,
        minLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsPhone"
        ).minLength,
        maxLength: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsPhone"
        ).maxLength,
        length: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsPhone"
        ).value.length,
      };
      const contactUsTwitter = {
        id: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsTwitter"
        ).id,
        value: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsTwitter"
        ).value,
        nodeTypeName: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsTwitter"
        ).nodeType,
      };
      const contactUsFacebook = {
        id: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsFacebook"
        ).id,
        value: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsFacebook"
        ).value,
        nodeTypeName: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsFacebook"
        ).nodeType,
      };
      const contactUsInstagram = {
        id: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsInstagram"
        ).id,
        value: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsInstagram"
        ).value,
        nodeTypeName: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsInstagram"
        ).nodeType,
      };
      const contactUsYoutube = {
        id: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsYoutube"
        ).id,
        value: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsYoutube"
        ).value,
        nodeTypeName: contactComponent.nodes.find(
          (node) => node.nodeType === "ContactUsYoutube"
        ).nodeType,
      };

      this.setState({
        contactUsTitle,
        contactUsText,
        contactUsAddress,
        contactUsEmail,
        contactUsPhone,
        contactUsTwitter,
        contactUsFacebook,
        contactUsInstagram,
        contactUsYoutube,
        loadingInfo: false,
      });
    }
  }

  async updateNode(nodesToUpdate) {
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t("modal.internalServerError"),
        contenNotificationModal: t("modal.internalServerErrorContactSupport"),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t("modal.editionSuccess"),
        contenNotificationModal: t("modal.contactSuccessfullyEdited"),
        typeNotificationModal: "success",
      });
    }
    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
    });
  }

  // Events
  handleChange = (event) => {
    const { id, value } = event.target;
    const { contactUsTitle } = { ...this.state };
    const { contactUsText } = { ...this.state };
    const { contactUsAddress } = { ...this.state };
    const { contactUsEmail } = { ...this.state };
    const { contactUsPhone } = { ...this.state };
    const { contactUsTwitter } = { ...this.state };
    const { contactUsFacebook } = { ...this.state };
    const { contactUsInstagram } = { ...this.state };
    const { contactUsYoutube } = { ...this.state };

    if (id === "ContactUsTitle") {
      if (value.length <= contactUsTitle.maxLength) {
        contactUsTitle.value = value;
        contactUsTitle.modified = true;
        contactUsTitle.length = value.length;
      }
    }
    if (id === "ContactUsText") {
      if (value.length <= contactUsText.maxLength) {
        contactUsText.value = value;
        contactUsText.modified = true;
        contactUsText.length = value.length;
      }
    }
    if (id === "ContactUsAddress") {
      if (value.length <= contactUsAddress.maxLength) {
        contactUsAddress.value = value;
        contactUsAddress.modified = true;
        contactUsAddress.length = value.length;
      }
    }
    if (id === "ContactUsEmail") {
      if (value.length <= contactUsEmail.maxLength) {
        contactUsEmail.value = value;
        contactUsEmail.modified = true;
        contactUsEmail.length = value.length;
      }
    }
    if (id === "ContactUsPhone") {
      if (value.length <= contactUsPhone.maxLength) {
        contactUsPhone.value = value;
        contactUsPhone.modified = true;
        contactUsPhone.length = value.length;
      }
    }
    if (id === "ContactUsTwitter") {
      contactUsTwitter.value = value;
      contactUsTwitter.modified = true;
    }
    if (id === "ContactUsFacebook") {
      contactUsFacebook.value = value;
      contactUsFacebook.modified = true;
    }
    if (id === "ContactUsInstagram") {
      contactUsInstagram.value = value;
      contactUsInstagram.modified = true;
    }
    if (id === "ContactUsYoutube") {
      contactUsYoutube.value = value;
      contactUsYoutube.modified = true;
    }

    this.setState({
      contactUsTitle,
      contactUsText,
      contactUsAddress,
      contactUsEmail,
      contactUsPhone,
      contactUsTwitter,
      contactUsFacebook,
      contactUsInstagram,
      contactUsYoutube,
      FlagclassName: true,
    });
  };

  saveContactEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagclassName: false,
    });
    const nodesToUpdate = [];
    const { contactUsTitle } = { ...this.state };
    const { contactUsText } = { ...this.state };
    const { contactUsAddress } = { ...this.state };
    const { contactUsEmail } = { ...this.state };
    const { contactUsPhone } = { ...this.state };
    const { contactUsTwitter } = { ...this.state };
    const { contactUsFacebook } = { ...this.state };
    const { contactUsInstagram } = { ...this.state };
    const { contactUsYoutube } = { ...this.state };
    let errorInput = false;

    const { t } = this.props;

    // Verify obligatory values of the component to add
    if (contactUsTitle.modified) {
      if (contactUsTitle.value === "") {
        errorInput = true;
      }
      nodesToUpdate.push(contactUsTitle);
    }
    if (contactUsText.modified) {
      // if (contactUsText.value === "") {
      //   errorInput = true;
      // }
      nodesToUpdate.push(contactUsText);
    }
    if (contactUsAddress.modified) {
      nodesToUpdate.push(contactUsAddress);
    }
    if (contactUsEmail.modified) {
      nodesToUpdate.push(contactUsEmail);
    }
    if (contactUsPhone.modified) {
      nodesToUpdate.push(contactUsPhone);
    }
    if (contactUsTwitter.modified) {
      nodesToUpdate.push(contactUsTwitter);
    }
    if (contactUsFacebook.modified) {
      nodesToUpdate.push(contactUsFacebook);
    }
    if (contactUsInstagram.modified) {
      nodesToUpdate.push(contactUsInstagram);
    }
    if (contactUsYoutube.modified) {
      nodesToUpdate.push(contactUsYoutube);
    }
    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          titleNotificationModal: t("modal.wrongFile"),
          contenNotificationModal: t("modal.fieldEmpty"),
          typeNotificationModal: "error",
        });
        this.setState({
          openNotificationModal: true,
          isLoadingButton: false,
        });
      }
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("modal.sectionUnselected"),
      contenNotificationModal: t("modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({ openNotificationModal: true });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  // Render Info
  render() {
    const {
      redirectBack,
      sectionName,
      contactUsTitle,
      contactUsText,
      contactUsTwitter,
      contactUsFacebook,
      contactUsInstagram,
      contactUsYoutube,
      contactUsAddress,
      contactUsEmail,
      contactUsPhone,
      organizationId,
      landingPageId,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
      FlagclassName,
      lpUrl,
      isLoadingButton,
      goToWithoutSaveModalVisible,
    } = this.state;

    const { t } = this.props;
    if (redirectBack) {
      return <Redirect to="/myWebPage" />;
    }

    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Card className="card">
          {isLoadingButton === true ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(800%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row spaceBreadcrumb">
                <BreadcrumbSection
                  principal={t("sections.contactUs")}
                  secondary={this.state.organizationName}
                  route="./myWebPage"
                  propsComponent={{
                    organizationId,
                    sectionName: sectionName,
                    lpId: landingPageId,
                    lpUrl: lpUrl,
                  }}
                  FlagclassName={FlagclassName}
                  onClick={(e) => this.saveButtonEvent(e)}
                  GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                  onCancelGotoWithoutMModal={(e) =>
                    this.onCancelGotoWithoutMModal(e)
                  }
                  goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                />
              </div>

              <Card
                className="card"
                style={{ margin: "2% 5.5% 1% 5.5% ", height: "auto" }}
              >
                <div className="row mainContent">
                  <div
                    className="rowSection adjustPositionYTop"
                    style={{
                      margin: "1%",
                    }}
                  >
                    <TitleCard text={t("generalUser.contentSection")} />
                  </div>
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "50%",
                        marginLeft: "1%",
                        marginRight: "0.7%",
                      }}
                    >
                      <GeneralInput
                        className="textInputWidth"
                        placeholder={t("generalMessages.sectionTitle")}
                        id={contactUsTitle ? contactUsTitle.nodeTypeName : null}
                        onChange={(e, id) => this.handleChange(e, id)}
                        value={contactUsTitle ? contactUsTitle.value : null}
                      />
                      {contactUsTitle.value && contactUsTitle.maxLength ? (
                        <span>
                          {contactUsTitle.length} / {contactUsTitle.maxLength}
                        </span>
                      ) : null}
                    </div>
                    <div
                      style={{
                        width: "50%",
                        marginLeft: "1%",
                        marginRight: "0.7%",
                      }}
                    >
                      <GeneralInput
                        className="textInputWidth"
                        placeholder={t("basicWords.description")}
                        id={contactUsText ? contactUsText.nodeTypeName : null}
                        onChange={(e, id) => this.handleChange(e, id)}
                        value={contactUsText ? contactUsText.value : null}
                      />
                      {contactUsText.value && contactUsText.maxLength ? (
                        <span>
                          {contactUsText.length} / {contactUsText.maxLength}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <br />
              </Card>

              <Card
                className="card"
                style={{ margin: "2% 5.5% 1% 5.5% ", height: "auto" }}
              >
                <div className="row mainContent">
                  <div
                    className="rowSection"
                    style={{
                      margin: "1%",
                    }}
                  >
                    <TitleCard text={t("generalUser.contactData")} />
                  </div>
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "32%",
                        marginLeft: "1.2%",
                        marginRight: "0.7%",
                      }}
                    >
                      <GeneralInput
                        className="textInputWidth"
                        placeholder={t("basicWords.address")}
                        id={
                          contactUsAddress
                            ? contactUsAddress.nodeTypeName
                            : null
                        }
                        onChange={(e, id) => this.handleChange(e, id)}
                        value={contactUsAddress ? contactUsAddress.value : null}
                      />
                      {contactUsAddress.value && contactUsAddress.maxLength ? (
                        <span>
                          {contactUsAddress.length} /{" "}
                          {contactUsAddress.maxLength}
                        </span>
                      ) : null}
                    </div>
                    <div
                      style={{
                        width: "32%",
                        marginLeft: "1%",
                        marginRight: "0.7%",
                      }}
                    >
                      <GeneralInput
                        className="textInputWidth"
                        placeholder={t("generalUser.userEmail")}
                        id={contactUsEmail ? contactUsEmail.nodeTypeName : null}
                        onChange={(e, id) => this.handleChange(e, id)}
                        value={contactUsEmail ? contactUsEmail.value : null}
                      />
                      {contactUsEmail.value && contactUsEmail.maxLength ? (
                        <span>
                          {contactUsEmail.length} / {contactUsEmail.maxLength}
                        </span>
                      ) : null}
                    </div>

                    <div
                      style={{
                        width: "32%",
                        marginLeft: "0.7%",
                        marginRight: "1%",
                      }}
                    >
                      <GeneralInput
                        className="textInputWidth"
                        placeholder={t("basicWords.telephone")}
                        id={contactUsPhone ? contactUsPhone.nodeTypeName : null}
                        onChange={(e, id) => this.handleChange(e, id)}
                        value={contactUsPhone ? contactUsPhone.value : null}
                      />
                      {contactUsPhone.value && contactUsPhone.maxLength ? (
                        <span>
                          {contactUsPhone.length} / {contactUsPhone.maxLength}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <br />
              </Card>

              {/* Social Networks */}
              <Card
                className="card"
                style={{ margin: "3% 5.5% 2% 5.5%", height: "auto" }}
              >
                <div className="row mainContent">
                  <div
                    className="rowSection"
                    style={{
                      margin: "1%",
                    }}
                  >
                    <TitleCard text={t("sections.socialnetworks")} />
                  </div>
                  <div
                    className="adjustPositionYTop"
                    style={{
                      margin: "2%",
                    }}
                  >
                    <div className="containerSocialNetWorkInContact">
                      <div className="containerIconContact">
                        <Twitter className="iconContact" />
                      </div>
                      <div className="urlContact">
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.urlProfile")}
                          id={
                            contactUsTwitter
                              ? contactUsTwitter.nodeTypeName
                              : null
                          }
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={
                            contactUsTwitter ? contactUsTwitter.value : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="adjustPositionYTop"
                    style={{
                      margin: "2%",
                    }}
                  >
                    <div className="containerSocialNetWorkInContact">
                      <div className="containerIconContact">
                        <Facebook className="iconContact" />
                      </div>
                      <div className="urlContact">
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.urlProfile")}
                          id={
                            contactUsFacebook
                              ? contactUsFacebook.nodeTypeName
                              : null
                          }
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={
                            contactUsFacebook ? contactUsFacebook.value : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="adjustPositionYTop"
                    style={{
                      margin: "2%",
                    }}
                  >
                    <div className="containerSocialNetWorkInContact">
                      <div className="containerIconContact">
                        <Instagram className="iconContact" />
                      </div>
                      <div className="urlContact">
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.urlProfile")}
                          id={
                            contactUsInstagram
                              ? contactUsInstagram.nodeTypeName
                              : null
                          }
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={
                            contactUsInstagram ? contactUsInstagram.value : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="adjustPositionYTop"
                    style={{
                      margin: "2%",
                    }}
                  >
                    <div className="containerSocialNetWorkInContact">
                      <div className="containerIconContact">
                        <Youtube className="iconContact" />
                      </div>
                      <div className="urlContact">
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.urlProfile")}
                          id={
                            contactUsYoutube
                              ? contactUsYoutube.nodeTypeName
                              : null
                          }
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={
                            contactUsYoutube ? contactUsYoutube.value : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </>
          )}
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            landingPageId,
            lpUrl,
          }}
          FlagclassName={FlagclassName}
          onClick={(e) => this.saveContactEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(ContactSection);
