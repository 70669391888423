import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/privilegebyrole";

export async function disablePrivilegeByRole(roleId, privilegeId) {
   try {
      let response = await axios({
         method: 'DELETE',
         url: host + port + route + datasource + resource,
         params: {
            role_id: roleId,
            privilege_id: privilegeId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}