import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// Queries
import {
  getOrganizationById,
  getCountries,
  getRegionsByCountryId,
  getCitiesByRegionId,
  getRolesByOrganizationId,
  insertUser,
} from "../../actions/index";
import { Card, CircularProgress, Backdrop } from "@material-ui/core";
import moment from "moment";
// Components
import {
  Title,
  Breadcrumb,
  NotificationModal,
  ButtonsFooterComponent,
} from "../../components/index";
import UserForm from "./components/UserForm.jsx";
import { Users } from "react-feather";

//Localization
import { withTranslation } from 'react-i18next';

const tableSize = 64 + 68 + 15 + 20 + 68 + 115 + 64;

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      organizationName: null,
      userToAdd: {
        organizationId: null,
        name: "",
        lastName1: "",
        lastName2: "",
        documentIdNumber: "",
        countryId: null,
        regionId: null,
        cityId: null,
        email: "",
        phone: "",
        username: "",
        dateOfBirth: null,
        roleIds: [],
      },

      countries: [],
      regions: [],
      cities: [],
      roles: [],

      loadingInfo: false,
      isLoadingButton: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    const { location } = this.props;

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const organizationId = location
      ? location.props
        ? location.props.organizationId
        : null
      : null;
    const organizationName = location.props
      ? location.props.organizationName
      : null;

    if (organizationName == null) {
      this.getOrganizationByIdFunction(organizationId);
    }

    if (organizationId != null) {
      const userToAdd = { ...this.state.userToAdd };
      userToAdd.organizationId = organizationId;
      const { organizationName } = location.props;

      this.getRolesByOrganizationIdFunction(organizationId);
      this.getCountries();

      this.setState({
        userToAdd,
        organizationName,
        organizationId,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getOrganizationByIdFunction(id) {
    const response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        this.setState({
          organizationName: response.data.data.name,
        });
      }
    }
  }

  async getCountries() {
    let countries = [];
    const response = await getCountries();

    if (String(response).includes("Error:")) {
    } else {
      countries = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ countries });
  }

  async getRegions(countryId) {
    let regions = [];
    const response = await getRegionsByCountryId(countryId);

    if (String(response).includes("Error:")) {
    } else {
      regions = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ regions });
  }

  async getCities(regionId) {
    let cities = [];
    const response = await getCitiesByRegionId(regionId);

    if (String(response).includes("Error:")) {
    } else {
      cities = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ cities });
  }

  async getRolesByOrganizationIdFunction(organizationId) {
    let roles = [];
    const response = await getRolesByOrganizationId(organizationId);

    if (String(response).includes("Error:")) {
    } else {
      roles = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ roles });
  }

  async postUser(userToAdd) {
    userToAdd.dateOfBirth = moment(userToAdd.dateOfBirth);
    const response = await insertUser(userToAdd);
    const { t } = this.props
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal:
          t('modal.internalServerErrorContactSupport'),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else if (response.data.code === 4011) {
      this.setState({
        titleNotificationModal: t('modal.userAlreadyCrated'),
        contenNotificationModal: t('modal.userAlreadyCrated'),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.successfulCreation'),
        contenNotificationModal: t('modal.userSuccesfullyCreated'),
        typeNotificationModal: "success",
      });
      this.setState({
        userToAdd: {
          name: "",
          lastName1: "",
          lastName2: "",
          documentIdNumber: "",
          countryId: null,
          regionId: null,
          cityId: null,
          email: "",
          phone: "",
          username: "",
          dateOfBirth: null,
          roleIds: [],
        },
      });
    }
    this.setState({
      isLoadingButton: false,
      openNotificationModal: true,
    });
  }

  // Events
  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { userToAdd } = { ...this.state };

    userToAdd[id] = value;

    this.setState({
      userToAdd,
    });
  };

  handleFormSelect = (e, event) => {
    let option = e.target;
    const { value } = option;
    const { userToAdd } = { ...this.state };

    userToAdd[event] = value;

    this.setState({
      userToAdd,
    });

    switch (event) {
      case "countryId":
        this.setState({
          countryId: option.value,
          regionId: null,
          cityId: null,
        });
        this.getRegions(option.value);
        break;
      case "regionId":
        this.setState({
          regionId: option.value,
          cityId: null,
        });
        this.getCities(option.value);
        break;
      default:
        break;
    }
  };

  handleFormSelectMulti = (options, source, name) => {
    const { userToAdd } = { ...this.state };
    userToAdd[name] = options.target.value.map((option) => {
      return parseInt(option, 10);
    });

    this.setState({
      userToAdd,
    });
  };

  handleChangeSelect = (e, name) => {
    const { userToAdd } = { ...this.state };

    userToAdd[name].push(parseInt(e.target.value));
    this.setState({
      userToAdd,
    });
  };

  handleOnDateChange = (date, dateString) => {
    const { userToAdd } = { ...this.state };

    userToAdd.dateOfBirth = dateString;

    this.setState({
      userToAdd,
    });
  };

  saveUserEvent = () => {
    const { userToAdd } = { ...this.state };
    this.setState({
      isLoadingButton: true,
    });
    const { t } = this.props
    userToAdd.organizationId = userToAdd.organizationId;
    userToAdd.name = userToAdd.name.trim();
    userToAdd.lastName1 = userToAdd.lastName1.trim();
    userToAdd.lastName2 = userToAdd.lastName2.trim();
    userToAdd.documentIdNumber = userToAdd.documentIdNumber.trim();
    userToAdd.email = userToAdd.email.trim();
    userToAdd.phone = userToAdd.phone.trim();
    userToAdd.username = userToAdd.username.trim();

    // Build error message
    var errorMessage = "";
    if (userToAdd.name.length === 0 || userToAdd.name === "") {
      userToAdd.name = "";
      errorMessage = `${t('basicWords.name')},`;
    }
    if (userToAdd.lastName1.length === 0 || userToAdd.lastName1 === "") {
      userToAdd.lastName1 = "";
      errorMessage = `${t('generalUser.lastName1')}, ${errorMessage}`;
    }
    if (
      userToAdd.documentIdNumber.length === 0 ||
      userToAdd.documentIdNumber === ""
    ) {
      userToAdd.documentIdNumber = "";
      errorMessage = `${t('generalUser.documentIdNumber')}, ${errorMessage}`;
    }
    if (userToAdd.phone.length === 0 || userToAdd.phone === "") {
      userToAdd.phone = "";
      errorMessage = `${t('basicWords.telephone')}, ${errorMessage}`;
    }
    if (userToAdd.username.length === 0 || userToAdd.username === "") {
      userToAdd.username = "";
      errorMessage = `${t('generalUser.username')}, ${errorMessage}`;
    }
    if (userToAdd.roleIds.length === 0) {
      errorMessage = `${t('generalUser.userRole')}, ${errorMessage}`;
    }
    if (userToAdd.countryId === null) {
      errorMessage = `${t('basicWords.country')}, ${errorMessage}`;
    }
    if (userToAdd.cityId === null) {
      errorMessage = `${t('basicWords.region')}, ${errorMessage}`;
    }
    const regexEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regexEmail.test(userToAdd.email)) {
      userToAdd.email = "";
      errorMessage = `${t('generalUser.userEmail')}, ${errorMessage}`;
    }

    if (userToAdd.lastName2.length === 0) {
      userToAdd.lastName2 = "";
    }

    if (!errorMessage) {
      this.postUser(userToAdd);
    } else {
      errorMessage = errorMessage.substring(
        0,
        errorMessage.length - 2
      );
      this.setState({
        titleNotificationModal: t('modal.fieldEmpty'),
        contenNotificationModal: ` ${t('streaming.modalErrorBody')}: ${errorMessage} `,
        typeNotificationModal: "error",
      });
      this.setState({
        isLoadingButton: false,
        openNotificationModal: true,
      });
    }
  };

  warning = () => {
    const { t } = this.props
    this.setState({
      titleNotificationModal: t('modal.organizationNotSelected'),
      contenNotificationModal: t('modal.noOrganizationSelected'),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({
      isLoadingButton: false,
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  // Render
  render() {
    const {
      userToAdd,
      redirectBack,
      organizationName,
      countries,
      regions,
      cities,
      roles,
      isLoadingButton,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    const { t } = this.props

    if (redirectBack) {
      return <Redirect to="/users" />;
    }
    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Card className="card">
          <Backdrop
            style={{ color: "#510f8a", zIndex: "5" }}
            open={isLoadingButton}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="row componentSpace">
            <Breadcrumb
              principal={t('generalUser.myUsers')}
              icon={<Users />}
              secondary={t('generalUser.myUsers')}
              third={t('sections.home')}
            />
          </div>
          <div className="row componentSpace" style={{ paddingLeft: "4.5%" }}>
            <Title title={t('basicWords.createUser')} />
          </div>
          <div
            style={{
              padding: "0px",
              paddingLeft: "3%",
              margin: "0px",
            }}
          >
            <UserForm
              user={userToAdd}
              countries={countries}
              regions={regions}
              cities={cities}
              roles={roles}
              organizationName={organizationName}
              onChange={this.handleFormChange}
              onSelect={this.handleFormSelect}
              onMultiSelect={this.handleFormSelectMulti}
              onDateChange={this.handleOnDateChange}
              handleChangeSelect={this.handleChangeSelect}
            />{" "}
          </div>
        </Card>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={{
            userToAdd,
          }}
          FlagclassName={true}
          onClick={(e) => this.saveUserEvent(e)}
          path="./users"
        />
      </>
    );
  }
}
export default withTranslation()(AddUser);