import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/group";

export async function getGroupById(
   groupId,
  ) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            group_id: groupId,
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function getGroupsPaged(
   organizationId,
   page,
   size,
   attribute,
   order
) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            organization_id: organizationId,
            page: page,
            size: size,
            attribute: attribute,
            order: order
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function insertGroup(groupToAdd) {
   try {
      const data = JSON.stringify(groupToAdd)
      let response = await axios({
         method: 'POST',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function updateGroup(groupToUpdate) {
   try {
      const data = JSON.stringify(groupToUpdate)
      let response = await axios({
         method: 'PUT',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function deleteGroup(groupId) {
   try {
      let response = await axios({
         method: 'DELETE',
         url: host + port + route + datasource + resource,
         params: {
            group_id: groupId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}