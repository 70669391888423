import axios from 'axios';
import base64 from 'base-64';

import { host, loginRoute, port, clientId, clientPass } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/oauth/token";
const encoded = base64.encode(clientId + ":" + clientPass);

export const SESSION_INFORMATION = "SESSION_INFORMATION"

export async function loginValidateUser(credentials) {
  var qs = require('qs');
  var data = qs.stringify({
    'grant_type': 'password',
    'password': credentials.password,
    'username': credentials.username
  });
  try {
    let response = await axios({
      method: 'POST',
      url: host + port + loginRoute + resource,
      params: {
        grant_type: "password",
      },
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Basic " + encoded
      }
    })
    if (!response.error) {
      let base64Url = response.data.access_token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      let payload = JSON.parse(jsonPayload);

      sessionStorage.setItem("access_token", response.data.access_token);
      sessionStorage.setItem("refresh_token", response.data.refresh_token);
      sessionStorage.setItem("organization_id", response.data.organization_id);
      sessionStorage.setItem("expires_in", response.data.expires_in);
    }

    return response;
  } catch (error) {
    return error;
  };
}

export async function refreshToken() {
  var qs = require('qs');
  var data = qs.stringify({
    'grant_type': 'refresh_token',
    'refresh_token': sessionStorage.getItem("refresh_token"),
  });
  try {
    let response = await axios({
      method: 'POST',
      url: host + port + loginRoute + resource,
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Basic " + encoded
      }
    })
    if (!response.error) {
      let base64Url = response.data.access_token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      let payload = JSON.parse(jsonPayload);

      sessionStorage.setItem("access_token", response.data.access_token)
      sessionStorage.setItem("refresh_token", response.data.refresh_token)
      sessionStorage.setItem("organization_id", response.data.organization_id);
      sessionStorage.setItem("expires_in", response.data.expires_in);
    }

    return response;
  } catch (error) {
    return error;
  };
}

export async function forgotPass(username) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + loginRoute + "/password/reset",
      params: {
        username: username
     },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Basic " + encoded
      }
    })
    return response;
  } catch (error) {
    return error;
  };
}