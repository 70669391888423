export function ValidatePermissionForComponentPart(toValidate, permissions) {
   if(permissions!=null){
     for(let i = 0; i < permissions.length; i++){
       let permission = permissions[i];
       if (permission === toValidate){
         return true;
       }
     }
   }
   return false;
}