import React from "react";

//Components
import { GeneralInput } from "../../../components/index";

//Localization
import { useTranslation } from "react-i18next";

const OrganizationDetailsForm = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="col-12"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="row containerField" style={{ display: "flex" }}>
        <div className="fieldLeft">
          <GeneralInput
            id="name"
            value={props.organization.name ? props.organization.name : ""}
            placeholder={t("basicWords.name")}
            isReq
            maxLength={200}
          />
        </div>
        <div className="fieldRigth">
          <GeneralInput
            id="name"
            value={props.organization.description ? props.organization.description : ""}
            placeholder={t("basicWords.description")}
            isReq
            maxLength={200}
          />
        </div>
      </div>

      <div className="row containerField" style={{ display: "flex" }}>
        <div className="fieldLeft">
          <GeneralInput
            id="phone"
            value={props.organization.phone ? props.organization.phone : ""}
            placeholder= {t("basicWords.telephone") + "*"}
            isReq
            maxLength={200}
          />
        </div>
        <div className="fieldRigth">
          <GeneralInput
            id="address"
            value={props.organization.address ? props.organization.address : ""}
            placeholder= {t("basicWords.address")}
            isReq
            maxLength={200}
          />
        </div>
      </div>

      <div className="row containerField" style={{ display: "flex" }}>
        <div className="fieldLeft">
          <GeneralInput
            id="country"
            value={props.organization.country}
            placeholder={t("basicWords.country")}
            isReq
            maxLength={200}
          />
        </div>
        <div className="fieldRigth">
          <GeneralInput
            id="region"
            value={props.organization.region ? props.organization.region : ""}
            placeholder= {t("basicWords.region")}
            isReq
            maxLength={200}
          />
        </div>
      </div>

      <div className="row containerField" style={{ display: "flex" }}>
        <div className="fieldLeft">
          <GeneralInput
            id="city"
            value={props.organization.city ? props.organization.city : ""}
            placeholder={t("basicWords.city")}
            isReq
            maxLength={200}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetailsForm;
