import axios from 'axios';

import { host, port, loginRoute, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/users";

export const USER_INFORMATION = "USER_INFORMATION"

export async function getUserById(userId) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            user_id: userId,
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}

export async function getUsersByOrganizationIdAndRoleId(organizationId, roleId) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            organization_id: organizationId,
            role_id: roleId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}

export async function getUsersByGroup(groupId) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            group_id: groupId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}

export async function getUsersByOrganizationId(organizationId) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            organization_id: organizationId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}

export async function getUsersPaginated(
   organizationId,
   identityCardNumber,
   page,
   size,
   attribute,
   order
) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            organization_id: organizationId,
            document_id_number: identityCardNumber,
            page: page,
            size: size,
            attribute: attribute,
            order: order
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}

export async function getUsersByOrganizationIdAndRoleIdPaginated(
   organizationId,
   roleId,
   identityCardNumber,
   page,
   size,
   attribute,
   order
) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            organization_id: organizationId,
            role_id: roleId,
            document_id_number: identityCardNumber,
            page: page,
            size: size,
            attribute: attribute,
            order: order
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}

export async function insertUser(userToAdd) {
   try {
      const data = JSON.stringify(userToAdd)
      let response = await axios({
         method: 'POST',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function putUser(userToEdit) {
   try {
      const data = JSON.stringify(userToEdit)
      let response = await axios({
         method: 'PUT',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function disableUser(userId) {
   try {
      let response = await axios({
         method: 'DELETE',
         url: host + port + route + datasource + resource,
         params: {
            user_id: userId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function changePassword(passwordDto) {
   try {
      const data = JSON.stringify(passwordDto)
      let response = await axios({
         method: 'POST',
         url: host + port + loginRoute + "/password/changepass",
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}