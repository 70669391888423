import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";

//Queries
import {
  getOrganizationById,
  getCountries,
  getRegionsByCountryId,
  getCitiesByRegionId,
  updateOrganization,
  getOrganizationTypes,
  getLpTypes,
  getPaymentPlatformsByOrganizationId,
  putPaymentPlatformParams,
} from "../../actions/index";

//Components
import {
  NotificationModal,
  Breadcrumb,
  ButtonsFooterComponent
} from "../../components/index";
import OrganizationForm from "./components/OrganitazionForm.jsx";
import { EditOrganizationForm } from "./components/EditOrganizationForm.jsx";
import PaymentPlatformForm from "./components/PaymentPlatformForm.jsx";
import PaymentsForm from "./components/PaymentsForm.jsx";
import {
  Tab,
  AppBar,
  Tabs,
  Box,
  Typography,
  Card,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
//Localization
import { withTranslation } from "react-i18next";

import { Home } from "react-feather";

let tableSize = 64 + 68 + 15 + 62 + 70 + 30;
// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
// const { TabPane } = Tabs;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class EditOrganization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      organizationToEdit: {
        id: null,
        name: "",
        url: "",
        description: "",
        countryId: null,
        regionId: null,
        cityId: null,
        phone: "",
        address: "",
        parentOrganizationId: null,
        organizationTypeId: 2,
        lptypeId: null,
      },

      organizationTypes: [],
      lpTypes: [],
      countries: [],
      regions: [],
      cities: [],

      paymentPlatforms: [],
      paymentPlatform: null,

      path: null,

      loadingInfo: true,
      isLoadingButton: false,
      redirectBack: false,

      tabIdx: 1,
      buttonsHidden: false, //Hides save and back button
      valueTab: 1,

      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { props } = this.props.location;

    if (props) {
      this.getOrganizationByIdFunction(props.organizationId);
      this.getCountries();
      this.getRegions(props.countryId);
      this.getCities(props.regionId);
      this.getOrganizationTypesFunction();
      this.getLPTypesFunction();
      this.getPaymentPlatformsByOrganizationId(props.organizationId);
      this.setState({
        path: props.path,
      });
    } else {
      this.warning();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  //Queries
  async getOrganizationByIdFunction(id) {
    let organizationToEdit = {};
    let response = await getOrganizationById(id);
    if (response) {
      if (String(response).includes("Error:")) {
      } else {
        organizationToEdit = {
          id: response.data.data.id,
          name: response.data.data.name,
          description: response.data.data.description,
          countryId: response.data.data.countryId,
          regionId: response.data.data.regionId,
          cityId: response.data.data.cityId,
          phone: response.data.data.phone,
          address: response.data.data.address,
          parentOrganizationId: response.data.data.parentOrganizationId,
          isEnabled: response.data.data.isEnabled,
        };
      }
      this.setState({
        organizationToEdit,
        loadingInfo: false,
      });
    }
  }
  async getOrganizationTypesFunction() {
    let organizationTypes = [];
    let response = await getOrganizationTypes();

    if (String(response).includes("Error:")) {
    } else {
      organizationTypes = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ organizationTypes });
  }
  async getLPTypesFunction() {
    let lpTypes = [];
    let response = await getLpTypes();

    if (String(response).includes("Error:")) {
    } else {
      lpTypes = response.data.data.map((item) => ({
        value: item.id,
        label: item.nameString,
      }));
    }

    this.setState({ lpTypes });
  }
  async getCountries() {
    let countries = [];
    let response = await getCountries();

    if (String(response).includes("Error:")) {
    } else {
      countries = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ countries });
  }
  async getRegions(countryId) {
    let regions = [];
    let response = await getRegionsByCountryId(countryId);

    if (String(response).includes("Error:")) {
    } else {
      regions = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ regions });
  }
  async getCities(regionId) {
    let cities = [];
    let response = await getCitiesByRegionId(regionId);

    if (String(response).includes("Error:")) {
    } else {
      cities = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    this.setState({ cities });
  }

  async updateOrganizationFunction(organizationToEdit) {
    let response = await updateOrganization(organizationToEdit);
    const {t} = this.props
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal:
          t('modal.internalServerErrorContactSupport'),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.editionSuccess'),
        contenNotificationModal: t('modal.organizationSuccesfullEdited'),
        typeNotificationModal: "success",
      });
    }
    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
    });
  }

  async getPaymentPlatformsByOrganizationId(organizationId) {
    let response = await getPaymentPlatformsByOrganizationId(organizationId);
    const {t} = this.props
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal: t('modal.internalServerError'),
        typeNotificationModal: "error",
      });
      this.setState({
        openNotificationModal: true,
      });
    } else {
      this.setState({
        paymentPlatforms: response.data.data,
        valueTab: 0,
      });
    }
  }

  async putPaymentPlatformParams(params) {
    let response = await putPaymentPlatformParams(params);
    const {t} = this.props
    if (String(response).includes("Error:")) {
      this.setState({
        titleNotificationModal: t('modal.internalServerError'),
        contenNotificationModal: t('modal.internalServerError'),
        typeNotificationModal: "error",
      });
    } else {
      this.setState({
        titleNotificationModal: t('modal.editionSuccess'),
        contenNotificationModal: t('modal.organizationSuccesfullEdited'),
        typeNotificationModal: "success",
      });
    }
    this.setState({
      openNotificationModal: true,
      isLoadingButton: false,
    });
  }

  //Events
  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { organizationToEdit } = { ...this.state };

    organizationToEdit[id] = value;

    this.setState({
      FlagclassName: true,
      organizationToEdit,
    });
  };

  handleFormSelect = (option, e) => {
    const  value  = option.target ? option.target.value : option.value;
    const { organizationToEdit } = { ...this.state };

    const event = e.name ? e.name : e;
    organizationToEdit[event] = value;
    this.setState({
      organizationToEdit,
      FlagclassName: true
    });

    switch (event) {
      case "countryId":
        this.setState({
          countryId: value,
          regionId: null,
          cityId: null,
        });
        this.getRegions(value);
        break;
      case "regionId":
        this.setState({
          regionId: value,
          cityId: null,
        });
        this.getCities(value);
        break;
    }
  };

  handlePaymentPlatformSelect = (paymentPlatformId) => {
    let paymentPlatform = this.state.paymentPlatforms.filter(
      (p) => p.id === paymentPlatformId.target.value.id
    )[0];
    this.setState({ paymentPlatform,
      FlagclassName: true
     });
  };

  handlePaymentParameterChange = (value, param) => {
    let { paymentPlatform } = this.state;
    paymentPlatform.parameters.forEach((p) => {
      if (p.paramId === param.paramId) {
        p.value = value;
      }
    });
    this.setState({ paymentPlatform, tabIdx: 2,
      FlagclassName: true
     });
  };

  onTabChange = (e) => {
    this.setState({ tabIdx: e, buttonsHidden: e === 3 });
  };

  saveOrganizationEvent = () => {
    let { organizationToEdit, tabIdx, paymentPlatform } = { ...this.state };
    const {t} = this.props
    switch (parseInt(tabIdx, 10)) {
      case 1: //General Information tab save action
        {
          this.setState({
            isLoadingButton: true,
          });

          organizationToEdit.name = organizationToEdit.name.trim();
          organizationToEdit.description =
            organizationToEdit.description.trim();
          organizationToEdit.phone = organizationToEdit.phone.trim();
          organizationToEdit.address = organizationToEdit.address.trim();

          if (organizationToEdit.name.length === 0) {
            organizationToEdit.name = "";
          }
          if (organizationToEdit.description.length === 0) {
            organizationToEdit.description = "";
          }
          if (organizationToEdit.phone.length === 0) {
            organizationToEdit.phone = "";
          }
          if (organizationToEdit.address.length === 0) {
            organizationToEdit.address = "";
          }

          if (
            organizationToEdit.name === "" ||
            organizationToEdit.phone === "" ||
            organizationToEdit.address === ""
          ) {
            this.setState({
              titleNotificationModal: t('modal.wrongFile'),
              contenNotificationModal: t('modal.fieldEmpty'),
              typeNotificationModal: "error",
            });
            this.setState({
              openNotificationModal: true,
              isLoadingButton: false,
            });
          } else {
            if (organizationToEdit.description.length === 0) {
              organizationToEdit.description = null;
            }
            this.updateOrganizationFunction(organizationToEdit);
          }
        }
        break;
      case 2: //Payment platform tab save action
        {
          this.setState({
            isLoadingButton: true,
          });
          this.putPaymentPlatformParams(paymentPlatform);
        }
        break;
    }
  };

  warning = () => {
    const {t} = this.props
    this.setState({
      titleNotificationModal: t('modal.organizationNotSelected'),
      contenNotificationModal: t('modal.noOrganizationSelected'),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({
      openNotificationModal: true,
    });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ valueTab: newValue });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  //Render
  render() {
    const {
      valueTab,
      isLoadingButton,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
      organizationToEdit,
      FlagclassName
    } = this.state;
    const { t } = this.props;
    if (this.state.redirectBack) {
      return <Redirect to={this.state.path} />;
    }
    if (this.state.path === "./organizations") {
      return (
        <Fragment>
          <NotificationModal
            visible={openNotificationModal}
            onClick={this.handleCloseNotificationModal}
            title={titleNotificationModal}
            content={contenNotificationModal}
            type={typeNotificationModal}
          />
          <div className="row">
            <Card className="card">
              <Backdrop
                style={{ color: "#510f8a", zIndex: "5" }}
                open={isLoadingButton}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div>
                <AppBar position="static">
                  <Tabs
                    value={valueTab}
                    onChange={this.handleChangeTab}
                    aria-label="simple tabs example"
                  >
                    <Tab label={t('basicWords.generalInformation')} />
                    <Tab label={t('basicWords.paymentPlatforms')} />
                    <Tab label={t('basicWords.payments')} />
                  </Tabs>
                </AppBar>
                <TabPanel value={valueTab} index={0}>
                  <OrganizationForm
                    organization={this.state.organizationToEdit}
                    organizationTypes={this.state.organizationTypes}
                    countries={this.state.countries}
                    regions={this.state.regions}
                    cities={this.state.cities}
                    lpTypes={this.state.lpTypes}
                    onChange={this.handleFormChange}
                    onSelect={this.handleFormSelect}
                    height={this.state.height}
                    isEdit={true}
                  />
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                  <PaymentPlatformForm
                    paymentPlatforms={this.state.paymentPlatforms}
                    paymentPlatform={this.state.paymentPlatform}
                    onSelect={this.handlePaymentPlatformSelect}
                    onParamChange={this.handlePaymentParameterChange}
                    height={this.state.height}
                  />
                </TabPanel>
                <TabPanel value={valueTab} index={2}>
                  <PaymentsForm
                    organizationId={this.state.organizationToEdit.id}
                    height={this.state.height}
                  />
                </TabPanel>
                <ButtonsFooterComponent
                propsComponent={{
                  organizationToEdit,
                }}
                path={this.state.path}
                FlagclassName={FlagclassName}
                onClick={(e) => this.saveOrganizationEvent(e)}
              />
              </div>
            </Card>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <NotificationModal
            visible={openNotificationModal}
            onClick={this.handleCloseNotificationModal}
            title={titleNotificationModal}
            content={contenNotificationModal}
            type={typeNotificationModal}
          />
          <div className="row">
            <Card className="card">
              <div className="row componentSpace">
                <br />
                <Breadcrumb
                  principal={t('organization.editOrganization')}
                  icon={<Home />}
                />
                <br />
              </div>

              <div style={{ height: this.state.height }}>
                <EditOrganizationForm
                  organization={this.state.organizationToEdit}
                  // organizationTypes={this.state.organizationTypes}
                  countries={this.state.countries}
                  regions={this.state.regions}
                  cities={this.state.cities}
                  // lpTypes={this.state.lpTypes}
                  onChange={this.handleFormChange}
                  onSelect={this.handleFormSelect}
                  isEdit={true}
                />
              </div>
              <ButtonsFooterComponent
                propsComponent={{
                  organizationToEdit,
                }}
                path={this.state.path}
                FlagclassName={FlagclassName}
                onClick={(e) => this.saveOrganizationEvent(e)}
              />
            </Card>
          </div>
        </Fragment>
      );
    }
  }
}
export default withTranslation()(EditOrganization)