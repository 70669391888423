import React from "react";

import {
  GeneralInput,
  SelectSingle,
  SelectMulti,
  DatePickerComponent,
} from "../../../components/index";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material/";
//Localization
import { useTranslation } from 'react-i18next';

import "../../../assets/styles/forms.scss";

const UserForm = (props) => {
  const { user, countries, regions, cities, roles, organizationName } = props;
  const { t, i18n } = useTranslation();

  let display = "flex";
  return (
    <div
      className="col-12"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="row containerField" style={{ display: display }}>
        <div className="fieldLeft">
          <GeneralInput
            disabled={true}
            id="organizationName"
            value={organizationName}
            placeholder={t('organization.organization')}
            isReq
            onChange={(e) => props.onChange(e)}
          />
        </div>
        <div className="fieldRigth">
          <GeneralInput
            id="phone"
            value={user.phone}
            defaultValue={user.phone}
            placeholder={t('basicWords.telephone')}
            isReq
            onChange={(e) => props.onChange(e)}
          />
        </div>
      </div>
      <div className="row containerField" style={{ display: display }}>
        <div className="fieldLeft">
          <div className="row" style={{ display: display }}>
            <div style={{ width: "48%", paddingRight: "5%" }}>
              <GeneralInput
                id="name"
                value={user.name}
                defaultValue={user.name}
                placeholder={t('basicWords.name')}
                isReq
                onChange={(e) => props.onChange(e)}
              />
            </div>
            <div style={{ width: "47%" }}>
              <GeneralInput
                id="lastName1"
                value={user.lastName1}
                defaultValue={user.lastName1}
                placeholder={t('generalUser.lastName1')}
                isReq
                onChange={(e) => props.onChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="fieldRigth">
          <GeneralInput
            id="email"
            value={user.email}
            defaultValue={user.email}
            placeholder={t('generalUser.userEmail')}
            isReq
            onChange={(e) => props.onChange(e)}
          />
        </div>
      </div>
      <div className="row containerField" style={{ display: display }}>
        <div className="fieldLeft">
          <GeneralInput
            id="lastName2"
            value={user.lastName2}
            defaultValue={user.lastName2}
            placeholder="Segundo apellido"
            isReq={false}
            onChange={(e) => props.onChange(e)}
          />
        </div>
        <div className="fieldRigth">
          <GeneralInput
            id="username"
            value={user.username}
            defaultValue={user.username}
            placeholder={t('generalUser.username')}
            isReq
            onChange={(e) => props.onChange(e)}
          />
        </div>
      </div>
      <div className="row containerField" style={{ display: display }}>
        <div
          className="fieldLeft"
          style={{ width: "40%", alignSelf: "center" }}
        >
          <GeneralInput
            id="documentIdNumber"
            value={user.documentIdNumber}
            defaultValue={user.documentIdNumber}
            placeholder={t('generalUser.documentIdNumber')}
            isReq
            onChange={(e) => props.onChange(e)}
          />
        </div>
        <div
          className="fieldRigth"
          style={{ width: "40%", alignSelf: "center" }}
        >
          <FormControl>
            <InputLabel id="demo-simple-select-label">
              {t('generalUser.userRole')}
            </InputLabel>
            <Select
              // style={{ width: "80%" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={user.roleIds}
              onChange={(e) => props.handleChangeSelect(e, "roleIds")}
            >
              {roles.map((op) => (
                <MenuItem key={op.label} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="row containerField" style={{ display: display }}>
        <div className="fieldLeft">
          <DatePickerComponent
            text={t('generalUser.dateOfBirth')}
            defaultValue={user.dateOfBirth}
            onChange={(date, dateString) =>
              props.onDateChange(date, dateString)
            }
            string={t('basicWords.birthDate')}
          />
        </div>
      </div>
      <div className="row containerField" style={{ display: display }}>
        <div className="fieldLeft">
          <div className="row" style={{ display: display }}>
            <div
              style={{ width: "47%", paddingRight: "5%", alignSelf: "center" }}
            >
              <SelectSingle
                text={t('basicWords.country')}
                onChange={(option) => props.onSelect(option, "countryId")}
                selectedOptions={user.countryId}
                options={countries}
              />
            </div>
            <div style={{ width: "48%", alignSelf: "center" }}>
              <SelectSingle
                text={t('basicWords.region')}
                onChange={(option) => props.onSelect(option, "regionId")}
                selectedOptions={user.regionId}
                options={regions}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row containerField" style={{ display: display }}>
        <div
          className="fieldLeft"
          style={{ width: "40%", alignSelf: "center" }}
        >
          <SelectSingle
            text={t('basicWords.city')}
            onChange={(option) => props.onSelect(option, "cityId")}
            selectedOptions={user.cityId}
            options={cities}
          />
        </div>
      </div>
    </div>
  );
};

export default UserForm;
