import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Edit, Eye, Layout } from "react-feather";
import { Helmet } from "react-helmet";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Card,
} from "@material-ui/core";

// Queries
import { getAllLandingPagesPaged } from "../../actions/index";

// Components
import { Title, Breadcrumb, NotificationModal } from "../../components/index";
import { withTranslation } from 'react-i18next';
import "./MyWebPage.scss";

const tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;

class WebPagesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,

      loadingInfo: false,
      registers: [],

      // pagination
      registersAmount: 0,
      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "ASC",
      initialPage: 0,

      //Modal notification
      haveChangeInLayout: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",

      //Helmet
      helmetPage: "",
    };
  }

  componentDidMount() {
    const { page, pageSize, attribute, order } = this.state;
    this.updateWindowDimensions();
    if (process.env.REACT_APP_ISAURA === "true") {
      this.setState({ helmetPage: "Somos Aura | CMS" });
    } else {
      this.setState({ helmetPage: "Uhoo | CMS" });
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKaADAAQAAAABAAAAKQAAAADAIIRfAAAFK0lEQVRYCbVZW2icRRQ+ZzaxjWml1jRpo33QCgrxQZB6QYoNGgvWF7Fg+iAWEeODPgQxyfapTyZbQVEiGgQfFDGFpohFkQYhKqKlD0JVpK1oa2torlZTbEJ2/+M38++6t3/ObNPsQPLPzLnMN3POzJw5y7TCIiPt19Ol7KMUSReRbIWadhJuR/0mIp4jlkn04Y/Pk+Fx2tBwjHsm/13JcHw1QiIHDA0NPwWZbgzeRSJNNcszX8EExsE/SgMvHmI+ENUqWzNIeXXTY1CawUB31arcz8c/gdbP+2c+9/MUKUGQ8lrbrZTNvU9CO4tiq1RjmqCG1LP8ytTvmkYVpBxseYhyPAazws/qVBj+m5InuW/2K98IxkeQodYeytF4XQHawe0CYBw3ngdM4ko6gSh61yNTv25jXuCB6ZHKAapAyuDmnUTZY/DBxkrmureZlilFXZWmLzO32ySUO6wAzBLTKXLHyQogWxBWnngxUdouDPZAjKPIUQYy3sVJmwRK2fTRlnXrOT17J93fcQMZ2guws0VVSi2eVC81b4jlH+hYT8Y8DcB/VUlZH7WnSUn539zxOSifldCKVWOega98UOyIa3CN7XCN77DyqUpaWZu5m9Mzh8r60JCDrQ9SNvoa1fLFcoy8u3COOqK7SdxBXanGtvlkEkBHSV88ge+orXsL04kkgJaf+6a/xf8jHtlMHld+Bpl3cM35bhI+7lESd3OATgE68/fJ+oEnvoLzICVn72NPkTkPId/Nf6t0oX9UOqn6sXjwBRfN2GDBVxjurZUIHqmVkLxo8txl8RkXbmnRjIgO0miDAL0EJmnYP0mLC+GgieNBbSlCNNZDrtBKhtQjXsXudgGrn5UDx4vk/CthtYYsEXIX4LNHEKJprZiEM6yEnwPmDq1kyF2ADyw25FcKB3xSFJ+yakMg1Y0DeeCz5tZjxZDjh1YyKB+YJPBZU87YCStF392hlVAU10biOQvyzwBzg0oXs6TTeVmlRxK492XSIJIJgWxTB2E+qdKN+VGlM7WqdDyL7d66EGC6WaNz+uJZTHQskYf5DN2Y+iSRVujk0OnC5w2leKLAn/gVuhtBaHMirdC5tvk5xJsfF5ruy3QcZ+TjwYSARDvK5CobSCywu7vnl6eh0A+EzR5OTyevVolSGWy7DUdOB0W5U5SeOcOs71zJbG2n6MoF79Vpg+WNjS0mP1P9kS7SU4LFW+X01G88MHWU98+eDgF0SnJLPV6AjkHGLb74NmE67B05Zu7CC3KXznN1VHmjZQvyRS8HpFxAHYO8r+MIzPSLKhDJ2/L6LRtVnhqJLuJe5PdUFyOkYpAzsiodSO6cwCvQ9OpjyDZaWhyTkXsadb4aqJnhDADuDnD2F5JaZbeJDG46andkQPhLalq3h3vPXgrwVZHdBOfPDcMPn68ilnYgR4RXaWehKzZ3oUXUC7PrzwGih2nx8s+Y0L7CQ6ko7q9JZvO9NH/umzBA5IaQxCrVVLaSlgBlnZTLfoHqdaWMiXXmHzDom8RNn3L6j6o3tLx1+xq6vLCLONoHCz2RqKO005PBqAJpZWSwZS8+H+nHQ6l2inDr/Iqe04jNFqBhLWS3wSp3ANyaMk6tUWsuqKAD5uzHAEOFdl2/Lv1iXkpKVtlxE1fSEsSG/UOtC6j4byLLeK3lWvKTlGndUX+ANtNrtldm0SrnnRgr4g5+hATZtbqVfM48vcKcuQy1dGMbfAh8iRNYMW6XWVulXx+wYUZhZiXtEoDJSC4LT6/m7zj/AexV0r66K8xIAAAAAElFTkSuQmCC";
    }
    window.addEventListener("resize", this.updateWindowDimensions);

    // Get all web pages
    this.getLandingPageInfoFunction(page, pageSize, attribute, order);
    this.setState({
      loadingInfo: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getLandingPageInfoFunction(page, pageSize, attribute, order) {
    let registers = this.state.registers;
    let registersAmount = 0;
    const {t} = this.props
    if (page === 0) {
      return registers;
    } else {
      const response = await getAllLandingPagesPaged(
        page,
        pageSize,
        attribute,
        order
      );
      if (response) {
        if (String(response).includes("Error:")) {
          // Modal.error({
          //   title: t('modal.internalServerError'),
          //   content: t('modal.internalServerErrorContactSupport'),
          // });
        } else {
          if (response.data.data) {
            registers = [...registers, ...response.data.data.content];
            registersAmount = response.data.data.totalElements;
          }
        }
      }
      this.setState({
        registers,
        registersAmount,
        loadingInfo: false,
      });
    }
  }

  getMyWebpage = (e) => {
    window.open("https://" + e);
  };

  // handleChangePage = (event, newPage) => {
  //   const { attribute, order, page, pageSize } = this.state;
  //   if (event !== undefined) {
  //     this.setState({ page: newPage });
  //     this.getLandingPageInfoFunction(page, pageSize, attribute, order);
  //   }
  // };

  // handleChangeRowsPerPage = (event) => {
  //   const { attribute, order, page, pageSize } = this.state;
  //   if (event !== undefined) {
  //     this.setState({ pageSize: parseInt(event.target.value) + 1 });
  //     this.setState({ page: 0 });
  //     this.getLandingPageInfoFunction(page, pageSize, attribute, order);
  //   }
  // };

  warning = () => {
    const {t} = this.props
    this.setState({
      titleNotificationModal: t('modal.sectionUnselected'),
      contenNotificationModal: t('modal.noSectionSelected'),
      typeNotificationModal: "warning",
    });
    this.setState({ openNotificationModal: true });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  // Pagination
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, initialPage: newPage });
    if (this.state.registers.length < this.state.registersAmount) {
      this.showPagination(newPage + 1, this.state.pageSize);
    } else {
      return this.state.registers;
    }
  };

  handleChangeRowsPerPage = (event) => {
    if (event !== undefined) {
      this.setState({
        pageSize: parseInt(event.target.value, 10) + 1,
        page: 0,
      });
    }
  };

  showPagination = (page, pageSize) => {
    const { attribute, order } = this.state;
    this.setState({
      loadingInfo: true,
      page,
      pageSize,
    });
    this.getLandingPageInfoFunction(page, pageSize, attribute, order);
  };

  render() {
    const {
      registers,
      // loadingInfo,
      // page,
      registersAmount,
      pageSize,
      initialPage,

      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,

      helmetPage,
    } = this.state;
    const {t} = this.props
    return (
      <>
        <NotificationModal
          visible={openNotificationModal}
          onClick={this.handleCloseNotificationModal}
          title={titleNotificationModal}
          content={contenNotificationModal}
          type={typeNotificationModal}
        />
        <Helmet>
          {/* <title> helmetPage </title> */}
          <meta
            name="description"
            content="Somos Aura - Conectamos la comunidad católica"
          />
          <meta
            name="image"
            content="https://somosaura.org/wp-content/uploads/2020/07/Aura-Verical-1-295x300.png"
          />
        </Helmet>
        <Card className="card">
          <div className="row componentSpace spaceBreadcrumb">
            <Breadcrumb
              principal={t('generalUser.pages')}
              icon={<Layout />}
              secondary={t('sections.home')}
            />
          </div>
          <div className="row componentSpace">
            <Title title={t('generalUser.pagesList')} />
            <div className="col-12">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('organization.organization')}</TableCell>
                      <TableCell>
                        {t('basicWords.organizationType')}
                      </TableCell>
                      <TableCell>{t('basicWords.phoneContact')}</TableCell>
                      <TableCell>{t('basicWords.webAddress')}</TableCell>
                      <TableCell>{t('basicWords.lptypeId')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registers
                      .slice(
                        initialPage * pageSize,
                        initialPage * pageSize + pageSize
                      )
                      .map((row) => {
                        return (
                          <TableRow key={row.orgName}>
                            <TableCell>{row.orgName}</TableCell>
                            <TableCell>{row.orgType}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell>{row.lpType}</TableCell>
                            <TableCell>{row.url}</TableCell>
                            <TableCell>
                              <div className="containerIconsTable">
                                <div className="bgIconEdit">
                                  <Link
                                    to={{
                                      pathname: "/myWebPage",
                                      props: {
                                        organizationId: row.orgId,
                                        previousPath: "/webPagesList",
                                        organizationName: row.orgName,
                                        lpUrl: row.url,
                                      },
                                    }}
                                  >
                                    <Edit className="iconEdit" />
                                  </Link>
                                </div>
                                <div className="bgIconEye">
                                  <Eye
                                    className="iconEye"
                                    onClick={() => this.getMyWebpage(row.url)}
                                  />
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                disabled={registers.length === 0}
                count={registersAmount}
                rowsPerPage={pageSize}
                page={initialPage}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Card>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
    userSession: state.userSession.userSession,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(WebPagesList));
