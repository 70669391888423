import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/CmsServerInformation';

const resource = "/lpsectioncomponentnode";

export async function updateLpSectionComponentNode(lpSectionComponentNodeEdit) {
   try {
      const data = JSON.stringify(lpSectionComponentNodeEdit)
      let response = await axios({
         method: 'PUT',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}