import React from "react";
import "./css/Breadcrumb.scss";
import { ArrowLeft, Edit2 } from "react-feather";
import { Breadcrumb } from "./index";
import { Link } from "react-router-dom";
import GoToWithoutSaveModal from "../pages/webpages/modalComponents/GoToWithoutSaveModal.jsx";

export function BreadcrumbSection(props) {
  const {
    route,
    propsComponent,
    path,
    goToWithoutSaveModalVisible,
    onCancelGotoWithoutMModal,
    FlagclassName,
    GoToWithoutSave,
  } = props;

  return (
    <>
      <GoToWithoutSaveModal
        visible={goToWithoutSaveModalVisible}
        handleClose={onCancelGotoWithoutMModal}
        propsComponent={propsComponent}
        path={path ? path : "./myWebPage"}
      />
      <div className="row inlineArrowWithBreadcrumb ">
        {FlagclassName === true ? ( // if user changed some in component
          <ArrowLeft
            className="iconArrowLeftSections"
            onClick={GoToWithoutSave}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <Link
            to={{
              pathname: route,
              props: propsComponent,
            }}
          >
            <ArrowLeft className="iconArrowLeftSections" />
          </Link>
        )}
        <Breadcrumb
          principal={
            propsComponent.customName && propsComponent.customName !== undefined
              ? propsComponent.customName
              : props.principal
          }
          secondary={props.secondary}
          className={props.className}
        />
        {propsComponent.customName !== undefined ? (
          <Edit2
            className="iconTitleSection"
            size={18}
            onClick={() =>
              props.handleClickOpenModalToChangeName(propsComponent.customName)
            }
          />
        ) : null}
      </div>
    </>
  );
}
